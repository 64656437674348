export default {
  index: {
    title: 'Service organisations (third party)',
    help: '',
    create: 'Create new',
    see_clients: 'See clients',
    filters: {
      name: 'Name',
    },
  },
  create: {
    title: 'Create service organisation page',
    help: '',
  },
  edit: {
    title: 'Edit service organisation page',
    help: '',
  },
  form: {
    general_info: 'General info',
    contacts: 'Contacts',
    other_information: 'Other information',
  },
  empty: 'No service organizations',
  attributes: {
    name: 'Name',
    type: 'Type',
    main_contact: 'Main contact',
    phone: 'Phone',
    email: 'Email',
    website: 'Webpage',
    shared_client: 'Shared client',
    partner_discount: 'Partner discount',
    responsible_user: 'Our reference',
    general_info: 'General info',
    systems_descriptions: 'System descriptions',
  },
  tooltip: {
    edit: 'Edit {name} service organization',
    unlink: 'This disconnects the service organization and all its contacts from relevant customers',
    destroy:
      'This deletes the service organization and all its contacts. It can only be deleted if it is no longer linked to any customers.',
  },
  confirm: {
    destroy: {
      title: 'Delete service organization',
      text: 'Are you sure you want to delete <strong>{name}</strong> service organization from the system?',
    },
    unlink: {
      title: 'Unlink service organization',
      text: 'Are you sure you want to unlink <strong>{name}</strong> from all customers?',
    },
  },
  templates: {
    title: 'Types & roles',
    help: '',
    add: 'Add',
  },
  type: {
    create: {
      title: 'Create new type',
    },
    edit: {
      title: 'Edit {name} type',
    },
    empty: 'No types',
    attributes: {
      name: 'Name',
    },
    tooltip: {
      create: 'Create new type',
      edit: 'Edit {name} type',
      destroy: 'Delete {name} type',
    },
    confirm: {
      destroy: {
        title: 'Delete type',
        text: 'Are you sure you want to delete <strong>{name}</strong> type from the system?',
      },
    },
  },
  role: {
    create: {
      title: 'Create new role',
    },
    edit: {
      title: 'Edit {name} role',
    },
    empty: 'No roles',
    attributes: {
      name: 'Name',
    },
    tooltip: {
      create: 'Create new role',
      edit: 'Edit {name} role',
      destroy: 'Delete {name} role',
    },
    confirm: {
      destroy: {
        title: 'Delete role',
        text: 'Are you sure you want to delete <strong>{name}</strong> role from the system?',
      },
    },
  },
  contact: {
    create: {
      title: 'Create new contact',
    },
    edit: {
      title: 'Edit {name} contact',
    },
    empty: 'No contacts',
    see_clients: 'See clients',
    attributes: {
      name: 'Name',
      short_name: 'Short name',
      role: 'Role',
      personal_number: 'Personal number',
      email: 'Email',
      phone: 'Phone',
      main_contact: 'Main contact',
      shared_clients: 'Shared clients',
    },
    tooltip: {
      create: 'Create new contact',
      edit: 'Edit {name} contact',
      destroy: 'Delete {name} contact',
    },
    confirm: {
      destroy: {
        title: 'Delete contact',
        text: 'Are you sure you want to delete <strong>{name}</strong> contact from the system?',
      },
    },
  },
};
