<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';
import { AppButton, AppCloseButton, FormLabel, FormTextarea, FormMinutesDuration } from '@/components';
import { ConfirmDialogConfirmParams } from '@/types/Common';
import { ITimeBalanceTransactionRequest } from '@/types/User';
import { useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/yup';
import { number, object, string } from 'yup';
import useLoading from '@/composables/useLoading';

type TransactionType = null | 'deposit' | 'debit';

const emit = defineEmits<{
  closed: [];
  create: [
    type: Exclude<TransactionType, null>,
    values: ITimeBalanceTransactionRequest,
    params: ConfirmDialogConfirmParams,
  ];
}>();

const { t } = useI18n({ useScope: 'global' });
const { loading, setLoading } = useLoading();

const { handleSubmit, defineField, meta, errors } = useForm<ITimeBalanceTransactionRequest & { type: TransactionType }>(
  {
    initialValues: {
      type: null,
      sum: null,
      comment: null,
    },
    validationSchema: toTypedSchema(
      object({
        type: string().required(),
        sum: number().required().positive().integer(),
        comment: string().max(1000).nullable(),
      }),
    ),
  },
);

const submit = handleSubmit((values) => {
  emit('create', type.value!, values, { setLoading });
});

const [type] = defineField('type');
const [sum] = defineField('sum');
const [comment] = defineField('comment');
</script>

<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container"
    :click-to-close="false"
    :esc-to-close="true"
    v-slot="{ close }"
    @closed="emit('closed')"
  >
    <form @submit="submit" @keydown.enter.prevent>
      <div class="modal-header">
        <h2 v-text="t('common.create')" />
        <AppCloseButton @close="close" />
      </div>
      <div class="modal-content">
        <!-- Type -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="type" required>{{ t('user.time_balance.form.type') }}</FormLabel>
            </div>
            <div class="col-md-9">
              <div class="form-wrapper">
                <select
                  id="type"
                  class="form-control"
                  v-model="type"
                  required
                  :class="{ 'is-invalid': !!errors?.type }"
                >
                  <option :value="null" selected disabled hidden v-text="t('common.select')" />
                  <option value="deposit" v-text="t('user.time_balance.type.deposit')" />
                  <option value="debit" v-text="t('user.time_balance.type.debit')" />
                </select>
              </div>
            </div>
          </div>
        </div>
        <!-- Sum -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="sum" required>{{ t('user.time_balance.form.sum') }}</FormLabel>
            </div>
            <div class="col-md-9">
              <FormMinutesDuration id="sum" :max="5965" :step="60" v-model="sum" :invalid="!!errors?.sum" />
            </div>
          </div>
        </div>
        <!-- Comment -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="comment" required>{{ t('user.time_balance.form.comment') }}</FormLabel>
            </div>
            <div class="col-md-9">
              <FormTextarea @keydown.enter.stop v-model="comment" id="comment" :invalid="!!errors?.comment" autosize />
            </div>
          </div>
        </div>
        <slot />
      </div>
      <div class="modal-footer">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
          <AppButton light @click.prevent="close" :disabled="loading">
            {{ t('common.cancel') }}
          </AppButton>
          <AppButton color="secondary" :disabled="!meta.valid" :loading="loading">
            {{ t('common.create') }}
          </AppButton>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>
