import route from 'ziggy-js';
import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import { ICustomDayDurationsListResource, ICustomDayDurationsRequestResource } from '@/types/BusinessDay';

export default {
  list() {
    return httpClient.get(route('customers.custom-day-durations')).json<IResponse<ICustomDayDurationsListResource>>();
  },
  store(json: ICustomDayDurationsRequestResource) {
    return httpClient
      .put(route('customers.custom-day-duration'), { json })
      .json<IResponse<ICustomDayDurationsListResource>>();
  },
};
