<script lang="ts" setup>
const emit = defineEmits(['change']);

defineProps<{
  required?: boolean;
  label?: string;
  groupClass?: string;
  id?: string;
}>();

const model = defineModel<boolean | number[]>();
</script>

<template>
  <div class="form-group" :class="groupClass">
    <input :id="id" v-bind="$attrs" type="checkbox" class="form-switch" v-model="model" @change="emit('change')" />
    <label :for="id" :class="['form-label', { required }]">
      <slot name="label" :text="label" :required="required">
        {{ label || '&nbsp;' }}
      </slot>
    </label>
  </div>
  <slot />
</template>
