export default {
  index: {
    title: 'Holiday calendar',
    help: '',
    create: 'Add holiday',
  },

  create: {
    title: 'Create holiday',
  },
  edit: {
    title: 'Edit holiday',
  },

  type: {
    imported: 'Imported',
    added: 'Added',
  },

  empty: 'No holidays',

  attributes: {
    date: 'Date',
    name: 'Holiday',
    weekday: 'Weekday',
    workday: 'Workday %',
    type: 'Type',
  },

  confirm: {
    destroy: {
      title: 'Delete holiday',
      text: 'Are you sure you want to delete <strong>{name}</strong> holiday from the system?',
    },
  },
};
