<script setup lang="ts">
import useAuthStore from '@/store/AuthStore';

const { authenticatedUser, authenticatedUserInitials } = useAuthStore();
</script>

<template>
  <div class="dropdown-user">
    <div class="avatar is-medium initials" v-text="authenticatedUserInitials" />
    <div class="user-details">
      <div class="text-700" v-text="authenticatedUser?.name" />
      <div class="text-6 text-neutral-500" v-text="authenticatedUser?.email" />
    </div>
  </div>
</template>
