import { defineStore } from 'pinia';
import { reactive, readonly, Ref, ref } from 'vue';

import api from '@/services/api';
import { ClientFilesSettings, IClientFullResource } from '@/types/Client';

const useClientStore = defineStore('ClientStore', () => {
  const client = ref<IClientFullResource>();
  const clientLoading = ref(false);
  const isUserPartOfTeam = ref(false);

  async function fetchClient(uuid: string) {
    const response = await api.clients.get(uuid);
    return {
      data: response.data,
      meta: response.meta,
    };
  }

  function setClient(clientToBeSet: IClientFullResource) {
    client.value = clientToBeSet;
  }

  function setUserPartOfTeam(value: boolean) {
    isUserPartOfTeam.value = value;
  }

  const filesSettings = reactive<ClientFilesSettings>({
    token: '',
    api_base_url: '',
  });

  async function fetchFilesSettings() {
    return await api.weFile.settings.get();
  }

  function setFilesSettings(settings: ClientFilesSettings) {
    filesSettings.token = settings.token;
    filesSettings.api_base_url = settings.api_base_url;
  }

  return {
    // Client
    client: client as Ref<IClientFullResource>,
    fetchClient,
    setClient,
    clientLoading,
    // Files settings
    filesSettings,
    fetchFilesSettings,
    setFilesSettings,
    // User part of team
    setUserPartOfTeam,
    isUserPartOfTeam: readonly(isUserPartOfTeam),
  };
});

export default useClientStore;
