export default {
  enter_your_email: 'Enter your email',
  enter_your_password: 'Enter your password',
  confirm_your_password: 'Confirm your password',

  confirm: {
    logout: {
      title: 'Logout',
      text: 'Are you sure you want to logout from the system?',
    },
  },

  accept_invite: {
    title: 'Accept invite',
  },
};
