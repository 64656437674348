export default {
  enter_your_email: 'Ange din e-postadress',
  enter_your_password: 'Ange ditt lösenord',
  confirm_your_password: 'Bekräfta ditt lösenord',
  forgot_password: 'Glömt ditt lösenord?',

  confirm: {
    logout: {
      title: 'Logga ut',
      text: 'Är du säker på att du vill logga ut från systemet?',
    },
  },

  accept_invite: {
    title: 'Accept invite',
  },
};
