import { computed, ref } from 'vue';
import api from '@/services/api';
import { IClientCategory, IClientPreviewResource } from '@/types/Client';
import { debounce } from 'perfect-debounce';
import { useI18n } from 'vue-i18n';
import useSystems from '@/composables/useSystems';

export default function useClients() {
  const { t } = useI18n({ useScope: 'global' });
  const { isSubscribedToWeFile } = useSystems();

  const clientCategories = ref<IClientCategory[]>([]);

  async function getClientCategories() {
    try {
      const response = await api.clients.categories.list();
      clientCategories.value = response.data;
    } catch (error) {
      console.error(error);
    }
  }

  const clients = ref<IClientPreviewResource[]>([]);

  async function fetchClients(opts?: { search?: string; withoutPagination?: boolean }): Promise<void> {
    try {
      const searchParams = new URLSearchParams();
      if (opts?.search) {
        searchParams.append('search', opts.search);
      }
      if (opts?.withoutPagination) {
        searchParams.append('without_pagination', '1');
      }
      const response = await api.clients.list({ searchParams });
      clients.value = response.data;
    } catch (error) {
      console.error(error);
    }
  }

  const onSearchClients = debounce(async (search: string, loading: (isLoading: boolean) => void) => {
    if (search.length <= 1) return;
    loading(true);
    await fetchClients({ search, withoutPagination: true });
    loading(false);
  }, 300);

  const clientTabs = computed(() => {
    const tabs = [
      { route: 'client.overview', title: t('client.tabs.dashboard'), icon: 'gauge' },
      { route: 'client.projects', title: t('client.tabs.project'), icon: 'briefcase' },
      { route: 'client.planning', title: t('client.tabs.planning'), icon: 'calendar-event' },
      { route: 'client.checkpoints', title: t('client.tabs.checkpoints'), icon: 'flag' },
      { route: 'client.billing', title: t('client.tabs.billing'), icon: 'receipt' },
      { route: 'client.reported_time', title: t('client.tabs.reported_time'), icon: 'report' },
      { route: 'client.expenses', title: t('client.tabs.purchases'), icon: 'wallet' },
      { route: 'client.processes', title: t('client.tabs.processes'), icon: 'progress' },
      { route: 'client.settings', title: t('client.tabs.settings'), icon: 'settings' },
    ];
    if (isSubscribedToWeFile.value) {
      tabs.splice(1, 0, { route: 'client.files', title: t('client.tabs.customer_folders'), icon: 'files' });
    }
    return tabs;
  });

  return { clientCategories, getClientCategories, clients, fetchClients, onSearchClients, clientTabs };
}
