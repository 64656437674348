<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';
import VueSelect from 'vue-select';

import api from '@/services/api';
import { AppButton, AppCloseButton, FormInput, FormLabel } from '@/components';
import { ActivityGroupType, IActivityGroupRequestBody, IActivityGroupResource } from '@/types/Activity';

const { t } = useI18n({ useScope: 'global' });

type Props = {
  activityGroupType: null | ActivityGroupType;
  activityGroup: null | IActivityGroupResource;
};

const { activityGroup, activityGroupType } = defineProps<Props>();

const loading = ref(false);

const form = reactive<IActivityGroupRequestBody>({
  name: null,
  type: null,
});

const emit = defineEmits<{
  cancel: [];
  created: [activityGroup: IActivityGroupResource];
  updated: [activityGroup: IActivityGroupResource];
  closed: [];
}>();

const disabled = computed(() => !(form.name && form.type));

async function submit() {
  try {
    loading.value = true;
    if (activityGroup) {
      const response = await api.activityGroups.update(activityGroup.id, form);
      emit('updated', response.data);
    } else {
      const response = await api.activityGroups.store(form);
      emit('created', response.data);
    }
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
}

onMounted(async () => {
  if (activityGroup) {
    form.name = activityGroup.name;
    form.type = activityGroup.type;
  } else {
    form.type ||= activityGroupType;
  }
});
</script>

<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container"
    :click-to-close="false"
    :esc-to-close="true"
    v-slot="{ close }"
    @closed="emit('closed')"
  >
    <form @submit.prevent="submit">
      <div class="modal-header">
        <h2
          v-text="
            activityGroup
              ? t('activities.groups.edit.title', { name: activityGroup.name })
              : t('activities.groups.create.title')
          "
        />
        <AppCloseButton @close="close" />
      </div>

      <div class="modal-content">
        <!-- Type -->
        <div v-if="!activityGroupType" class="row align-items-center form-group">
          <div class="col-md-3">
            <FormLabel html-for="activity_type" required>{{ t('activities.templates.attributes.type') }}</FormLabel>
          </div>
          <div class="col-md-9">
            <VueSelect
              :clearable="false"
              v-model="form.type"
              input-id="activity_type"
              :placeholder="t('common.select')"
              :options="Object.values(ActivityGroupType)"
              :get-option-label="(option: string) => t(`activities.groups.type.${option}`)"
            >
              <template #search="{ attributes, events }">
                <input class="vs__search" :required="!form.type" v-bind="attributes as object" v-on="events" />
              </template>
            </VueSelect>
          </div>
        </div>
        <!-- Name -->
        <div class="row align-items-center form-group">
          <div class="col-md-3">
            <FormLabel html-for="activity_group_name" required>
              {{ t('activities.groups.attributes.name') }}
            </FormLabel>
          </div>
          <div class="col-md-9">
            <FormInput v-model="form.name" id="activity_group_name" trim required />
          </div>
        </div>
        <slot />
      </div>
      <div class="modal-footer">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
          <AppButton light @click.prevent="close" :disabled="loading">
            {{ t('common.cancel') }}
          </AppButton>
          <AppButton v-if="activityGroup" class="mt-2 mt-sm-0" color="success" :loading="loading" :disabled="disabled">
            {{ t('common.update') }}
          </AppButton>
          <AppButton v-else class="mt-2 mt-sm-0" color="secondary" :loading="loading" :disabled="disabled">
            {{ t('common.create') }}
          </AppButton>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>
