<script setup lang="ts">
import VueDatePicker from '@vuepic/vue-datepicker';
import { useI18n } from 'vue-i18n';
import useDate from '@/composables/useDate';
import { computed } from 'vue';

type Props = {
  id?: string;
  size?: 'small';
  required?: boolean;
  invalid?: boolean;
  placeholder?: string;
  format?: string;
  clearable?: boolean;
  disabled?: boolean;
  minDate?: Date | string;
  maxDate?: Date | string;
  allowManual?: boolean;
};

const { t, locale } = useI18n({ useScope: 'global' });

const { required, placeholder, minDate = '', maxDate = '', allowManual = false } = defineProps<Props>();

const { convertWeekNumberToDateRange, convertDateRangeToWeekNumber } = useDate();

const model = defineModel<null | number>({ required: true });

const modelValue = computed(() => {
  return model.value ? convertWeekNumberToDateRange(model.value) : null;
});

function onModelUpdate(dates: [Date, Date] | null) {
  model.value = dates ? convertDateRangeToWeekNumber(dates) : null;
}
</script>

<template>
  <div class="form-wrapper has-icon d-flex align-items-center">
    <VueDatePicker
      :uid="id"
      :ui="{ input: ['form-control', invalid ? 'is-invalid' : ''] }"
      :model-value="modelValue"
      @update:model-value="onModelUpdate"
      :placeholder="placeholder ?? t('common.select')"
      :enable-time-picker="false"
      :month-change-on-scroll="false"
      :week-num-name="t('common.week_short')"
      format="RR-II"
      week-numbers="iso"
      :clearable="clearable"
      :required="required"
      :locale="locale"
      :min-date="minDate"
      :max-date="maxDate"
      week-picker
      auto-apply
      :text-input="allowManual"
    >
      <template #input-icon><i class="form-icon ti ti-calendar" /></template>
    </VueDatePicker>
  </div>
</template>
