<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';
import VueSelect from 'vue-select';

import { AppButton, FormLabel } from '@/components';

import api from '@/services/api';
import { IServiceCompanyListResource } from '@/types/ServiceCompany';

const { t } = useI18n({ useScope: 'global' });

const props = defineProps<{
  loading: boolean;
  exclude: string[];
}>();

const serviceCompany = ref('');

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'add', serviceCompany: string): void;
}>();

const serviceCompanies = ref<IServiceCompanyListResource[]>([]);
const serviceCompaniesLoading = ref(false);

const disabled = computed(() => serviceCompany.value === '');

function submit() {
  emit('add', serviceCompany.value);
}

async function getServiceCompanies() {
  try {
    serviceCompaniesLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.serviceCompanies.list({ searchParams });
    serviceCompanies.value = response.data.filter(({ uuid }) => !props.exclude.includes(uuid));
  } catch (error) {
    console.error(error);
  } finally {
    serviceCompaniesLoading.value = false;
  }
}

onMounted(async () => {
  await getServiceCompanies();
  serviceCompany.value = '';
});
</script>

<template>
  <VueFinalModal class="modal-overlay" content-class="modal-container" :click-to-close="false" :esc-to-close="true">
    <form @submit.prevent="submit">
      <div class="modal-header">
        <h2 v-text="t('client.service_company.add.title')" />
      </div>
      <div class="modal-content">
        <!-- Manager -->
        <div class="form-group">
          <div class="row align-items-center mb-5">
            <div class="col-md-3">
              <FormLabel html-for="service_company" required>
                {{ t('client.service_company.add.service_company') }}
              </FormLabel>
            </div>
            <div class="col-md-9">
              <VueSelect
                :clearable="false"
                v-model="serviceCompany"
                :options="serviceCompanies"
                :reduce="(option) => option.uuid"
                label="name"
                input-id="service_company"
                :placeholder="t('common.select')"
                required
                :loading="serviceCompaniesLoading"
                :disabled="serviceCompaniesLoading"
              >
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!serviceCompany" v-bind="attributes" v-on="events" />
                </template>
              </VueSelect>
            </div>
          </div>
        </div>
        <slot />
      </div>
      <div class="modal-footer">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
          <AppButton light @click.prevent="emit('cancel')" :disabled="loading">
            {{ t('common.cancel') }}
          </AppButton>
          <AppButton class="mt-2 mt-sm-0" color="secondary" :loading="loading" :disabled="disabled">
            {{ t('common.add') }}
          </AppButton>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>
