<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';
import VueSelect from 'vue-select';
import { AppButton, AppCloseButton, FormLabel } from '@/components';
import useClients from '@/composables/useClients';
import { IClientPreviewResource } from '@/types/Client';
import { ConfirmDialogConfirmParams } from '@/types/Common';
import useLoading from '@/composables/useLoading';

const emit = defineEmits<{
  closed: [];
  create: [clientUuid: string, params: ConfirmDialogConfirmParams];
}>();

const { t } = useI18n({ useScope: 'global' });
const { clients, onSearchClients } = useClients();
const { loading, setLoading } = useLoading();

const clientUuid = ref<string>('');

function submit() {
  if (clientUuid.value) {
    emit('create', clientUuid.value, { setLoading });
  }
}
</script>

<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container"
    :click-to-close="false"
    :esc-to-close="false"
    content-style="overflow: visible;"
    @closed="emit('closed')"
    v-slot="{ close }"
  >
    <form @submit.prevent="submit">
      <div class="modal-header">
        <h2>{{ t('invoice.create_separate_invoice.title') }}</h2>
        <AppCloseButton @close="close" />
      </div>
      <div class="modal-content">
        <div class="form-group">
          <FormLabel html-for="client_uuid" required>
            {{ t('project.attributes.client') }}
          </FormLabel>
          <VueSelect
            :filterable="false"
            @search="onSearchClients"
            v-model="clientUuid"
            :reduce="(option: IClientPreviewResource) => option.uuid"
            :options="clients"
            label="name"
            input-id="client_uuid"
            :placeholder="t('common.search')"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" :required="!clientUuid" v-bind="attributes as object" v-on="events" />
            </template>
            <template #no-options>{{ t('common.type_to_search') }}</template>
          </VueSelect>
        </div>
      </div>
      <div class="modal-footer">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
          <AppButton light @click.prevent="close" :disabled="loading">
            {{ t('common.cancel') }}
          </AppButton>
          <AppButton class="mt-2 mt-sm-0" color="secondary" :loading="loading" :disabled="!clientUuid">
            {{ t('common.create') }}
          </AppButton>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>
