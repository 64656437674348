export default {
  index: {
    mapped_services_title: 'Mappade tjänster',
    not_mapped_services_title: 'Ej mappade tjänster',
    link_services: 'Länka tjänster',
    mapped_services_help: '',
    not_mapped_services_help: '',
    table: {
      services_in_planner: 'Tjänster i detta system',
      services_in_crm: 'Tjänster i CRM',
      comment: 'Kommentar',
    },
  },
  create: {
    title: 'Länka tjänster',
  },
  edit: {
    title: 'Redigera länkade tjänster',
  },
  mapping_comment_modal: {
    title: 'Uppdatera',
    note: 'Kommentar',
  },
  form: {
    planner_services: 'Tjänster i detta system',
    crm_services: 'WeCRM tjänster',
    internal_note: 'Notering',
  },

  // Confirms
  confirm: {
    destroy: {
      title: 'Ta bort länkade tjänster',
      text: 'Är du säker på att du vill radera den länkade tjänsten?',
    },
  },
};
