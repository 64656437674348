<script lang="ts" setup>
import { ITodoResource, TodoType } from '@/types/Todo';
import { useI18n } from 'vue-i18n';
import { AppButton, FontIcon, TodoTypeBadge, UserTodoComments } from '@/components';
import { computed, ref } from 'vue';
import api from '@/services/api';
import useAuthStore from '@/store/AuthStore';
import { DateTime } from 'luxon';
import useLoadingButton from '@/composables/useLoadingButton';
import useTodos from '@/composables/useTodos';

const { todo } = defineProps<{
  todo: ITodoResource;
  userUuid: string;
}>();

const emit = defineEmits<{
  edit: [];
  delete: [];
  reassign: [];
  updated: [];
}>();

const { t, d } = useI18n({ useScope: 'global' });
const { authenticatedUser } = useAuthStore();
const { hiddenTodos, isHiddenTodo } = useTodos();
const showCommentForm = ref(false);

async function onRead(event: PointerEvent) {
  const { setLoading } = useLoadingButton(event);
  setLoading(true);
  try {
    await api.todos.markAsRead(todo.id);
    emit('updated');
  } catch (error) {
    console.error(error);
  } finally {
    setLoading(false);
  }
}

async function onComplete(event: PointerEvent) {
  const { setLoading } = useLoadingButton(event);
  setLoading(true);
  try {
    await api.todos.close(todo.id);
    emit('updated');
  } catch (error) {
    console.error(error);
  } finally {
    setLoading(false);
  }
}
function onHide() {
  if (isHiddenTodo(todo.id)) {
    hiddenTodos.value = hiddenTodos.value.map((item) => ({
      id: item.id,
      comments_count: item.id === todo.id ? todo.comments.length : item.comments_count,
    }));
  } else {
    hiddenTodos.value = [...hiddenTodos.value, { id: todo.id, comments_count: todo.comments.length }];
  }
}

const deadlineClass = computed(() => {
  if (!todo.deadline) return '';
  if (DateTime.fromISO(todo.deadline) <= DateTime.now()) {
    return 'text-danger-500 font-bold';
  }
  if (DateTime.fromISO(todo.deadline).weekNumber === DateTime.now().weekNumber) {
    return 'text-warning-500 font-semibold';
  }
  return 'text-neutral-500 font-semibold';
});
</script>

<template>
  <div class="todo">
    <div class="d-flex align-items-start justify-content-between">
      <div class="flex-grow-1">
        <strong>{{ todo.authorUser.name }}</strong>
        <FontIcon name="chevron-right" class="text-neutral-400" />
        <RouterLink
          v-if="todo.client"
          target="_blank"
          :to="{ name: 'client.overview', params: { uuid: todo.client.uuid } }"
        >
          <span v-if="todo.recepientUser" v-text="todo.receiptantName" />
          <span v-else v-text="todo.client.name" />
        </RouterLink>
        <template v-else>
          <span v-if="todo.recepientUser" v-text="todo.recepientUser.name" />
          <span v-else v-text="t('common.all')" />
        </template>
        <AppButton
          v-if="todo.recepientUserUuid === authenticatedUser.uuid"
          @click.prevent="emit('reassign')"
          size="small"
          light
          circle
          v-tooltip.bottom="t('common.reassign')"
          class="ml-2"
        >
          <FontIcon name="user-share" />
        </AppButton>
      </div>
      <div class="flex-shrink-0">
        <TodoTypeBadge :type="todo.type" />
        <span class="text-5 font-semibold ml-2" v-text="d(todo.createdAt)" />
      </div>
    </div>
    <div class="mt-3">
      <div v-if="todo.text" class="text-neutral-600">
        {{ todo.text }}
      </div>
      <div class="mt-2" :class="deadlineClass" v-if="todo.deadline">
        {{ t('todo.attributes.deadline') }}: {{ d(todo.deadline) }}
      </div>
    </div>

    <div class="d-flex mt-3 gap-2">
      <template v-if="userUuid === authenticatedUser.uuid">
        <!-- Complete button -->
        <AppButton v-if="TodoType.Todo === todo.type" @click.prevent="onComplete" size="small" color="secondary">
          <FontIcon name="check" />
          {{ t('user.todos.tooltip.complete') }}
        </AppButton>
        <!-- Answered button -->
        <AppButton v-if="TodoType.Message === todo.type" @click.prevent="onComplete" size="small" color="secondary">
          <FontIcon name="check" />
          {{ t('user.todos.tooltip.answered') }}
        </AppButton>
        <!-- Hide for me button -->
        <AppButton v-if="todo.type === TodoType.Info" @click.prevent="onRead" size="small" color="secondary">
          <FontIcon name="eye-off" />
          {{ t('user.todos.tooltip.hide') }}
        </AppButton>
      </template>
      <!-- Comment button -->
      <AppButton @click.prevent="showCommentForm = !showCommentForm" size="small" light>
        <template v-if="todo.type === TodoType.Message && todo.recepientUserUuid === authenticatedUser.uuid">
          <FontIcon name="arrow-back-up" />
          {{ t('user.todos.tooltip.answer') }}
        </template>
        <template v-else>
          <FontIcon name="message-plus" />
          {{ t('user.todos.tooltip.comment') }}
        </template>
      </AppButton>
      <template v-if="userUuid === authenticatedUser.uuid">
        <!-- Hide button -->
        <AppButton
          v-if="[TodoType.Todo, TodoType.Message].includes(todo.type)"
          @click.prevent="onHide"
          v-tooltip.bottom="t('user.todos.tooltip.hide')"
          size="small"
          light
          circle
          :disabled="todo.deadline && DateTime.fromISO(todo.deadline) <= DateTime.now()"
        >
          <FontIcon name="eye-off" />
        </AppButton>
      </template>
      <template v-if="authenticatedUser.uuid === todo.createdByUserUuid">
        <!-- Edit button -->
        <AppButton @click.prevent="emit('edit')" size="small" v-tooltip.bottom="t('common.edit')" light circle>
          <FontIcon name="pencil" />
        </AppButton>
        <!-- Delete button -->
        <AppButton
          @click.prevent="emit('delete')"
          size="small"
          v-tooltip.bottom="t('common.delete')"
          light
          circle
          color="danger"
        >
          <FontIcon name="trash" />
        </AppButton>
      </template>
    </div>
    <UserTodoComments
      v-model:form-visible="showCommentForm"
      :class="{ 'mt-3': todo.comments.length > 0 || showCommentForm }"
      :comments="todo.comments.toReversed()"
      :todo-id="todo.id"
      :hide-form-after-send-comment="true"
      @commented="emit('updated')"
    />
  </div>
</template>
