<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n({ useScope: 'global' });

defineProps<{
  text?: string;
}>();
</script>

<template>
  <i class="text-neutral-300" v-text="text ?? t('common.empty')" />
</template>
