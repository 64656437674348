import route from 'ziggy-js';
import { Options } from 'ky';
import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import { IOfficeResource, IOfficeListResource, IOfficeRequestBody } from '@/types/Office';

export default {
  async list(options?: Options): Promise<IResponse<IOfficeListResource[]>> {
    return await httpClient.get(route('offices.list'), options).json();
  },
  async get(id: number): Promise<IResponse<IOfficeResource>> {
    return await httpClient.get(route('offices.get', { id })).json();
  },
  async store(body: IOfficeRequestBody): Promise<IResponse<IOfficeResource>> {
    return await httpClient.post(route('offices.store'), { json: body }).json();
  },
  async update(id: number, body: IOfficeRequestBody): Promise<IResponse<IOfficeResource>> {
    return await httpClient.patch(route('offices.update', { id }), { json: body }).json();
  },
  async destroy(id: number): Promise<IResponse<never>> {
    return await httpClient.delete(route('offices.delete', { id })).json();
  },
};
