import { createPinia } from 'pinia';
import router from '@/router';
import i18n from '@/i18n';

const pinia = createPinia();

pinia.use(({ store }) => {
  store.$onAction((action) => {
    if (action.store.$id === 'AuthStore') {
      if (action.name === 'unsetAuthenticatedUser') {
        action.after(async () => {
          await router.push({ name: 'login' });
        });
      }
    }
    if (action.store.$id === 'AuthStore') {
      if (action.name === 'setAuthenticatedUser') {
        action.after(async () => {
          await router.push({ name: 'dashboard' });
        });
      }
    }
    if (action.store.$id === 'LocaleStore') {
      if (action.name === 'setLocale') {
        action.after(() => {
          // @ts-ignore
          i18n.global.locale.value = action.args[0];
        });
      }
    }
  });
});

export default pinia;
