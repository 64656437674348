export default {
  role: {
    responsible: 'Projektansvarig',
    team_member: 'Team-medlem',
  },

  frequency: {
    once: 'Engång',
    weekly: 'Vecka',
    monthly: 'Månad',
    quarterly: 'Kvartal',
    'semi-annually': 'Halvår',
    annually: 'År',
  },

  // Tooltips
  tooltip: {
    edit: 'Redigera "{name}"',
    destroy: 'Radera "{name}"',
    restore: 'Återställ "{name}"',
  },

  statuses: {
    all: 'Alla',
    done: 'Klar',
    not_done: 'Ej klar',
  },

  // Confirms
  confirm: {
    destroy: {
      title: 'Radera uppgift',
      text: 'Är du säker på att du vill radera <strong>{name}</strong>?',
      with_events: 'Radera även alla enskilda moment.',
    },
  },
};
