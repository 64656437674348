export default {
  index: {
    title: 'Bokföring',
    help: '',
    tabs: {
      articles: 'Artiklar',
      invoice_items: 'Fakturarader',
    },
  },
  articles: {
    title: 'Artiklar',
    help: '',
    fetch_articles: 'Hämta artiklar',
    attributes: {
      number: 'Nummer',
      name_en: 'Name (ENG)',
      name_sv: 'Namn (SWE)',
      unit: 'Enhet',
      status: 'Status',
    },

    status: {
      enabled: 'Aktiverad',
      disabled: 'Inaktiverad',
    },
  },
  invoice_items: {
    title: 'Fakturarad',
    help: '',
  },
  attributes: {
    activity_type: 'Interntidstyo',
    activity_group: 'Interntidskategori',
    activity_template: 'Interntidskod',
    assigned: 'Tilldelad',
    comment: 'Kommentar',
    start_date: 'Start-datum',
    end_date: 'Slut-datum',
  },
  empty: 'Inga uppgifter',
  types: {
    index: {
      title: 'Typer',
      create: 'Skapa intenrtidskod',
    },
    create: {
      title: 'Skapa interntidskategori',
    },
    edit: {
      title: 'Redigera interntidsstyp: {name}',
    },
    empty: 'Inga interntidstyper',
    attributes: {
      name: 'Namn',
      groups_count: 'Antal',
    },
    tooltip: {
      edit: 'Redigera interntidstyp "{name}"',
      destroy: 'Radera interntidstyp "{name}"',
    },

    confirm: {
      destroy: {
        title: 'Radera interntidstyp',
        text: 'Är du säker på att du vill radera interntidstyp <strong>{name}</strong> och dess aktivitetsgrupper?',
      },
    },
  },
  groups: {
    index: {
      title: 'Aktivitetskategorier',
      create: 'Skapa aktivitetskategori',
    },
    create: {
      title: 'Skapa aktivitetskategori',
    },
    edit: {
      title: 'Redigera aktivitetskategori: {name}',
    },
    empty: 'Ingen aktivitetskategori',
    attributes: {
      name: 'Namn',
      type: 'Typ',
      templates_count: 'Antal',
    },
    tooltip: {
      edit: 'Redigera aktivitetskategori "{name}"',
      destroy: 'Radera aktivitetskategori "{name}"',
    },

    confirm: {
      destroy: {
        title: 'Radera aktivitetskategori "{name}',
        text: 'Är du söker på att du vill radera aktivitetskategori <strong>{name}</strong>?',
      },
    },
  },
  templates: {
    index: {
      title: 'Interntidskod',
      create: 'Skapa interntidskod',
    },
    create: {
      title: 'Skapa interntidskod',
    },
    edit: {
      title: 'Redigera interntidskod: {name}',
    },

    empty: 'Ingen interntidskod',

    attributes: {
      name: 'Namn',
      type: 'Typ',
      group: 'Gruppnamn',
      duration_rule: 'Regel',
      meta_input: 'Ytterligare uppgifter',
    },

    tooltip: {
      edit: 'Redigera interntidskod "{name}"',
      destroy: 'Radera interntidskod "{name}',
    },

    confirm: {
      destroy: {
        title: 'Radera interntidskod',
        text: 'Är du säker på att du vill radera interntidskod <strong>{name}</strong>?',
      },
    },

    duration_rule: {
      none: 'Inga tidsbegräsningar - Standard',
      available_all_day: 'Max en arbetsdag - Interntid',
      available_time_percentage_range: 'Endast specificerad andel av dag - Frånvaro',
      only_all_day: 'Endast heldag - Semester',
    },
  },
  meta_input: {
    attributes: {
      label: 'Rubrik',
      is_required: 'Krävs',
      type: 'Typ',
    },
    type: {
      input_text: 'Inmatningstext',
    },
    tooltip: {
      destroy: 'Radera extra info "{name}"',
    },
    confirm: {
      destroy: {
        title: 'Radera extra info "{name}',
        text: 'Är du säker på att du vill radara extra info <strong>{name}</strong>?',
      },
    },
  },
};
