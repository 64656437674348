<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.74229 0H28.2577C30.3262 0 32 1.67383 32 3.74229V28.2577C32 30.3262 30.3262 32 28.2577 32H3.74229C1.67383 32 0 30.3262 0 28.2577V3.74229C0 1.67383 1.67383 0 3.74229 0Z"
      fill="#F42487"
    />
    <path
      d="M7.93347 27.312C7.08976 26.7064 6.89244 25.5361 7.4912 24.6923L21.5078 5.10996C22.1134 4.26624 23.2837 4.07572 24.1274 4.68129C24.9711 5.28687 25.1684 6.45718 24.5697 7.3009L10.5531 26.8833C9.95431 27.727 8.77719 27.9175 7.93347 27.312Z"
      fill="#FEFFFF"
    />
    <path
      d="M13.7716 25.7878C13.7716 24.7467 14.6153 23.903 15.6564 23.903H24.6787C25.7197 23.903 26.5634 24.7467 26.5634 25.7878C26.5634 26.8288 25.7197 27.6725 24.6787 27.6725H15.6564C14.6153 27.6657 13.7716 26.822 13.7716 25.7878Z"
      fill="#FEFFFF"
    />
    <path d="M17.3506 19.5824L23.3791 27.1419L25.8762 24.2297L20.045 16.0715L17.3506 19.5824Z" fill="#FEFFFF" />
    <path
      d="M18.2282 6.21789C18.2282 7.25893 17.3845 8.10264 16.3435 8.10264H7.32116C6.28012 8.10264 5.4364 7.25893 5.4364 6.21789C5.4364 5.17685 6.28012 4.33313 7.32116 4.33313H16.3435C17.3845 4.33993 18.2282 5.17685 18.2282 6.21789Z"
      fill="#FEFFFF"
    />
    <path d="M14.6423 12.4177L8.62063 4.85822L6.1167 7.77721L11.9547 15.9286L14.6423 12.4177Z" fill="#FEFFFF" />
    <path
      d="M25.7742 27.312C26.6179 26.7064 26.8152 25.5293 26.2096 24.6856L20.045 16.0715L23.1477 26.8765C23.7533 27.727 24.9304 27.9176 25.7742 27.312Z"
      fill="#FEFFFF"
    />
    <path
      d="M6.22588 4.68782C5.38217 5.29339 5.18485 6.47051 5.79042 7.31422L11.955 15.9283L8.85229 5.12328C8.24672 4.27957 7.0696 4.08225 6.22588 4.68782Z"
      fill="#FEFFFF"
    />
  </svg>
</template>
