import { defineStore } from 'pinia';
import { useLocalStorage } from '@vueuse/core';

const useSidebarStore = defineStore('SidebarStore', () => {
  const expanded = useLocalStorage<boolean>('sidebar', true);

  function toggle(): void {
    expanded.value = !expanded.value;
  }

  function open(): void {
    expanded.value = true;
  }
  function close(): void {
    expanded.value = false;
  }

  return { expanded, toggle, open, close };
});

export default useSidebarStore;
