<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import VueSelect from 'vue-select';
import { useModal } from 'vue-final-modal';
import { onBeforeRouteLeave } from 'vue-router';

import api from '@/services/api';
import toast from '@/services/toast';
import {
  AppBox,
  AppBoxBody,
  AppButton,
  AppLoader,
  ClientsItSystemModal,
  ConfirmModal,
  FontIcon,
  FormLabel,
  FormSwitch,
  FormTextarea,
  ProcessesFormItem,
} from '@/components';
import useLoader from '@/composables/useLoader';
import useClientSettings from '@/composables/client/useClientSettings';
import useTrackChanges from '@/composables/useTrackChanges';
import { IClientItSystemResource, IClientProcesses } from '@/types/Client';

type Props = { uuid: string };
const { uuid } = defineProps<Props>();

const form = reactive<IClientProcesses>({
  general_process_information: {
    description: '',
  },
  general_it_information: {
    description: '',
  },
  it_access_and_permissions: {
    description: '',
  },
  accounting_and_general_ledger: {
    enabled: false,
    framework: null,
    deadline: null,
    system: [],
    we_do: false,
    description: '',
  },
  salary_fringe_benefits_and_tax: {
    enabled: false,
    system: [],
    we_do: false,
    description: '',
  },
  supplier_invoices: {
    enabled: false,
    system: [],
    we_do: false,
    description: '',
  },
  receipts_and_travel_expense_reports: {
    enabled: false,
    system: [],
    we_do: false,
    description: '',
  },
  customer_invoices_and_collection: {
    enabled: true,
    system: [],
    we_do: false,
    description: '',
  },
  project_reporting_and_work_in_progress: {
    enabled: false,
    system: [],
    we_do: false,
    description: '',
  },
  accruals_and_prepayments: {
    enabled: false,
    system: [],
    we_do: false,
    description: '',
  },
  bank_and_payments: {
    enabled: false,
    system: [],
    we_do: false,
    description: '',
  },
  loans_and_other_credits: {
    enabled: false,
    system: [],
    we_do: false,
    description: '',
  },
  cash_register_and_daily_sales: {
    enabled: false,
    system: [],
    we_do: false,
    description: '',
  },
  inventory: {
    enabled: false,
    system: [],
    we_do: false,
    description: '',
  },
  real_estate: {
    enabled: false,
    system: [],
    we_do: false,
    description: '',
  },
  fixed_assets: {
    enabled: false,
    system: [],
    we_do: false,
    description: '',
  },
  activates_internally_developed_assets: {
    enabled: false,
    system: [],
    we_do: false,
    description: '',
  },
  securities_trading: {
    enabled: false,
    system: [],
    we_do: false,
    description: '',
  },
  suspense_account_and_missing_documents: {
    enabled: false,
    system: [],
    we_do: false,
    description: '',
  },
  annual_closing_and_financial_statement: {
    enabled: false,
    system: [],
    we_do: false,
    description: '',
  },
  income_tax_return: {
    enabled: false,
    system: [],
    we_do: false,
    description: '',
  },
  vat: {
    enabled: false,
    system: [],
    we_do: false,
    description: '',
    reporting_deadline: null,
    reporting_frequency: null,
    periodic_summary: false,
    deadline: null,
  },
  management_reporting: {
    enabled: false,
    system: [],
    we_do: false,
    description: '',
  },
  archiving_and_backup: {
    enabled: false,
    system: [],
    we_do: false,
    description: '',
  },
});
const tracker = useTrackChanges(form);

const loader = useLoader({ useProgress: false });
const submitLoader = useLoader();
const i18n = useI18n({ useScope: 'global' });
const { t } = i18n;
const { reportingFrequencyOptions, getVatReportingDeadlines } = useClientSettings();

const itSystems = ref<IClientItSystemResource[]>([]);
const itSystemsLoading = ref(false);

async function getItSystems() {
  itSystemsLoading.value = true;
  try {
    const response = await api.clients.itSystems.index();
    itSystems.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    itSystemsLoading.value = false;
  }
}

async function getClientProcesses() {
  try {
    const { data } = await api.clients.processes.get(uuid);
    form.general_process_information = {
      description: data.general_process_information.description ?? '',
    };
    form.general_it_information = {
      description: data.general_it_information.description ?? '',
    };
    form.it_access_and_permissions = {
      description: data.it_access_and_permissions.description ?? '',
    };
    form.accounting_and_general_ledger = {
      ...form.accounting_and_general_ledger,
      ...data.accounting_and_general_ledger,
      description: data.accounting_and_general_ledger.description ?? '',
    };
    form.salary_fringe_benefits_and_tax = {
      ...form.salary_fringe_benefits_and_tax,
      ...data.salary_fringe_benefits_and_tax,
      description: data.salary_fringe_benefits_and_tax.description ?? '',
    };
    form.supplier_invoices = {
      ...form.supplier_invoices,
      ...data.supplier_invoices,
      description: data.supplier_invoices.description ?? '',
    };
    form.receipts_and_travel_expense_reports = {
      ...form.receipts_and_travel_expense_reports,
      ...data.receipts_and_travel_expense_reports,
      description: data.receipts_and_travel_expense_reports.description ?? '',
    };
    form.customer_invoices_and_collection = {
      ...form.customer_invoices_and_collection,
      ...data.customer_invoices_and_collection,
      description: data.customer_invoices_and_collection.description ?? '',
    };
    form.project_reporting_and_work_in_progress = {
      ...form.project_reporting_and_work_in_progress,
      ...data.project_reporting_and_work_in_progress,
      description: data.project_reporting_and_work_in_progress.description ?? '',
    };
    form.accruals_and_prepayments = {
      ...form.accruals_and_prepayments,
      ...data.accruals_and_prepayments,
      description: data.accruals_and_prepayments.description ?? '',
    };
    form.bank_and_payments = {
      ...form.bank_and_payments,
      ...data.bank_and_payments,
      description: data.bank_and_payments.description ?? '',
    };
    form.loans_and_other_credits = {
      ...form.loans_and_other_credits,
      ...data.loans_and_other_credits,
      description: data.loans_and_other_credits.description ?? '',
    };
    form.cash_register_and_daily_sales = {
      ...form.cash_register_and_daily_sales,
      ...data.cash_register_and_daily_sales,
      description: data.cash_register_and_daily_sales.description ?? '',
    };
    form.inventory = {
      ...form.inventory,
      ...data.inventory,
      description: data.inventory.description ?? '',
    };
    form.real_estate = {
      ...form.real_estate,
      ...data.real_estate,
      description: data.real_estate.description ?? '',
    };
    form.fixed_assets = {
      ...form.fixed_assets,
      ...data.fixed_assets,
      description: data.fixed_assets.description ?? '',
    };
    form.activates_internally_developed_assets = {
      ...form.activates_internally_developed_assets,
      ...data.activates_internally_developed_assets,
      description: data.activates_internally_developed_assets.description ?? '',
    };
    form.securities_trading = {
      ...form.securities_trading,
      ...data.securities_trading,
      description: data.securities_trading.description ?? '',
    };
    form.suspense_account_and_missing_documents = {
      ...form.suspense_account_and_missing_documents,
      ...data.suspense_account_and_missing_documents,
      description: data.suspense_account_and_missing_documents.description ?? '',
    };
    form.annual_closing_and_financial_statement = {
      ...form.annual_closing_and_financial_statement,
      ...data.annual_closing_and_financial_statement,
      description: data.annual_closing_and_financial_statement.description ?? '',
    };
    form.income_tax_return = {
      ...form.income_tax_return,
      ...data.income_tax_return,
      description: data.income_tax_return.description ?? '',
    };
    form.vat = {
      ...form.vat,
      ...data.vat,
      description: data.vat.description ?? '',
    };
    form.management_reporting = {
      ...form.management_reporting,
      ...data.management_reporting,
      description: data.management_reporting.description ?? '',
    };
    form.archiving_and_backup = {
      ...form.archiving_and_backup,
      ...data.archiving_and_backup,
      description: data.archiving_and_backup.description ?? '',
    };
  } catch (error) {
    console.error(error);
  }
}

function onSystemCreated(itSystem: IClientItSystemResource) {
  itSystems.value = [...itSystems.value, itSystem];
}

function onCreateItSystem(systems: number[]) {
  const { open, close, destroy } = useModal({
    component: ClientsItSystemModal,
    attrs: {
      onCreated(itSystem: IClientItSystemResource) {
        itSystems.value = [...itSystems.value, itSystem];
        systems.push(itSystem.id);
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

async function submit() {
  try {
    submitLoader.start();
    await api.clients.processes.update(uuid, form);
    toast.success(t('common.updated'));
    tracker.commit();
  } catch (error) {
    console.error(error);
  } finally {
    submitLoader.finish();
  }
}

onMounted(async () => {
  loader.start();
  await getItSystems();
  await getClientProcesses();
  tracker.commit();
  loader.finish();
});

onBeforeRouteLeave((_to, _from, next) => {
  if (tracker.isModified.value) {
    const { open, close, destroy } = useModal({
      component: ConfirmModal,
      attrs: {
        title: t('common.confirms.unsaved.title'),
        message: t('common.confirms.unsaved.text'),
        onConfirm() {
          next();
          close();
        },
        onCancel() {
          next(false);
          close();
        },
        onClosed() {
          destroy();
        },
      },
    });
    open();
  } else {
    next();
  }
});
</script>

<template>
  <form @submit.prevent="submit">
    <AppBox shadow v-if="loader.isLoading.value" class="text-center py-3">
      <AppLoader />
    </AppBox>
    <AppBox shadow v-else>
      <AppBoxBody>
        <h2 class="mt-4">{{ t('client.processes.general_process_information') }}</h2>
        <div class="form-group">
          <FormLabel html-for="general_process_information-description">
            {{ t('client.processes.description') }}
          </FormLabel>
          <FormTextarea
            id="general_process_information-description"
            class="form-control"
            size="large"
            v-model="form.general_process_information.description"
          />
        </div>

        <h2>{{ t('client.processes.general_it_information') }}</h2>
        <div class="form-group">
          <FormLabel html-for="general_it_information-description">{{ t('client.processes.description') }}</FormLabel>
          <FormTextarea
            id="general_it_information-description"
            class="form-control"
            size="large"
            v-model="form.general_it_information.description"
          />
        </div>

        <h2>{{ t('client.processes.it_access_and_permissions') }}</h2>
        <div class="form-group">
          <FormLabel html-for="it_access_and_permissions-description">
            {{ t('client.processes.description') }}
          </FormLabel>
          <FormTextarea
            id="it_access_and_permissions-description"
            class="form-control"
            size="large"
            v-model="form.it_access_and_permissions.description"
          />
        </div>

        <h2>{{ t('client.processes.process_descriptions') }}</h2>
      </AppBoxBody>
      <ProcessesFormItem
        id="accounting_and_general_ledger"
        :title="t('client.processes.accounting_and_general_ledger')"
        v-model:enabled="form.accounting_and_general_ledger.enabled"
        v-model:framework="form.accounting_and_general_ledger.framework"
        v-model:deadline="form.accounting_and_general_ledger.deadline"
        v-model:systems="form.accounting_and_general_ledger.system"
        v-model:we_do="form.accounting_and_general_ledger.we_do"
        v-model:description="form.accounting_and_general_ledger.description"
        :show_framework_field="true"
        :it-systems="itSystems"
        :it-systems-loading="itSystemsLoading"
        @system-created="onSystemCreated"
      />
      <ProcessesFormItem
        id="salary_fringe_benefits_and_tax"
        :title="t('client.processes.salary_fringe_benefits_and_tax')"
        v-model:enabled="form.salary_fringe_benefits_and_tax.enabled"
        v-model:systems="form.salary_fringe_benefits_and_tax.system"
        v-model:we_do="form.salary_fringe_benefits_and_tax.we_do"
        v-model:description="form.salary_fringe_benefits_and_tax.description"
        :it-systems="itSystems"
        :it-systems-loading="itSystemsLoading"
        @system-created="onSystemCreated"
      />
      <ProcessesFormItem
        id="supplier_invoices"
        :title="t('client.processes.supplier_invoices')"
        v-model:enabled="form.supplier_invoices.enabled"
        v-model:systems="form.supplier_invoices.system"
        v-model:we_do="form.supplier_invoices.we_do"
        v-model:description="form.supplier_invoices.description"
        :it-systems="itSystems"
        :it-systems-loading="itSystemsLoading"
        @system-created="onSystemCreated"
      />
      <ProcessesFormItem
        id="receipts_and_travel_expense_reports"
        :title="t('client.processes.receipts_and_travel_expense_reports')"
        v-model:enabled="form.receipts_and_travel_expense_reports.enabled"
        v-model:systems="form.receipts_and_travel_expense_reports.system"
        v-model:we_do="form.receipts_and_travel_expense_reports.we_do"
        v-model:description="form.receipts_and_travel_expense_reports.description"
        :it-systems="itSystems"
        :it-systems-loading="itSystemsLoading"
        @system-created="onSystemCreated"
      />
      <ProcessesFormItem
        id="customer_invoices_and_collection"
        :title="t('client.processes.customer_invoices_and_collection')"
        v-model:enabled="form.customer_invoices_and_collection.enabled"
        v-model:systems="form.customer_invoices_and_collection.system"
        v-model:we_do="form.customer_invoices_and_collection.we_do"
        v-model:description="form.customer_invoices_and_collection.description"
        :it-systems="itSystems"
        :it-systems-loading="itSystemsLoading"
        @system-created="onSystemCreated"
      />
      <ProcessesFormItem
        id="project_reporting_and_work_in_progress"
        :title="t('client.processes.project_reporting_and_work_in_progress')"
        v-model:enabled="form.project_reporting_and_work_in_progress.enabled"
        v-model:systems="form.project_reporting_and_work_in_progress.system"
        v-model:we_do="form.project_reporting_and_work_in_progress.we_do"
        v-model:description="form.project_reporting_and_work_in_progress.description"
        :it-systems="itSystems"
        :it-systems-loading="itSystemsLoading"
        @system-created="onSystemCreated"
      />
      <ProcessesFormItem
        id="accruals_and_prepayments"
        :title="t('client.processes.accruals_and_prepayments')"
        v-model:enabled="form.accruals_and_prepayments.enabled"
        v-model:systems="form.accruals_and_prepayments.system"
        v-model:we_do="form.accruals_and_prepayments.we_do"
        v-model:description="form.accruals_and_prepayments.description"
        :it-systems="itSystems"
        :it-systems-loading="itSystemsLoading"
        @system-created="onSystemCreated"
      />
      <ProcessesFormItem
        id="bank_and_payments"
        :title="t('client.processes.bank_and_payments')"
        v-model:enabled="form.bank_and_payments.enabled"
        v-model:systems="form.bank_and_payments.system"
        v-model:we_do="form.bank_and_payments.we_do"
        v-model:description="form.bank_and_payments.description"
        :it-systems="itSystems"
        :it-systems-loading="itSystemsLoading"
        @system-created="onSystemCreated"
      />
      <ProcessesFormItem
        id="loans_and_other_credits"
        :title="t('client.processes.loans_and_other_credits')"
        v-model:enabled="form.loans_and_other_credits.enabled"
        v-model:systems="form.loans_and_other_credits.system"
        v-model:we_do="form.loans_and_other_credits.we_do"
        v-model:description="form.loans_and_other_credits.description"
        :it-systems="itSystems"
        :it-systems-loading="itSystemsLoading"
        @system-created="onSystemCreated"
      />
      <ProcessesFormItem
        id="cash_register_and_daily_sales"
        :title="t('client.processes.cash_register_and_daily_sales')"
        v-model:enabled="form.cash_register_and_daily_sales.enabled"
        v-model:systems="form.cash_register_and_daily_sales.system"
        v-model:we_do="form.cash_register_and_daily_sales.we_do"
        v-model:description="form.cash_register_and_daily_sales.description"
        :it-systems="itSystems"
        :it-systems-loading="itSystemsLoading"
        @system-created="onSystemCreated"
      />
      <ProcessesFormItem
        id="inventory"
        :title="t('client.processes.inventory')"
        v-model:enabled="form.inventory.enabled"
        v-model:systems="form.inventory.system"
        v-model:we_do="form.inventory.we_do"
        v-model:description="form.inventory.description"
        :it-systems="itSystems"
        :it-systems-loading="itSystemsLoading"
        @system-created="onSystemCreated"
      />
      <ProcessesFormItem
        id="real_estate"
        :title="t('client.processes.real_estate')"
        v-model:enabled="form.real_estate.enabled"
        v-model:systems="form.real_estate.system"
        v-model:we_do="form.real_estate.we_do"
        v-model:description="form.real_estate.description"
        :it-systems="itSystems"
        :it-systems-loading="itSystemsLoading"
        @system-created="onSystemCreated"
      />

      <ProcessesFormItem
        id="fixed_assets"
        :title="t('client.processes.fixed_assets')"
        v-model:enabled="form.fixed_assets.enabled"
        v-model:systems="form.fixed_assets.system"
        v-model:we_do="form.fixed_assets.we_do"
        v-model:description="form.fixed_assets.description"
        :it-systems="itSystems"
        :it-systems-loading="itSystemsLoading"
        @system-created="onSystemCreated"
      />

      <ProcessesFormItem
        id="activates_internally_developed_assets"
        :title="t('client.processes.activates_internally_developed_assets')"
        v-model:enabled="form.activates_internally_developed_assets.enabled"
        v-model:systems="form.activates_internally_developed_assets.system"
        v-model:we_do="form.activates_internally_developed_assets.we_do"
        v-model:description="form.activates_internally_developed_assets.description"
        :it-systems="itSystems"
        :it-systems-loading="itSystemsLoading"
        @system-created="onSystemCreated"
      />

      <ProcessesFormItem
        id="securities_trading"
        :title="t('client.processes.securities_trading')"
        v-model:enabled="form.securities_trading.enabled"
        v-model:systems="form.securities_trading.system"
        v-model:we_do="form.securities_trading.we_do"
        v-model:description="form.securities_trading.description"
        :it-systems="itSystems"
        :it-systems-loading="itSystemsLoading"
        @system-created="onSystemCreated"
      />
      <ProcessesFormItem
        id="suspense_account_and_missing_documents"
        :title="t('client.processes.suspense_account_and_missing_documents')"
        v-model:enabled="form.suspense_account_and_missing_documents.enabled"
        v-model:systems="form.suspense_account_and_missing_documents.system"
        v-model:we_do="form.suspense_account_and_missing_documents.we_do"
        v-model:description="form.suspense_account_and_missing_documents.description"
        :it-systems="itSystems"
        :it-systems-loading="itSystemsLoading"
        @system-created="onSystemCreated"
      />
      <ProcessesFormItem
        id="annual_closing_and_financial_statement"
        :title="t('client.processes.annual_closing_and_financial_statement')"
        v-model:enabled="form.annual_closing_and_financial_statement.enabled"
        v-model:systems="form.annual_closing_and_financial_statement.system"
        v-model:we_do="form.annual_closing_and_financial_statement.we_do"
        v-model:description="form.annual_closing_and_financial_statement.description"
        :it-systems="itSystems"
        :it-systems-loading="itSystemsLoading"
        @system-created="onSystemCreated"
      />
      <ProcessesFormItem
        id="income_tax_return"
        :title="t('client.processes.income_tax_return')"
        v-model:enabled="form.income_tax_return.enabled"
        v-model:systems="form.income_tax_return.system"
        v-model:we_do="form.income_tax_return.we_do"
        v-model:description="form.income_tax_return.description"
        :it-systems="itSystems"
        :it-systems-loading="itSystemsLoading"
        @system-created="onSystemCreated"
      />

      <AppBoxBody>
        <div class="row">
          <div class="col-10">
            <h3>{{ t('client.processes.vat') }}</h3>
          </div>
          <div class="col-2 text-right">
            <FormSwitch group-class="mb-0" id="vat-enabled" v-model="form.vat.enabled" />
          </div>
        </div>
        <template v-if="form.vat.enabled">
          <div class="row">
            <div class="col-8">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <FormLabel html-for="vat-system">{{ t('client.processes.system') }}</FormLabel>
                    <div class="d-flex">
                      <VueSelect
                        class="flex-grow-1"
                        :reduce="(option: IClientItSystemResource) => option.id"
                        :clearable="false"
                        :searchable="false"
                        v-model="form.vat.system"
                        :options="itSystems"
                        label="name"
                        input-id="vat-system"
                        :placeholder="t('common.select')"
                        :loading="itSystemsLoading"
                        multiple
                      />
                      <AppButton
                        @click.prevent="onCreateItSystem(form.vat.system)"
                        color="success"
                        class="flex-shrink-0 ml-2"
                        light
                        circle
                        v-tooltip.left="t('common.add')"
                      >
                        <FontIcon name="plus" />
                      </AppButton>
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <FormLabel html-for="vat_we_do">{{ t('client.processes.we_do') }}</FormLabel>
                    <FormSwitch group-class="mb-0" id="vat_we_do" v-model="form.vat.we_do" />
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <FormLabel html-for="vat_periodic_summary">{{ t('client.processes.periodic_summary') }}</FormLabel>
                    <FormSwitch group-class="mb-0" id="vat_periodic_summary" v-model="form.vat.periodic_summary" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-8">
              <div class="row row-cols-2">
                <div class="col">
                  <div class="form-group">
                    <FormLabel html-for="vat_reporting_frequency">
                      {{ t('client.processes.reporting_frequency') }}
                    </FormLabel>
                    <VueSelect
                      :reduce="(option: any) => option.value"
                      :clearable="false"
                      :searchable="false"
                      v-model="form.vat.reporting_frequency"
                      :options="reportingFrequencyOptions"
                      label="label"
                      input-id="vat_reporting_frequency"
                      @option:selected="form.vat.reporting_deadline = null"
                      :placeholder="t('common.select')"
                    />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <FormLabel html-for="vat_reporting_deadline">
                      {{ t('client.processes.reporting_deadline') }}
                    </FormLabel>
                    <VueSelect
                      :reduce="(option: any) => option.value"
                      :clearable="false"
                      :searchable="false"
                      v-model="form.vat.reporting_deadline"
                      :options="getVatReportingDeadlines(form.vat.reporting_frequency)"
                      label="label"
                      input-id="vat_reporting_deadline"
                      :placeholder="t('common.select')"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="row row-cols-2">
                <!--                <div class="col">-->
                <!--                  <div class="form-group">-->
                <!--                    <FormLabel html-for="vat_deadline">{{ t('client.processes.deadline') }}</FormLabel>-->
                <!--                    <VueSelect-->
                <!--                      :reduce="(option: any) => option.value"-->
                <!--                      :clearable="false"-->
                <!--                      :searchable="false"-->
                <!--                      v-model="form.vat.deadline"-->
                <!--                      :options="[]"-->
                <!--                      label="label"-->
                <!--                      input-id="vat_deadline"-->
                <!--                    />-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
            </div>
          </div>
          <div class="form-group">
            <FormLabel html-for="vat-description">{{ t('client.processes.description') }}</FormLabel>
            <FormTextarea id="vat-description" class="form-control" size="large" v-model="form.vat.description" />
          </div>
        </template>
      </AppBoxBody>

      <ProcessesFormItem
        id="management_reporting"
        :title="t('client.processes.management_reporting')"
        v-model:enabled="form.management_reporting.enabled"
        v-model:systems="form.management_reporting.system"
        v-model:we_do="form.management_reporting.we_do"
        v-model:description="form.management_reporting.description"
        :it-systems="itSystems"
        :it-systems-loading="itSystemsLoading"
        @system-created="onSystemCreated"
      />
      <ProcessesFormItem
        id="archiving_and_backup"
        :title="t('client.processes.archiving_and_backup')"
        v-model:enabled="form.archiving_and_backup.enabled"
        v-model:systems="form.archiving_and_backup.system"
        v-model:we_do="form.archiving_and_backup.we_do"
        v-model:description="form.archiving_and_backup.description"
        :it-systems="itSystems"
        :it-systems-loading="itSystemsLoading"
        @system-created="onSystemCreated"
      />

      <AppBoxBody>
        <div class="text-right">
          <AppButton color="success" :loading="submitLoader.isLoading.value">
            {{ t('common.update') }}
          </AppButton>
        </div>
      </AppBoxBody>
    </AppBox>
  </form>
</template>
