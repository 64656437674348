import route from 'ziggy-js';
import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import { IErpArticleRequestBody, IErpArticleResource } from '@/types/Article';

export default {
  async index(): Promise<IResponse<IErpArticleResource[]>> {
    return await httpClient.get(route('invoices.erp-articles.index')).json();
  },
  async sync(): Promise<IResponse<IErpArticleResource[]>> {
    return await httpClient.get(route('invoices.erp-articles.sync')).json();
  },
  async get(erpArticle: number): Promise<IResponse<IErpArticleResource>> {
    return await httpClient.get(route('invoices.erp-articles.get', { erpArticle })).json();
  },
  async update(erpArticle: number, body: IErpArticleRequestBody): Promise<IResponse<IErpArticleResource>> {
    return await httpClient.patch(route('invoices.erp-articles.update', { erpArticle }), { json: body }).json();
  },
};
