<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps<{
  cssClass?: string;
  size?: 'tiny' | 'small' | 'large';
}>();

const classes = computed(() => ['box-body', props.cssClass, props.size && `is-${props.size}`]);
</script>

<template>
  <div :class="classes">
    <slot />
  </div>
</template>
