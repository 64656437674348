import route from 'ziggy-js';
import { Options } from 'ky';
import httpClient from '@/services/api/ky';
import { ICreateActivityRequest, IActivityResource } from '@/types/Activity';
import { IEventAffectedWeeks } from '@/types/Event';

export default {
  store(json: ICreateActivityRequest, options: Options = {}) {
    return httpClient
      .post(route('activities.store'), { ...options, json })
      .json<{ data: IActivityResource } & IEventAffectedWeeks>();
  },
  destroy(activity: number, options: Options = {}) {
    return httpClient.delete(route('activities.destroy', { activity }), options).json<IEventAffectedWeeks>();
  },
};
