import { Decimal } from 'decimal.js';

export function getNextInvoiceId(ids: number[], currentId: number) {
  return ids.length === 0 ? null : ids.find((id) => id > currentId) ?? Math.min(...ids);
}

export function calculateAmount(quantity: number | null, pricePerUnit: number | null, discount: number | null) {
  if (
    pricePerUnit === null ||
    pricePerUnit === 0 ||
    quantity === null ||
    quantity === 0 ||
    (typeof discount === 'number' && discount >= 100)
  ) {
    return 0;
  }

  const pricePerUnitDecimal = new Decimal(pricePerUnit);
  const quantityDecimal = new Decimal(quantity);
  const discountDecimal = new Decimal(discount ?? 0).div(100);

  const totalBeforeDiscount = pricePerUnitDecimal.times(quantityDecimal);
  const discountAmount = totalBeforeDiscount.times(discountDecimal);
  const totalAfterDiscount = totalBeforeDiscount.minus(discountAmount);

  return totalAfterDiscount.toDecimalPlaces(2).toNumber();
}
