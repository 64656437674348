export default {
  resource: {
    name: 'Internt | Interna',
  },
  status: {
    draft: 'Utkast',
    active: 'Aktivt',
    closed: 'Stängt',
  },
  index: {
    title: 'Interna projekt & händelser',
    active_events: 'Aktiva interna händelser',
    closed_events: 'Stängda interna händelser',
    closed_events_description: 'Lista av interna händelser där datum passerats eller manuellt stängt av användare',
    add_event: 'Lägg till händelse',
    help: '',
    tabs: {
      active: 'Aktiv',
      closed: 'Stängd',
    },
  },
  create: {
    title: 'Lägg till intern händelse',
    help: '',
  },
  edit: {
    title: 'Editera intern händelse',
    help: '',
  },
  attributes: {
    name: 'Namn',
    start_date: 'Startdatum',
    end_date: 'Slutdatum',
    activity_group: 'Aktivitietstyp',
    activities: 'Aktiviteter',
    frequency: {
      once: 'Engång',
      weekly: 'Vecko',
      monthly: 'Måndad',
      quarterly: 'Kavartal',
      'semi-annually': 'Halvår',
      annually: 'År',
      varying: 'Varierande',
    },
    task: {
      multiple: 'Multipla',
    },
  },
  activities: {
    title: 'Aktiviteter',
    empty: 'Inga aktiviteter',
    table: {
      name: 'Aktivitet',
      frequency: 'Frekvens',
      start_week: 'Startvecka',
      scheduled_time: 'Planerad tid ',
      users: 'Tilldelade',
      note: 'Notering',
    },
    tooltip: {
      add_people: 'Lägg till personer',
      note: 'Notering',
    },
  },
  table: {
    name: 'Händelse',
    status: 'Status',
    date: 'Datum',
    task: 'Aktivitet',
    frequency: 'Frekvens',
    hours: 'Tid',
    group: 'Typ',
  },
  empty: 'Inga interna händelser',
  tooltip: {
    edit: 'Editera "{name}"',
    destroy: 'Ta bort "{name}"',
    activate: 'Aktivera "{name}"',
    close: 'Stäng "{name}"',
    reopen: 'Återöppna "{name}"',
  },
  messages: {
    choose_activity_group: 'Välj typ först',
  },
  confirm: {
    destroy: {
      title: 'Ta bort intern händelse',
      text: 'Är du säker du vill ta bort <strong>{name}</strong> ?',
    },
    activate: {
      title: 'Aktivera intern händelse?',
      text: 'Är du säker på du vill aktivera <strong>{name}</strong>?',
    },
    close: {
      title: 'Stäng intern händelse',
      text: 'Är du säker på du vil stänga <strong>{name}</strong>?',
    },
    reopen: {
      title: 'Återöppna intern händelse',
      text: 'Är du säker på du vill återöppna <strong>{name}</strong>?',
    },
  },
};
