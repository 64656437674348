export default {
  index: {
    title: 'Jobbtitlar',
    create: 'Skapa jobbtitel',
    help: '',
  },

  create: {
    title: 'Skapa jobbtitel',
  },
  edit: {
    title: 'Redigera jobbtitel: {name}',
  },
  form: {
    name: 'Namn',
  },

  empty: 'Inga jobbtitlar',

  // Attributes
  attributes: {
    name: 'Namn',
  },

  // Tooltips
  tooltip: {
    edit: 'Redigera "{name}"',
    destroy: 'Radera "{name}"',
  },

  // Confirms
  confirm: {
    destroy: {
      title: 'Radera jobbtitel',
      text: 'Är du säker på att du vill radera <strong>{name}</strong>?',
    },
  },
};
