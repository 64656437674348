<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { clsx } from 'clsx';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';

import { FontIcon } from '@/components';
import { NavigationItemProps } from '@/types/Navigation';
import useSidebarStore from '@/store/SidebarStore';

const router = useRouter();
const sidebarStore = useSidebarStore();
const { expanded } = storeToRefs(sidebarStore);
const { open } = sidebarStore;

const { level = 1, text, route, icon, children, guard } = defineProps<NavigationItemProps & { level?: number }>();

const collapsed = ref(false);

const linkClasses = computed(() =>
  clsx('navigation-link', {
    'is-collapsed': children && collapsed.value && expanded.value,
    'is-parent-active': isParentActive.value,
  }),
);
const iconClasses = computed(() => clsx('navigation-icon'));

function onClickParent() {
  if (expanded.value === false) {
    open();
    if (collapsed.value === false) {
      collapsed.value = true;
    }
  } else {
    collapsed.value = !collapsed.value;
  }
}

const isParentActive = computed(() => {
  if (!children) return false;
  function search(children: NavigationItemProps[]): boolean {
    return children.some((child) => {
      if (child.children) {
        return search(child.children);
      }
      return !!(child.route && child.route?.name === router.currentRoute.value.name);
    });
  }
  if (children) {
    return search(children);
  }
  return false;
});

onMounted(() => {
  if (isParentActive.value) {
    collapsed.value = true;
  }
});
</script>

<template>
  <li class="navigation-item" v-if="guard ? guard() : true">
    <RouterLink :class="linkClasses" v-if="route" :to="route" v-tooltip.right="expanded ? null : text">
      <FontIcon :class="iconClasses" v-if="icon" :name="icon" />
      <span v-text="text" v-if="expanded" />
    </RouterLink>
    <a @click.prevent="onClickParent" v-else :class="linkClasses" href="#" v-tooltip.right="expanded ? null : text">
      <FontIcon :class="iconClasses" v-if="icon" :name="icon" />
      <span v-text="text" v-if="expanded" />
      <FontIcon
        v-if="expanded && children"
        class="navigation-arrow ml-auto"
        :name="collapsed ? 'chevron-up' : 'chevron-down'"
      />
    </a>
    <ul class="navigation-submenu" :class="`level-${level}`" v-if="expanded && children && collapsed">
      <NavigationItem v-for="(child, index) in children" :key="index" v-bind="child" :level="level + 1" />
    </ul>
  </li>
</template>
