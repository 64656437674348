import { IClientListResource } from '@/types/Client';
import { IProjectPreviewResource } from '@/types/Project';

export interface ITravelResource {
  id: number;
  vehicle_id: number;
  mileage: number | string;
  amount: number | string;
}

export enum ExpenseType {
  RECEIPT = 'receipt',
  INVOICE = 'invoice',
  MILEAGE = 'mileage',
}

export enum ExpenseSource {
  SYNC = 'sync',
  TIME_SHEET = 'time_sheet',
  MANUAL = 'manual',
}

export interface IExpenseResource {
  id: number;
  type: ExpenseType;
  source: ExpenseSource;
  date: string;
  amount: number | string;
  ref_number: null | string;
  description: null | string;
  client: IClientListResource;
  project: IProjectPreviewResource;
  is_invoiced: boolean;
}

export interface IExpenseRequest {
  client_uuid: null | string;
  project_id: null | number;
  type: null | ExpenseType;
  date: null | string;
  amount: null | number;
  description: null | string;
}
