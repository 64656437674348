<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

import svFlag from '@/../images/flags/sv.svg';
import enFlag from '@/../images/flags/en.svg';

import { AppDropdownSection, AppDropdownItem } from '@/components';
import useLocaleStore from '@/store/LocaleStore';

const { t } = useI18n({ useScope: 'global' });
const localeStore = useLocaleStore();
const { locale } = storeToRefs(localeStore);
const { setLocale } = localeStore;
</script>

<template>
  <AppDropdownSection>
    <AppDropdownItem>
      <a @click.prevent="setLocale('sv')" href="#" :class="{ disabled: locale === 'sv' }">
        <img width="18" :src="svFlag" alt="sv" />
        <span class="pl-1" v-text="t('common.sv')" />
      </a>
    </AppDropdownItem>
    <AppDropdownItem>
      <a @click.prevent="setLocale('en')" href="#" :class="{ disabled: locale === 'en' }">
        <img width="18" :src="enFlag" alt="sv" />
        <span class="pl-1" v-text="t('common.en')" />
      </a>
    </AppDropdownItem>
  </AppDropdownSection>
</template>
