<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';
import { DateTime } from 'luxon';

import useModalFeatures from '@/composables/useModalFeatures';
import useTrackChanges from '@/composables/useTrackChanges';
import { AppButton, AppCloseButton, FormLabel, FormTextarea } from '@/components';
import { CheckpointEventStateType } from '@/types/Checkpoint';
import { IProjectCheckpointEventResource } from '@/types/Project';
import api from '@/services/api';

type Props = {
  event: IProjectCheckpointEventResource;
};

const { event } = defineProps<Props>();

const emit = defineEmits<{
  cancel: [];
  updated: [event: IProjectCheckpointEventResource];
  closed: [];
}>();

const { t, locale } = useI18n({ useScope: 'global' });
const { onCtrlEnter } = useModalFeatures();

const loading = ref(false);
const form = reactive<{
  state: CheckpointEventStateType;
  comment: string;
}>({
  state: CheckpointEventStateType.N_A,
  comment: '',
});
const tracker = useTrackChanges(form);

onMounted(async () => {
  form.state = event.state;
  form.comment = event.comment ?? '';
  tracker.commit();
});

async function update() {
  try {
    loading.value = true;
    const response = await api.clients.projects.checkpoints.events.update(event.id, form);
    emit('updated', response.data);
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
}

onCtrlEnter(() => {
  if (tracker.isModified.value) {
    update();
  } else {
    emit('cancel');
  }
});
</script>

<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container"
    :click-to-close="false"
    :esc-to-close="true"
    v-slot="{ close }"
    @closed="emit('closed')"
  >
    <form @submit.prevent="update" @keydown.enter.prevent>
      <div class="modal-header">
        <h2 v-text="t('checkpoint.edit.title')" />
        <AppCloseButton @close="close" />
      </div>
      <div class="modal-content">
        <!-- Name -->
        <div class="form-group row align-items-center">
          <div class="col-4">
            <FormLabel>{{ t('checkpoint.attributes.name') }}</FormLabel>
          </div>
          <div class="col">
            {{ event.name }}
          </div>
        </div>

        <!-- Period -->
        <div class="form-group row align-items-center">
          <div class="col-4">
            <FormLabel>{{ t('checkpoint.attributes.period') }}</FormLabel>
          </div>
          <div class="col">
            {{ DateTime.fromFormat(event.year_month.toString(), 'yyyyMM').setLocale(locale).toFormat('yyyy-MMM') }}
          </div>
        </div>

        <!-- State -->
        <div class="form-group row align-items-center">
          <div class="col-4">
            <FormLabel html-for="checkpoint_status">{{ t('checkpoint.attributes.status') }}</FormLabel>
          </div>
          <div class="col">
            <div class="form-group mb-0">
              <input
                :id="`event-state-${event.id}-modal`"
                type="checkbox"
                class="form-switch"
                v-model="form.state"
                :true-value="CheckpointEventStateType.DONE"
                :false-value="CheckpointEventStateType.NOT_DONE"
              />
              <label :for="`event-state-${event.id}-modal`" class="form-label" v-text="'&nbsp;'" />
            </div>
          </div>
        </div>

        <!-- Comment -->
        <div class="form-group row align-items-center">
          <div class="col-4">
            <FormLabel html-for="checkpoint_comment">{{ t('checkpoint.attributes.comment') }}</FormLabel>
          </div>
          <div class="col">
            <FormTextarea id="checkpoint_comment" v-model="form.comment" />
          </div>
        </div>
        <slot />
      </div>
      <div class="modal-footer d-flex">
        <AppButton light @click.prevent="close" :disabled="loading">
          {{ t('common.cancel') }}
        </AppButton>
        <AppButton class="ml-auto" color="success" :loading="loading" :disabled="!tracker.isModified.value">
          {{ t('common.update') }}
        </AppButton>
      </div>
    </form>
  </VueFinalModal>
</template>
