import route from 'ziggy-js';
import { Options } from 'ky';
import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import {
  IClientVisitResource,
  IDeadlineEventResource,
  IEventWeekRequestBody,
  ISplitEventRequestBody,
  ITimeEntryActivityEventResource,
  ITimeEntryEventResource,
  ITimeEntryProjectTaskEventResource,
  ITimeEntryProjectTaskEventRequest,
  ITimeEntryActivityEventRequest,
  IPrioritiseEventsRequestBody,
  IResourcePlanning,
  IMoveEventsRequestBody,
  IDoneEventRequestBody,
  IEventAffectedWeeks,
} from '@/types/Event';

export default {
  updateWeek(event: number, json: IEventWeekRequestBody, options: Options = {}) {
    return httpClient
      .patch(route('events.update-week', { event }), { ...options, json })
      .json<IResponse<ITimeEntryEventResource>>();
  },
  done(event: number, json: IDoneEventRequestBody, options: Options = {}) {
    return httpClient
      .post(route('events.done', { event }), { ...options, json })
      .json<IResponse<ITimeEntryEventResource>>();
  },
  move(json: IMoveEventsRequestBody, options: Options = {}) {
    return httpClient.post(route('events.move'), { ...options, json }).json<IEventAffectedWeeks>();
  },
  moveWeekForward(options: Options = {}) {
    return httpClient.post(route('events.move_week_forward'), options).json<IEventAffectedWeeks>();
  },
  prioritiseBulk(json: IPrioritiseEventsRequestBody, options: Options = {}) {
    return httpClient.post(route('events.prioritise_bulk'), { ...options, json }).json<void>();
  },
  destroy(event: number, options: Options = {}) {
    return httpClient.delete(route('events.delete', { event }), options).json<IEventAffectedWeeks>();
  },
  activities: {
    get(event: number, options: Options = {}) {
      return httpClient.get(route('events.get', { event }), options).json<IResponse<ITimeEntryActivityEventResource>>();
    },
    update(event: number, json: ITimeEntryActivityEventRequest, options: Options = {}) {
      return httpClient
        .patch(route('events.update', { event }), { json, ...options })
        .json<{ data: ITimeEntryActivityEventResource } & IEventAffectedWeeks>();
    },
    split(event: number, json: ISplitEventRequestBody, options: Options = {}) {
      return httpClient
        .post(route('events.split', { event }), { ...options, json })
        .json<{ data: ITimeEntryActivityEventResource } & IEventAffectedWeeks>();
    },
  },
  projectTasks: {
    get(event: number, options: Options = {}) {
      return httpClient
        .get(route('events.get', { event }), options)
        .json<IResponse<ITimeEntryProjectTaskEventResource>>();
    },
    update(event: number, json: ITimeEntryProjectTaskEventRequest, options: Options = {}) {
      return httpClient
        .patch(route('events.update', { event }), { ...options, json })
        .json<{ data: ITimeEntryProjectTaskEventResource } & IEventAffectedWeeks>();
    },
    split(event: number, json: ISplitEventRequestBody, options: Options = {}) {
      return httpClient
        .post(route('events.split', { event }), { ...options, json })
        .json<{ data: ITimeEntryProjectTaskEventResource } & IEventAffectedWeeks>();
    },
  },
  deadlines: {
    index(options: Options = {}) {
      return httpClient.get(route('events.deadlines.index'), options).json<IResponse<IDeadlineEventResource[]>>();
    },
  },
  clientVisits: {
    index(options: Options = {}) {
      return httpClient.get(route('events.client-visits.index'), options).json<IResponse<IClientVisitResource[]>>();
    },
  },
  resourcePlanning: {
    index(options: Options = {}) {
      return httpClient.get(route('events.resource-planning.index'), options).json<IResourcePlanning>();
    },
  },
};
