<script setup lang="ts">
import { computed, onMounted, reactive, ref, useTemplateRef, UnwrapRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';
import VueSelect, { VueSelectInstance } from 'vue-select';
import { useModal } from 'vue-final-modal';
import VueDatePicker from '@vuepic/vue-datepicker';
import { Tooltip } from 'floating-vue';

import api from '@/services/api';
import progress from '@/services/progress';
import {
  AppAlert,
  AppBox,
  AppBoxBody,
  AppButton,
  AppLoader,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  CheckpointEventModal,
  FontIcon,
  FormLabel,
  FormSwitch,
} from '@/components';
import useLoader from '@/composables/useLoader';
import useAuthStore from '@/store/AuthStore';
import useExpandedList from '@/composables/useExpandedList';
import { IUserListResource } from '@/types/User';
import { IServicePreviewResource } from '@/types/Service';
import { CheckpointEventStateType } from '@/types/Checkpoint';
import { IClientCheckpointListResource } from '@/types/Client';
import { IProjectCheckpointEventResource, IProjectCheckpointListResource } from '@/types/Project';

type ViewMode = 'calendar' | 'list';

type ClientList = Pick<IClientCheckpointListResource, 'uuid' | 'name' | 'responsible'> & {
  projects: (IProjectCheckpointListResource & { events: IProjectCheckpointEventResource[] })[];
};

type Props = {
  clientUuid?: null | string;
  projectId?: null | number;
  title: string;
  initialViewMode: ViewMode;
  disableChangeViewMode?: boolean;
};

type Filters = {
  from_date: string;
  to_date: string;
  customer_responsible: string[];
  project_responsible: string[];
  services: string[];
  hide_done: boolean;
};

const {
  clientUuid = null,
  projectId = null,
  disableChangeViewMode = false,
  title,
  initialViewMode,
} = defineProps<Props>();

const { t, d, locale } = useI18n({ useScope: 'global' });
const initialLoader = useLoader({ useProgress: false });
const checkpointsLoader = useLoader({ useProgress: true });
const { authenticatedUser } = useAuthStore();
const {
  expand: expandProject,
  collapse: collapseProject,
  isExpanded: isExpandedProject,
  reset: resetExpandedProjects,
} = useExpandedList();

const isCalendarView = computed(() => viewMode.value === 'calendar');
const isListView = computed(() => viewMode.value === 'list');

const checkpoints = ref<IClientCheckpointListResource[]>([]);
const viewMode = ref<ViewMode>('calendar');

const projectResponsibleDropdown = useTemplateRef<VueSelectInstance>('projectResponsibleDropdown');
const clientResponsibleDropdown = useTemplateRef<VueSelectInstance>('clientResponsibleDropdown');
const servicesDropdown = useTemplateRef<VueSelectInstance>('servicesDropdown');

const filters = reactive<Filters>({
  from_date: '',
  to_date: '',
  customer_responsible: [],
  project_responsible: [],
  services: [],
  hide_done: true,
});

function resetFilters() {
  filters.customer_responsible = [];
  filters.project_responsible = [authenticatedUser.uuid];
  filters.services = [];
  filters.hide_done = true;
  if (isListView.value) {
    setDefaultFilterDates();
  }
  resetDropdown();
  getCheckpoints();
}

function resetDropdown() {
  if (projectResponsibleDropdown.value) {
    projectResponsibleDropdown.value.search = '';
  }
  if (clientResponsibleDropdown.value) {
    clientResponsibleDropdown.value.search = '';
  }
  if (servicesDropdown.value) {
    servicesDropdown.value.search = '';
  }
}

const sortBy = ref<'client_name' | 'project_name' | 'period'>('client_name');
const sortDir = ref<'asc' | 'desc'>('asc');

const list = computed<ClientList[]>(() => {
  const results = checkpoints.value.map((client) => ({
    ...client,
    projects: client.projects.map((project) => ({
      ...project,
      events: project.checkpoints.reduce((events, checkpoint) => {
        return [...events, ...checkpoint.events];
      }, [] as IProjectCheckpointEventResource[]),
    })),
  }));

  switch (sortBy.value) {
    case 'client_name':
      return results.toSorted((a, b) => {
        return sortDir.value === 'asc'
          ? a.name.localeCompare(b.name, locale.value)
          : b.name.localeCompare(a.name, locale.value);
      });
    case 'project_name':
      return results.map((client) => ({
        ...client,
        projects: client.projects.toSorted((a, b) => {
          return sortDir.value === 'asc'
            ? a.name.localeCompare(b.name, locale.value)
            : b.name.localeCompare(a.name, locale.value);
        }),
      }));
    case 'period':
      return results.map((client) => ({
        ...client,
        projects: client.projects.map((project) => ({
          ...project,
          events: project.events.toSorted((a, b) => {
            return sortDir.value === 'asc' ? a.year_month - b.year_month : b.year_month - a.year_month;
          }),
        })),
      }));
    default:
      return results;
  }
});

function setSort(by: UnwrapRef<typeof sortBy>) {
  sortBy.value = by;
  sortDir.value = sortDir.value === 'asc' ? 'desc' : 'asc';
}

async function getCheckpoints() {
  checkpointsLoader.start();
  try {
    const searchParams = new URLSearchParams();
    if (clientUuid) searchParams.append('client', clientUuid);
    if (projectId) searchParams.append('project', projectId.toString());
    filters.customer_responsible.forEach((uuid) => searchParams.append('client_responsible[]', uuid));
    filters.project_responsible.forEach((uuid) => searchParams.append('project_responsible[]', uuid));
    searchParams.append('from_date', filters.from_date);
    searchParams.append('to_date', filters.to_date);
    filters.services.forEach((uuid) => searchParams.append('services[]', uuid));
    searchParams.append('hide_done', Number(filters.hide_done).toString());
    const response = await api.clients.projects.checkpoints.events.index({ searchParams });
    checkpoints.value = response.data;

    // Expand projects if they have not done events in past
    if (isCalendarView.value) {
      checkpoints.value.forEach((client) => {
        client.projects.forEach((project) => {
          if (isPeriodIncludeNotDoneEvent(project, DateTime.now().month, DateTime.now().year)) {
            expandProject(project.id);
          }
        });
      });
    }
  } catch (error) {
    console.error(error);
  } finally {
    checkpointsLoader.finish();
  }
}

// Services
const services = ref<IServicePreviewResource[]>([]);
const servicesLoading = ref(false);

async function getServices() {
  try {
    servicesLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.services.list({ searchParams });
    services.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    servicesLoading.value = false;
  }
}

// Users
const users = ref<IUserListResource[]>([]);
const usersLoading = ref(false);

async function getUsers() {
  try {
    usersLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.users.list({ searchParams });
    users.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    usersLoading.value = false;
  }
}

function hasDoneEvents(events: IProjectCheckpointEventResource[]) {
  return events.some((event) => event.state === CheckpointEventStateType.DONE);
}

function getDoneEvents(events: IProjectCheckpointEventResource[]) {
  return {
    completed: events.filter((event) => event.state === CheckpointEventStateType.DONE).length,
    total: events.length,
  };
}

function getMinPeriod(events: IProjectCheckpointEventResource[]) {
  return Math.min(...events.map(({ year_month }) => year_month));
}

function getMaxPeriod(events: IProjectCheckpointEventResource[]) {
  return Math.max(...events.map(({ year_month }) => year_month));
}

async function onStateChange(event: IProjectCheckpointEventResource, e: Event) {
  const input = e.target as HTMLInputElement;
  const state = input.checked ? CheckpointEventStateType.DONE : CheckpointEventStateType.NOT_DONE;
  try {
    progress.start();
    input.disabled = true;
    const response = await api.clients.projects.checkpoints.events.update(event.id, { state });
    event.state = response.data.state;
    event.state_changed_at = response.data.state_changed_at;
  } catch (error) {
    console.error(error);
    input.checked = !input.checked;
  } finally {
    input.disabled = false;
    progress.done();
  }
}

function onEdit(event: IProjectCheckpointEventResource) {
  const { open, close, destroy } = useModal({
    component: CheckpointEventModal,
    attrs: {
      event,
      onUpdated(updatedEvent: IProjectCheckpointEventResource) {
        event.state = updatedEvent.state;
        event.state_changed_at = updatedEvent.state_changed_at;
        event.comment = updatedEvent.comment;
        close();
      },
      onCancel() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

async function setViewMode(mode: ViewMode) {
  initialLoader.start();
  viewMode.value = mode;
  setDefaultFilterDates();
  resetExpandedProjects();
  await getCheckpoints();
  initialLoader.finish();
}

const yearAndMonths = computed(() => {
  const result: { year: number; months: number[] }[] = [];
  let current = DateTime.fromISO(filters.from_date);
  const endDate = DateTime.fromISO(filters.to_date);
  while (current <= endDate) {
    let yearEntry = result.find((entry) => entry.year === current.year);
    if (!yearEntry) {
      yearEntry = { year: current.year, months: [] };
      result.push(yearEntry);
    }
    yearEntry.months.push(current.month);
    current = current.plus({ months: 1 });
  }
  return result;
});

const periods = computed(() => {
  const result: number[] = [];
  let current = DateTime.fromISO(filters.from_date);
  const endDate = DateTime.fromISO(filters.to_date);
  while (current <= endDate) {
    result.push(Number(current.toFormat('yyyyMM')));
    current = current.plus({ months: 1 });
  }
  return result;
});

function setCalendarPeriod(months: 1 | 3, action: 'plus' | 'minus') {
  filters.from_date = DateTime.fromISO(filters.from_date)[action]({ months }).startOf('month').toFormat('yyyy-MM-dd');
  filters.to_date = DateTime.fromISO(filters.to_date)[action]({ months }).endOf('month').toFormat('yyyy-MM-dd');
  getCheckpoints();
}

function setDefaultFilterDates() {
  switch (viewMode.value) {
    case 'calendar':
      filters.from_date = DateTime.now().minus({ months: 5 }).startOf('month').toFormat('yyyy-MM-dd');
      filters.to_date = DateTime.now().plus({ months: 5 }).endOf('month').toFormat('yyyy-MM-dd');
      break;
    case 'list':
      filters.from_date = DateTime.now().minus({ months: 2 }).startOf('month').toFormat('yyyy-MM-dd');
      filters.to_date = DateTime.now().endOf('month').toFormat('yyyy-MM-dd');
      break;
    default:
      break;
  }
}

function isCurrentMonthAndYear(period: number) {
  const currentDate = DateTime.now();
  const dateToCompare = DateTime.fromFormat(period.toString(), 'yyyyMM');
  return currentDate.year === dateToCompare.year && currentDate.month === dateToCompare.month;
}

function isPastEventAndNotDone(
  event: IProjectCheckpointEventResource,
  month = DateTime.now().month,
  year = DateTime.now().year,
) {
  const date = DateTime.fromObject({ day: 1, month, year });
  const eventDate = DateTime.fromFormat(event.year_month.toString(), 'yyyyMM');
  return event.state === CheckpointEventStateType.NOT_DONE && eventDate < date;
}

function isPeriodIncludeNotDoneEvent(project: IProjectCheckpointListResource, month: number, year: number) {
  const period = DateTime.fromObject({ day: 1, month, year });
  return project.checkpoints.some((checkpoints) =>
    checkpoints.events.some((event) => {
      const eventDate = DateTime.fromFormat(event.year_month.toString(), 'yyyyMM');
      return event.state === CheckpointEventStateType.NOT_DONE && eventDate <= period && period < DateTime.now();
    }),
  );
}

onMounted(async () => {
  initialLoader.start();
  viewMode.value = initialViewMode;
  setDefaultFilterDates();
  await Promise.all([getUsers(), getServices()]);
  filters.project_responsible = [authenticatedUser.uuid];
  await Promise.all([getCheckpoints()]);
  initialLoader.finish();
});
</script>

<template>
  <div class="d-flex align-items-center mb-2">
    <slot :name="title" :title="title">
      <h2>{{ title }}</h2>
    </slot>
    <div class="ml-auto" v-if="!disableChangeViewMode">
      <AppButton size="small" :disabled="isCalendarView" @click.prevent="setViewMode('calendar')" color="secondary">
        <FontIcon name="calendar" />
        {{ t('checkpoint.mode.calendar') }}
      </AppButton>
      <AppButton
        class="ml-2"
        size="small"
        :disabled="isListView"
        @click.prevent="setViewMode('list')"
        color="secondary"
      >
        <FontIcon name="list" />
        {{ t('checkpoint.mode.list') }}
      </AppButton>
    </div>
  </div>
  <AppBox shadow>
    <AppBoxBody>
      <form @submit.prevent="getCheckpoints">
        <!-- Customer responsible -->
        <div v-if="!clientUuid" class="row form-group d-flex align-items-center">
          <FormLabel class="col-2 mb-0" html-for="customer_responsible">
            {{ t('checkpoint.index.filters.customer_responsible') }}
          </FormLabel>
          <div class="col-6">
            <VueSelect
              v-model="filters.customer_responsible"
              :reduce="(option: IUserListResource) => option.uuid"
              :options="users"
              label="name"
              input-id="customer_responsible"
              :placeholder="t('common.select')"
              :loading="usersLoading"
              :clearable="false"
              multiple
              ref="projectResponsibleDropdown"
            />
          </div>
        </div>
        <!-- Project responsible -->
        <div v-if="!projectId" class="row form-group d-flex align-items-center">
          <FormLabel class="col-2 mb-0" html-for="project_responsible">
            {{ t('checkpoint.index.filters.project_responsible') }}
          </FormLabel>
          <div class="col-6">
            <VueSelect
              v-model="filters.project_responsible"
              :reduce="(option: IUserListResource) => option.uuid"
              :options="users"
              label="name"
              input-id="project_responsible"
              :placeholder="t('common.select')"
              :loading="usersLoading"
              :clearable="false"
              multiple
              ref="clientResponsibleDropdown"
            />
          </div>
        </div>
        <!-- From date -->
        <div v-if="isListView" class="row form-group d-flex align-items-center">
          <FormLabel class="col-2 mb-0" html-for="dp-input-from_date">
            {{ t('checkpoint.index.filters.from_date') }}
          </FormLabel>
          <div class="col-6">
            <div class="form-wrapper has-icon">
              <VueDatePicker
                uid="from_date"
                :ui="{ input: 'form-control' }"
                v-model="filters.from_date"
                model-type="format"
                format="yyyy-MM-dd"
                :enable-time-picker="false"
                :month-change-on-scroll="false"
                auto-apply
                week-numbers="iso"
                :max-date="DateTime.fromISO(filters.to_date).toJSDate()"
                text-input
                :locale="locale"
                :week-num-name="t('common.week_short')"
                :clearable="false"
              >
                <template #input-icon><i class="form-icon ti ti-calendar" /></template>
              </VueDatePicker>
            </div>
          </div>
        </div>
        <!-- From to -->
        <div v-if="isListView" class="row form-group d-flex align-items-center">
          <FormLabel class="col-2 mb-0" html-for="dp-input-to_date">
            {{ t('checkpoint.index.filters.to_date') }}
          </FormLabel>
          <div class="col-6">
            <div class="form-wrapper has-icon">
              <VueDatePicker
                uid="to_date"
                :ui="{ input: 'form-control' }"
                v-model="filters.to_date"
                model-type="format"
                format="yyyy-MM-dd"
                :enable-time-picker="false"
                :month-change-on-scroll="false"
                auto-apply
                week-numbers="iso"
                :min-date="DateTime.fromISO(filters.from_date).toJSDate()"
                text-input
                :locale="locale"
                :week-num-name="t('common.week_short')"
                :clearable="false"
              >
                <template #input-icon><i class="form-icon ti ti-calendar" /></template>
              </VueDatePicker>
            </div>
          </div>
        </div>
        <!-- Services -->
        <div class="row form-group d-flex align-items-center">
          <FormLabel class="col-2 mb-0" html-for="services">
            {{ t('checkpoint.index.filters.services') }}
          </FormLabel>
          <div class="col-6">
            <VueSelect
              v-model="filters.services"
              :reduce="(option: IServicePreviewResource) => option.uuid"
              :options="services"
              label="name"
              input-id="services"
              :placeholder="t('common.all')"
              :loading="servicesLoading"
              :clearable="true"
              multiple
              ref="servicesDropdown"
            />
          </div>
        </div>
        <!-- Hide done -->
        <div class="row form-group d-flex align-items-center">
          <FormLabel class="col-2 mb-0" html-for="hide_done">
            {{ t('checkpoint.index.filters.hide_done') }}
          </FormLabel>
          <div class="col-6">
            <FormSwitch group-class="mb-0" id="hide_done" v-model="filters.hide_done" />
          </div>
        </div>
        <!-- Buttons -->
        <div class="mt-4">
          <AppButton :disabled="checkpointsLoader.isLoading.value">
            {{ t('common.apply_filters') }}
          </AppButton>
          <AppButton class="ml-2" light @click.prevent="resetFilters" :disabled="checkpointsLoader.isLoading.value">
            {{ t('common.reset_filters') }}
          </AppButton>
        </div>
      </form>
    </AppBoxBody>
  </AppBox>
  <div v-if="initialLoader.isLoading.value" class="text-center">
    <AppLoader size="large" />
  </div>
  <div class="mt-4 mb-5" v-else>
    <!-- Calendar view -->
    <template v-if="isCalendarView">
      <AppTable bordered>
        <AppTableHead>
          <!-- Sizer -->
          <AppTableTr class="tr-sizer">
            <AppTableTh style="width: 17%" />
            <AppTableTh style="width: 17%" />
            <AppTableTh v-for="i in 11" style="width: 6%" :key="i" />
          </AppTableTr>
        </AppTableHead>
        <AppTableBody>
          <!-- Months -->
          <AppTableTr>
            <AppTableTd colspan="2" nowrap>
              <div class="d-flex align-items-center justify-content-between flex-nowrap">
                <div>
                  <AppButton
                    size="small"
                    @click.prevent="setCalendarPeriod(3, 'minus')"
                    light
                    circle
                    v-tooltip.top="'-3'"
                    :disabled="checkpointsLoader.isLoading.value"
                  >
                    <FontIcon name="chevrons-left" />
                  </AppButton>
                  <AppButton
                    class="ml-1"
                    size="small"
                    @click.prevent="setCalendarPeriod(1, 'minus')"
                    light
                    circle
                    v-tooltip.top="'-1'"
                    :disabled="checkpointsLoader.isLoading.value"
                  >
                    <FontIcon name="chevron-left" />
                  </AppButton>
                </div>
                <span class="px-2 font-bold">{{ t('common.months') }}</span>
                <div>
                  <AppButton
                    size="small"
                    @click.prevent="setCalendarPeriod(1, 'plus')"
                    light
                    circle
                    v-tooltip.top="'+1'"
                    :disabled="checkpointsLoader.isLoading.value"
                  >
                    <FontIcon name="chevron-right" />
                  </AppButton>
                  <AppButton
                    class="ml-1"
                    size="small"
                    @click.prevent="setCalendarPeriod(3, 'plus')"
                    light
                    circle
                    v-tooltip.top="'+3'"
                    :disabled="checkpointsLoader.isLoading.value"
                  >
                    <FontIcon name="chevrons-right" />
                  </AppButton>
                </div>
              </div>
            </AppTableTd>
            <AppTableTd
              :colspan="item.months.length"
              class="text-center font-bold"
              v-for="item in yearAndMonths"
              :key="item.year"
            >
              {{ item.year }}
            </AppTableTd>
          </AppTableTr>
        </AppTableBody>
        <AppTableBody v-for="client in checkpoints" :key="client.uuid">
          <template v-for="project in client.projects" :key="project.id">
            <!-- Customer, Project, Years -->
            <AppTableTr>
              <AppTableTd class="font-bold">{{ client.name }}</AppTableTd>
              <AppTableTd class="font-bold">
                <div class="d-flex align-items-center justify-content-between">
                  <Tooltip class="flex-grow-1" placement="bottom">
                    <RouterLink
                      :to="{ name: 'projects.view', params: { uuid: client.uuid, id: project.id } }"
                      target="_blank"
                    >
                      {{ project.name }}
                    </RouterLink>
                    <template #popper>
                      {{ t('project.attributes.start_date') }}: {{ d(project.start_date) }}
                      <br />
                      <br />
                      {{ t('project.attributes.end_date') }}: {{ d(project.start_date) }}
                      <br />
                      <br />
                      {{ t('project.attributes.financial_year') }}: {{ d(project.financial_year.start_date) }} -
                      {{ d(project.financial_year.end_date) }}
                    </template>
                  </Tooltip>
                  <div class="flex-shrink-0 ml-3">
                    <AppButton
                      v-if="isExpandedProject(project.id)"
                      @click.prevent="collapseProject(project.id)"
                      light
                      circle
                      size="small"
                    >
                      <FontIcon name="chevron-up" />
                    </AppButton>
                    <AppButton v-else @click.prevent="expandProject(project.id)" light circle size="small">
                      <FontIcon name="chevron-down" />
                    </AppButton>
                  </div>
                </div>
              </AppTableTd>
              <template v-for="item in yearAndMonths" :key="item.year">
                <AppTableTd
                  :class="{
                    'current-month-accent': isCurrentMonthAndYear(
                      Number(`${item.year}${month.toString().padStart(2, '0')}`),
                    ),
                    'text-danger-500': isPeriodIncludeNotDoneEvent(project, month, item.year),
                  }"
                  class="font-bold text-center"
                  v-for="month in item.months"
                  :key="month"
                >
                  {{ DateTime.fromObject({ year: item.year, month, day: 1 }).setLocale(locale).toFormat('MMM') }}
                </AppTableTd>
              </template>
            </AppTableTr>
            <!-- Events -->
            <template v-if="isExpandedProject(project.id)">
              <AppTableTr>
                <AppTableTd class="font-bold">{{ t('checkpoint.attributes.checkpoint') }}</AppTableTd>
                <AppTableTd class="font-bold">{{ t('checkpoint.attributes.frequency') }}</AppTableTd>
                <AppTableTd colspan="11" />
              </AppTableTr>
              <AppTableTr v-for="checkpoint in project.checkpoints" :key="checkpoint.id">
                <AppTableTd>{{ checkpoint.name }}</AppTableTd>
                <AppTableTd>
                  {{ t(`service.checkpoints.frequency.${checkpoint.frequency}`) }}
                </AppTableTd>
                <AppTableTd
                  :class="{ 'current-month-blink': isCurrentMonthAndYear(period) }"
                  class="p-0"
                  v-for="period in periods"
                  :key="period"
                >
                  <template v-for="event in checkpoint.events" :key="event.id">
                    <div
                      @click="onEdit(event)"
                      v-if="event.year_month === period && checkpointsLoader.isNotLoading.value"
                      class="d-flex align-items-center px-2 pointer"
                      style="min-height: 40px"
                      :class="{ 'background-danger-100': isPastEventAndNotDone(event) }"
                    >
                      <div class="form-group mb-0" @click.stop>
                        <input
                          :id="`event-state-${event.id}`"
                          type="checkbox"
                          class="form-switch"
                          :checked="event.state === CheckpointEventStateType.DONE"
                          @change="onStateChange(event, $event)"
                        />
                        <label
                          :for="`event-state-${event.id}`"
                          class="form-label"
                          v-tooltip.bottom="`${t('common.updated')}: ${d(event.state_changed_at)}`"
                          v-text="'&nbsp;'"
                        />
                      </div>
                      <FontIcon
                        v-if="event.comment"
                        v-tooltip.bottom="event.comment"
                        class="text-3 text-neutral-500"
                        name="info-circle"
                      />
                    </div>
                  </template>
                </AppTableTd>
              </AppTableTr>
            </template>
          </template>
        </AppTableBody>
      </AppTable>

      <AppAlert class="mt-2" v-if="checkpoints.length === 0">{{ t('checkpoint.empty') }}</AppAlert>
    </template>

    <!-- List view -->
    <template v-if="isListView">
      <AppTable bordered v-if="list.length > 0">
        <AppTableHead>
          <AppTableTr>
            <AppTableTh nowrap v-if="!clientUuid" class="underline pointer" @click.prevent="setSort('client_name')">
              {{ t('checkpoint.attributes.client') }}
            </AppTableTh>
            <AppTableTh nowrap>{{ t('checkpoint.attributes.service') }}</AppTableTh>
            <AppTableTh nowrap v-if="!projectId" class="underline pointer" @click.prevent="setSort('project_name')">
              {{ t('checkpoint.attributes.project') }}
            </AppTableTh>
            <AppTableTh nowrap v-if="!clientUuid">{{ t('checkpoint.attributes.customer_responsible') }}</AppTableTh>
            <AppTableTh nowrap v-if="!projectId">{{ t('checkpoint.attributes.project_responsible') }}</AppTableTh>
            <AppTableTh nowrap class="underline pointer" @click.prevent="setSort('period')">
              {{ t('checkpoint.attributes.period') }}
            </AppTableTh>
            <AppTableTh nowrap>{{ t('checkpoint.attributes.status') }}</AppTableTh>
            <AppTableTh class="text-right" nowrap>{{ t('common.actions') }}</AppTableTh>
          </AppTableTr>
        </AppTableHead>
        <template v-for="client in list" :key="client.uuid">
          <AppTableBody
            :class="{ 'is-expanded': isExpandedProject(project.id) }"
            v-for="project in client.projects"
            :key="project.id"
          >
            <AppTableTr>
              <AppTableTd nowrap v-if="!clientUuid">{{ client.name }}</AppTableTd>
              <AppTableTd nowrap class="font-bold">
                {{ project.service.name }}
              </AppTableTd>
              <AppTableTd nowrap v-if="!projectId">
                <RouterLink
                  target="_blank"
                  :to="{ name: 'projects.view', params: { uuid: client.uuid, id: project.id } }"
                >
                  {{ project.name }}
                </RouterLink>
              </AppTableTd>
              <AppTableTd nowrap v-if="!clientUuid">
                {{ client.responsible.name }}
              </AppTableTd>
              <AppTableTd nowrap v-if="!projectId">
                {{ project.responsible.name }}
              </AppTableTd>
              <AppTableTd nowrap>
                {{
                  DateTime.fromFormat(getMinPeriod(project.events).toString(), 'yyyyMM')
                    .setLocale(locale)
                    .toFormat('yyyy-MMM')
                }}
                <span v-if="getMinPeriod(project.events) !== getMaxPeriod(project.events)">
                  -
                  {{
                    DateTime.fromFormat(getMaxPeriod(project.events).toString(), 'yyyyMM')
                      .setLocale(locale)
                      .toFormat('yyyy-MMM')
                  }}
                </span>
              </AppTableTd>
              <AppTableTd nowrap>
                {{
                  t(
                    `checkpoint.events_status.${hasDoneEvents(project.events) ? 'done' : 'not_done'}`,
                    getDoneEvents(project.events),
                  )
                }}
              </AppTableTd>
              <AppTableTd class="text-right" nowrap>
                <AppButton
                  v-if="isExpandedProject(project.id)"
                  @click.prevent="collapseProject(project.id)"
                  light
                  circle
                  size="small"
                >
                  <FontIcon name="chevron-up" />
                </AppButton>
                <AppButton v-else @click.prevent="expandProject(project.id)" light circle size="small">
                  <FontIcon name="chevron-down" />
                </AppButton>
              </AppTableTd>
            </AppTableTr>
            <template v-if="isExpandedProject(project.id)">
              <AppTableTr
                @click.prevent="onEdit(event)"
                class="hoverable pointer"
                v-for="event in project.events"
                :key="event.id"
              >
                <AppTableTd v-if="!clientUuid" />
                <AppTableTd nowrap>{{ event.name }}</AppTableTd>
                <AppTableTd v-if="!projectId" />
                <AppTableTd v-if="!clientUuid" />
                <AppTableTd v-if="!projectId" />
                <AppTableTd nowrap>
                  {{
                    DateTime.fromFormat(event.year_month.toString(), 'yyyyMM').setLocale(locale).toFormat('yyyy-MMM')
                  }}
                </AppTableTd>
                <AppTableTd nowrap>
                  <div class="d-flex align-items-center">
                    <div class="form-group mb-0" @click.stop>
                      <input
                        :id="`event-state-${event.id}`"
                        type="checkbox"
                        class="form-switch"
                        :checked="event.state === CheckpointEventStateType.DONE"
                        @change="onStateChange(event, $event)"
                      />
                      <label
                        :for="`event-state-${event.id}`"
                        class="form-label"
                        v-tooltip.bottom="`${t('common.updated')}: ${d(event.state_changed_at)}`"
                        v-text="'&nbsp;'"
                      />
                    </div>
                    <FontIcon
                      v-if="event.comment"
                      v-tooltip.bottom="event.comment"
                      class="text-3 text-neutral-500"
                      name="info-circle"
                    />
                  </div>
                </AppTableTd>
                <AppTableTd />
              </AppTableTr>
            </template>
          </AppTableBody>
        </template>
      </AppTable>
      <AppAlert v-else>{{ t('checkpoint.empty') }}</AppAlert>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.current-month-accent {
  background-color: var(--color-success-500-hex);
  color: var(--color-success-100-hex);
}
.current-month-blink {
  background-color: var(--color-neutral-50-hex);
}
.is-expanded {
  border: 2px solid var(--color-neutral-400-hex);
}
</style>
