export default {
  index: {
    title: 'Kundbesök',
    help: '',
    filters: {
      assigned_user: 'Anställd',
      project_responsible: 'Projektansvarig',
      date_from: 'Från',
      date_to: 'Till',
      not_booked: 'Ej bokat',
    },
  },
  status: {
    booked: 'Inbokat',
    not_booked: 'Ej bokat',
  },
  attributes: {
    employee: 'Anställd',
    project_responsible: 'Projektansvarig',
    week: 'Vecka',
    client: 'Kundnamn',
    status: 'Status',
    address: 'Besöksadress (Stad)',
    task: 'Uppgift',
    financial_year: 'Räkenskapsår',
  },
  empty: 'Inga uppgifter',
};
