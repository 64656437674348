export default {
  // Settings
  settings: {
    title: 'Salary settings',
    help: '',
    attributes: {
      system: 'Salary system',
      mileage_allowance_amount: 'Mileage allowance amount',
      car_reinbursment_heading: 'Mileage allowance',
      car_type: 'Type of car',
      car_type_own_car: 'Own car',
      car_type_employee_diesel: 'Company car diesel',
      car_type_employee_other: 'Company car gas/other',
      car_type_employee_electric: 'Company car electric',
      payment_km_taxable: 'Allowance before tax',
      payment_km_taxfree: 'Allowance taxfree part',
      salary_code_taxable: 'Salary code taxable',
      salary_code_taxfree: 'Salary code taxfree',
    },
  },

  // Codes
  codes: {
    title: 'Salary codes',
    help: '',
    add_new: 'Add new',
    empty: 'No salary codes',
    create: {
      title: 'Create salary code',
    },
    edit: {
      title: 'Edit salary code',
    },
    destroy: {
      title: 'Delete salary code',
      text: 'Are you sure you want to delete <strong>{name}</strong> salary code?',
    },
    attributes: {
      name: 'Name',
      system_id: 'ID',
      type: 'Type',
      absence_type: 'Absence type',
      is_default: 'Default',
      client_work_time: 'Client work time',
    },
  },

  // Messages
  message: {
    system_is_not_set: 'Set salary system',
  },
};
