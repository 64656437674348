import route from 'ziggy-js';
import { Options } from 'ky';
import httpClient from '@/services/api/ky';

import { IResponse } from '@/types/Response';
import { IFreeDayRequest, IFreeDayResource } from '@/types/FreeDay';

export default {
  async index(options: Options = {}) {
    return await httpClient.get(route('free-days.index'), options).json<IResponse<IFreeDayResource[]>>();
  },
  async store(json: IFreeDayRequest, options: Options = {}) {
    return await httpClient.post(route('free-days.create'), { ...options, json }).json<IResponse<IFreeDayResource>>();
  },
  async get(freeDay: number, options: Options = {}) {
    return await httpClient.get(route('free-days.get', { freeDay }), options).json<IResponse<IFreeDayResource>>();
  },
  async update(freeDay: number, json: IFreeDayRequest, options: Options = {}) {
    return await httpClient
      .patch(route('free-days.update', { freeDay }), { ...options, json })
      .json<IResponse<IFreeDayResource>>();
  },
  async destroy(freeDay: number, options: Options = {}) {
    return await httpClient.delete(route('free-days.delete', { freeDay }), options).json<void>();
  },
};
