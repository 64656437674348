import { createRouter, createWebHashHistory } from 'vue-router';

import accounting from '@/router/accounting';
import activities from '@/router/activities';
import auth from '@/router/auth';
import businessDay from '@/router/business-day';
import checkpoints from '@/router/checkpoints';
import clients from '@/router/clients';
import contacts from '@/router/contacts';
import coworkers from '@/router/coworkers';
import deadlines from '@/router/deadlines';
import departments from '@/router/departments';
import freeDays from '@/router/free-days';
import internalProjects from '@/router/internal-projects';
import invoices from '@/router/invoices';
import main from '@/router/main';
import notFound from '@/router/not-found';
import offices from '@/router/offices';
import priceMatrix from '@/router/price-matrix';
import projects from '@/router/projects';
import resourcePlanning from '@/router/resource-planning';
import salary from '@/router/salary';
import serviceMapping from '@/router/service-mapping';
import serviceCompanies from '@/router/service-companies';
import services from '@/router/services';
import timePeriods from '@/router/time-periods';
import timeSheets from '@/router/time-sheets';
import todos from '@/router/todos';
import users from '@/router/users';
import workTitles from '@/router/work-titles';

import useAuthStore from '@/store/AuthStore';

const routes = [
  ...accounting,
  ...activities,
  ...auth,
  ...businessDay,
  ...checkpoints,
  ...clients,
  ...contacts,
  ...coworkers,
  ...deadlines,
  ...departments,
  ...freeDays,
  ...internalProjects,
  ...invoices,
  ...main,
  ...offices,
  ...priceMatrix,
  ...projects,
  ...resourcePlanning,
  ...salary,
  ...serviceMapping,
  ...serviceCompanies,
  ...services,
  ...timePeriods,
  ...timeSheets,
  ...workTitles,
  ...todos,
  ...users,
  ...notFound,
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  linkExactActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { el: '#content', top: 0, behavior: 'smooth' };
  },
});

router.beforeEach(async (to) => {
  const { authenticatedUser } = useAuthStore();

  if (!authenticatedUser && to.meta.requiresAuth) {
    return { name: 'login' };
  }

  if (authenticatedUser && to.meta.isAuthPage) {
    return { name: 'dashboard' };
  }

  return true;
});

export default router;
