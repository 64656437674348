export default {
  index: {
    title: 'Helgdagskalender',
    help: '',
    create: 'Lägg till helgdag',
  },

  create: {
    title: 'Skapa helgdag',
  },
  edit: {
    title: 'Redigera helgdag',
  },

  type: {
    imported: 'Importerad',
    added: 'Tillagd',
  },

  empty: 'Inga helgdagar',

  attributes: {
    date: 'Datum',
    name: 'Helgdag',
    weekday: 'Veckodag',
    workday: 'Arbetsdag %',
    type: 'Typ',
  },

  confirm: {
    destroy: {
      title: 'Radera helgdag',
      text: 'Är du säker på att du vill radera <strong>{name}</strong>?',
    },
  },
};
