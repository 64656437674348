import { defineStore } from 'pinia';
import { ref } from 'vue';
import api from '@/services/api';
import { ICustomDayDurationResource, ICustomDayDurationsListResource } from '@/types/BusinessDay';

const useDayDurationStore = defineStore('DayDurationStore', () => {
  const defaultWorkingHours = ref(0);
  const customDayDuration = ref<ICustomDayDurationResource[]>([]);
  const dayDurationLoading = ref(false);

  async function getDayDuration() {
    try {
      dayDurationLoading.value = true;
      const response = await api.businessDay.list();
      setDayDuration(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      dayDurationLoading.value = false;
    }
  }

  function setDayDuration(data: ICustomDayDurationsListResource) {
    defaultWorkingHours.value = data.default_working_hours;
    customDayDuration.value = data.custom_day_durations;
  }

  return { defaultWorkingHours, customDayDuration, dayDurationLoading, getDayDuration };
});

export default useDayDurationStore;
