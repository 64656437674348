<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import useTime from '@/composables/useTime';
import useDate from '@/composables/useDate';
import { AppTable, AppTableHead, AppTableBody, AppTableTr, AppTableTh, AppTableTd } from '@/components';
import { ISplitEventResource } from '@/types/Event';

defineProps<{
  splitEvents: ISplitEventResource[];
  originalScheduledTime: number;
}>();

const { t } = useI18n({ useScope: 'global' });
const { convertMinutesToTime } = useTime();
const { formatYearWeek } = useDate();
</script>

<template>
  <div v-if="splitEvents.length > 0">
    <h3 v-text="t('time-entry.modal.split_events.title')" />
    <h4>
      {{ t('time-entry.modal.split_events.original_scheduled_time') }}:
      {{ convertMinutesToTime(originalScheduledTime) }}
    </h4>
    <AppTable hoverable>
      <AppTableHead>
        <AppTableTr>
          <AppTableTh class="pl-0" nowrap>
            {{ t('time-entry.modal.split_events.week') }}
          </AppTableTh>
          <AppTableTh nowrap>
            {{ t('time-entry.modal.split_events.time') }}
          </AppTableTh>
          <AppTableTh class="pr-0" nowrap>
            {{ t('time-entry.modal.split_events.employee') }}
          </AppTableTh>
        </AppTableTr>
      </AppTableHead>
      <AppTableBody>
        <AppTableTr v-for="event in splitEvents" :key="event.id">
          <AppTableTd class="pl-0">{{ formatYearWeek(event.week) }}</AppTableTd>
          <AppTableTd>{{ convertMinutesToTime(event.tracked_time) }}</AppTableTd>
          <AppTableTd class="pr-0">{{ event.user.name }}</AppTableTd>
        </AppTableTr>
      </AppTableBody>
    </AppTable>
  </div>
</template>
