<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { AppBox, AppBoxBody } from '@/components';

const { t } = useI18n({ useScope: 'global' });
</script>

<template>
  <div class="container-fluid">
    <AppBox>
      <AppBoxBody>
        <div class="text-center py-5">
          <h1 v-text="t('common.not_found.title')" />
          <p class="mb-0" v-text="t('common.not_found.text')"></p>
        </div>
      </AppBoxBody>
    </AppBox>
  </div>
</template>
