export default {
  index: {
    title: 'Resource planning',
    help: '',
  },
  filters: {
    from: 'From week',
    to: 'To week',
    offices: 'Offices',
    departments: 'Departments',
    work_titles: 'Titles',
    employees: 'Employees',
  },
  attributes: {
    total: 'Total',
    name: 'Name',
    office: 'Office',
    department: 'Division',
    work_rate: 'Workrate',
    planned: 'Planned H',
    available: 'Available H',
    net: 'Net H',
  },
};
