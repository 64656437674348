import route from 'ziggy-js';
import { Options, SearchParamsOption } from 'ky';
import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import {
  IGetProjectTasksEstimatesRequestBody,
  IProjectClientTabResource,
  IProjectEstimates,
  IProjectResource,
  IProjectMargin,
  IProjectPreviewResource,
  IProjectRequestActivateStepBody,
  IProjectRequestNotes,
  IProjectRequestPriceStepBody,
  IProjectRequestServiceStepBody,
  IProjectRequestTeamStepBody,
  IProjectTaskEstimates,
  IManageProjectTasksRequest,
  IProjectTeamMemberListResource,
  IProjectTeamMemberRequestBody,
  IProjectWithTeamsResource,
  IProjectTaskGeneratedEvents,
  IProjectTaskGenerateEventsRequestBody,
  ICancelOrRolloverProjectOptions,
} from '@/types/Project';
import { IServiceTaskResource } from '@/types/Service';
import { IClientPlanningResource } from '@/types/Client';

export default {
  async list(searchParams?: SearchParamsOption): Promise<IResponse<IProjectPreviewResource[]>> {
    return await httpClient.get(route('clients.projects.list'), { searchParams }).json();
  },
  async index(client: string, searchParams: SearchParamsOption): Promise<IResponse<IProjectPreviewResource[]>> {
    return await httpClient.get(route('clients.projects.index', { client }), { searchParams }).json();
  },
  async listWithFullResource(
    client: string,
    searchParams: SearchParamsOption,
  ): Promise<IResponse<IProjectWithTeamsResource[]>> {
    return await httpClient.get(route('clients.projects.list-with-full-resource', { client }), { searchParams }).json();
  },
  async listProjectTabResource(
    client: string,
    searchParams: SearchParamsOption,
  ): Promise<IResponse<IProjectClientTabResource[]>> {
    return await httpClient.get(route('clients.projects.list-for-project-tab', { client }), { searchParams }).json();
  },
  async listForPlanning(client: string, searchParams: SearchParamsOption): Promise<IResponse<IClientPlanningResource>> {
    return await httpClient.get(route('clients.projects.list-for-planning', { client }), { searchParams }).json();
  },
  async get(client: string, id: number): Promise<IResponse<IProjectResource>> {
    return await httpClient.get(route('clients.projects.get', { client, id })).json();
  },
  async store(body: IProjectRequestServiceStepBody, client: string): Promise<IResponse<IProjectResource>> {
    return await httpClient.post(route('clients.projects.store', { client }), { json: body }).json();
  },
  async update(
    body:
      | IProjectRequestServiceStepBody
      | IProjectRequestPriceStepBody
      | IProjectRequestTeamStepBody
      | IProjectRequestActivateStepBody
      | IProjectRequestNotes,
    client: string,
    id: number,
    options?: Options,
  ): Promise<IResponse<IProjectResource>> {
    return await httpClient
      .patch(route('clients.projects.update', { client, id }), {
        json: body,
        ...(options ?? {}),
      })
      .json();
  },
  async cancel(
    client: string,
    id: number,
    body: ICancelOrRolloverProjectOptions,
  ): Promise<IResponse<IProjectResource>> {
    return await httpClient.post(route('clients.projects.cancel', { client, id }), { json: body }).json();
  },
  async rollover(
    client: string,
    id: number,
    body: ICancelOrRolloverProjectOptions,
  ): Promise<IResponse<IProjectResource>> {
    return await httpClient.post(route('clients.projects.rollover', { client, id }), { json: body }).json();
  },
  async reactivate(client: string, id: number): Promise<IResponse<never>> {
    return await httpClient.post(route('clients.projects.reactivate', { client, id })).json();
  },
  async destroy(client: string, id: number): Promise<IResponse<never>> {
    return await httpClient.delete(route('clients.projects.delete', { client, id })).json();
  },
  async margin(searchParams: SearchParamsOption): Promise<{ [key: number]: IProjectMargin }> {
    return await httpClient.get(route('clients.projects.margin'), { searchParams }).json();
  },

  teamMember: {
    async list(client: string, id: number): Promise<IResponse<IProjectTeamMemberListResource[]>> {
      return await httpClient.get(route('clients.projects.team-members.index', { client, id })).json();
    },
    async add(
      client: string,
      id: number,
      body: IProjectTeamMemberRequestBody,
    ): Promise<IResponse<IProjectTeamMemberListResource>> {
      return await httpClient.put(route('clients.projects.team-members.add', { client, id }), { json: body }).json();
    },
    async destroy(client: string, id: number, user_uuid: string): Promise<IResponse<never>> {
      return await httpClient.delete(route('clients.projects.team-members.delete', { client, id, user_uuid })).json();
    },
  },
  serviceTasks: {
    async index(client: string, id: number): Promise<IResponse<IServiceTaskResource[]>> {
      return await httpClient.get(route('clients.projects.service-tasks.index', { client, id })).json();
    },
  },
  tasks: {
    async update(client: string, id: number, body: IManageProjectTasksRequest): Promise<IResponse<IProjectResource>> {
      return await httpClient.put(route('clients.projects.tasks.manage', { client, id }), { json: body }).json();
    },
    async getEstimates(
      client: string,
      id: number,
      body: IGetProjectTasksEstimatesRequestBody,
    ): Promise<IProjectTaskEstimates> {
      return await httpClient
        .post(route('clients.projects.tasks.get_estimates', { client, id }), { json: body })
        .json();
    },
    async generateEvents(
      client: string,
      id: number,
      serviceTask: number,
      body: IProjectTaskGenerateEventsRequestBody,
    ): Promise<IProjectTaskGeneratedEvents[]> {
      return await httpClient
        .post(route('clients.projects.tasks.generate_events', { client, id, serviceTask }), { json: body })
        .json();
    },
  },

  async summaryEstimates(client: string, id: number, options: Options): Promise<IProjectEstimates> {
    return await httpClient.post(route('clients.projects.summary_estimates', { client, id }), options).json();
  },
};
