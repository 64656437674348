export default {
  index: {
    title: 'Business day management',
    help: '',
  },

  empty: 'No business days',

  // Attributes
  attributes: {
    default_working_hours: 'Default working hours',
    work_day_durations: 'Working day durations',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
  },

  confirm: {
    destroy: {
      title: 'Delete Business day management',
      text: 'Are you sure you want to delete <strong>{hours}</strong> hours from Business day management',
      textIfIssetMonths: 'Please move all months before deleting the row',
    },
  },
};
