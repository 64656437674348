export default {
  index: {
    title: 'Time periods',
    help: '',
  },
  form: {
    status: 'Status',
  },

  empty: 'No time periods',

  // Attributes
  attributes: {
    year: 'Year',
    month: 'Month',
    status: 'Status',
  },

  status: {
    closed: 'Closed',
    opened: 'Opened',
  },

  tooltip: {
    open: 'Open time period',
    close: 'Close time period',
  },

  // Confirms
  confirm: {
    change_status: {
      title: 'Change status',
      opened: 'Are you sure you want to close this time period?',
      closed: 'Are you sure you want to open this time period?',
    },
  },
};
