<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal';

type Props = {
  size?: 'small' | 'default' | 'large' | 'huge';
};

const { size = 'default' } = defineProps<Props>();

const emit = defineEmits<{
  (e: 'beforeOpen', event: { stop: () => void }): void;
  (e: 'opened'): void;
  (e: 'beforeClose', event: { stop: () => void }): void;
  (e: 'closed'): void;
  (e: 'clickOutside'): void;
}>();
</script>
<template>
  <VueFinalModal
    @before-open="emit('beforeOpen', $event)"
    @opened="emit('opened')"
    @before-close="emit('beforeClose', $event)"
    @closed="emit('closed')"
    @click-outside="emit('clickOutside')"
    class="modal-overlay modal-teleport"
    :content-class="['modal-container', `is-${size}`]"
    :click-to-close="false"
    :esc-to-close="true"
    v-slot="{ close }"
  >
    <slot :close="close" />
  </VueFinalModal>
</template>
