<script lang="ts" setup>
import { ITodoCommentResource } from '@/types/Todo';
import { useI18n } from 'vue-i18n';
import useAuthStore from '@/store/AuthStore';

const { comment } = defineProps<{
  comment: ITodoCommentResource;
}>();

const { d } = useI18n({ useScope: 'global' });
const { authenticatedUser } = useAuthStore();
</script>

<template>
  <li class="comment-item" :class="{ my: comment.createdByUserUuid === authenticatedUser.uuid }">
    <div class="comment-author">{{ comment.authorUser.name }}</div>
    <div class="comment-content" v-html="comment.text" />
    <div class="comment-date">
      {{ d(comment.createdAt, 'full') }}
    </div>
  </li>
</template>
