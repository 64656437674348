import { RouteRecordRaw } from 'vue-router';

import MainLayout from '@/layouts/MainLayout.vue';
import BusinessDay from '@/views/BusinessDay.vue';
import useDayDurationStore from '@/store/DayDurationStore';
import progress from '@/services/progress';

const prefix = 'business-day';

const routes: RouteRecordRaw[] = [
  {
    path: `/${prefix}`,
    name: `${prefix}.index`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: BusinessDay,
    async beforeEnter(to, from, next) {
      progress.start();
      const { getDayDuration } = useDayDurationStore();
      await getDayDuration();
      progress.done();
      next();
    },
  },
];

export default routes;
