<script lang="ts" setup>
import { IContactFullResource, IContactRequestBody } from '@/types/Contact';
import {
  AppAlert,
  AppBox,
  AppButton,
  AppLoader,
  AppPagination,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  ClientContactModal,
  ConfirmModal,
  EmptyValue,
  FontIcon,
  PortalUserStatus,
} from '@/components';
import { useI18n } from 'vue-i18n';
import useSystems from '@/composables/useSystems';
import { useModal } from 'vue-final-modal';
import { ConfirmDialogConfirmParams } from '@/types/Common';
import api from '@/services/api';
import toast from '@/services/toast';
import usePermissions from '@/composables/usePermissions';

const emit = defineEmits<{
  updated: [];
  deleted: [];
}>();

type Column =
  | 'name'
  | 'short_name'
  | 'personal_number'
  | 'email'
  | 'phone'
  | 'roles'
  | 'subscription_list'
  | 'owner'
  | 'access_to_portal'
  | 'portal_status'
  | 'actions';

type Props = {
  title: string;
  clientUuid: string;
  clientCreatedWithCreditSafe: boolean;
  contacts: IContactFullResource[];
  loading: boolean;
  columns: Column[];
  settingUseWeConnect: null | boolean;
  total: number;
  perPage: number;
};

const [page] = defineModel<number>('page', { required: true });

const {
  title,
  clientUuid,
  clientCreatedWithCreditSafe,
  contacts,
  loading,
  columns,
  settingUseWeConnect,
  total,
  perPage,
} = defineProps<Props>();

const { t, locale } = useI18n({ useScope: 'global' });
const { isSubscribedToWeConnect } = useSystems();
const { can } = usePermissions();

function onPortalAccess(contact: IContactFullResource) {
  const isActive = ['invited', 'active'].includes(contact.portal_status);
  const { open, close, destroy } = useModal({
    component: ConfirmModal,
    attrs: {
      title: t(`user.portal.confirm.${isActive ? 'deactivate' : 'activate'}.title`),
      message: t(`user.portal.confirm.${isActive ? 'deactivate' : 'activate'}.text`, {
        name: contact.name,
      }),
      async onConfirm({ setLoading }: ConfirmDialogConfirmParams) {
        try {
          setLoading(true);
          await api.users.portal.companyContacts[isActive ? 'deactivate' : 'activate'](contact.uuid);
          emit('updated');
          toast.success(t('common.updated'));
          await close();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onEdit(contact: IContactFullResource) {
  const { open, close, destroy } = useModal({
    component: ClientContactModal,
    attrs: {
      title: t('client.contact.modal.edit_title', { name: contact.name }),
      clientCreatedWithCreditSafe,
      contact,
      async onUpdate(id: string, form: IContactRequestBody, { setLoading }: ConfirmDialogConfirmParams) {
        try {
          setLoading(true);
          await api.clients.contacts.update(clientUuid, id, form);
          emit('updated');
          // toast.success(t('common.updated'));
          await close();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onCancel() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onDelete(contact: IContactFullResource) {
  const { open, close, destroy } = useModal({
    component: ConfirmModal,
    attrs: {
      title: t('client.contact.confirm.delete.title'),
      message: t('client.contact.confirm.delete.text', { name: contact.name }),
      async onConfirm({ setLoading }: ConfirmDialogConfirmParams) {
        try {
          setLoading(true);
          await api.clients.contacts.delete(clientUuid, contact.uuid);
          emit('deleted');
          toast.success(t('common.deleted'));
          await close();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}
</script>

<template>
  <div id="contacts">
    <div class="d-flex align-items-center justify-content-between mb-3">
      <h2 class="mb-0" v-text="title" />
      <slot name="buttons" />
    </div>
    <AppBox shadow v-if="loading" class="text-center py-3">
      <AppLoader />
    </AppBox>
    <template v-else>
      <AppAlert v-if="contacts.length === 0">{{ t('client.contact.empty') }}</AppAlert>
      <AppTable v-else hoverable>
        <AppTableHead>
          <AppTableTr>
            <AppTableTh v-if="columns.includes('name')" nowrap>{{ t('client.contact.name') }}</AppTableTh>
            <AppTableTh v-if="columns.includes('short_name')" nowrap>{{ t('client.contact.short_name') }}</AppTableTh>
            <AppTableTh v-if="columns.includes('personal_number')" nowrap>
              {{ t('client.contact.personal_number') }}
            </AppTableTh>
            <AppTableTh v-if="columns.includes('email')" nowrap>{{ t('client.contact.email') }}</AppTableTh>
            <AppTableTh v-if="columns.includes('phone')" nowrap>{{ t('client.contact.phone') }}</AppTableTh>
            <AppTableTh v-if="columns.includes('roles')" nowrap>{{ t('client.contact.roles') }}</AppTableTh>
            <AppTableTh v-if="columns.includes('subscription_list')">
              {{ t('client.contact.subscription_list') }}
            </AppTableTh>
            <AppTableTh v-if="columns.includes('owner')">{{ t('client.contact.owner') }}</AppTableTh>
            <template v-if="isSubscribedToWeConnect">
              <AppTableTh v-if="columns.includes('access_to_portal')">
                {{ t('client.contact.access_to_portal') }}
              </AppTableTh>
              <AppTableTh v-if="columns.includes('portal_status')" nowrap>
                {{ t('client.contact.portal_status') }}
              </AppTableTh>
            </template>
            <AppTableTh v-if="columns.includes('actions')" nowrap class="text-right">
              {{ t('common.actions') }}
            </AppTableTh>
          </AppTableTr>
        </AppTableHead>
        <AppTableBody>
          <AppTableTr v-for="contact in contacts" :key="contact.uuid">
            <AppTableTd v-if="columns.includes('name')">
              <strong v-text="contact.name" />
            </AppTableTd>
            <AppTableTd v-if="columns.includes('short_name')" nowrap>
              <span v-if="contact.short_name" v-text="contact.short_name" />
              <EmptyValue v-else />
            </AppTableTd>
            <AppTableTd v-if="columns.includes('personal_number')" nowrap>
              <span v-if="contact.personal_number" v-text="contact.personal_number" />
              <EmptyValue v-else />
            </AppTableTd>
            <AppTableTd v-if="columns.includes('email')" nowrap>
              <span v-if="contact.email" v-text="contact.email" />
              <i v-else class="text-danger-500" v-text="t('common.empty')" />
            </AppTableTd>
            <AppTableTd v-if="columns.includes('phone')" nowrap>
              <span v-if="contact.phone" v-text="contact.phone" />
              <EmptyValue v-else />
            </AppTableTd>
            <AppTableTd v-if="columns.includes('roles')">
              {{ contact.roles.map((role) => role.name[locale]).join(', ') }}
            </AppTableTd>
            <AppTableTd v-if="columns.includes('subscription_list')" nowrap>
              <div class="text-3">
                <FontIcon class="text-neutral-500" v-if="contact.is_in_subscription_list" name="checkbox" />
                <FontIcon class="text-neutral-200" v-else name="square" />
              </div>
            </AppTableTd>
            <AppTableTd v-if="columns.includes('owner')" nowrap>
              <div class="text-3">
                <FontIcon class="text-neutral-500" v-if="contact.is_owner" name="checkbox" />
                <FontIcon class="text-neutral-200" v-else name="square" />
              </div>
            </AppTableTd>
            <template v-if="isSubscribedToWeConnect">
              <AppTableTd v-if="columns.includes('access_to_portal')" nowrap>
                <div
                  v-tooltip.left="
                    settingUseWeConnect === false
                      ? t('client.contact.tooltip.activate_we_connect')
                      : !contact.email
                      ? t('validation.required', { name: t('client.contact.email') })
                      : null
                  "
                >
                  <div class="form-group mb-0">
                    <input
                      :id="`${contact.uuid}-access-to-portal`"
                      type="checkbox"
                      class="form-switch"
                      :disabled="!contact.email || settingUseWeConnect === false"
                      :checked="['invited', 'active'].includes(contact.portal_status)"
                    />
                    <label
                      :for="`${contact.uuid}-access-to-portal`"
                      class="form-label"
                      @click.prevent="onPortalAccess(contact)"
                      v-text="'&nbsp;'"
                    />
                  </div>
                </div>
              </AppTableTd>
              <AppTableTd v-if="columns.includes('portal_status')" nowrap>
                <PortalUserStatus :status="contact.portal_status" />
              </AppTableTd>
            </template>

            <AppTableTd v-if="columns.includes('actions')" nowrap class="text-right">
              <AppButton
                v-tooltip.left="t('client.contact.tooltip.edit', { name: contact.name })"
                @click.stop.prevent="onEdit(contact)"
                size="small"
                light
                circle
              >
                <FontIcon name="pencil" />
              </AppButton>

              <AppButton
                v-tooltip.left="t('client.contact.tooltip.destroy', { name: contact.name })"
                @click.stop.prevent="onDelete(contact)"
                size="small"
                color="danger"
                class="ml-2"
                light
                circle
                v-if="can('contacts.delete', contact.is_manual_created)"
              >
                <FontIcon name="trash" />
              </AppButton>
            </AppTableTd>
          </AppTableTr>
        </AppTableBody>
      </AppTable>
      <AppPagination class="mt-3" :per-page="perPage" :total="total" v-model="page" />
    </template>
  </div>
</template>
