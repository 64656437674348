export enum SalarySystem {
  VISMA = 'visma',
  HOGIA = 'hogia',
  OTHER = 'other',
}

export interface ISalarySettingsResource {
  system: SalarySystem;
  payment_km_tax_free_own_car: number;
  payment_km_before_tax_own_car: number;
  payment_km_before_tax_employee_car_other: number;
  payment_km_tax_free_employee_car_other: number;
  payment_km_before_tax_employee_car_diesel: number;
  payment_km_tax_free_employee_car_diesel: number;
  payment_km_before_tax_employee_car_electric: number;
  payment_km_tax_free_employee_car_electric: number;

  own_car_tax_free_pay_type_id: null | string;
  own_car_taxable_pay_type_id: null | string;
  employee_car_other_tax_free_pay_type_id: null | string;
  employee_car_other_taxable_pay_type_id: null | string;
  employee_car_diesel_tax_free_pay_type_id: null | string;
  employee_car_diesel_taxable_pay_type_id: null | string;
  employee_car_electricity_tax_free_pay_type_id: null | string;
  employee_car_electricity_taxable_pay_type_id: null | string;
}

export interface ISalarySettingsRequest {
  system: null | SalarySystem;
  payment_km_tax_free_own_car: number;
  payment_km_before_tax_own_car: number;
  payment_km_before_tax_employee_car_other: number;
  payment_km_tax_free_employee_car_other: number;
  payment_km_before_tax_employee_car_diesel: number;
  payment_km_tax_free_employee_car_diesel: number;
  payment_km_before_tax_employee_car_electric: number;
  payment_km_tax_free_employee_car_electric: number;

  own_car_tax_free_pay_type_id: null | string;
  own_car_taxable_pay_type_id: null | string;
  employee_car_other_tax_free_pay_type_id: null | string;
  employee_car_other_taxable_pay_type_id: null | string;
  employee_car_diesel_tax_free_pay_type_id: null | string;
  employee_car_diesel_taxable_pay_type_id: null | string;
  employee_car_electricity_tax_free_pay_type_id: null | string;
  employee_car_electricity_taxable_pay_type_id: null | string;
}

export interface ISalaryCodeResource {
  id: number;
  name: string;
  system_id: string;
  type: string;
  absence_type: null | string;
  is_default: boolean;
  client_work_time: boolean;
  is_in_use: boolean;
}

export interface ISalaryCodeRequest {
  name: null | string;
  system_id: null | string;
  type: null | string;
  absence_type: null | string;
  is_default: boolean;
  client_work_time: boolean;
}
