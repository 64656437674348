import api from '@/services/api';
import { ref } from 'vue';
import { Options } from 'ky';
import { IFinancialYearListResource, IFinancialYearResource } from '@/types/FinancialYear';
import { useModal } from 'vue-final-modal';
import { ConfirmModal, FinancialYearModal } from '@/components';
import { DateTime } from 'luxon';
import { ConfirmDialogConfirmParams } from '@/types/Common';
import { useI18n } from 'vue-i18n';

type GetFinancialYearsHooks = {
  onSet?: (data: IFinancialYearListResource[]) => IFinancialYearListResource[];
  onError?: (error: unknown) => void;
  onFinally?: () => void;
};

export default function useFinancialYears(clientUuid: string) {
  const { t } = useI18n({ useScope: 'global' });
  const financialYears = ref<IFinancialYearListResource[]>([]);
  const financialYearsLoading = ref(false);

  async function getFinancialYears(options?: Options, hooks?: GetFinancialYearsHooks) {
    try {
      financialYearsLoading.value = true;
      const response = await api.financialYears.list(clientUuid, options);
      financialYears.value = hooks?.onSet ? hooks.onSet(response.data) : response.data;
    } catch (error) {
      console.error(error);
      if (hooks?.onError) {
        hooks?.onError(error);
      }
    } finally {
      financialYearsLoading.value = false;
      if (hooks?.onFinally) {
        hooks?.onFinally();
      }
    }
  }

  async function openFinancialYearCreateModal({
    onCreated,
  }: {
    onCreated: (
      financialYear: IFinancialYearResource,
      { close }: { close: () => Promise<string> },
    ) => void | Promise<void>;
  }) {
    const { open, close, destroy } = useModal({
      component: FinancialYearModal,
      attrs: {
        clientUuid,
        initialStartDate:
          financialYears.value.length === 0
            ? DateTime.now().startOf('year').toFormat('yyyy-MM-dd')
            : DateTime.fromISO(financialYears.value[0].end_date).plus({ days: 1 }).toFormat('yyyy-MM-dd'),
        disabledStartDate: financialYears.value.length > 0,
        async onCreated(financialYear: IFinancialYearResource) {
          await onCreated(financialYear, { close });
        },
        onClosed() {
          destroy();
        },
      },
    });
    await open();
  }

  async function openFinancialYearEditModal(
    financialYear: IFinancialYearResource,
    {
      onUpdated,
    }: {
      onUpdated: (
        financialYear: IFinancialYearResource,
        { close }: { close: () => Promise<string> },
      ) => void | Promise<void>;
    },
  ) {
    const { open, close, destroy } = useModal({
      component: FinancialYearModal,
      attrs: {
        clientUuid,
        financialYear,
        async onUpdated(financialYear: IFinancialYearResource) {
          await onUpdated(financialYear, { close });
        },
        onClosed() {
          destroy();
        },
      },
    });
    await open();
  }

  async function openFinancialYearDeleteModal(
    financialYear: IFinancialYearResource,
    {
      onDeleted,
    }: {
      onDeleted: ({ close }: { close: () => Promise<string> }) => void | Promise<void>;
    },
  ) {
    const { open, close, destroy } = useModal({
      component: ConfirmModal,
      attrs: {
        title: t('financial-year.confirm.delete.title'),
        message: t('financial-year.confirm.delete.text'),
        async onConfirm({ setLoading }: ConfirmDialogConfirmParams) {
          try {
            setLoading(true);
            await api.financialYears.destroy(clientUuid, financialYear.uuid);
            await onDeleted({ close });
          } catch (error) {
            console.error(error);
          } finally {
            setLoading(false);
          }
        },
        onCancel() {
          close();
        },
        onClosed() {
          destroy();
        },
      },
    });
    await open();
  }

  return {
    financialYears,
    financialYearsLoading,
    getFinancialYears,
    openFinancialYearCreateModal,
    openFinancialYearEditModal,
    openFinancialYearDeleteModal,
  };
}
