import route from 'ziggy-js';
import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import { SearchParamsOption } from 'ky';
import {
  ICrmPlannerServiceMapListResource,
  ICrmPlannerServiceMapRequestBody,
  ICrmServiceListResource,
  ICrmServiceRequestBody,
} from '@/types/Crm';

export default {
  services: {
    async list(searchParams?: SearchParamsOption): Promise<IResponse<ICrmServiceListResource[]>> {
      return await httpClient.get(route('crm.service.index'), { searchParams }).json();
    },
    async update(crmService: string, body: ICrmServiceRequestBody): Promise<IResponse<ICrmServiceListResource>> {
      return await httpClient.patch(route('crm.service.update', { crmService }), { json: body }).json();
    },
  },
  mappedServices: {
    async list(searchParams?: SearchParamsOption): Promise<IResponse<ICrmPlannerServiceMapListResource[]>> {
      return await httpClient.get(route('crm.planner-service-mapping.index'), { searchParams }).json();
    },
    async store(body: ICrmPlannerServiceMapRequestBody): Promise<IResponse<ICrmPlannerServiceMapListResource>> {
      return await httpClient.post(route('crm.planner-service-mapping.store'), { json: body }).json();
    },
    async update(
      service: string,
      body: ICrmPlannerServiceMapRequestBody,
    ): Promise<IResponse<ICrmPlannerServiceMapListResource>> {
      return await httpClient.patch(route('crm.planner-service-mapping.update', { service }), { json: body }).json();
    },
    async destroy(service: string): Promise<never> {
      return await httpClient.delete(route('crm.planner-service-mapping.delete', { service })).json();
    },
  },
};
