import api from '@/services/api';
import { ref } from 'vue';
import { IWorkTitleResource } from '@/types/WorkTitle';
import { Options } from 'ky';

type GetWorkTitlesHooks = {
  onSet?: (data: IWorkTitleResource[]) => IWorkTitleResource[];
  onError?: (error: unknown) => void;
  onFinally?: () => void;
};

export default function useWorkTitles() {
  const workTitles = ref<IWorkTitleResource[]>([]);
  const workTitlesLoading = ref(false);

  async function getWorkTitles(options?: Options, hooks?: GetWorkTitlesHooks) {
    try {
      workTitlesLoading.value = true;
      const response = await api.workTitles.list(options);
      workTitles.value = hooks?.onSet ? hooks.onSet(response.data) : response.data;
    } catch (error) {
      console.error(error);
      if (hooks?.onError) {
        hooks?.onError(error);
      }
    } finally {
      workTitlesLoading.value = false;
      if (hooks?.onFinally) {
        hooks?.onFinally();
      }
    }
  }

  return {
    workTitles,
    workTitlesLoading,
    getWorkTitles,
  };
}
