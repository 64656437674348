<script setup lang="ts">
import { AppButton, AppCloseButton, AppModal, FormLabel } from '@/components';
import { useI18n } from 'vue-i18n';
import VueSelect from 'vue-select';
import useUsers from '@/composables/useUsers';
import { onMounted, ref } from 'vue';
import { IUserListResource, UserStatusType } from '@/types/User';
import useLoading from '@/composables/useLoading';
import { ConfirmDialogConfirmParams } from '@/types/Common';
import useModalFeatures from '@/composables/useModalFeatures';

type Props = {
  initialUuid: string;
};

const { initialUuid } = defineProps<Props>();

const emit = defineEmits<{
  reassign: [uuid: string, params: ConfirmDialogConfirmParams];
  closed: [];
  cancel: [];
}>();

const { t } = useI18n({ useScope: 'global' });
const { loading, setLoading } = useLoading();
const { users, usersLoading, getUsers } = useUsers();
const { onCtrlEnter } = useModalFeatures();

const uuid = ref(initialUuid);

onMounted(async () => {
  const searchParams = new URLSearchParams();
  searchParams.append('without_pagination', '1');
  searchParams.append('statuses[]', UserStatusType.Active);
  searchParams.append('statuses[]', UserStatusType.Invited);
  await getUsers({ searchParams });
});

onCtrlEnter(() => {
  if (initialUuid !== uuid.value) {
    emit('reassign', uuid.value, { setLoading });
  } else {
    emit('cancel');
  }
});
</script>

<template>
  <AppModal size="small" @closed="emit('closed')" v-slot="{ close }" content-style="overflow: visible;">
    <form @submit.prevent="emit('reassign', uuid, { setLoading })">
      <div class="modal-header">
        <h2 v-text="t('common.reassign')" />
        <AppCloseButton @close="close" />
      </div>
      <div class="modal-content">
        <FormLabel html-for="reassign_user">
          {{ t('common.user') }}
        </FormLabel>
        <VueSelect
          v-model="uuid"
          :loading="usersLoading"
          :options="users"
          :disabled="!uuid || usersLoading"
          label="name"
          :reduce="(option: IUserListResource) => option.uuid"
          input-id="reassign_user"
          :placeholder="usersLoading ? t('common.loading') : t('common.all')"
          :clearable="false"
        >
          <template #no-options>{{ t('common.type_to_search') }}</template>
        </VueSelect>
        <slot />
      </div>
      <div class="modal-footer">
        <div class="d-flex">
          <AppButton light @click.prevent="close" :disabled="loading">
            {{ t('common.cancel') }}
          </AppButton>
          <AppButton class="ml-auto" color="secondary" :disabled="loading" :loading="loading">
            {{ t('common.reassign') }}
          </AppButton>
        </div>
      </div>
    </form>
  </AppModal>
</template>
