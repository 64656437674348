<script setup lang="ts">
import { Decimal } from 'decimal.js';
import { useI18n } from 'vue-i18n';

import { IProjectBillingPlanResource } from '@/types/Project';

type Props = {
  plan: IProjectBillingPlanResource;
};

defineProps<Props>();

const { n, t } = useI18n({ useScope: 'global' });
</script>

<template>
  <div class="scheduled-item">
    <div class="scheduled-item-title">{{ t('common.scheduled') }}: {{ t(`client.billing.fees.${plan.type}`) }}</div>
    {{ n(new Decimal(plan.price ?? 0).toNumber(), 'currency') }}
  </div>
</template>
