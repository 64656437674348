export default {
  resource: {
    name: 'Expense | Expenses',
  },
  index: {
    title: 'Expenses',
    help: '',
    create: 'Add expense',
  },

  create: {
    title: 'Add expense',
  },
  edit: {
    title: 'Edit expense',
  },

  source: {
    sync: 'Synced',
    time_sheet: 'Time sheet',
    manual: 'Manually added',
  },

  type: {
    receipt: 'Receipt',
    invoice: 'Invoice',
    mileage: 'Mileage allowance',
  },

  empty: 'No expenses',

  attributes: {
    type: 'Type',
    ref_number: 'RefNr',
    date: 'Date',
    client: 'Customer',
    project: 'Project',
    description: 'Description',
    source: 'Source',
    amount: 'Net amount',
    is_invoiced: 'Added to invoice',
  },

  confirm: {
    destroy: {
      title: 'Delete expense',
      text: 'Are you sure you want to delete this expense?',
    },
  },
};
