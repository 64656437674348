<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps<{
  type?: 'info' | 'success' | 'warning' | 'danger';
}>();

const classes = computed(() => ['alert', props.type && { [`is-${props.type}`]: props.type }]);
</script>

<template>
  <div v-bind="$attrs" :class="classes" role="alert">
    <slot />
  </div>
</template>
