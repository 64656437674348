export default {
  // Settings
  settings: {
    title: 'Löneinställningar',
    help: '',
    attributes: {
      system: 'Lönesystem',
      mileage_allowance_amount: 'Kilometer ersättnings belopp',
      car_reinbursment_heading: 'Milersättning',
      car_type: 'Biltyp',
      car_type_own_car: 'Egen bil',
      car_type_employee_diesel: 'Förmånsbil diesel',
      car_type_employee_other: 'Förmånsbil bensin/annan ',
      car_type_employee_electric: 'Förmånsbil el',
      payment_km_taxable: 'Ersättning per km före skatt',
      payment_km_taxfree: 'Skattefri del per km',
      salary_code_taxable: 'Lönekod skattad del',
      salary_code_taxfree: 'Lönekod skattefri del',
    },
  },

  // Codes
  codes: {
    title: 'Lönekoder',
    help: '',
    add_new: 'Lägg till',
    empty: 'Inga lönekoder',
    create: {
      title: 'Skapa lönekod',
    },
    edit: {
      title: 'Redigera lönekod',
    },
    destroy: {
      title: 'Radera lönekod',
      text: 'Är du säker på att du vill radera <strong>{name}</strong> lönekoder?',
    },
    attributes: {
      name: 'Namn',
      system_id: 'ID',
      type: 'Typ',
      absence_type: 'Frånvarotyp',
      is_default: 'Standard',
      client_work_time: 'Klientarbetstid',
    },
  },

  // Messages
  message: {
    system_is_not_set: 'Välj lönesystem',
  },
};
