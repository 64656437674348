<script lang="ts" setup>
import { computed } from 'vue';
import useTime from '@/composables/useTime';

interface Props {
  green?: number;
  orange?: number;
  gray?: number;
}

const { convertMinutesToTime } = useTime();

const props = defineProps<Props>();

const values = computed(() => {
  const filteredColors = Object.entries(props)
    // eslint-disable-next-line
    .filter(([_, value]) => value !== undefined)
    .map(([color, value]) => ({ color, value: value as number }));

  const totalValue = filteredColors.reduce((sum, { value }) => sum + value, 0);

  let remainingPercentage = 100;
  const colorPercentages: Props = {};

  for (const { color, value } of filteredColors) {
    const percentage = (value / totalValue) * 100;
    const roundedPercentage = Math.round(percentage * 100) / 100;

    if (color === filteredColors[filteredColors.length - 1].color) {
      // @ts-ignore
      colorPercentages[color] = remainingPercentage;
    } else {
      // @ts-ignore
      colorPercentages[color] = roundedPercentage;
      remainingPercentage -= roundedPercentage;
    }
  }

  return colorPercentages;
});
</script>

<template>
  <div class="progress">
    <div
      class="progress-bar is-success"
      v-if="props.green ?? 0 > 0"
      style="min-width: 30px"
      :style="{ width: `${values.green}%` }"
    >
      <div class="progress-value" v-text="convertMinutesToTime(props.green ?? 0)" />
    </div>
    <div
      class="progress-bar is-warning"
      v-if="props.orange ?? 0 > 0"
      style="min-width: 30px"
      :style="{ width: `${values.orange}%` }"
    >
      <div class="progress-value" v-text="convertMinutesToTime(props.orange ?? 0)" />
    </div>
    <div class="progress-bar" v-if="props.gray ?? 0 > 0" style="min-width: 30px" :style="{ width: `${values.gray}%` }">
      <div class="progress-value" v-text="convertMinutesToTime(props.gray ?? 0)" />
    </div>
  </div>
</template>
