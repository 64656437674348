export default {
  index: {
    title: 'Avdelningar',
    help: '',
    create: 'Skapa avdelning',
  },

  create: {
    title: 'Skapa avdelning',
  },
  edit: {
    title: 'Redigera avdelning: {name}',
  },
  form: {
    name: 'Namn',
  },

  empty: 'Ingen avdelning',

  // Attributes
  attributes: {
    name: 'Namn',
  },

  // Tooltips
  tooltip: {
    edit: 'Redigera "{name}"',
    destroy: 'Radera "{name}"',
  },

  // Confirms
  confirm: {
    destroy: {
      title: 'Radera avdelning',
      text: 'Är du säker på att du vill radera <strong>{name}</strong>?',
    },
  },
};
