<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useModal } from 'vue-final-modal';
import { useTitle } from '@vueuse/core';

import {
  AppAlert,
  AppBox,
  AppBoxBody,
  AppButton,
  AppLink,
  AppLoader,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  ConfirmModal,
  FontIcon,
  UserVehicleModal,
} from '@/components';
import api from '@/services/api';
import useAuthStore from '@/store/AuthStore';
import { IVehicleResource } from '@/types/User';
import { ConfirmDialogConfirmParams } from '@/types/Common';

const { authenticatedUser, updateAuthenticatedUser } = useAuthStore();
const { t, d, n } = useI18n({ useScope: 'global' });
const title = useTitle(computed(() => t('user.profile.title')));

const userLoading = ref(false);

const vehicles = ref<IVehicleResource[]>([]);
const vehiclesLoading = ref(false);

async function getVehicles() {
  try {
    vehiclesLoading.value = true;
    const response = await api.users.vehicles.index(authenticatedUser.uuid);
    vehicles.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    vehiclesLoading.value = false;
  }
}

async function getCurrentUser() {
  try {
    userLoading.value = true;
    const response = await api.users.currentAuthenticatedUser();
    updateAuthenticatedUser(response);
  } catch (error) {
    console.error(error);
  } finally {
    userLoading.value = false;
  }
}

function onCreateVehicle() {
  const { open, close, destroy } = useModal({
    component: UserVehicleModal,
    attrs: {
      userUuid: authenticatedUser.uuid,
      vehicle: null,
      onCreated() {
        getVehicles();
        close();
      },
      onCancel() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onEditVehicle(vehicle: IVehicleResource) {
  const { open, close, destroy } = useModal({
    component: UserVehicleModal,
    attrs: {
      userUuid: authenticatedUser.uuid,
      vehicle,
      onUpdated() {
        getVehicles();
        close();
      },
      onCancel() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onDeleteVehicle(vehicle: IVehicleResource) {
  const { open, close, destroy } = useModal({
    component: ConfirmModal,
    attrs: {
      title: t('user.vehicle.confirm.delete.title'),
      message: t('user.vehicle.confirm.delete.text', { name: vehicle.name }),
      async onConfirm({ setLoading }: ConfirmDialogConfirmParams) {
        try {
          setLoading(true);
          await api.users.vehicles.delete(authenticatedUser.uuid, vehicle.id);
          vehicles.value = vehicles.value.filter(({ id }) => id !== vehicle.id);
          await close();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

onMounted(() => {
  getCurrentUser();
  getVehicles();
});
</script>
<template>
  <div v-if="userLoading" class="text-center">
    <AppLoader size="large" />
  </div>
  <div v-else class="container-wide">
    <h1 class="mb-4" v-text="title" />

    <!-- Contact information -->
    <AppBox shadow>
      <AppBoxBody>
        <h2 v-text="t('user.profile.contact_information')" />
        <div class="row mb-1">
          <div class="col-2 font-bold">{{ t('user.attributes.name') }}</div>
          <div class="col">{{ authenticatedUser.name }}</div>
        </div>
        <div class="row mb-1" v-if="authenticatedUser.work_title">
          <div class="col-2 font-bold">{{ t('user.attributes.work_title') }}</div>
          <div class="col">{{ authenticatedUser.work_title.name }}</div>
        </div>
        <div class="row mb-1">
          <div class="col-2 font-bold">{{ t('user.attributes.system_role') }}</div>
          <div class="col">{{ t(`user.system_role.${authenticatedUser.system_role ?? 'employee'}`) }}</div>
        </div>
        <div class="row mb-1" v-if="authenticatedUser.phone_number">
          <div class="col-2 font-bold">{{ t('user.attributes.phone_number') }}</div>
          <div class="col">
            <AppLink :value="authenticatedUser.phone_number" mode="phone" />
          </div>
        </div>
        <div class="row" v-if="authenticatedUser.email">
          <div class="col-2 font-bold">{{ t('user.attributes.email') }}</div>
          <div class="col">
            <AppLink :value="authenticatedUser.email" mode="email" />
          </div>
        </div>
      </AppBoxBody>
    </AppBox>

    <!-- Vehicles -->
    <div class="d-flex align-items-center justify-content-between mb-3 mt-5">
      <h2 class="mb-0" v-text="t('user.vehicle.index.title')" />
      <AppButton color="secondary" @click.prevent="onCreateVehicle" href="#">
        {{ t('user.vehicle.index.create') }}
      </AppButton>
    </div>
    <AppBox shadow v-if="vehiclesLoading" class="text-center py-3">
      <AppLoader />
    </AppBox>
    <template v-else>
      <AppAlert v-if="vehicles.length === 0">{{ t('user.vehicle.empty') }}</AppAlert>
      <template v-else>
        <AppTable hoverable>
          <AppTableHead>
            <AppTableTr>
              <AppTableTh nowrap>{{ t('user.vehicle.attributes.name') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('user.vehicle.attributes.type') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('user.vehicle.attributes.created_at') }}</AppTableTh>
              <AppTableTh class="text-right" nowrap>{{ t('common.actions') }}</AppTableTh>
            </AppTableTr>
          </AppTableHead>
          <AppTableBody>
            <AppTableTr v-for="vehicle in vehicles" :key="vehicle.id">
              <AppTableTd nowrap>
                <strong v-text="vehicle.name" />
              </AppTableTd>
              <AppTableTd nowrap>
                {{ t(`user.vehicle.type.${vehicle.type}`) }}
              </AppTableTd>
              <AppTableTd nowrap>
                {{ d(vehicle.created_at) }}
              </AppTableTd>
              <AppTableTd nowrap class="text-right">
                <AppButton
                  v-tooltip.left="t('user.vehicle.tooltip.edit', { name: vehicle.name })"
                  @click.stop.prevent="onEditVehicle(vehicle)"
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="pencil" />
                </AppButton>
                <AppButton
                  v-if="!vehicle.in_use"
                  v-tooltip.left="t('user.vehicle.tooltip.delete', { name: vehicle.name })"
                  @click.stop.prevent="onDeleteVehicle(vehicle)"
                  class="ml-2"
                  color="danger"
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="trash" />
                </AppButton>
              </AppTableTd>
            </AppTableTr>
          </AppTableBody>
        </AppTable>
      </template>
    </template>

    <!-- Other info -->
    <AppBox class="mt-5" shadow>
      <AppBoxBody>
        <h2 v-text="t('user.profile.other_info')" />
        <h3 v-text="t('user.profile.my_prices')" />
        <div class="row mb-1">
          <div class="col-2 font-bold">{{ t('user.attributes.hourly_price') }}</div>
          <div class="col">{{ n(authenticatedUser.hourly_price ?? 0, 'currency') }}</div>
        </div>
        <div class="row mb-1">
          <div class="col-2 font-bold">{{ t('user.attributes.internal_price') }}</div>
          <div class="col">{{ n(authenticatedUser.internal_cost ?? 0, 'currency') }}</div>
        </div>
      </AppBoxBody>
    </AppBox>
  </div>
</template>
