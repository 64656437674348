<script lang="ts" setup>
import { computed } from 'vue';

const {
  cssClass = '',
  shadow,
  border,
} = defineProps<{
  cssClass?: string;
  border?: boolean;
  shadow?: boolean;
}>();

const classes = computed(() => ['box', cssClass, { 'is-bordered': border }, { 'is-shadowed': shadow }]);
</script>

<template>
  <div :class="classes">
    <slot />
  </div>
</template>
