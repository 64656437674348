export enum FreeDayType {
  IMPORTED = 'imported',
  ADDED = 'added',
}

export interface IFreeDayResource {
  id: number;
  name: string;
  date: string;
  workday_percent: number;
  type: FreeDayType;
}

export interface IFreeDayRequest {
  name: null | string;
  date: null | string;
  workday_percent: null | number;
}
