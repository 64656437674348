export default {
  index: {
    title: 'Departments',
    help: '',
    create: 'Create department',
  },

  create: {
    title: 'Create department',
  },
  edit: {
    title: 'Edit department: {name}',
  },
  form: {
    name: 'Name',
  },

  empty: 'No departments',

  // Attributes
  attributes: {
    name: 'Name',
  },

  // Tooltips
  tooltip: {
    edit: 'Edit "{name}" department',
    destroy: 'Delete "{name}" department',
  },

  // Confirms
  confirm: {
    destroy: {
      title: 'Delete department',
      text: 'Are you sure you want to delete <strong>{name}</strong> department from the system?',
    },
  },
};
