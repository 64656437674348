import route from 'ziggy-js';
import { Options } from 'ky';
import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import { IDepartmentResource, IDepartmentRequestBody } from '@/types/Department';

export default {
  async list(options: Options = {}) {
    return await httpClient.get(route('departments.index'), options).json<IResponse<IDepartmentResource[]>>();
  },
  async get(department: number, options: Options = {}) {
    return await httpClient
      .get(route('departments.show', { department }), options)
      .json<IResponse<IDepartmentResource>>();
  },
  async store(json: IDepartmentRequestBody, options: Options = {}) {
    return await httpClient
      .post(route('departments.store'), { ...options, json })
      .json<IResponse<IDepartmentResource>>();
  },
  async update(department: number, json: IDepartmentRequestBody, options: Options = {}) {
    return await httpClient
      .patch(route('departments.update', { department }), { ...options, json })
      .json<IResponse<IDepartmentResource>>();
  },
  async destroy(department: number, options: Options = {}) {
    return await httpClient.delete(route('departments.destroy', { department }), options).json<void>();
  },
};
