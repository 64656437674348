import axios from 'axios';
import toast from '../toast';
import { forEach, isArray, isObject, isString } from 'lodash';

export default class ErrorService {
  static onError(e: unknown) {
    if (axios.isAxiosError(e) && e.response) {
      ErrorService.displayErrors(e.response.data.message, e.response.data.errors);
    } else if (typeof e === 'string') {
      ErrorService.displayErrors(e);
    } else if (e instanceof Error) {
      ErrorService.displayErrors(e.message);
    }
  }

  static displayErrors(message: string, errors: object | undefined | null = null): void {
    toast.error(message ?? '');
    if (errors) {
      ErrorService.handleError(errors);
    }
  }

  static handleError(error: string | [] | object): void {
    if (isArray(error)) {
      toast.error((error as []).join(', '));
    } else if (isString(error)) {
      toast.error(error);
    } else if (isObject(error)) {
      forEach(error, (innerError) => {
        ErrorService.handleError(innerError);
      });
    }
  }
}
