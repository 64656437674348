export default {
  type: {
    'Limited company': 'Aktiebolag',
    'Housing cooperative': 'Bostadsrättsförening',
    'Individual company': 'Enskild näringsverksamhet',
    'Trading company': 'Handelsbolag',
    'Non-profit association': 'Ideell förening',
    'Limited partnership': 'Kommanditbolag',
    Foundation: 'Stiftelse',
    'In public': 'Offentlig verksamhet',
    Branch: 'Filial',
    'Economic Association': 'Ekonomisk förening',
    Private: 'Privat',
    Other: 'Annan',
  },
};
