import { RouteRecordRaw } from 'vue-router';

import MainLayout from '@/layouts/MainLayout.vue';
import InternalProjects from '@/views/InternalProjects/InternalProjects.vue';
import usePermissions from '@/composables/usePermissions';

const prefix = 'internal-projects';

const routes: RouteRecordRaw[] = [
  {
    path: `/${prefix}`,
    name: `${prefix}.index`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: InternalProjects,
    beforeEnter: (to, from, next) => {
      const { can } = usePermissions();
      if (can('internal-activities.view')) {
        return next();
      }
      return next({ name: 'dashboard' });
    },
  },
];

export default routes;
