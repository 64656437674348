<script lang="ts" setup>
import { onMounted, reactive, ref } from 'vue';
import api from '@/services/api';
import { AppBox, AppBoxBody, AppLoader } from '@/components';
import { useI18n } from 'vue-i18n';
import route from 'ziggy-js';
import useAuthStore from '@/store/AuthStore';

const { userUuid } = defineProps<{
  userUuid: string;
}>();

const loading = ref(false);
const stats = reactive({
  myTasksCount: 0,
  activeProjects: 0,
  activeTasks: 0,
  messages: 0,
});

const { t } = useI18n({ useScope: 'global' });

const { authenticatedUser } = useAuthStore();

onMounted(async () => {
  try {
    loading.value = true;
    const response = await api.users.portal.stats(userUuid);
    stats.myTasksCount = response.result.myTasksCount;
    stats.activeProjects = response.result.activeProjects;
    stats.activeTasks = response.result.activeTasks;
    stats.messages = response.result.messages;
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
});
</script>

<template>
  <AppBox shadow>
    <AppBoxBody>
      <h3>{{ t('user.stats.title') }}</h3>
    </AppBoxBody>
    <AppBoxBody>
      <div v-if="loading" class="text-center">
        <AppLoader size="small" />
      </div>
      <template v-else>
        <div class="text-3 mb-2">
          <span class="badge mr-2">{{ stats.myTasksCount }}</span>
          <a v-if="authenticatedUser.uuid === userUuid" :href="route('system-redirects.portal.tasks')" target="_blank">
            {{ t('user.stats.tasks') }}
          </a>
          <span v-else>{{ t('user.stats.tasks') }}</span>
        </div>
        <div class="text-3 mb-2">
          <span class="badge mr-2">{{ stats.messages }}</span>
          <span>{{ t('user.stats.messages') }}</span>
        </div>
      </template>
    </AppBoxBody>
  </AppBox>
</template>
