export default {
  resource: {
    name: 'Faktura | Fakturor',
  },
  index: {
    title: 'Fakturor',
    help: '',
    create_periodical_invoices: 'Generera månadens fakturor',
    create_separate_invoice: 'Skapa separat faktura',
  },

  drafts: {
    title: 'Fakturautkast',
    help: '',
    inbox_free: 'Snyggt fakturerat!',
  },

  create_periodical_invoices: {
    title: 'Generera månadens fakturor',
    description:
      'Du som admin kan här generera byråns fakturor för den månad du väljer. Det skapas en faktura på alla kunder som antingen har rapporterad tid den månad som föregår vald period samt de som har faktura schemalagd på vald månad.<br><br>Om du generar fakturor på nytt för en månad, kommer eventuella tillkommande tid eller priser läggas till.',
  },
  create_separate_invoice: {
    title: 'Skapa fristående faktura',
  },

  edit: {
    title: 'Redigera faktura: (id#{id})',
    help: '',
  },

  fetch_not_invoiced: {
    title: 'Hämta alla ej fakturerade arvoden',
    text: 'Det finns flera projekt för denna kund. Välj vilka projekt som du vill fakturera.',
    add_to_existing_invoices: 'Lägg till priser på eventuella befintliga fakturor som inte har skickats',
  },

  form: {
    name: 'Namn',
  },

  empty: 'Ingen faktura',

  // Attributes
  attributes: {
    client: 'Kund',
    client_reference: 'Kundens referens',
    user: 'Vår referens',
    amount: 'Belopp',
    created_at: 'Skapad',
    updated_at: 'Uppdaterad',
    internal_status: 'Intern status',
    external_status: 'Extern status',
    synced_at: 'Synkad',
    sync_status: 'Synkstatus',
    external_amount: 'Externt fakturabelopp',
    difference: 'Avvikelse',
    invoice_language: 'Fakturaspråk',
    other_information: 'Övrig faktureringsinfo',
    billing_adress: 'Faktureringsadress',
    internal_note: 'Intern notering',
    invoice_text: 'Fakturatext',
  },

  not_settled_prepayments: 'Ej avräknade förskott',
  not_settled_prepayments_text:
    'Tidigare skickat, men ej avräknat kvarstående förskott: <strong>{value}</strong>.<br>Lägg till en ny rad med pristyp Förskott och ange negativt belopp för att räkna av förskott på denna.',
  negative_advance:
    'Du har gjort avdrag för förskott med ett högre belopp än kvarstående. Förslagsvis använder du pristyp “Rabatt” för att minska fakturabeloppet.',
  no_positive_prepayment:
    'Det finns ingen förskottsbetalning att dra av negativt förskottsbelopp från. Använd pristyp “Rabatt” för att minska fakturabeloppet.',

  details: {
    project: 'Projekt',
    estimated_margin_percent: 'Marg%',
    price_type: 'Pristyp',
    description: 'Beskrivning',
    article: 'Artikel',
    qty: 'Antal',
    unit: 'Enhet',
    price_unit: 'Pris/Enhet',
    discount: 'Rabatt %',
    amount: 'Belopp',
    postpone_to_next_month: 'Flytta till nästa månad',
    invoicing: 'Faktureras',
    write_off: 'Nedskrivning',
    total: 'Ursprungligt belopp',
    total_invoice_amount: 'Summa faktura',
    fetch_not_invoiced_prices: 'Hämta alla ej fakturerade arvoden',
    approve_and_sync: 'Godkänn & Synka',
    save_and_sync: 'Spara & Synka',
  },

  filters: {
    clients: 'Kund',
    users: 'Vår referens',
    internal_statuses: 'Intern status',
    external_statuses: 'Extern status',
    from_date: 'Skapad från datum',
    to_date: 'Skapad till datum',
    show_deleted: 'Visa raderade',
    credited_or_deleted: 'Show credited/deleted',
    show_all: 'Visa alla',
  },

  // Tooltips
  tooltip: {
    edit: 'Redigera faktura',
    destroy: 'Radera faktura',
    postpone: 'Flytta till nästa månad',
    write_off: 'Nedskrivning',
    expand: 'Expandera',
    minimize: 'Minimera',
    expand_all: 'Expandera alla',
    minimize_all: 'Minimera alla',
    report_link: 'Se rapporterad tid på projektet (öppnas i nytt fönster)',
    billing_link: 'Se faktureraöversikt (öppnas i nytt fönster)',
    project_link: 'Projektöversikt (öppnas i nytt fönster)',
  },

  // Confirms
  confirm: {
    destroy: {
      title: 'Radera faktura',
      text: 'Är du säker att du vill radera faktura?',
    },
  },

  unit: {
    pieces: 'Antal',
    hours: 'Timmar',
  },

  internal_status: {
    draft: 'Utkast',
    approved: 'Godkänd',
    deleted: 'Raderad',
    unmatched: 'Ej matchad',
    matched: 'Matchad',
    postponed: 'Uppskjuten',
    action_required: 'Åtgärd krävs',
    empty: 'Tom',
  },

  external_status: {
    NotSent: 'Ej skickad',
    Unpaid: 'Ej betald',
    OverdueReminderSent: 'Förfallen, påminnelse skickad',
    OverdueReminderNotSent: 'Förfallen, påminnelse ej skickad',
    Paid: 'Betald',
    Cancelled: 'Krediterad',
    Collection: 'Inkasso',
    Expires: 'Utgått',
  },

  sync_status: {
    error: 'Synkfel',
    success: 'Synk lyckad',
  },

  price_type: {
    fixed: 'Fast pris',
    hourly: 'Timarvode',
    advance: 'Förskott',
    extra_fee: 'Extra arvode',
    discount: 'Rabatt',
    outlays: 'Utlägg',
  },

  unsaved_modal: {
    title: 'Du har osparade ändringar. Hur vill du göra?',
    save_continue: 'Spara ändringar och fortsätt',
    discard_continue: 'Släng ändringar och fortsätt',
  },

  distribution: {
    e_invoice: 'E-faktura',
    email: 'Epost',
    mail: 'Post',
  },

  unmatched_error:
    'Beloppet på den skickade fakturan stämmer inte överens med summan nedan. Fakturabeloppet är {external_amount}',

  customer_settings_incomplete: {
    text: 'Det saknas info i kundens fakturainställningar.',
    link: 'Ändra dem här.',
  },
};
