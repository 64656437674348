import { BillingFrequency, ProjectPriceType, ProjectUserRole, RepetitionFrequencyType } from '@/types/Common';
import { ISalaryCodeResource } from '@/types/Salary';
import { CheckpointFrequency } from '@/types/Checkpoint';

export interface IServiceMinimizedResource {
  uuid: string;
  name: string;
}

export interface IServiceTaskResource {
  id: number;
  name: string;
  order: number;
  default_role: ProjectUserRole;
  default_time_budget: number;
  default_frequency: null | RepetitionFrequencyType;
  requires_visit: boolean;
  auto_include_in_planning: boolean;
  is_in_use: boolean;
  salary_code: null | Pick<ISalaryCodeResource, 'id' | 'name'>;
}

export interface IServiceTaskRequest {
  id?: number;
  uid: string;
  name: string;
  order: number;
  default_role: null | ProjectUserRole;
  default_time_budget: number;
  default_frequency: null | RepetitionFrequencyType;
  requires_visit: boolean;
  auto_include_in_planning: boolean;
  salary_code_id: null | number;
  is_in_use: boolean;
}

export type ServiceTaskForm = Omit<IServiceTaskRequest, 'uid' | 'order' | 'is_in_use'>;

export interface IServiceCheckpointResource {
  id: number;
  name: string;
  frequency: CheckpointFrequency;
  order: number;
  is_in_use: boolean;
}

export interface IServiceCheckpointRequest {
  id?: number;
  uid: string;
  name: string;
  frequency: null | CheckpointFrequency;
  order: number;
  is_in_use: boolean;
}

export type ServiceCheckpointForm = Pick<IServiceCheckpointRequest, 'name' | 'frequency'>;

export enum ServiceDefaultDeadline {
  MONTH_END_PLUS_26_DAYS = 'op4',
  PROJECT_START_DATE_PLUS_2_MONTHS = 'op7',
  CALENDAR_YEAR_END_PLUS_4_MONTHS = 'op5',
  CALENDAR_YEAR_END_PLUS_160_DAYS = 'op1',
  CALENDAR_QUARTER_END_PLUS_40_DAYS = 'op3',
  FINANCIAL_YEAR_END_PLUS_1_MONTH = 'op6',
  FINANCIAL_YEAR_END_PLUS_6_MONTHS = 'op2',
}

export interface IServiceResource {
  uuid: string;
  name: string;
  active: boolean;
  default_invoice_frequency: BillingFrequency;
  default_price_type: ProjectPriceType;
  default_fixed_price: number;
  default_hourly_price_adjustment: string;
  default_hourly_price_adjustment_list: IServicePriceAdjustmentListResource[];
  default_dead_line: ServiceDefaultDeadline;
  tasks: IServiceTaskResource[];
  checkpoints: IServiceCheckpointResource[];
}

export interface IServiceStoreRequest {
  name: string;
  default_invoice_frequency: null | BillingFrequency;
  default_price_type: null | ProjectPriceType;
  default_fixed_price: number;
  default_hourly_price_adjustment: number;
  default_dead_line: null | ServiceDefaultDeadline;
  tasks: IServiceTaskRequest[];
  checkpoints: IServiceCheckpointRequest[];
}

export type IServiceUpdateRequest = Omit<IServiceStoreRequest, 'default_hourly_price_adjustment'> & {
  notes: string;
  active: boolean;
};

export interface IServicePreviewResource {
  uuid: string;
  name: string;
  active: boolean;
  note: string;
  default_invoice_frequency: BillingFrequency;
  default_price_type: ProjectPriceType;
  default_fixed_price: number;
  default_hourly_price_adjustment: string;
  default_dead_line: ServiceDefaultDeadline;
  in_use: boolean;
}

export interface IServicePriceAdjustmentListResource {
  id: string;
  price: string;
  start_date: string;
}
