import { computed, ref } from 'vue';

type Key = number | string;

export default function useExpandedList(initialKeys: Key[] = []) {
  const expanded = ref<Key[]>([...initialKeys]);

  function isExpanded(key: Key) {
    return expanded.value.includes(key);
  }

  function expand(toBeExpanded: Key) {
    if (isExpanded(toBeExpanded)) return;
    expanded.value = [...expanded.value, toBeExpanded];
  }

  function collapse(toBeCollapsed: Key) {
    if (!isExpanded(toBeCollapsed)) return;
    expanded.value = expanded.value.filter((key) => key !== toBeCollapsed);
  }

  function toggle(toBeToggled: Key) {
    if (expanded.value.includes(toBeToggled)) {
      expanded.value = expanded.value.filter((item) => item !== toBeToggled);
    } else {
      expanded.value.push(toBeToggled);
    }
  }

  function expandAll(keys: Key[]) {
    expanded.value = keys;
  }

  function toggleAll(keys: Key[]) {
    expanded.value = expanded.value.length === 0 ? keys : [];
  }

  function reset() {
    expanded.value = [];
  }

  const isEmpty = computed(() => expanded.value.length === 0);

  return { expanded, isEmpty, expand, collapse, reset, toggle, isExpanded, expandAll, toggleAll };
}
