import { RouteRecordRaw } from 'vue-router';

import MainLayout from '@/layouts/MainLayout.vue';
import ClientVisits from '@/views/ClientVisits.vue';
import ResourceManagement from '@/views/ResourceManagement.vue';
import usePermissions from '@/composables/usePermissions';

const routes: RouteRecordRaw[] = [
  {
    path: '/resource-management',
    name: 'resource-management.index',
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: ResourceManagement,
    beforeEnter: (to, from, next) => {
      const { can } = usePermissions();
      if (can('resource-planning.view')) {
        return next();
      }
      return next({ name: 'dashboard' });
    },
  },
  {
    path: '/client-visits',
    name: 'client-visits.index',
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: ClientVisits,
  },
];

export default routes;
