<script setup lang="ts">
import { useId, computed, ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';

import { AppButton, FormLabel, FormTextarea } from '@/components';
import { ConfirmDialogConfirmParams } from '@/types/Common';
import useLoading from '@/composables/useLoading';

type Props = {
  title: string;
  label: string;
  initialComment: string;
  required: boolean;
  action: string;
};

const { t } = useI18n({ useScope: 'global' });
const { loading, setLoading } = useLoading();
const id = useId();

const { initialComment, required = false, title, label } = defineProps<Props>();

const comment = ref('');

const disabled = computed(() => (required ? !comment.value : false));

const emit = defineEmits<{
  cancel: [];
  submit: [params: ConfirmDialogConfirmParams, comment: string];
  closed: [];
}>();

onMounted(() => {
  if (initialComment) {
    comment.value = initialComment;
  }
});
</script>

<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container"
    :click-to-close="false"
    :esc-to-close="true"
    @closed="emit('closed')"
  >
    <form @submit.prevent="emit('submit', { setLoading }, comment)">
      <div class="modal-header">
        <h2 v-text="title" />
      </div>
      <div class="modal-content">
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel :html-for="id" :required="required">{{ label }}</FormLabel>
            </div>
            <div class="col-md-9">
              <FormTextarea v-model="comment" :id="id" :required="required" />
            </div>
          </div>
        </div>
        <slot />
      </div>
      <div class="modal-footer d-flex">
        <AppButton light @click.prevent="emit('cancel')">
          {{ t('common.cancel') }}
        </AppButton>
        <AppButton class="ml-auto" color="success" :disabled="disabled" :loading="loading">
          {{ action }}
        </AppButton>
      </div>
    </form>
  </VueFinalModal>
</template>
