import { ref } from 'vue';
import { defineStore } from 'pinia';

const useActivityTemplatesStore = defineStore('ActivityTemplatesStore', () => {
  const lockedTabs = ref(true);

  function lockTabs(): void {
    lockedTabs.value = true;
  }

  function unlockTabs(): void {
    lockedTabs.value = false;
  }

  return { lockedTabs, lockTabs, unlockTabs };
});

export default useActivityTemplatesStore;
