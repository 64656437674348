import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { IProjectResource } from '@/types/Project';
import { IUserPreviewResource } from '@/types/User';

const useProjectStore = defineStore('ProjectStore', () => {
  const project = ref<null | IProjectResource>(null);
  const clientResponsibleUser = ref<null | IUserPreviewResource>(null);

  const plannedTasksExists = computed(() => {
    if (!project.value) return false;
    return project.value.planned_tasks.length > 0;
  });

  const plannedBillingPlansExists = computed(() => {
    if (!project.value) return false;
    return project.value.planned_billing_plans.length > 0;
  });

  function setClientResponsibleUser(user: IUserPreviewResource) {
    clientResponsibleUser.value = user;
  }

  function setProject(projectToSet: IProjectResource) {
    project.value = projectToSet;
  }

  function unsetProject() {
    project.value = null;
  }

  const isDraft = computed(() => project.value?.status === 'draft');
  const isActive = computed(() => project.value?.status === 'active');
  const isDeleted = computed(() => project.value?.status === 'deleted');
  const isCancelled = computed(() => project.value?.status === 'cancelled');
  const isDone = computed(() => project.value?.status === 'done');

  const lockedTabs = ref(false);

  function lockTabs(): void {
    lockedTabs.value = true;
  }

  function unlockTabs(): void {
    lockedTabs.value = false;
  }

  return {
    project: computed(() => project.value as IProjectResource),
    setProject,
    unsetProject,
    isDraft,
    isActive,
    isDeleted,
    isCancelled,
    isDone,
    plannedTasksExists,
    plannedBillingPlansExists,
    clientResponsibleUser,
    setClientResponsibleUser,
    lockedTabs,
    lockTabs,
    unlockTabs,
  };
});

export default useProjectStore;
