<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';

import { EmptyValue } from '@/components';

type Props = {
  deadline: null | string;
  doneAt: null | string;
};

const { deadline, doneAt } = defineProps<Props>();

const { d } = useI18n({ useScope: 'global' });

const color = computed(() => {
  if (!deadline) return '';
  const todayDate = DateTime.now();
  const deadlineDate = DateTime.fromISO(deadline);
  if (doneAt) {
    const doneAtDate = DateTime.fromISO(doneAt);
    return doneAtDate <= deadlineDate ? 'text-success-500' : 'text-orange-500';
  }
  if (todayDate >= deadlineDate) {
    return 'text-danger-500';
  }
  return todayDate.plus({ day: 7 }) >= deadlineDate ? 'text-yellow-500' : '';
});
</script>

<template>
  <span :class="color" v-if="deadline" v-text="d(deadline)" />
  <EmptyValue v-else />
</template>
