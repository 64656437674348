import { defineStore } from 'pinia';
import { useLocalStorage } from '@vueuse/core';
import type { Locale } from '@/types/Locale';

const useLocaleStore = defineStore('LocaleStore', () => {
  const locale = useLocalStorage<Locale>('locale', 'sv');

  function setLocale(newLocale: Locale) {
    locale.value = newLocale;
  }

  return { locale, setLocale };
});

export default useLocaleStore;
