<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import { CheckpointsList } from '@/components';
import { useTitle } from '@vueuse/core';
import { computed } from 'vue';

const { t } = useI18n({ useScope: 'global' });

useTitle(computed(() => t('checkpoint.index.title')));
</script>
<template>
  <div class="container-fluid">
    <CheckpointsList :title="t('checkpoint.index.title')" initial-view-mode="calendar">
      <template #title="{ title }">
        <h1>{{ title }}</h1>
      </template>
    </CheckpointsList>
  </div>
</template>
