import route from 'ziggy-js';
import { Options } from 'ky';
import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import { IWorkTitleResource, IWorkTitleRequestBody } from '@/types/WorkTitle';

export default {
  async list(options: Options = {}) {
    return await httpClient.get(route('work-titles.index'), options).json<IResponse<IWorkTitleResource[]>>();
  },
  async get(work_title: number, options: Options = {}) {
    return await httpClient
      .get(route('work-titles.show', { work_title }), options)
      .json<IResponse<IWorkTitleResource>>();
  },
  async store(json: IWorkTitleRequestBody, options: Options = {}) {
    return await httpClient
      .post(route('work-titles.store'), { ...options, json })
      .json<IResponse<IWorkTitleResource>>();
  },
  async update(work_title: number, json: IWorkTitleRequestBody, options: Options = {}) {
    return await httpClient
      .patch(route('work-titles.update', { work_title }), { ...options, json })
      .json<IResponse<IWorkTitleResource>>();
  },
  async destroy(work_title: number, options: Options = {}) {
    return await httpClient.delete(route('work-titles.destroy', { work_title }), options).json<void>();
  },
};
