import route from 'ziggy-js';
import { Options } from 'ky';
import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import { IActivityTemplateListResource, IActivityTemplateRequest, IActivityTemplateResource } from '@/types/Activity';

type StoreOrUpdateRequestOptions = Omit<Options, 'json'> & { json: IActivityTemplateRequest };

export default {
  async list(options?: Options): Promise<IResponse<IActivityTemplateListResource[]>> {
    return await httpClient.get(route('activities.activity-templates.index'), options).json();
  },
  async index(group: number, options: Options = {}): Promise<IResponse<IActivityTemplateListResource[]>> {
    return await httpClient.get(route('activities.activity-groups.templates.index', { group }), options).json();
  },
  async store(group: number, options: StoreOrUpdateRequestOptions): Promise<IResponse<IActivityTemplateResource>> {
    return await httpClient.post(route('activities.activity-groups.templates.store', { group }), options).json();
  },
  async get(group: number, template: number): Promise<IResponse<IActivityTemplateResource>> {
    return await httpClient.get(route('activities.activity-groups.templates.show', { group, template })).json();
  },
  async update(
    group: number,
    template: number,
    options: StoreOrUpdateRequestOptions,
  ): Promise<IResponse<IActivityTemplateResource>> {
    return await httpClient
      .patch(route('activities.activity-groups.templates.update', { group, template }), options)
      .json();
  },
  async destroy(group: number, template: number): Promise<IResponse<never>> {
    return await httpClient.delete(route('activities.activity-groups.templates.destroy', { group, template })).json();
  },
};
