<script setup lang="ts">
import { useModal } from 'vue-final-modal';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import {
  FontIcon,
  ConfirmModal,
  TheSearch,
  AppDropdown,
  AppDropdownSection,
  AppDropdownItem,
  AppDropdownLanguages,
  AppDropdownUser,
} from '@/components';
import useAuth from '@/composables/useAuth';
import { ConfirmDialogConfirmParams } from '@/types/Common';

const { logout } = useAuth();
const { t } = useI18n();
const router = useRouter();

function onLogout() {
  const { open, close, destroy } = useModal({
    component: ConfirmModal,
    attrs: {
      title: t('auth.confirm.logout.title'),
      message: t('auth.confirm.logout.text'),
      params: {},
      async onConfirm({ setLoading }: ConfirmDialogConfirmParams) {
        try {
          setLoading(true);
          await logout();
          await close();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}
</script>

<template>
  <header class="header">
    <div class="container-fluid">
      <div class="row d-flex align-items-center">
        <div class="col-6">
          <TheSearch />
        </div>
        <div class="col-6 d-flex justify-content-end">
          <AppDropdown button-class="ml-3" light circle>
            <template #button>
              <FontIcon name="user" />
            </template>
            <template #menu>
              <AppDropdownUser />
              <AppDropdownLanguages />
              <AppDropdownSection>
                <AppDropdownItem>
                  <a @click.prevent="router.push({ name: 'profile' })" href="#">
                    <FontIcon name="user" />
                    {{ t('common.my_profile') }}
                  </a>
                </AppDropdownItem>
                <AppDropdownItem>
                  <a @click.prevent="onLogout" href="#">
                    <FontIcon name="logout" />
                    {{ t('common.logout') }}
                  </a>
                </AppDropdownItem>
              </AppDropdownSection>
            </template>
          </AppDropdown>
        </div>
      </div>
    </div>
  </header>
</template>
