<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';
import VueSelect from 'vue-select';

import { AppButton, FormLabel } from '@/components';
import useClients from '@/composables/useClients';

const { t } = useI18n({ useScope: 'global' });
const { clients, onSearchClients } = useClients();
const clientUuid = ref('');

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'confirm', clientUuid: string): void;
}>();

const disabled = computed(() => !clientUuid.value);
</script>

<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container"
    content-style="overflow: visible;"
    :click-to-close="false"
    :esc-to-close="true"
  >
    <form class="form" @submit.prevent="emit('confirm', clientUuid)">
      <div class="modal-header">
        <h2 v-text="t('project.index.create_modal.title')" />
      </div>
      <div class="modal-content">
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="client_uuid" required>{{ t('project.index.create_modal.name') }}</FormLabel>
            </div>
            <div class="col-md-9">
              <VueSelect
                :filterable="false"
                @search="onSearchClients"
                v-model="clientUuid"
                :options="clients"
                label="name"
                input-id="client_uuid"
                :placeholder="t('common.select')"
                :reduce="(option) => option.uuid"
              >
                <template #no-options>{{ t('common.type_to_search') }}</template>
              </VueSelect>
            </div>
          </div>
        </div>
        <slot />
      </div>
      <div class="modal-footer">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
          <AppButton light @click.prevent="emit('cancel')">
            {{ t('common.cancel') }}
          </AppButton>
          <AppButton class="mt-2 mt-sm-0" color="secondary" :disabled="disabled">
            {{ t('common.confirm') }}
          </AppButton>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>
