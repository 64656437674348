export default {
  index: {
    title: 'Task deadlines',
    help: '',
    filters: {
      assigned_user: 'Employee',
      client: 'Customer',
      service: 'Service',
      project: 'Project',
      project_responsible: 'Project responsible',
      task: 'Task',
      deadline: 'Deadline',
      date_from: 'From',
      date_to: 'To',
      tasks_with_deadlines: 'Tasks with deadline',
      unfinished_tasks: 'Unfinished tasks',
    },
  },
  attributes: {
    employee: 'Employee',
    client: 'Customer',
    service: 'Service',
    project: 'Project',
    project_responsible: 'Project responsible',
    task: 'Task',
    deadline: 'Deadline',
  },
  empty: 'No events',
  tooltip: {
    set_as_done: 'Set task as Done',
    open_task: 'Open task',
    open_project: 'Open project info',
  },
};
