import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useLocalStorage } from '@vueuse/core';
import { IUserResource, UserRole, UserSystemRole } from '@/types/User';
import { SystemId } from '@/types/Common';

const useAuthStore = defineStore('AuthStore', () => {
  const authenticatedUserJSON = useLocalStorage<string | null>('authenticatedUser', null);

  const authenticatedUser = computed<IUserResource>(() =>
    authenticatedUserJSON.value ? JSON.parse(authenticatedUserJSON.value) : null,
  );

  const authenticatedUserInitials = computed(() => {
    if (authenticatedUser.value) {
      return authenticatedUser.value.name.charAt(0);
    }
    return null;
  });

  function setAuthenticatedUser(authenticatedUser: IUserResource) {
    authenticatedUserJSON.value = JSON.stringify(authenticatedUser);
  }

  function updateAuthenticatedUser(authenticatedUser: IUserResource) {
    authenticatedUserJSON.value = JSON.stringify(authenticatedUser);
  }

  function unsetAuthenticatedUser() {
    authenticatedUserJSON.value = null;
  }

  // Roles
  const isUserRole = computed(() => authenticatedUser.value?.role === UserRole.User);
  const isCustomerAdminRole = computed(() => authenticatedUser.value?.role === UserRole.CustomerAdmin);
  const isSuperAdminRole = computed(() => authenticatedUser.value?.role === UserRole.SuperAdmin);

  const isEmployeeRole = computed(() => authenticatedUser.value?.system_role === null);
  const isManagerRole = computed(() => authenticatedUser.value?.system_role === UserSystemRole.Manager);

  function hasSubscriptionToSystem(systemId: SystemId) {
    return authenticatedUser.value?.customer.systems.includes(systemId);
  }

  return {
    authenticatedUserInitials,
    authenticatedUser,
    isEmployeeRole,
    isCustomerAdminRole,
    isSuperAdminRole,
    isManagerRole,
    isUserRole,
    setAuthenticatedUser,
    updateAuthenticatedUser,
    unsetAuthenticatedUser,
    hasSubscriptionToSystem,
  };
});

export default useAuthStore;
