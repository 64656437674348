<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import VueSelect from 'vue-select';
import { useRouteQuery } from '@vueuse/router';
import {
  AppButton,
  FontIcon,
  FormLabel,
  AppLoader,
  AppTableTr,
  AppTableHead,
  AppTableBody,
  AppTableTh,
  AppTableTd,
  AppCollapse,
  AppAlert,
  PlanningEventCell,
} from '@/components';
import api from '@/services/api';
import progress from '@/services/progress';
import useDate from '@/composables/useDate';
import useLoader from '@/composables/useLoader';
import useTime from '@/composables/useTime';
import useExpandedList from '@/composables/useExpandedList';
import {
  IClientPlanningPeriodMonth,
  IClientPlanningPeriodWeek,
  IClientPlanningProject,
  IClientPlanningProjectEvent,
} from '@/types/Client';
import { ProjectStatusType } from '@/types/Project';
import useTimeReportModal from '@/composables/useTimeReportModal';

type Props = { uuid: string };
const { uuid } = defineProps<Props>();

type StatusOption = { label: string; value: string };

const { convertMinutesToTime } = useTime();
const router = useRouter();
const { t } = useI18n({ useScope: 'global' });
const { isWeekCurrent, getCurrentYearAndWeek, isWeekGreaterThanCurrent } = useDate();

const loader = useLoader({ useProgress: false });
const projectsLoader = useLoader({ useProgress: true });

const projectsWeekOffset = useRouteQuery('week-offset', 0, { transform: Number });
const filterStatus = useRouteQuery<
  'all' | ProjectStatusType.Active | ProjectStatusType.Done | ProjectStatusType.Cancelled
>('status', ProjectStatusType.Active);

const tableElement = ref<null | HTMLDivElement>(null);
const projects = ref<IClientPlanningProject[]>([]);
const periodWeeks = ref<IClientPlanningPeriodWeek[]>([]);
const periodMonths = ref<IClientPlanningPeriodMonth[]>([]);

const { collapse: collapseProject, expand: expandProject, isExpanded: isProjectExpanded } = useExpandedList();
const { openProjectTaskEditModal } = useTimeReportModal();

async function getProjects() {
  projectsLoader.start();
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('week', projectsWeekOffset.value.toString());
    if (filterStatus.value === 'all') {
      for (const status of [ProjectStatusType.Active, ProjectStatusType.Done, ProjectStatusType.Cancelled]) {
        searchParams.append('statuses[]', status);
      }
    } else {
      searchParams.append('statuses[]', filterStatus.value);
    }

    const response = await api.projects.listForPlanning(uuid, searchParams);

    projects.value = response.data.projects;
    periodWeeks.value = response.data.periods.weeks;
    periodMonths.value = response.data.periods.months;
  } catch (error) {
    console.error(error);
  } finally {
    projectsLoader.finish();
  }
}

function onProjectTaskEdit(event: IClientPlanningProjectEvent) {
  openProjectTaskEditModal(event.id, {
    onUpdated({ close }) {
      getProjects();
      close();
    },
    onSplit({ close }) {
      getProjects();
      close();
    },
    onDeleted({ close }) {
      getProjects();
      close();
    },
  });
}

function getEvents(events: IClientPlanningProjectEvent[], week: number) {
  return events.filter((event) => event.week == week);
}

function getEventsOfProject(project: IClientPlanningProject, week: number) {
  return project.tasks.reduce((acc, task) => {
    return [...acc, ...getEvents(task.events, week)];
  }, [] as IClientPlanningProjectEvent[]);
}

function getEventsPriceOfProject(project: IClientPlanningProject, week: number) {
  let trackedTime = 0;
  let eventsSum = 0;

  for (const event of getEventsOfProject(project, week)) {
    trackedTime += event.total_tracked_time;
    eventsSum += event.scheduled_time;
  }

  return {
    trackedTime,
    eventsSum,
  };
}

function startEventDrag(dragEvent: DragEvent, event: IClientPlanningProjectEvent, projectId: number) {
  if (!dragEvent.dataTransfer) return;
  dragEvent.stopPropagation();
  const target = dragEvent.target as HTMLDivElement;
  target.classList.add('dragging');
  dragEvent.dataTransfer.dropEffect = 'move';
  dragEvent.dataTransfer.effectAllowed = 'move';
  dragEvent.dataTransfer.setData('project_id', projectId.toString());
  dragEvent.dataTransfer.setData('event_week', event.week.toString());
  dragEvent.dataTransfer.setData('event_id', event.id.toString());
}

async function dropEvent(dragEvent: DragEvent, projectId: number, week: number, isTimePeriodLocked: boolean) {
  dragEvent.preventDefault();
  dragEvent.stopPropagation();
  if (
    isTimePeriodLocked ||
    !dragEvent.dataTransfer ||
    !dragEvent.dataTransfer.getData('project_id') ||
    Number(dragEvent.dataTransfer.getData('project_id')) !== projectId ||
    Number(dragEvent.dataTransfer.getData('event_week')) === week ||
    week < getCurrentYearAndWeek()
  )
    return;
  const id = Number.parseInt(dragEvent.dataTransfer.getData('event_id'));
  const element = (dragEvent.target as HTMLElement).closest('.week-cell.droppable');
  if (element) element.classList.remove('over');
  if (!Number.isInteger(id)) return;
  try {
    progress.start();
    await api.events.updateWeek(id, { week, projectId });
    document.getElementById(`event-element-${id}`)?.remove();
  } catch (error) {
    console.error(error);
  } finally {
    await getProjects();
    progress.done();
  }
}

async function resetFilters() {
  filterStatus.value = ProjectStatusType.Active;
  await getProjects();
}

function scheduleScroll(e: WheelEvent) {
  const element = tableElement.value as HTMLDivElement;
  const target = e.target as HTMLElement;
  if (target.closest('.scroll-ignore')) return;
  const race = 80;
  element.scrollLeft = e.deltaY > 0 ? element.scrollLeft + race : element.scrollLeft - race;
  e.preventDefault();
}

function endEventDrag(event: DragEvent) {
  (event.target as HTMLDivElement).classList.remove('dragging');
}

async function onWeekDrop(dragEvent: DragEvent, toWeek: number, isTimePeriodLocked: boolean) {
  dragEvent.preventDefault();
  dragEvent.stopPropagation();
  if (
    isTimePeriodLocked ||
    !dragEvent.dataTransfer ||
    !dragEvent.dataTransfer.getData('from_week') ||
    !dragEvent.dataTransfer.getData('project_id') ||
    !dragEvent.dataTransfer.getData('events') ||
    toWeek < getCurrentYearAndWeek()
  )
    return;

  const ids = dragEvent.dataTransfer
    .getData('events')
    .split(',')
    .map((id) => Number.parseInt(id));
  const element = (dragEvent.target as HTMLElement).closest('.week.droppable');
  if (element) element.classList.remove('background-neutral-50');
  if (ids.length === 0) return;
  try {
    progress.start();
    await api.events.move({ ids, week: toWeek });
  } catch (error) {
    console.error(error);
  } finally {
    await getProjects();
    progress.done();
  }
}

function onWeekOver(dragEvent: DragEvent) {
  const element = (dragEvent.target as HTMLElement).closest('.week.droppable');
  if (element) element.classList.add('background-neutral-50');
}

function onWeekLeave(dragEvent: DragEvent) {
  const element = (dragEvent.target as HTMLElement).closest('.week.droppable');
  if (element) element.classList.remove('background-neutral-50');
}

function onStartWeekDrag(dragEvent: DragEvent, project: IClientPlanningProject, fromWeek: number) {
  if (!dragEvent.dataTransfer) return;
  dragEvent.stopPropagation();
  dragEvent.dataTransfer.dropEffect = 'move';
  dragEvent.dataTransfer.effectAllowed = 'move';
  dragEvent.dataTransfer.setData('project_id', project.id.toString());
  dragEvent.dataTransfer.setData('from_week', fromWeek.toString());
  dragEvent.dataTransfer.setData(
    'events',
    getEventsOfProject(project, fromWeek)
      .filter((event) => !event.time_sheet_locked && event.total_tracked_time === 0 && !event.is_done)
      .map(({ id }) => id)
      .join(','),
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function onEndWeekDrag(_event: DragEvent) {
  //
}

onMounted(async () => {
  loader.start();
  await getProjects();
  loader.finish();
});

watch(projectsWeekOffset, () => {
  getProjects();
});
</script>

<template>
  <div class="d-flex align-items-center justify-content-between">
    <h1 v-text="t('client.planning.weekly_overview')" />
    <AppButton
      @click.prevent="router.push({ name: 'projects.create', params: { uuid } })"
      color="secondary"
      class="float-right"
      v-if="uuid"
    >
      {{ t('client.projects.index.create') }}
    </AppButton>
  </div>
  <AppCollapse class="my-3" :title="t('common.filters')">
    <form @submit.prevent="getProjects">
      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 align-items-end">
        <!-- Customer name or number -->
        <div class="form-group col">
          <FormLabel html-for="filter_name">
            {{ t('client.planning.status') }}
          </FormLabel>
          <VueSelect
            v-model="filterStatus"
            :reduce="(option: StatusOption) => option.value"
            :options="[
              { label: t('common.all'), value: 'all' },
              { label: t('project.statuses.active'), value: ProjectStatusType.Active },
              { label: t('project.statuses.done'), value: ProjectStatusType.Done },
              { label: t('project.statuses.cancelled'), value: ProjectStatusType.Cancelled },
            ]"
            label="label"
            input-id="filter_status"
            :placeholder="t('common.all')"
            :disabled="loader.isLoading.value"
            :clearable="false"
          />
        </div>
      </div>
      <div>
        <AppButton :disabled="loader.isLoading.value">
          {{ t('common.apply_filters') }}
        </AppButton>
        <AppButton class="ml-2" light @click.prevent="resetFilters" :disabled="loader.isLoading.value">
          {{ t('common.reset_filters') }}
        </AppButton>
      </div>
    </form>
  </AppCollapse>
  <div v-if="loader.isLoading.value" class="text-center">
    <AppLoader size="large" />
  </div>
  <template v-else>
    <h2>{{ t('client.planning.planned_elements_per_week') }}</h2>
    <template v-if="projects.length > 0">
      <div @wheel="scheduleScroll" ref="tableElement" class="table-responsive" style="padding-left: 1px">
        <table class="table">
          <!-- Months switcher -->
          <AppTableHead>
            <AppTableTr>
              <AppTableTh nowrap class="scroll-ignore">
                <div class="d-flex align-items-center justify-content-between flex-nowrap">
                  <div>
                    <AppButton
                      size="small"
                      @click.prevent="projectsWeekOffset -= 14"
                      light
                      circle
                      v-tooltip.top="'-14'"
                      :disabled="projectsLoader.isLoading.value"
                    >
                      <FontIcon name="chevrons-left" />
                    </AppButton>
                    <AppButton
                      class="ml-1"
                      size="small"
                      @click.prevent="projectsWeekOffset -= 5"
                      light
                      circle
                      v-tooltip.top="'-5'"
                      :disabled="projectsLoader.isLoading.value"
                    >
                      <FontIcon name="chevron-left" />
                    </AppButton>
                  </div>
                  <span class="px-4">{{ t('common.months') }}</span>
                  <div>
                    <AppButton
                      size="small"
                      @click.prevent="projectsWeekOffset += 5"
                      light
                      circle
                      v-tooltip.top="'+5'"
                      :disabled="projectsLoader.isLoading.value"
                    >
                      <FontIcon name="chevron-right" />
                    </AppButton>
                    <AppButton
                      class="ml-1"
                      size="small"
                      @click.prevent="projectsWeekOffset += 14"
                      light
                      circle
                      v-tooltip.top="'+14'"
                      :disabled="projectsLoader.isLoading.value"
                    >
                      <FontIcon name="chevrons-right" />
                    </AppButton>
                  </div>
                </div>
              </AppTableTh>
              <AppTableTh
                style="min-width: 10rem"
                :colspan="periodWeeks.reduce((total, current) => total + current.count_months, 0)"
              />
            </AppTableTr>
          </AppTableHead>

          <!-- Projects -->
          <AppTableBody
            style="border-bottom: 30px solid var(--color-neutral-100-hex)"
            v-for="project in projects"
            :key="project.id"
          >
            <!-- Project: name, weeks -->
            <AppTableTr>
              <AppTableTd class="scroll-ignore">
                <div class="d-flex align-items-center justify-content-between">
                  <RouterLink
                    target="_blank"
                    :to="{ name: 'projects.edit', params: { uuid, id: project.id } }"
                    class="pointer mr-2 flex-grow-1"
                  >
                    {{ project.name }}
                  </RouterLink>
                  <div class="flex-shrink-0">
                    <AppButton
                      v-if="!isProjectExpanded(project.id) && project.tasks.length > 0"
                      size="small"
                      light
                      circle
                      @click.prevent="expandProject(project.id)"
                      class="float-right"
                    >
                      <FontIcon name="chevron-right" />
                    </AppButton>
                    <AppButton
                      class="float-right"
                      v-if="isProjectExpanded(project.id)"
                      size="small"
                      light
                      circle
                      @click.prevent="collapseProject(project.id)"
                    >
                      <FontIcon name="chevron-down" />
                    </AppButton>
                  </div>
                </div>
              </AppTableTd>
              <AppTableTd
                v-for="periodWeek in periodWeeks"
                :class="{ 'text-success-100 background-success-500': isWeekCurrent(periodWeek.week) }"
                class="p-0"
                style="min-width: 10rem"
                :key="periodWeek.week"
                :colspan="periodWeek.count_months"
                nowrap
              >
                <div
                  :draggable="true"
                  :class="{ droppable: isWeekGreaterThanCurrent(periodWeek.week_with_year, true) }"
                  class="week font-bold d-flex align-items-center justify-content-center pointer"
                  style="min-height: 4em"
                  @dragenter.prevent
                  @drop.prevent="onWeekDrop($event, periodWeek.week_with_year, periodWeek.is_time_period_locked)"
                  @dragover.prevent="onWeekOver($event)"
                  @dragleave.prevent="onWeekLeave($event)"
                  @dragstart="onStartWeekDrag($event, project, periodWeek.week_with_year)"
                  @dragend="onEndWeekDrag($event)"
                >
                  {{ t('common.week') }} {{ periodWeek.week }}
                </div>
              </AppTableTd>
            </AppTableTr>

            <!-- Project: events - collapsed view -->
            <AppTableTr v-if="!isProjectExpanded(project.id)">
              <AppTableTd />
              <PlanningEventCell
                v-for="periodWeek in periodWeeks"
                :key="periodWeek.week"
                :period-week="periodWeek"
                :week-current="isWeekCurrent(periodWeek.week)"
                :droppable="isWeekGreaterThanCurrent(periodWeek.week_with_year, true)"
                :events="getEventsOfProject(project, periodWeek.week_with_year)"
                @drop="dropEvent($event, project.id, periodWeek.week_with_year, periodWeek.is_time_period_locked)"
                @edit="onProjectTaskEdit($event)"
                @dragstart="(e, event) => startEventDrag(e, event, project.id)"
                @dragend="endEventDrag($event)"
              />
            </AppTableTr>

            <!-- Project: events - expanded view -->
            <template v-if="isProjectExpanded(project.id)">
              <AppTableTr v-for="task in project.tasks" :key="task.id">
                <AppTableTd style="vertical-align: top" class="scroll-ignore" nowrap>{{ task.name }}</AppTableTd>
                <PlanningEventCell
                  v-for="periodWeek in periodWeeks"
                  :key="periodWeek.week"
                  :period-week="periodWeek"
                  :week-current="isWeekCurrent(periodWeek.week)"
                  :droppable="isWeekGreaterThanCurrent(periodWeek.week_with_year, true)"
                  :events="getEvents(task.events, periodWeek.week_with_year)"
                  @drop="dropEvent($event, project.id, periodWeek.week_with_year, periodWeek.is_time_period_locked)"
                  @edit="onProjectTaskEdit($event)"
                  @dragstart="(e, event) => startEventDrag(e, event, project.id)"
                  @dragend="endEventDrag($event)"
                />
              </AppTableTr>
            </template>

            <!-- Project: total -->
            <AppTableTr>
              <AppTableTd class="font-bold scroll-ignore">
                {{ t('client.planning.sum') }} {{ project.name }}
              </AppTableTd>
              <AppTableTd
                :key="periodWeek.week"
                v-for="periodWeek in periodWeeks"
                nowrap
                :colspan="periodWeek.count_months"
                class="total-item font-bold"
                :class="{ 'background-neutral-50': isWeekCurrent(periodWeek.week) }"
              >
                {{ convertMinutesToTime(getEventsPriceOfProject(project, periodWeek.week_with_year)['trackedTime']) }}
                /
                {{ convertMinutesToTime(getEventsPriceOfProject(project, periodWeek.week_with_year)['eventsSum']) }}
              </AppTableTd>
            </AppTableTr>
          </AppTableBody>
        </table>
      </div>
    </template>
    <AppAlert v-else type="info">{{ t('common.no_results') }}</AppAlert>
  </template>
</template>

<style lang="scss" scoped>
.table tr td:first-child,
.table tr th:first-child {
  position: sticky;
  left: 0;
  z-index: 100;
  background-color: white;
  outline: 1px solid var(--color-neutral-100-hex);
}

.table {
  border-spacing: 0;
  border-collapse: collapse;
  border: none;
  td,
  th {
    border: 1px solid var(--color-neutral-200-hex);
  }
}

.total-item {
  font-size: 0.85rem;
  text-align: center;
}
</style>
