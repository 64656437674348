export default {
  index: {
    title: 'Deadlines',
    help: '',
    filters: {
      assigned_user: 'Anställd',
      client: 'Kund',
      service: 'Tjänst',
      project: 'Projekt',
      project_responsible: 'Projektansvarig',
      task: 'Uppgift',
      deadline: 'Deadline',
      date_from: 'Från',
      date_to: 'Till',
      tasks_with_deadlines: 'Uppgifter med deadline',
      unfinished_tasks: 'Ej avslutade uppgifter',
    },
  },
  attributes: {
    employee: 'Anställd',
    client: 'Kund',
    service: 'Tjänst',
    project: 'Projekt',
    project_responsible: 'Projektansvarig',
    task: 'Uppgift',
    deadline: 'Deadline',
  },
  empty: 'Inga event',
  tooltip: {
    set_as_done: 'Klarmarkera uppgift',
    open_task: 'Öppna uppgiften',
    open_project: 'Öppna projektinfo',
  },
};
