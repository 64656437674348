import route from 'ziggy-js';
import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import { ISearchResponse } from '@/types/Search';

export default {
  async index(search: string): Promise<IResponse<ISearchResponse>> {
    const searchParams = new URLSearchParams();
    searchParams.append('search', search);
    return await httpClient.get(route('search.index'), { searchParams }).json();
  },
};
