import api from '@/services/api';
import { ref } from 'vue';
import { Options } from 'ky';
import { IOfficeListResource } from '@/types/Office';

type GetOfficesHooks = {
  onSet?: (data: IOfficeListResource[]) => IOfficeListResource[];
  onError?: (error: unknown) => void;
  onFinally?: () => void;
};

export default function useOffices() {
  const offices = ref<IOfficeListResource[]>([]);
  const officesLoading = ref(false);

  async function getOffices(options?: Options, hooks?: GetOfficesHooks) {
    try {
      officesLoading.value = true;
      const response = await api.offices.list(options);
      offices.value = hooks?.onSet ? hooks.onSet(response.data) : response.data;
    } catch (error) {
      console.error(error);
      if (hooks?.onError) {
        hooks?.onError(error);
      }
    } finally {
      officesLoading.value = false;
      if (hooks?.onFinally) {
        hooks?.onFinally();
      }
    }
  }

  return {
    offices,
    officesLoading,
    getOffices,
  };
}
