export default {
  index: {
    title: 'Time sheets',
    help: '',
  },
  show: {
    title: 'Time sheet for {username}',
    help: '',
  },
  form: {
    status: 'Status',
  },

  empty: 'No time sheets',

  // Attributes
  attributes: {
    total_tracked_time: 'Reported time',
    time_period: 'Month',
    status: 'Status',
    review_status: 'Review status',
    user: 'Name',
    locked: 'Locked',
    approved: 'Approved',
    time_report_status: 'Time report status',
    review_comment: 'Review comment',
  },

  status: {
    unlocked: 'Unlocked',
    locked: 'Locked',
  },

  review_status: {
    not_reviewed: 'Not reviewed',
    not_approved: 'Not approved',
    approved: 'Approved',
  },

  time_report_status: {
    completed: 'Completed',
    not_completed: 'Not completed',
  },

  tooltip: {
    lock: 'Lock',
    unlock: 'Unlock',
    approve: 'Approve',
    not_approve: 'Disapprove',
  },

  confirm: {
    approve: {
      title: 'Approve the time sheet',
      text: 'Are you sure you want to approve or disapprove this time sheet?',
    },
    lock: {
      title: 'Lock the time sheet',
      text: 'Are you sure you want to lock this time sheet?',
    },
    unlock: {
      title: 'Unlock the time sheet',
      text: 'Are you sure you want to unlock this time sheet?',
    },
  },

  reported: {
    title: 'My reported time',
  },
  parental: {
    title: 'My parental leave',
  },
  childcare: {
    title: 'My Child care leave',
  },
  review: {
    general_info: 'General info',
    reported_hours: 'Reported hours',
    weekly_reported: 'Weekly reported time',
    manager_review: 'Manager review',

    attributes: {
      month: 'Month',
      time_sheet: 'Time sheet',
      status: 'Locked',
      review_status: 'Review status',
      scheduled_time_for_the_month: 'Scheduled time for the month',
      overtime_for_the_month: 'Reported v Scheduled for the month',
      flex_time: 'Total flex time',
      total_time: 'Total reported time for the month',
      client_time: 'Customer work',
      internal_time: 'Internal work',
      absence: 'Absence',
      time_balance_before_approval: 'Time balance before approval',
      time_balance_after_approval: 'Time balance after approval',
      time_balance_after_approval_est: 'Time balance after approval (est.)',
    },
  },
};
