export default {
  home: { index: 'Hem' },
  clients: { index: 'Kunder' },
  contacts: { index: 'Kontakter' },
  todos: { index: 'ToDo & Meddelanden' },
  my_tasks: { index: 'Mina uppgifter' },
  checkpoints: { index: 'Checkpoints' },
  deadlines: { index: 'Deadlines' },
  projects: { index: 'Projekt' },
  internal_projects: { index: 'Interna projekt och händelser' },
  resource_planning: {
    index: 'Resursplanering',
    management: { index: 'Resursfördelning' },
    client_visits: { index: 'Kundbesök' },
  },
  invoices: {
    index: 'Fakturering',
    all: { index: 'Alla fakturor' },
    draft: { index: 'Fakturautkast att hantera' },
  },

  my_coworkers: 'Mina kollegor',
  service_organizations: 'Samarbetspartners',
  time_sheets: {
    index: 'Tidrapporter',
    my_time: {
      index: 'Min tid',
      reported: 'Min rapporterade tid',
      parental: 'Föräldraledigt',
      childcare: 'VAB',
    },
    time_sheets: { index: 'Hantera tidrapporter' },
    time_periods: { index: 'Arbetsmånader' },
  },
  statistics: {
    index: 'Statistik',
    revenue: {
      index: 'Intäkter & lönsamhet',
      billing: { index: 'Fakturering' },
      billing_forecast: { index: 'Faktureringsprognos' },
      profitability: { index: 'Lönsamhet' },
      write_offs: { index: 'Nedskrivningar' },
      wip_fixed_prices: { index: 'Pågående arbete - fastpris' },
      wip_hourly_prices: { index: 'Pågående arbete - löpande' },
    },
    planning: {
      index: 'Planerings & Tidsanalys',
      burndown: { index: 'Burndown' },
      progress: { index: 'Progress' },
      reported_budgeted_time: { index: 'Rapporterad v Budgeterad tid' },
      education: { index: 'Utbildningsrapport' },
      time_analysis: { index: 'Tidsanalys' },
      my_activities: { index: 'Mina uppgifter' },
    },
  },
  settings: {
    index: 'Inställningar',
    services: {
      index: 'Tjänsteinställningar',
      services: { index: 'Byråns tjänster' },
      service_mapping: { index: 'Mappning tjänster (CRM)' },
      prices: { index: 'Standardpriser' },
    },
    internal_activities: {
      index: 'Interntid',
      templates: { index: 'Interntidskoder' },
    },
    service_organizations: {
      index: 'Externa samarbetspartners inställningar',
      types_and_roles: { index: 'Typer and roller' },
    },
    offices: { index: 'Kontor' },
    departments: { index: 'Avdelningar' },
    work_titles: { index: 'Jobbtitlar' },
    employees: { index: 'Anställda' },
    salary: { index: 'Löneinställningar' },
    working_schedule: {
      index: 'Arbetstidsschema',
      holidays: { index: 'Helgdagar' },
      working_day_duration: { index: 'Arbetsdagslängd' },
    },
    invoicing: {
      index: 'Faktureringsinställningar',
      accounting: { index: 'Bokföring & artiklar' },
    },
  },
};
