<script lang="ts" setup>
import { SortOrder } from '@/types/Common';
import { provide } from 'vue';

const sortBy = defineModel<null | string>('sortBy');
const sortOrder = defineModel<null | SortOrder>('sortOrder');

function updateSortBy(value: string) {
  sortBy.value = value;
}

function updateSortOrder(value: SortOrder) {
  sortOrder.value = value;
}

provide('sortBy', {
  sortBy,
  updateSortBy,
});
provide('sortOrder', {
  sortOrder,
  updateSortOrder,
});
</script>

<template>
  <thead>
    <slot />
  </thead>
</template>
