<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { TodoType } from '@/types/Todo';

const { t } = useI18n({ useScope: 'global' });

const props = defineProps<{
  type: TodoType;
}>();

const color = computed(() => {
  switch (props.type) {
    case TodoType.Todo:
      return 'is-warning';
    case TodoType.Info:
      return 'is-secondary';
    case TodoType.Message:
      return 'is-success';
    default:
      return '';
  }
});

const icon = computed(() => {
  switch (props.type) {
    case TodoType.Todo:
      return 'ti-checkbox';
    case TodoType.Info:
      return 'ti-info-circle';
    case TodoType.Message:
      return 'ti-message';
    default:
      return '';
  }
});
</script>

<template>
  <span v-if="type" class="badge" :class="color">
    <i class="ti" :class="icon" />
    {{ t(`todo.type.${type}`) }}
  </span>
</template>
