export default {
  index: {
    title: 'Resursplanering',
    help: '',
  },
  filters: {
    from: 'Från vecka',
    to: 'Till vecka',
    offices: 'Kontor',
    departments: 'Avdelningar',
    work_titles: 'Befattningar',
    employees: 'Anställda',
  },
  attributes: {
    total: 'Totalt',
    name: 'Namn',
    office: 'Kontor',
    department: 'Avdelning',
    work_rate: 'Arbetstakt',
    planned: 'Planerade T',
    available: 'Tillgängliga T',
    net: 'Nettotimmar',
  },
};
