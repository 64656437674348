export default {
  index: {
    title: 'Längd på arbetsdagar',
    help: '',
  },

  empty: 'Inga arbetsdagar',

  // Attributes
  attributes: {
    default_working_hours: 'Standardvärde arbetsdag',
    work_day_durations: 'Arbetsdagens längd',
    january: 'Januari',
    february: 'Februari',
    march: 'Mars',
    april: 'April',
    may: 'Maj',
    june: 'Juni',
    july: 'Juli',
    august: 'Augusti',
    september: 'September',
    october: 'Oktober',
    november: 'November',
    december: 'December',
  },

  confirm: {
    destroy: {
      title: 'Radera alternativ arbetsdagslängd',
      text: 'Är du säker på att vill radera <strong>{hours}</strong>',
      textIfIssetMonths: 'Flytta aktuella månader till en annan rad innan du raderar denna.',
    },
  },
};
