import { useModal } from 'vue-final-modal';
import { TimeEntryActivityModal, TimeEntryProjectTaskModal } from '@/components';
import {
  TimeReportProjectTaskUpdateModalCallbacks,
  TimeReportProjectTaskSplitEmit,
  TimeReportProjectTaskUpdateEmit,
  TimeReportProjectTaskDeleteEmit,
  TimeReportProjectTaskCreateModalCallbacks,
  TimeReportProjectTaskCreateEmit,
  TimeReportActivityTaskUpdateModalCallbacks,
  TimeReportActivityTaskDeleteEmit,
  TimeReportActivityTaskUpdateEmit,
  TimeReportActivityTaskSplitEmit,
  TimeReportActivityTaskCreateModalCallbacks,
  TimeReportActivityTaskCreateEmit,
} from '@/types/TimeReportModals';
import { ProjectTaskModalProps } from '@/types/ProjectTask';
import { ActivityModalProps } from '@/types/Activity';

export default function useTimeReportModal() {
  async function openProjectTaskCreateModal(
    attrs: ProjectTaskModalProps,
    { onCreated }: TimeReportProjectTaskCreateModalCallbacks,
  ) {
    const { open, close, destroy } = useModal({
      component: TimeEntryProjectTaskModal,
      attrs: {
        ...attrs,
        async onCreated({ event, affectedWeeks }: TimeReportProjectTaskCreateEmit) {
          onCreated({
            event,
            affectedWeeks,
            close,
          });
        },
        onClose() {
          close();
        },
        onClosed() {
          destroy();
        },
      },
    });
    await open();
  }

  async function openProjectTaskEditModal(
    id: number,
    { onUpdated, onSplit, onDeleted }: TimeReportProjectTaskUpdateModalCallbacks,
  ) {
    const { open, close, destroy } = useModal({
      component: TimeEntryProjectTaskModal,
      attrs: {
        id,
        async onSplit({ event, affectedWeeks }: TimeReportProjectTaskSplitEmit) {
          onSplit({
            event,
            affectedWeeks,
            close,
          });
        },
        async onUpdated({ event, affectedWeeks }: TimeReportProjectTaskUpdateEmit) {
          onUpdated({
            event,
            affectedWeeks,
            close,
          });
        },
        async onDeleted({ affectedWeeks }: TimeReportProjectTaskDeleteEmit) {
          onDeleted({
            affectedWeeks,
            close,
          });
        },
        onClose() {
          close();
        },
        onClosed() {
          destroy();
        },
      },
    });
    await open();
  }

  async function openActivityTaskCreateModal(
    attrs: ActivityModalProps,
    { onCreated }: TimeReportActivityTaskCreateModalCallbacks,
  ) {
    const { open, close, destroy } = useModal({
      component: TimeEntryActivityModal,
      attrs: {
        ...attrs,
        async onCreated({ event, affectedWeeks }: TimeReportActivityTaskCreateEmit) {
          onCreated({
            event,
            affectedWeeks,
            close,
          });
        },
        onClose() {
          close();
        },
        onClosed() {
          destroy();
        },
      },
    });
    await open();
  }

  async function openActivityTaskEditModal(
    id: number,
    { onUpdated, onSplit, onDeleted }: TimeReportActivityTaskUpdateModalCallbacks,
  ) {
    const { open, close, destroy } = useModal({
      component: TimeEntryActivityModal,
      attrs: {
        id,
        async onSplit({ event, affectedWeeks }: TimeReportActivityTaskSplitEmit) {
          onSplit({
            event,
            affectedWeeks,
            close,
          });
        },
        async onUpdated({ event, affectedWeeks }: TimeReportActivityTaskUpdateEmit) {
          onUpdated({
            event,
            affectedWeeks,
            close,
          });
        },
        async onDeleted({ affectedWeeks }: TimeReportActivityTaskDeleteEmit) {
          onDeleted({
            affectedWeeks,
            close,
          });
        },
        onClose() {
          close();
        },
        onClosed() {
          destroy();
        },
      },
    });
    await open();
  }

  return {
    openProjectTaskCreateModal,
    openProjectTaskEditModal,
    openActivityTaskCreateModal,
    openActivityTaskEditModal,
  };
}
