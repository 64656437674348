<script setup lang="ts">
import { computed, reactive, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';

import { AppButton, FormInput, FormLabel } from '@/components';
import api from '@/services/api';
import { IContactRole, IContactRoleRequestBody } from '@/types/Contact';
import useLoader from '@/composables/useLoader';

const { t } = useI18n({ useScope: 'global' });

const loader = useLoader();

const form = reactive<IContactRoleRequestBody>({
  name_sv: '',
  name_en: '',
});

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'created', createRole: IContactRole): void;
}>();

const disabled = computed(() => form.name_sv === '' || form.name_en === '');

async function submit() {
  try {
    loader.start();
    const response = await api.clients.contacts.roles.store(form);
    emit('created', response.data);
  } catch (error) {
    console.error(error);
  } finally {
    loader.finish();
  }
}

onUnmounted(() => {
  form.name_en = '';
  form.name_sv = '';
});
</script>

<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container is-small"
    :click-to-close="false"
    :esc-to-close="true"
  >
    <form @submit.prevent="submit">
      <div class="modal-header">
        <h2 v-text="t('contact.role.create.title')" />
      </div>
      <div class="modal-content">
        <div class="form-group">
          <FormLabel html-for="name_sv" required>{{ t('contact.role.attributes.name_sv') }}</FormLabel>
          <FormInput id="name_sv" v-model="form.name_sv" required />
        </div>
        <div class="form-group">
          <FormLabel html-for="name_en" required>{{ t('contact.role.attributes.name_en') }}</FormLabel>
          <FormInput id="name_en" v-model="form.name_en" required />
        </div>
      </div>
      <div class="modal-footer">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
          <AppButton light @click.prevent="emit('cancel')" :disabled="loader.isLoading.value">{{
            t('common.cancel')
          }}</AppButton>
          <AppButton class="mt-2 mt-sm-0" color="secondary" :loading="loader.isLoading.value" :disabled="disabled">
            {{ t('common.create') }}
          </AppButton>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>
