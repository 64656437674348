import progress from '@/services/progress';
import { computed, ref } from 'vue';

export default function useLoader(opts?: { useProgress?: boolean }) {
  const loading = ref(false);

  const isLoading = computed(() => loading.value);
  const isNotLoading = computed(() => !loading.value);

  function start() {
    if (opts?.useProgress !== false) {
      progress.start();
    }
    loading.value = true;
  }

  function finish() {
    if (opts?.useProgress !== false) {
      progress.done();
    }
    loading.value = false;
  }

  return {
    isLoading,
    isNotLoading,
    start,
    finish,
  };
}
