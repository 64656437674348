import route from 'ziggy-js';
import httpClient from '@/services/api/ky';
import { IAuthLoginRequestBody, IAuthPortalUserAcceptInviteRequestBody } from '@/types/Auth';
import { SearchParamsOption } from 'ky';

export default {
  async login(body: IAuthLoginRequestBody) {
    await httpClient.post(route('auth.login'), { json: body }).json();
  },
  async logout() {
    await httpClient.post(route('auth.logout')).json();
  },
  invite: {
    async accept(invite: string, body: IAuthPortalUserAcceptInviteRequestBody, searchParams: SearchParamsOption) {
      await httpClient.post(route('invite.accept', { invite }), { json: body, searchParams }).json();
    },
    async check(invite: string, body: IAuthPortalUserAcceptInviteRequestBody, searchParams?: SearchParamsOption) {
      await httpClient.post(route('invite.check', { invite }), { json: body, searchParams }).json();
    },
  },
};
