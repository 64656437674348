import { Decimal } from 'decimal.js';
import { useModal } from 'vue-final-modal';
import { FetchNotInvoicedAmountsModal } from '@/components';
import { IFetchNotInvoicedWorksRequest } from '@/types/Invoice';

export default function useInvoice() {
  function distributeValues(value: number, ...values: number[]) {
    const totalPrice = values.reduce((total, price) => total.plus(price), new Decimal(0));

    if (totalPrice.isZero()) {
      return values.map(() => 0);
    }

    const proportions = values.map((price) => new Decimal(price).div(totalPrice).times(value));
    const roundedProportions = proportions.map((prop) => prop.toDecimalPlaces(2));
    const roundedTotal = roundedProportions.reduce((acc, prop) => acc.plus(prop), new Decimal(0));
    const difference = new Decimal(value).minus(roundedTotal);

    if (!difference.isZero()) {
      const maxPropIndex = roundedProportions.reduce(
        (maxIndex, current, index, array) => (current.greaterThan(array[maxIndex]) ? index : maxIndex),
        0,
      );
      roundedProportions[maxPropIndex] = roundedProportions[maxPropIndex].plus(difference);
    }

    return roundedProportions.map((prop) => prop.toNumber());
  }

  async function openFetchNotInvoicedModal(
    args: Pick<IFetchNotInvoicedWorksRequest, 'client' | 'time_period'> & { selectedProjects?: number[] },
    { onFetched }: { onFetched: () => Promise<void> | void },
  ) {
    const { open, close, destroy } = useModal({
      component: FetchNotInvoicedAmountsModal,
      attrs: {
        clientUuid: args.client,
        timePeriodId: args.time_period,
        selectedProjects: args.selectedProjects,
        async onFetched() {
          await Promise.all([onFetched(), close()]);
        },
        onClosed() {
          destroy();
        },
      },
    });
    await open();
  }

  return {
    distributeValues,
    openFetchNotInvoicedModal,
  };
}
