import api from '@/services/api';
import { ref } from 'vue';
import { Options } from 'ky';
import { ITimePeriodResource } from '@/types/TimePeriod';

type GetTimePeriodsHooks = {
  onSet?: (data: ITimePeriodResource[]) => ITimePeriodResource[];
  onError?: (error: unknown) => void;
  onFinally?: () => void;
};

export default function useTimePeriods() {
  const timePeriods = ref<ITimePeriodResource[]>([]);
  const timePeriodsLoading = ref(false);

  async function getTimePeriods(options?: Options, hooks?: GetTimePeriodsHooks) {
    try {
      timePeriodsLoading.value = true;
      const response = await api.timePeriods.index(options);
      timePeriods.value = hooks?.onSet ? hooks.onSet(response.data) : response.data;
    } catch (error) {
      console.error(error);
      if (hooks?.onError) {
        hooks?.onError(error);
      }
    } finally {
      timePeriodsLoading.value = false;
      if (hooks?.onFinally) {
        hooks?.onFinally();
      }
    }
  }

  return {
    timePeriods,
    timePeriodsLoading,
    getTimePeriods,
  };
}
