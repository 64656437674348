<script setup lang="ts">
import { AppDropdownSection } from '@/components';

type Props = {
  columns: string[];
  getLabel: (columnName: string) => string;
};

defineProps<Props>();

const modelValue = defineModel<string[]>();
</script>

<template>
  <AppDropdownSection class="d-flex flex-column gap-2">
    <div v-for="columnName in columns" :key="columnName">
      <input
        :id="`columns-${columnName}`"
        type="checkbox"
        class="form-check"
        v-model="modelValue"
        :value="columnName"
      />
      <label :for="`columns-${columnName}`" class="form-label" v-text="getLabel(columnName)" />
    </div>
  </AppDropdownSection>
</template>
