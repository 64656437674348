export default {
  index: {
    title: 'Articles',
    fetch_articles: 'Fetch articles',
  },
  attributes: {
    erp_id: 'Number',
    erp_type: 'Erp Type',
    name_sv: 'Name (SWE)',
    name_en: 'Name (ENG)',
    unit: 'Unit',
    status: 'Status',
  },
  status: {
    enabled: 'Enabled',
    disabled: 'Disabled',
  },
  empty: 'No articles',
  tooltip: {
    enable: 'Enable {name}',
    disable: 'Disable {name}',
  },
  confirm: {
    disable: {
      title: 'Disable article',
      text: 'This article is used for the invoice item. You will need to select some active article instead of this one.',
    },
  },
};
