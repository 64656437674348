<script setup lang="ts">
import { ref, onMounted, watch, reactive, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import VueSelect, { VueSelectInstance } from 'vue-select';
import { useRouteQuery } from '@vueuse/router';
import { Dropdown } from 'floating-vue';
import api from '@/services/api';
import {
  AppBox,
  AppBoxBody,
  AppButton,
  AppAlert,
  AppLoader,
  AppTable,
  AppTableHead,
  AppTableBody,
  AppTableTr,
  AppTableTd,
  AppTableTh,
  FormLabel,
  AppPagination,
  FontIcon,
  HelpInformation,
  TimeEntryDropdownOptions,
} from '@/components';
import useLoader from '@/composables/useLoader';
import useAuthStore from '@/store/AuthStore';
import useTime from '@/composables/useTime';
import useDate from '@/composables/useDate';
import useClients from '@/composables/useClients';
import { EventType, IEventPreviewResource } from '@/types/Event';
import { IProjectPreviewResource } from '@/types/Project';
import { ActivityModalProps } from '@/types/Activity';
import { ProjectTaskModalProps } from '@/types/ProjectTask';
import { IClientPreviewResource } from '@/types/Client';
import { useTitle } from '@vueuse/core';
import useTimeReportModal from '@/composables/useTimeReportModal';

const currentPage = useRouteQuery('page', '1', { transform: Number });
const { t } = useI18n({ useScope: 'global' });
const loader = useLoader();
const { authenticatedUser } = useAuthStore();
const { convertMinutesToTime } = useTime();
const { formatYearWeek } = useDate();
const { clients, onSearchClients } = useClients();

const projects = ref<IProjectPreviewResource[]>([]);
const projectLoading = ref(false);

const filters = reactive<{
  project_id: null | number;
  client_uuid: null | string;
  eventable_type: null | EventType;
  dirty: boolean;
}>({
  project_id: null,
  client_uuid: null,
  eventable_type: null,
  dirty: false,
});

const filterDisabled = computed(() => loader.isLoading.value);
const filterApplyDisabled = computed(
  () => filters.project_id === null && filters.client_uuid === null && filters.eventable_type === null,
);

const filterClientDropdown = ref<VueSelectInstance | null>(null);
const filterProjectDropdown = ref<VueSelectInstance | null>(null);
const filterEventTypeDropdown = ref<VueSelectInstance | null>(null);

const perPage = ref(20);
const total = ref(0);

const events = ref<IEventPreviewResource[]>([]);

const { openProjectTaskCreateModal, openProjectTaskEditModal, openActivityTaskCreateModal, openActivityTaskEditModal } =
  useTimeReportModal();

async function getEvents() {
  try {
    loader.start();
    const searchParams = new URLSearchParams();
    searchParams.append('page', currentPage.value.toString());
    if (filters.project_id) searchParams.append('project_id', filters.project_id.toString());
    if (filters.client_uuid) searchParams.append('client_uuid', filters.client_uuid);
    if (filters.eventable_type) searchParams.append('eventable_type', filters.eventable_type);
    const response = await api.users.events.index(authenticatedUser.uuid, { searchParams });
    events.value = response.data;
    perPage.value = response.meta!.per_page;
    total.value = response.meta!.total;
  } catch (error) {
    console.error(error);
  } finally {
    loader.finish();
  }
}

async function getProjects() {
  if (filters.client_uuid === null) return;
  try {
    projectLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.clients.projects.list(filters.client_uuid, { searchParams });
    projects.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    projectLoading.value = false;
  }
}

function onEditEvent(id: number, mode: EventType) {
  if (mode === 'activity') {
    openActivityTaskEditModal(id, {
      onUpdated({ close }) {
        getEvents();
        close();
      },
      onSplit({ close }) {
        getEvents();
        close();
      },
      async onDeleted({ close }) {
        await Promise.all([getEvents(), close()]);
        if (events.value.length === 0 && currentPage.value > 1) {
          currentPage.value -= 1;
        }
      },
    });
  } else if (mode === 'project_task') {
    openProjectTaskEditModal(id, {
      onUpdated({ close }) {
        getEvents();
        close();
      },
      onSplit({ close }) {
        getEvents();
        close();
      },
      async onDeleted({ close }) {
        await Promise.all([getEvents(), close()]);
        if (events.value.length === 0 && currentPage.value > 1) {
          currentPage.value -= 1;
        }
      },
    });
  }
}

async function onFilter() {
  currentPage.value = 1;
  await getEvents();
  filters.dirty = true;
}

async function resetFilters() {
  filters.client_uuid = null;
  filterClientDropdown.value!.search = '';
  clients.value = [];
  filters.project_id = null;
  filterProjectDropdown.value!.search = '';
  projects.value = [];
  filters.eventable_type = null;
  filterEventTypeDropdown.value!.search = '';
  await onFilter();
  filters.dirty = false;
}

onMounted(getEvents);

watch(currentPage, getEvents);

watch(
  () => filters.client_uuid,
  (value) => {
    if (value) {
      getProjects();
    } else {
      filters.project_id = null;
      projects.value = [];
    }
  },
);

function onActivityCreate(attrs: ActivityModalProps) {
  openActivityTaskCreateModal(attrs, {
    onCreated({ close }) {
      getEvents();
      close();
    },
  });
}

function onProjectTaskCreate(attrs: ProjectTaskModalProps) {
  openProjectTaskCreateModal(attrs, {
    onCreated({ close }) {
      getEvents();
      close();
    },
  });
}

const title = useTitle(computed(() => t('activities.index.title')));
</script>

<template>
  <div class="container-fluid">
    <div class="d-flex align-items-center justify-content-between mb-4">
      <div class="d-flex align-items-end">
        <h1 class="mb-0" v-text="title" />
        <HelpInformation class="ml-1" translation="activities.index.help" />
      </div>
      <Dropdown placement="bottom-end" :distance="10">
        <AppButton @click.prevent.stop color="secondary" :disabled="loader.isLoading.value">
          {{ t('activities.index.create') }}
        </AppButton>
        <template #popper="{ hide }">
          <TimeEntryDropdownOptions
            @on-absence="onActivityCreate($event)"
            @on-project-task="onProjectTaskCreate($event)"
            @on-internal="onActivityCreate($event)"
            :hide="hide"
          />
        </template>
      </Dropdown>
    </div>
    <form @submit.prevent="onFilter" class="my-3">
      <AppBox>
        <AppBoxBody>
          <div class="row">
            <div class="form-group col-md-4">
              <FormLabel html-for="filter_client">
                {{ t('event.tracked_time.attributes.client') }}
              </FormLabel>
              <VueSelect
                :filterable="false"
                @search="onSearchClients"
                v-model="filters.client_uuid"
                :reduce="(option: IClientPreviewResource) => option.uuid"
                :options="clients"
                label="name"
                input-id="filter_client"
                :placeholder="t('common.search')"
                :disabled="filterDisabled"
                ref="filterClientDropdown"
              >
                <template #no-options>{{ t('common.type_to_search') }}</template>
              </VueSelect>
            </div>
            <div class="form-group col-md-4">
              <FormLabel html-for="filter_project">
                {{ t('event.tracked_time.attributes.project') }}
              </FormLabel>
              <VueSelect
                :clearable="false"
                v-model="filters.project_id"
                :options="projects"
                :reduce="(option: IProjectPreviewResource) => option.id"
                label="name"
                input-id="filter_project"
                :placeholder="t('common.select')"
                :disabled="projectLoading || filterDisabled || filters.client_uuid === null"
                :loading="projectLoading"
                ref="filterProjectDropdown"
              />
            </div>
            <div class="form-group col-md-4">
              <FormLabel html-for="filter_type">
                {{ t('event.tracked_time.attributes.type') }}
              </FormLabel>
              <VueSelect
                :clearable="false"
                v-model="filters.eventable_type"
                :options="['project_task', 'activity']"
                input-id="filter_type"
                :placeholder="t('common.select')"
                :get-option-label="(option: string) => t(`event.type.${option}`)"
                :disabled="filterDisabled"
                ref="filterEventTypeDropdown"
              />
            </div>
            <div class="col-12">
              <AppButton :disabled="filterDisabled || filterApplyDisabled">
                {{ t('common.apply_filters') }}
              </AppButton>
              <AppButton class="ml-2" light @click.prevent="resetFilters" :disabled="filterDisabled || !filters.dirty">
                {{ t('common.reset_filters') }}
              </AppButton>
            </div>
          </div>
        </AppBoxBody>
      </AppBox>
    </form>
    <div v-if="loader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <template v-else>
      <AppAlert class="mt-3" v-if="events.length === 0">
        {{ filters.dirty ? t('common.no_search_results') : t('event.empty') }}
      </AppAlert>
      <AppTable v-else hoverable>
        <AppTableHead>
          <AppTableTr>
            <AppTableTh nowrap>{{ t('event.tracked_time.attributes.name') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('event.tracked_time.attributes.done') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('event.tracked_time.attributes.type') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('event.tracked_time.attributes.project') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('event.tracked_time.attributes.client') }}</AppTableTh>
            <AppTableTh class="text-right" nowrap>{{ t('event.tracked_time.attributes.scheduled_time') }}</AppTableTh>
            <AppTableTh class="text-right" nowrap>{{ t('event.tracked_time.attributes.estimated_time') }}</AppTableTh>
            <AppTableTh class="text-right" nowrap>{{ t('event.tracked_time.attributes.reported_time') }}</AppTableTh>
            <AppTableTh class="text-right" nowrap>{{ t('common.actions') }}</AppTableTh>
          </AppTableTr>
        </AppTableHead>
        <AppTableBody>
          <AppTableTr v-for="event in events" :key="event.id">
            <AppTableTd nowrap>
              <strong>{{ event.eventable_data.name }}</strong>
            </AppTableTd>
            <AppTableTd nowrap>
              <i class="ti ti-check text-success-500 text-3" v-if="event.done_at" />
            </AppTableTd>
            <AppTableTd nowrap>
              {{ t(`event.type.${event.eventable_type}`) }}
            </AppTableTd>
            <AppTableTd nowrap>
              <span v-if="event.eventable_type === 'project_task'" v-text="event.eventable_data.project?.name" />
              <i v-else class="text-neutral-300" v-text="t('common.empty')" />
            </AppTableTd>
            <AppTableTd nowrap>
              <span
                v-if="event.eventable_type === 'project_task'"
                v-text="event.eventable_data.project?.client?.name"
              />
              <i v-else class="text-neutral-300" v-text="t('common.empty')" />
            </AppTableTd>
            <AppTableTd class="text-right" nowrap>
              {{ formatYearWeek(event.week) }}
            </AppTableTd>
            <AppTableTd class="text-right" nowrap>
              <span v-text="convertMinutesToTime(event.scheduled_time)" />
            </AppTableTd>
            <AppTableTd class="text-right" nowrap>
              {{ convertMinutesToTime(event.tracked_time) }}
            </AppTableTd>
            <AppTableTd class="text-right" nowrap>
              <AppButton
                v-tooltip.left="t('event.tracked_time.tooltip.edit')"
                @click.stop.prevent="onEditEvent(event.id, event.eventable_type)"
                size="small"
                light
                circle
                class="ml-2"
              >
                <FontIcon name="clock-plus" />
              </AppButton>
            </AppTableTd>
          </AppTableTr>
        </AppTableBody>
      </AppTable>
      <AppPagination :per-page="perPage" :total="total" v-model="currentPage" />
    </template>
  </div>
</template>
