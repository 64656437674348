export default {
  index: {
    title: 'Pristabell',
    general_info: 'Allmän info',
    help: '',
  },
  filter: {
    user: 'Användare',
    user_search: 'Skriv för att söka',
  },
  modal: {
    title: 'Schemalägg pris',
    history: 'Prishistorik',
  },
  attributes: {
    amount: 'Belopp',
    work_title: 'Jobbtitel',
    apply_from: 'Tillämpa f.o.m',
    applied_from: 'Gäller från',
    create_update: 'Skapa / Uppdatera',
  },
  // Confirms
  confirm: {
    destroy: {
      title: 'Radera priser',
      text: 'Är du säker att du vill radera <strong>{price}</strong>?',
    },
  },

  // Tooltips
  tooltip: {
    price_change_is_scheduled: 'Prisändring är schemalagd',
  },

  empty_history: 'Ingen prishistorik',
};
