<script lang="ts" setup>
import { computed } from 'vue';
import useHelpers from '@/composables/useHelpers';
import { clsx } from 'clsx';

const { parseAmount } = useHelpers();

defineOptions({
  inheritAttrs: false,
});

const [modelValue, modifiers] = defineModel<unknown, 'trim' | 'number' | 'lazy' | 'money'>({
  required: false,
  set(value) {
    if (modifiers.trim && typeof value === 'string') {
      return value.trim();
    }
    if (modifiers.number && typeof value === 'string') {
      return parseInt(value, 10);
    }
    if (modifiers.money && (typeof value === 'string' || typeof value === 'number')) {
      return parseAmount(value);
    }
    return value;
  },
});

const props = defineProps<{
  size?: 'small' | 'large';
  icon?: string;
  groupClass?: string;
  trim?: boolean;
  invalid?: boolean;
}>();

const groupClasses = computed(() =>
  clsx(
    'form-wrapper',
    {
      [`is-${props.size}`]: props.size,
      'has-icon': props.icon,
    },
    props.groupClass,
  ),
);

const classes = computed(() =>
  clsx({
    'is-invalid': props.invalid,
  }),
);
</script>

<template>
  <div :class="groupClasses">
    <i v-if="icon" class="form-icon ti" :class="`ti-${props.icon}`" />
    <input v-if="modifiers.lazy" v-bind="$attrs" class="form-control" :class="classes" v-model.lazy="modelValue" />
    <input v-else v-bind="$attrs" class="form-control" :class="classes" v-model="modelValue" />
  </div>
  <slot />
  <div v-if="$slots.popup" class="form-popup">
    <slot name="popup" />
  </div>
</template>
