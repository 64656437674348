import { defineStore } from 'pinia';
import { ref } from 'vue';
import api from '@/services/api';

import { IContactRole } from '@/types/Contact';

const useContactRolesStore = defineStore('ContactRolesStore', () => {
  const contactRoles = ref<IContactRole[]>([]);
  const contactRolesLoading = ref(false);

  async function getContactRoles() {
    try {
      contactRolesLoading.value = true;
      const searchParams = new URLSearchParams();
      searchParams.append('without_pagination', '1');
      const response = await api.clients.contacts.roles.list(searchParams);
      contactRoles.value = response.data;
    } catch (error) {
      console.error(error);
    } finally {
      contactRolesLoading.value = false;
    }
  }

  function addContactRole(role: IContactRole) {
    contactRoles.value = [...contactRoles.value, role];
  }

  return { contactRoles, contactRolesLoading, getContactRoles, addContactRole };
});

export default useContactRolesStore;
