import { RouteRecordRaw } from 'vue-router';

import Invoices from '@/views/Invoices/Invoices.vue';
import Invoice from '@/views/Invoices/Invoice.vue';
import MainLayout from '@/layouts/MainLayout.vue';
import DraftInvoices from '@/views/Invoices/DraftInvoices.vue';

const prefix = 'invoices';

const routes: RouteRecordRaw[] = [
  {
    path: `/${prefix}`,
    name: `${prefix}.index`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: Invoices,
  },
  {
    path: `/${prefix}/drafts`,
    name: `${prefix}.drafts`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: DraftInvoices,
  },
  {
    path: `/${prefix}/:id/edit`,
    name: `${prefix}.edit`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: Invoice,
    props: (route) => ({
      ...route.params,
      id: Number(route.params.id),
    }),
  },
];

export default routes;
