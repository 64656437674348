<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { AppButton, AppCloseButton, AppLoader, AppModal, FormLabel, FormSwitch } from '@/components';
import useLoading from '@/composables/useLoading';
import { IProjectPreviewResource } from '@/types/Project';
import { onMounted, reactive, ref } from 'vue';
import { IFetchNotInvoicedWorksRequest } from '@/types/Invoice';
import api from '@/services/api';
import toast from '@/services/toast';

const { loading, setLoading } = useLoading();
const { t } = useI18n({ useScope: 'global' });

type Props = {
  clientUuid: string;
  timePeriodId?: number;
  selectedProjects?: number[];
};

const projects = ref<IProjectPreviewResource[]>([]);
const projectsLoading = ref(false);

const { clientUuid, timePeriodId, selectedProjects = [] } = defineProps<Props>();

const emit = defineEmits<{
  closed: [];
  fetched: [];
}>();

const form = reactive<IFetchNotInvoicedWorksRequest>({
  client: clientUuid,
  time_period: timePeriodId,
  projects: [],
  add_to_existing_invoices: true,
});

async function getProjects() {
  try {
    projectsLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    searchParams.append('statuses[]', 'active');
    const response = await api.projects.index(clientUuid, searchParams);
    projects.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    projectsLoading.value = false;
  }
}

async function submit() {
  setLoading(true);
  try {
    await api.invoices.fetchNotInvoicedWorks(form);
    toast.success(t('common.updated'));
    emit('fetched');
  } catch (error) {
    console.error(error);
  } finally {
    setLoading(false);
  }
}

onMounted(async () => {
  await getProjects();
  if (selectedProjects.length > 0) {
    selectedProjects.forEach((id) => {
      if (projects.value.some((p) => p.id === id)) {
        form.projects.push(id);
      }
    });
  } else {
    form.projects = projects.value.map(({ id }) => id);
  }
});
</script>

<template>
  <AppModal @closed="emit('closed')" v-slot="{ close }">
    <form @submit.prevent="submit">
      <div class="modal-header">
        <h2 v-text="t('invoice.fetch_not_invoiced.title')" />
        <AppCloseButton @close="close" />
      </div>
      <div class="modal-content">
        <p v-text="t('invoice.fetch_not_invoiced.text')" />
        <div class="text-center">
          <AppLoader size="small" v-if="projectsLoading" />
        </div>
        <div class="row" v-for="project in projects" :key="project.id">
          <div class="col-1">
            <FormSwitch :id="`project-${project.id}`" v-model="form.projects" :disabled="loading" :value="project.id" />
          </div>
          <div class="col">
            <FormLabel :html-for="`project-${project.id}`">{{ project.name }}</FormLabel>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-1">
            <FormSwitch id="add_to_existing_invoices" v-model="form.add_to_existing_invoices" :disabled="loading" />
          </div>
          <div class="col">
            <FormLabel html-for="add_to_existing_invoices">
              {{ t('invoice.fetch_not_invoiced.add_to_existing_invoices') }}
            </FormLabel>
          </div>
        </div>
        <slot />
      </div>
      <div class="modal-footer">
        <div class="d-flex">
          <AppButton light @click.prevent="close" :disabled="loading">
            {{ t('common.cancel') }}
          </AppButton>
          <AppButton class="ml-auto" color="secondary" :disabled="loading || projectsLoading" :loading="loading">
            {{ t('common.fetch') }}
          </AppButton>
        </div>
      </div>
    </form>
  </AppModal>
</template>
