<script setup lang="ts">
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMagicKeys } from '@vueuse/core';

const { escape, enter } = useMagicKeys();

const { t } = useI18n({ useScope: 'global' });

defineProps<{
  isRevealed: unknown;
}>();

const emit = defineEmits<{
  (e: 'cancel', isConfirmed: boolean): void;
  (e: 'confirm', isConfirmed: boolean): void;
}>();

watch(escape, (v) => {
  if (v) emit('cancel', false);
});
watch(enter, (v) => {
  if (v) emit('confirm', true);
});
</script>
<template>
  <teleport to="body">
    <div v-if="isRevealed" class="modal-overlay" role="dialog" style="z-index: 1000">
      <div class="modal-container">
        <div class="modal-header">
          <h2 v-text="t('common.confirms.unsaved.title')" />
        </div>
        <div class="modal-content">
          <p v-text="t('common.confirms.unsaved.text')" />
        </div>
        <div class="modal-footer">
          <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
            <button class="button is-light" @click="emit('cancel', false)">
              {{ t('common.cancel') }}
            </button>
            <button class="button is-danger mt-2 mt-sm-0" @click.prevent="emit('confirm', true)">
              {{ t('common.confirm') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>
