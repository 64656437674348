<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouteQuery } from '@vueuse/router';
import { useRouter } from 'vue-router';
import VueSelect, { VueSelectInstance } from 'vue-select';
import VueDatePicker from '@vuepic/vue-datepicker';
import { DateTime } from 'luxon';
import { useModal } from 'vue-final-modal';

import toast from '@/services/toast';
import api from '@/services/api';
import {
  AppAlert,
  AppBox,
  AppBoxBody,
  AppButton,
  AppLoader,
  AppPagination,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  CommentModal,
  FontIcon,
  FormLabel,
  HelpInformation,
} from '@/components';
import useLoader from '@/composables/useLoader';
import useAuthStore from '@/store/AuthStore';
import useTime from '@/composables/useTime';
import { IUserListResource } from '@/types/User';
import { ITimeSheetPreviewResource, TimeSheetReviewStatus, TimeSheetStatus } from '@/types/TimeSheet';
import { ConfirmDialogConfirmParams } from '@/types/Common';
import { useTitle } from '@vueuse/core';

type Filters = {
  period: string;
  user_uuid: string[];
  status: TimeSheetStatus[];
  review_status: TimeSheetReviewStatus[];
};

const currentPage = useRouteQuery('page', '1', { transform: Number });
const perPage = ref(20);
const total = ref(0);

const filterStatusDropdown = ref<VueSelectInstance | null>(null);
const filterReviewStatusDropdown = ref<VueSelectInstance | null>(null);
const filterUserDropdown = ref<VueSelectInstance | null>(null);

const { t, locale } = useI18n({ useScope: 'global' });
const loader = useLoader();
const { isCustomerAdminRole, isSuperAdminRole, isManagerRole, isEmployeeRole, authenticatedUser } = useAuthStore();
const { convertMinutesToTime } = useTime();
const router = useRouter();

const timeSheets = ref<ITimeSheetPreviewResource[]>([]);

const editable = computed(() => isCustomerAdminRole || isSuperAdminRole || (isManagerRole && !isEmployeeRole));

const users = ref<IUserListResource[]>([]);
const usersLoading = ref(false);

const filters = reactive<Filters>({
  period: DateTime.now().minus({ month: 1 }).startOf('month').toFormat('MM-yyyy'),
  user_uuid: [],
  status: [],
  review_status: [TimeSheetReviewStatus.NOT_REVIEWED, TimeSheetReviewStatus.NOT_APPROVED],
});

async function getTimeSheets() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('page', currentPage.value.toString());
    if (filters.period) {
      const [month, year] = filters.period.split('-');
      searchParams.append('month', month);
      searchParams.append('year', year);
    }
    filters.user_uuid.forEach((uuid) => searchParams.append('users_uuid[]', uuid));
    filters.status.forEach((status) => searchParams.append('statuses[]', status));
    filters.review_status.forEach((status) => searchParams.append('review_statuses[]', status));
    const response = await api.timeSheets.index({ searchParams });
    timeSheets.value = response.data;
    if (response.meta) {
      perPage.value = response.meta.per_page;
      total.value = response.meta.total;
    }
  } catch (error) {
    console.error(error);
  }
}

async function getUsers() {
  try {
    usersLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    // Filter users by manager uuid if is manager and not is admin
    if (!isSuperAdminRole && !isCustomerAdminRole && isManagerRole) {
      searchParams.append('manager_uuid', authenticatedUser.uuid);
    }
    const response = await api.users.list({ searchParams });
    users.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    usersLoading.value = false;
  }
}

async function onStatusUpdate(timeSheet: ITimeSheetPreviewResource, event: PointerEvent, status: TimeSheetStatus) {
  if (event.target) {
    const target = event.target as HTMLElement;
    const button = (target.tagName.toLowerCase() === 'button' ? event.target : target.parentNode) as HTMLButtonElement;
    button.classList.add('is-loading');
    try {
      await api.timeSheets.update(timeSheet.id, { status });
      await getTimeSheets();
      toast.success(t('common.updated'));
    } catch (error) {
      console.error(error);
    } finally {
      button.classList.remove('is-loading');
    }
  }
}

async function onReviewStatusUpdate(
  timeSheet: ITimeSheetPreviewResource,
  event: PointerEvent,
  review_status: TimeSheetReviewStatus,
) {
  if (review_status === TimeSheetReviewStatus.NOT_APPROVED) {
    event.stopPropagation();
    const { open, close, destroy } = useModal({
      component: CommentModal,
      attrs: {
        title: t('time-sheet.attributes.review_comment'),
        label: t('common.comment'),
        required: true,
        initialComment: '',
        action: t('common.send'),
        async onSubmit({ setLoading }: ConfirmDialogConfirmParams, comment: string) {
          setLoading(true);
          try {
            await api.timeSheets.update(timeSheet.id, { review_status, review_comment: comment });
            await getTimeSheets();
            toast.success(t('common.updated'));
            await close();
          } catch (error) {
            console.error(error);
          } finally {
            setLoading(false);
          }
        },
        onCancel() {
          close();
        },
        onClosed() {
          destroy();
        },
      },
    });
    await open();
  } else {
    if (event.target) {
      const target = event.target as HTMLElement;
      const button = (
        target.tagName.toLowerCase() === 'button' ? event.target : target.parentNode
      ) as HTMLButtonElement;
      button.classList.add('is-loading');
      try {
        await api.timeSheets.update(timeSheet.id, { review_status });
        await getTimeSheets();
        toast.success(t('common.updated'));
      } catch (error) {
        console.error(error);
      } finally {
        button.classList.remove('is-loading');
      }
    }
  }
}

async function onFilter() {
  loader.start();
  currentPage.value = 1;
  await getTimeSheets();
  loader.finish();
}

async function resetFilters() {
  filters.status = [];
  filters.review_status = [TimeSheetReviewStatus.NOT_REVIEWED, TimeSheetReviewStatus.NOT_APPROVED];
  filters.user_uuid = [];
  if (filterStatusDropdown.value) {
    filterStatusDropdown.value.search = '';
  }
  if (filterUserDropdown.value) {
    filterUserDropdown.value.search = '';
  }
  if (filterReviewStatusDropdown.value) {
    filterReviewStatusDropdown.value.search = '';
  }
  filters.period = DateTime.now().minus({ month: 1 }).startOf('month').toFormat('MM-yyyy');
  await onFilter();
}

onMounted(async () => {
  loader.start();
  await Promise.all([getTimeSheets(), getUsers()]);
  loader.finish();
});

watch(currentPage, async () => {
  loader.start();
  await getTimeSheets();
  loader.finish();
});

const title = useTitle(computed(() => t('time-sheet.index.title')));
</script>

<template>
  <div class="container-fluid">
    <div class="d-flex align-items-end">
      <h1 class="mb-0" v-text="title" />
      <HelpInformation class="ml-1" translation="time-sheet.index.help" />
    </div>
    <form @submit.prevent="onFilter" class="my-3">
      <AppBox>
        <AppBoxBody>
          <div class="row">
            <div class="form-group col-md">
              <FormLabel html-for="filters_time_period_month">
                {{ t('time-sheet.attributes.time_period') }}
              </FormLabel>
              <div class="form-wrapper has-icon">
                <VueDatePicker
                  :ui="{ input: 'form-control' }"
                  :placeholder="t('common.select')"
                  v-model="filters.period"
                  format="MM-yyyy"
                  model-type="format"
                  :enable-time-picker="false"
                  :month-change-on-scroll="false"
                  auto-apply
                  month-picker
                  :disabled="loader.isLoading.value"
                  position="left"
                  :locale="locale"
                  :week-num-name="t('common.week_short')"
                >
                  <template #input-icon><i class="form-icon ti ti-calendar" /></template>
                </VueDatePicker>
              </div>
            </div>
            <div class="form-group col-md">
              <FormLabel html-for="filters_time_period_status">
                {{ t('time-sheet.attributes.status') }}
              </FormLabel>
              <VueSelect
                v-model="filters.status"
                :options="Object.values(TimeSheetStatus)"
                label="label"
                input-id="filters_time_period_status"
                :placeholder="t('common.select')"
                multiple
                :disabled="loader.isLoading.value"
                :get-option-label="(option: string) => t(`time-sheet.status.${option}`)"
                ref="filterStatusDropdown"
              />
            </div>
            <div class="form-group col-md">
              <FormLabel html-for="filters_time_period_review_status">
                {{ t('time-sheet.attributes.review_status') }}
              </FormLabel>
              <VueSelect
                v-model="filters.review_status"
                :options="Object.values(TimeSheetReviewStatus)"
                label="label"
                input-id="filters_time_period_review_status"
                :placeholder="t('common.select')"
                multiple
                :disabled="loader.isLoading.value"
                :get-option-label="(option: string) => t(`time-sheet.review_status.${option}`)"
                ref="filterReviewStatusDropdown"
              />
            </div>
            <div v-if="!isEmployeeRole" class="form-group col-md">
              <FormLabel html-for="filters_time_period_user">
                {{ t('time-sheet.attributes.user') }}
              </FormLabel>
              <VueSelect
                v-model="filters.user_uuid"
                :options="users"
                :reduce="(option: IUserListResource) => option.uuid"
                label="name"
                input-id="filters_time_period_user"
                :placeholder="t('common.search')"
                multiple
                :disabled="usersLoading || loader.isLoading.value"
                :loading="usersLoading"
                ref="filterUserDropdown"
              />
            </div>
            <div class="col-12">
              <AppButton :disabled="loader.isLoading.value">
                {{ t('common.apply_filters') }}
              </AppButton>
              <AppButton class="ml-2" light @click.prevent="resetFilters" :disabled="loader.isLoading.value">
                {{ t('common.reset_filters') }}
              </AppButton>
            </div>
          </div>
        </AppBoxBody>
      </AppBox>
    </form>
    <div v-if="loader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <template v-else>
      <AppAlert v-if="timeSheets.length === 0">
        {{ t('time-sheet.empty') }}
      </AppAlert>
      <AppTable v-else hoverable>
        <AppTableHead>
          <AppTableTr>
            <AppTableTh nowrap>{{ t('time-sheet.attributes.time_period') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('time-sheet.attributes.user') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('time-sheet.attributes.total_tracked_time') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('time-sheet.attributes.time_report_status') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('time-sheet.attributes.locked') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('time-sheet.attributes.approved') }}</AppTableTh>
            <AppTableTh nowrap class="text-right" v-if="editable">{{ t('common.actions') }}</AppTableTh>
          </AppTableTr>
        </AppTableHead>
        <AppTableBody>
          <AppTableTr
            @click="router.push({ name: 'time-sheets.show', params: { id: timeSheet.id } })"
            class="pointer"
            v-for="timeSheet in timeSheets"
            :key="timeSheet.id"
          >
            <AppTableTd nowrap>
              {{ timeSheet.time_period.month.toString().padStart(2, '0') }}-{{ timeSheet.time_period.year }}
            </AppTableTd>
            <AppTableTd>
              {{ timeSheet.user.name }}
            </AppTableTd>
            <AppTableTd>
              {{ convertMinutesToTime(timeSheet.total_tracked_time) }}
            </AppTableTd>
            <AppTableTd nowrap>
              {{ t(`time-sheet.time_report_status.${timeSheet.is_completed ? 'completed' : 'not_completed'}`) }}
            </AppTableTd>
            <AppTableTd nowrap>
              <span class="text-success-500" v-if="timeSheet.status === TimeSheetStatus.LOCKED">
                {{ t('common.yes') }}
              </span>
              <span class="text-danger-500" v-else-if="timeSheet.status === TimeSheetStatus.UNLOCKED">
                {{ t('common.no') }}
              </span>
            </AppTableTd>
            <AppTableTd nowrap>
              <span class="text-success-500" v-if="timeSheet.review_status === TimeSheetReviewStatus.APPROVED">
                {{ t('common.yes') }}
              </span>
              <span class="text-danger-500" v-else>
                {{ t('common.no') }}
              </span>
            </AppTableTd>
            <AppTableTd @click.stop v-if="editable" nowrap class="text-right">
              <template v-if="timeSheet.review_status === null">
                <AppButton
                  v-if="timeSheet.status === TimeSheetStatus.LOCKED"
                  v-tooltip="t('time-sheet.tooltip.unlock')"
                  @click.stop.prevent="onStatusUpdate(timeSheet, $event, TimeSheetStatus.UNLOCKED)"
                  size="small"
                  light
                  circle
                  class="ml-2"
                  color="success"
                >
                  <FontIcon name="lock-open" />
                </AppButton>
                <AppButton
                  v-if="timeSheet.status === TimeSheetStatus.UNLOCKED"
                  v-tooltip="t('time-sheet.tooltip.lock')"
                  @click.stop.prevent="onStatusUpdate(timeSheet, $event, TimeSheetStatus.LOCKED)"
                  size="small"
                  light
                  circle
                  class="ml-2"
                  color="danger"
                >
                  <FontIcon name="lock" />
                </AppButton>
              </template>
              <AppButton
                v-if="timeSheet.review_status !== TimeSheetReviewStatus.APPROVED"
                v-tooltip="t('time-sheet.tooltip.approve')"
                @click.stop.prevent="onReviewStatusUpdate(timeSheet, $event, TimeSheetReviewStatus.APPROVED)"
                size="small"
                light
                circle
                class="ml-2"
                color="success"
              >
                <FontIcon name="check" />
              </AppButton>
              <AppButton
                v-if="timeSheet.review_status !== TimeSheetReviewStatus.NOT_APPROVED"
                v-tooltip="t('time-sheet.tooltip.not_approve')"
                @click.stop.prevent="onReviewStatusUpdate(timeSheet, $event, TimeSheetReviewStatus.NOT_APPROVED)"
                size="small"
                light
                circle
                class="ml-2"
                color="danger"
              >
                <FontIcon name="ban" />
              </AppButton>
              <AppButton
                v-tooltip="t('common.view')"
                @click.stop.prevent="router.push({ name: 'time-sheets.show', params: { id: timeSheet.id } })"
                size="small"
                light
                circle
                class="ml-2"
              >
                <FontIcon name="eye" />
              </AppButton>
            </AppTableTd>
          </AppTableTr>
        </AppTableBody>
      </AppTable>
      <AppPagination :per-page="perPage" :total="total" v-model="currentPage" />
    </template>
  </div>
</template>
