export default {
  index: {
    title: 'Fiscal years',
  },
  create: {
    title: 'Create new fiscal year',
  },
  edit: {
    title: 'Edit fiscal year',
  },
  attributes: {
    start_date: 'Start date',
    end_date: 'End date',
  },
  confirm: {
    delete: {
      title: 'Delete fiscal year',
      text: 'Are you sure you want to delete this fiscal year?',
    },
  },
  message: {
    can_be_deleted:
      'The fiscal year has projects attached to it. Delete projects, or link to another financial year to be able to delete.',
    editing_can_have_effect:
      'This changes the fiscal year. Any linked projects may need updated project dates and deadlines. Any customer folder named after the fiscal year will be automatically renamed. Do you want to continue?',
  },
};
