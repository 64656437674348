<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { UserStatusType } from '@/types/User';

const { t } = useI18n({ useScope: 'global' });

const props = defineProps<{
  status: UserStatusType;
}>();

const color = computed(() => {
  switch (props.status) {
    case UserStatusType.Active:
      return 'is-success';
    case UserStatusType.Blocked:
      return 'is-danger';
    case UserStatusType.Invited:
      return 'is-secondary';
    default:
      return '';
  }
});

const icon = computed(() => {
  switch (props.status) {
    case UserStatusType.Active:
      return 'ti-check';
    case UserStatusType.Blocked:
      return 'ti-ban';
    case UserStatusType.Invited:
      return 'ti-hourglass';
    default:
      return '';
  }
});
</script>

<template>
  <span v-if="status" class="badge" :class="color">
    <i class="ti" :class="icon" />
    {{ t(`user.status.${status}`) }}
  </span>
</template>
