export default function useLoadingButton(event: PointerEvent) {
  const target = event.target as HTMLElement;
  const button = (target.tagName.toLowerCase() === 'button' ? event.target : target.parentNode) as HTMLButtonElement;
  button.classList.add('is-loading');

  function setLoading(value: boolean) {
    button.classList[value ? 'add' : 'remove']('is-loading');
  }

  return { setLoading };
}
