export default {
  index: {
    title: 'ToDo & messages',
    help: '',
    create: 'Create',
  },
  create: {
    title: 'Create ToDo',
  },
  edit: {
    title: 'Edit ToDo',
  },
  attributes: {
    type: 'Type',
    client: 'Customer',
    recipient: 'Recipient',
    text: 'Text',
    deadline: 'Deadline',
    status: 'Status',
    created_by: 'Created by',
    updated_at: 'Updated at',
  },
  filters: {
    search: 'Search',
    from_date: 'From date',
    to_date: 'To date',
    created_by: 'Created by',
    recipient: 'Recipient',
    client: 'Client',
    project: 'Project',
    type: 'Type',
    status: 'Status',
  },
  type: {
    Info: 'Information',
    ToDo: 'ToDo',
    Message: 'Message',
  },
  status: {
    opened: 'Opened',
    closed: 'Closed',
  },
  tooltip: {
    complete: 'Complete todo',
    reopen: 'Reopen todo',
    edit: 'Edit todo',
    destroy: 'Delete todo',
    show_comments: 'Show comments',
  },
  empty: 'No todos',
  message_or_todo: 'Message or ToDo',
  confirm: {
    destroy: {
      title: 'Delete todo',
      text: 'Are you sure you want to delete this todo from the system?',
    },
  },
};
