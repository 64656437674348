<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useRouteParams } from '@vueuse/router';
import VueSelect from 'vue-select';

import toast from '@/services/toast';
import api from '@/services/api';
import {
  AppBox,
  AppBoxBody,
  AppButton,
  AppLoader,
  FormLabel,
  FormTextarea,
  HelpInformation,
  TimeSheetReport,
} from '@/components';
import useLoader from '@/composables/useLoader';
import useHelpers from '@/composables/useHelpers';
import useTrackChanges from '@/composables/useTrackChanges';

import {
  ITimeSheetPreviewResource,
  ITimeSheetRequest,
  ITimeSheetResource,
  TimeSheetReviewStatus,
  TimeSheetStatus,
} from '@/types/TimeSheet';
import useAuthStore from '@/store/AuthStore';
import { useTitle } from '@vueuse/core';

const { id } = defineProps<{
  id: number;
}>();

const timeSheetId = useRouteParams('id', null, { transform: Number });
const { t } = useI18n({ useScope: 'global' });
const loader = useLoader();
const router = useRouter();
const { findClosestIds } = useHelpers();
const { isSuperAdminRole, isCustomerAdminRole, isManagerRole } = useAuthStore();

const loading = ref(false);
const timeSheets = ref<ITimeSheetPreviewResource[]>([]);
const timeSheet = ref<null | ITimeSheetResource>(null);

const form = reactive<Required<ITimeSheetRequest>>({
  status: TimeSheetStatus.UNLOCKED,
  review_status: TimeSheetReviewStatus.NOT_REVIEWED,
  review_comment: '',
});
const tracker = useTrackChanges(form);

async function getTimeSheet() {
  try {
    const response = await api.timeSheets.get(id);
    timeSheet.value = response.data;
    form.status = response.data.status;
    form.review_status = response.data.review_status;
    form.review_comment = response.data.review_comment ?? '';
  } catch (error) {
    console.error(error);
  }
}

async function getTimeSheets() {
  if (timeSheet.value === null) return;
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    searchParams.append('users_uuid[]', timeSheet.value.user.uuid);
    const response = await api.timeSheets.index({ searchParams });
    timeSheets.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

async function update() {
  if (timeSheet.value === null) return;
  try {
    loading.value = true;
    if (tracker.isModified.value) {
      await api.timeSheets.update(id, form);
      toast.success(t('common.updated'));
    }
    const otherTimeSheetsIds = timeSheets.value
      .filter((item) => item.review_status === null && item.id !== id)
      .map((item) => item.id);
    if (otherTimeSheetsIds.length === 0) {
      return router.push({ name: 'time-sheets.index' });
    }
    const { next } = findClosestIds(id, otherTimeSheetsIds);
    if (next) {
      await router.push({ name: 'time-sheets.show', params: { id: next } });
    } else {
      await router.push({ name: 'time-sheets.show', params: { id: Math.min(...otherTimeSheetsIds) } });
    }
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
}

onMounted(async () => {
  loader.start();
  await getTimeSheet();
  await getTimeSheets();
  tracker.commit();
  loader.finish();
});

const title = useTitle(computed(() => t('time-sheet.show.title', { username: timeSheet.value?.user.name ?? '' })));
</script>

<template>
  <div v-if="loader.isLoading.value" class="text-center">
    <AppLoader size="large" />
  </div>
  <template v-else>
    <div v-if="timeSheet" class="container-fluid">
      <div class="d-flex align-items-end">
        <h1 class="mb-0" v-text="title" />
        <HelpInformation class="ml-1" translation="time-sheet.show.help" />
      </div>
      <!-- Details -->
      <AppBox class="mt-3">
        <AppBoxBody>
          <TimeSheetReport v-model:id="timeSheetId" :time-sheet="timeSheet" :time-sheets="timeSheets" />
        </AppBoxBody>
        <AppBoxBody>
          <template v-if="isSuperAdminRole || isCustomerAdminRole || isManagerRole">
            <h2>{{ t('time-sheet.review.manager_review') }}</h2>
            <form @submit.prevent="update">
              <div class="row form-group align-items-center">
                <div class="col-2">
                  <FormLabel html-for="status">{{ t('time-sheet.attributes.status') }}</FormLabel>
                </div>
                <div class="col-4">
                  <VueSelect
                    v-model="form.status"
                    :get-option-label="(option: TimeSheetStatus) => t(`time-sheet.status.${option}`)"
                    :options="Object.values(TimeSheetStatus)"
                    input-id="status"
                    :clearable="false"
                    @update:model-value="($event: TimeSheetStatus) => {
                      if (form.review_status !== null) {
                        if ($event === TimeSheetStatus.LOCKED) {
                          form.review_status = TimeSheetReviewStatus.APPROVED;
                        } else if ($event === TimeSheetStatus.UNLOCKED) {
                          form.review_status = TimeSheetReviewStatus.NOT_APPROVED;
                        }
                      }
                    }"
                  />
                </div>
              </div>
              <div class="row form-group align-items-center">
                <div class="col-2">
                  <FormLabel html-for="status">{{ t('time-sheet.attributes.review_status') }}</FormLabel>
                </div>
                <div class="col-4">
                  <VueSelect
                    v-model="form.review_status"
                    :get-option-label="(option: TimeSheetReviewStatus) => t(`time-sheet.review_status.${option}`)"
                    :options="[TimeSheetReviewStatus.APPROVED, TimeSheetReviewStatus.NOT_APPROVED]"
                    input-id="review_status"
                    :clearable="false"
                    :placeholder="t('time-sheet.review_status.not_reviewed')"
                    @update:model-value="($event: TimeSheetReviewStatus) => {
                      if ($event === TimeSheetReviewStatus.APPROVED) {
                        form.status = TimeSheetStatus.LOCKED;
                      } else if ($event === TimeSheetReviewStatus.NOT_APPROVED) {
                        form.status = TimeSheetStatus.UNLOCKED;
                      }
                    }"
                  />
                </div>
              </div>
              <div class="row form-group align-items-center">
                <div class="col-2">
                  <FormLabel html-for="comment" :required="form.review_status === TimeSheetReviewStatus.NOT_APPROVED">
                    {{ t('time-sheet.attributes.review_comment') }}
                  </FormLabel>
                </div>
                <div class="col-4">
                  <FormTextarea
                    v-model="form.review_comment"
                    id="comment"
                    :required="form.review_status === TimeSheetReviewStatus.NOT_APPROVED"
                  />
                </div>
              </div>
              <div class="mt-4 d-flex justify-content-between">
                <AppButton @click.prevent="router.push({ name: 'time-sheets.index' })" light :disabled="loading">
                  {{ t('common.back') }}
                </AppButton>
                <AppButton color="secondary" :loading="loading">
                  {{ t('common.next') }}
                </AppButton>
              </div>
            </form>
          </template>
          <AppButton v-else @click.prevent="router.push({ name: 'time-sheets.index' })" light :disabled="loading">
            {{ t('common.back') }}
          </AppButton>
        </AppBoxBody>
      </AppBox>
    </div>
  </template>
</template>
