export default {
  index: {
    title: 'Månader',
    help: '',
  },
  form: {
    status: 'Status',
  },

  empty: 'Inga månader',

  // Attributes
  attributes: {
    year: 'År',
    month: 'Månad',
    status: 'Status',
  },

  status: {
    closed: 'Stängd',
    opened: 'Öppen',
  },

  tooltip: {
    open: 'Öppna månad',
    close: 'Stäng månad',
  },

  // Confirms
  confirm: {
    change_status: {
      title: 'Ändra status',
      opened:
        'Är du säker på att du vill stänga månaden? Anställda kommer inte längre kunna rapportera tid för denna månaden.',
      closed: 'Är du säker på att du vill öppna månaden?',
    },
  },
};
