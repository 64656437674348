import useAuthStore from '@/store/AuthStore';
import { computed } from 'vue';
import { SystemId } from '@/types/Common';

export default function useSystems() {
  const { authenticatedUser } = useAuthStore();

  const isSubscribedToWeConnect = computed(() => authenticatedUser.customer.systems.includes(SystemId.MY_AUDIT));
  const isSubscribedToWeCRM = computed(() => authenticatedUser.customer.systems.includes(SystemId.CRM));
  const isSubscribedToWeVerify = computed(() => authenticatedUser.customer.systems.includes(SystemId.WE_VERIFY));
  const isSubscribedToWeFile = computed(() => authenticatedUser.customer.systems.includes(SystemId.STORE));
  const isSubscribedToWeSign = computed(() => authenticatedUser.customer.systems.includes(SystemId.WE_SIGN));

  return {
    isSubscribedToWeConnect,
    isSubscribedToWeCRM,
    isSubscribedToWeVerify,
    isSubscribedToWeFile,
    isSubscribedToWeSign,
  };
}
