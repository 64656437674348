<script setup lang="ts">
import { computed, reactive, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';
import VueSelect from 'vue-select';

import { AppButton, FormLabel, FormInput } from '@/components';
import {
  IServiceCompanyContactRequestBody,
  IServiceCompanyContactResource,
  IServiceCompanyContactRoleResource,
} from '@/types/ServiceCompany';

const { t } = useI18n({ useScope: 'global' });

const props = defineProps<{
  loading?: boolean;
  contact?: IServiceCompanyContactResource;
  roles: IServiceCompanyContactRoleResource[];
}>();

const form = reactive<IServiceCompanyContactRequestBody>({
  company_contact_role_uuid: '',
  name: '',
  short_name: '',
  personal_number: '',
  email: '',
  phone: '',
});

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'create', form: IServiceCompanyContactRequestBody): void;
  (e: 'update', uuid: string, form: IServiceCompanyContactRequestBody): void;
}>();

const disabled = computed(() => form.name === '' || form.company_contact_role_uuid === '');
const title = computed(() =>
  props.contact
    ? t('service-company.contact.edit.title', { name: props.contact.name })
    : t('service-company.contact.create.title'),
);

function submit() {
  if (props.contact) {
    emit('update', props.contact.uuid, form);
  } else {
    emit('create', form);
  }
}

onMounted(() => {
  if (props.contact) {
    form.name = props.contact.name;
    form.short_name = props.contact.short_name ?? '';
    form.company_contact_role_uuid = props.contact.role.uuid ?? '';
    form.personal_number = props.contact.personal_number ? props.contact.personal_number.toString() : '';
    form.email = props.contact.email ?? '';
    form.phone = props.contact.phone ?? '';
  } else {
    form.name = '';
    form.short_name = '';
    form.company_contact_role_uuid = '';
    form.personal_number = '';
    form.email = '';
    form.phone = '';
  }
});
</script>

<template>
  <VueFinalModal class="modal-overlay" content-class="modal-container" :click-to-close="false" :esc-to-close="true">
    <form @submit.prevent="submit">
      <div class="modal-header">
        <h2 v-text="title" />
      </div>
      <div class="modal-content">
        <!-- Name -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="contact_name" required>
                {{ t('service-company.contact.attributes.name') }}
              </FormLabel>
            </div>
            <div class="col-md-9">
              <FormInput v-model="form.name" id="contact_name" trim required />
            </div>
          </div>
        </div>
        <!-- Short name -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="contact_short_name">
                {{ t('service-company.contact.attributes.short_name') }}
              </FormLabel>
            </div>
            <div class="col-md-9">
              <FormInput v-model="form.short_name" id="contact_short_name" trim />
            </div>
          </div>
        </div>
        <!-- Role -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="contact_role" required>{{ t('service-company.contact.attributes.role') }}</FormLabel>
            </div>
            <div class="col-md-9">
              <VueSelect
                :clearable="false"
                :filterable="false"
                v-model="form.company_contact_role_uuid"
                :options="roles"
                :reduce="(option) => option.uuid"
                label="name"
                input-id="contact_role"
                :placeholder="t('common.select')"
              >
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="!form.company_contact_role_uuid"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </VueSelect>
            </div>
          </div>
        </div>
        <!-- Personal number -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="contact_personal_number">
                {{ t('service-company.contact.attributes.personal_number') }}
              </FormLabel>
            </div>
            <div class="col-md-9">
              <FormInput v-model="form.personal_number" id="contact_personal_number" />
            </div>
          </div>
        </div>
        <!-- Email -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="contact_email">
                {{ t('service-company.contact.attributes.email') }}
              </FormLabel>
            </div>
            <div class="col-md-9">
              <FormInput v-model="form.email" id="contact_email" />
            </div>
          </div>
        </div>
        <!-- Phone -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="contact_phone">
                {{ t('service-company.contact.attributes.phone') }}
              </FormLabel>
            </div>
            <div class="col-md-9">
              <FormInput v-model="form.phone" id="contact_phone" />
            </div>
          </div>
        </div>
        <slot />
      </div>
      <div class="modal-footer">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
          <AppButton light @click.prevent="emit('cancel')" :disabled="loading">
            {{ t('common.cancel') }}
          </AppButton>
          <AppButton v-if="contact" class="mt-2 mt-sm-0" color="success" :loading="loading" :disabled="disabled">
            {{ t('common.update') }}
          </AppButton>
          <AppButton v-else class="mt-2 mt-sm-0" color="secondary" :loading="loading" :disabled="disabled">
            {{ t('common.create') }}
          </AppButton>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>
