<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';

import { AppButton } from '@/components';
import useLoader from '@/composables/useLoader';

const { t } = useI18n({ useScope: 'global' });
const submitLoader = useLoader({ useProgress: false });

defineProps<{
  loading?: boolean;
  clientUuid: string;
}>();

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'create'): void;
}>();

async function submit() {
  submitLoader.start();
  emit('create');
  submitLoader.finish();
}
</script>

<template>
  <VueFinalModal class="modal-overlay" content-class="modal-container" :click-to-close="false" :esc-to-close="true">
    <div class="modal-header">
      <h2 v-text="t('client.billing.attributes.create_empty_invoice')" />
    </div>
    <div class="modal-content">
      <p>{{ t('client.billing.attributes.create_empty_invoice_text') }}</p>
      <slot />
    </div>
    <div class="modal-footer">
      <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
        <AppButton light @click.prevent="emit('cancel')">
          {{ t('common.cancel') }}
        </AppButton>
        <AppButton
          @click.prevent="submit"
          class="mt-2 mt-sm-0"
          color="secondary"
          :loading="submitLoader.isLoading.value"
        >
          {{ t('common.create') }}
        </AppButton>
      </div>
    </div>
  </VueFinalModal>
</template>

<style scoped></style>
