<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

const { t, d } = useI18n({ useScope: 'global' });

defineProps<{
  closed: boolean;
  closedAt: null | string;
}>();
</script>

<template>
  <span
    class="badge"
    :class="closed ? 'is-success' : 'is-secondary'"
    v-tooltip.left="closed && closedAt ? d(closedAt) : null"
  >
    {{ closed ? t('todo.status.closed') : t('todo.status.opened') }}
  </span>
</template>
