<script lang="ts" setup>
import { useId } from 'vue';

const { label, disabled } = defineProps<{
  label?: string;
  disabled?: boolean;
}>();

const [model] = defineModel<number | boolean>();

const id = useId();
</script>

<template>
  <div class="form-group">
    <input
      :id="id"
      type="checkbox"
      class="form-check"
      v-model="model"
      :true-value="typeof model === 'boolean' ? true : 1"
      :false-value="typeof model === 'boolean' ? false : 0"
      :disabled="disabled"
    />
    <label :for="id" class="form-label">
      <slot name="label" :label="label">
        {{ label }}
      </slot>
    </label>
  </div>
</template>
