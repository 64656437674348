export const downloadFile = (fileLink: string) => {
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = fileLink;

  document.body.appendChild(link);
  link.click();

  setTimeout(() => {
    link.parentNode?.removeChild(link);
  }, 0);
};
