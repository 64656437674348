<script setup lang="ts">
import { computed, reactive, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';

import api from '@/services/api';
import { AppButton, FormLabel, FormInput, AppCloseButton } from '@/components';
import useModalFeatures from '@/composables/useModalFeatures';
import useTrackChanges from '@/composables/useTrackChanges';
import { IDepartmentResource, IDepartmentRequestBody } from '@/types/Department';

const { t } = useI18n({ useScope: 'global' });
const { onCtrlEnter } = useModalFeatures();

type Props = {
  department: null | IDepartmentResource;
};

const { department } = defineProps<Props>();

const emit = defineEmits<{
  cancel: [];
  created: [department: IDepartmentResource];
  updated: [department: IDepartmentResource];
  closed: [];
}>();

const loading = ref(false);

const form = reactive<IDepartmentRequestBody>({
  name: '',
});
const tracker = useTrackChanges(form);

const disabled = computed(() => !form.name);

async function submit() {
  loading.value = true;
  try {
    if (department) {
      const response = await api.departments.update(department.id, form);
      emit('updated', response.data);
    } else {
      const response = await api.departments.store(form);
      emit('created', response.data);
    }
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
}

onMounted(async () => {
  form.name = department?.name ?? '';
  tracker.commit();
});

onCtrlEnter(() => {
  if (!disabled.value) {
    if (tracker.isModified.value) {
      submit();
    } else {
      emit('cancel');
    }
  }
});
</script>

<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container"
    :click-to-close="false"
    :esc-to-close="true"
    v-slot="{ close }"
    @closed="emit('closed')"
  >
    <form @submit.prevent="submit" @keydown.enter.prevent>
      <div class="modal-header">
        <h2
          v-text="department ? t('department.edit.title', { name: department.name }) : t('department.create.title')"
        />
        <AppCloseButton @close="close" />
      </div>
      <div class="modal-content">
        <!-- Name -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="department_name" required>{{ t('department.attributes.name') }}</FormLabel>
            </div>
            <div class="col-md-9">
              <FormInput v-model="form.name" id="department_name" required />
            </div>
          </div>
        </div>
        <slot />
      </div>
      <div class="modal-footer">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
          <AppButton light @click.prevent="close" :disabled="loading">
            {{ t('common.cancel') }}
          </AppButton>
          <AppButton v-if="department" class="mt-2 mt-sm-0" color="success" :loading="loading" :disabled="disabled">
            {{ t('common.update') }}
          </AppButton>
          <AppButton v-else class="mt-2 mt-sm-0" color="secondary" :loading="loading" :disabled="disabled">
            {{ t('common.create') }}
          </AppButton>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>
