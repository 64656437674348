import { IProjectCheckpointEventResource } from '@/types/Project';

export enum CheckpointEventStateType {
  DONE = 'done',
  NOT_DONE = 'not_done',
  N_A = 'n_a',
}

export enum CheckpointFrequency {
  FINANCIAL_YEAR = 'financial_year',
  CALENDAR_YEAR = 'calendar_year',
  CALENDAR_YEAR_QUARTER = 'calendar_year_quarter',
  FINANCIAL_YEAR_QUARTER = 'financial_year_quarter',
  MONTHLY = 'monthly',
  ONCE_AT_PROJECT_START = 'once_at_project_start',
  ONCE_AT_PROJECT_END = 'once_at_project_end',
}

export interface ICheckpointListResource {
  id: number;
  name: string;
  frequency: CheckpointFrequency;
  events: IProjectCheckpointEventResource[];
}
