<script setup lang="ts">
import { computed, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';

import api from '@/services/api';
import useLoader from '@/composables/useLoader';
import { AppButton, AppCloseButton, FormInput, FormLabel } from '@/components';
import { IClientTagRequest, IClientTagResource } from '@/types/Client';

const { t } = useI18n({ useScope: 'global' });
const submitLoader = useLoader({ useProgress: false });

const form = reactive<IClientTagRequest>({
  name: '',
});

const emit = defineEmits<{
  created: [clientTag: IClientTagResource];
}>();

const disabled = computed(() => !form.name);

async function submit() {
  try {
    submitLoader.start();
    const response = await api.clients.tags.store(form);
    emit('created', response.data);
  } catch (error) {
    console.error(error);
  } finally {
    submitLoader.finish();
  }
}
</script>

<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container"
    content-style="overflow: visible;"
    :click-to-close="false"
    :esc-to-close="true"
    v-slot="{ close }"
  >
    <form @submit.prevent="submit">
      <div class="modal-header">
        <h2 v-text="t('client.tags.modal.create.title')" />
        <AppCloseButton @close="close" />
      </div>
      <div class="modal-content">
        <!-- Name -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="client_tag_name" required>{{ t('client.tags.attributes.name') }}</FormLabel>
            </div>
            <div class="col-md-9">
              <FormInput v-model="form.name" id="client_tag_name" trim required />
            </div>
          </div>
        </div>
        <slot />
      </div>
      <div class="modal-footer">
        <div class="d-flex">
          <AppButton light @click.prevent="close">
            {{ t('common.cancel') }}
          </AppButton>
          <AppButton class="ml-auto" color="secondary" :disabled="disabled" :loading="submitLoader.isLoading.value">
            {{ t('common.create') }}
          </AppButton>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>
