export default {
  type: {
    'Limited company': 'Limited company',
    'Housing cooperative': 'Housing cooperative',
    'Individual company': 'Individual company',
    'Trading company': 'Partnership',
    'Non-profit association': 'Non-profit association',
    'Limited partnership': 'Limited partnership',
    Foundation: 'Foundation',
    'In public': 'Public sector',
    Branch: 'Branch',
    'Economic Association': 'Economic Association',
    Private: 'Private',
    Other: 'Other',
  },
};
