<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { AppDropdownSection } from '@/components/index';
import { ActivityModalProps } from '@/types/Activity';
import { ProjectTaskModalProps } from '@/types/ProjectTask';

type Props = {
  initialWeek?: number;
  hide: () => void;
  newCustomerLink?: boolean;
};

const { t } = useI18n({ useScope: 'global' });

defineProps<Props>();

const emit = defineEmits<{
  onAbsence: [params: ActivityModalProps];
  onInternal: [params: ActivityModalProps];
  onProjectTask: [params: ProjectTaskModalProps];
}>();
</script>

<template>
  <AppDropdownSection class="p-0">
    <a
      class="dropdown-option"
      @click.prevent="
        () => {
          emit('onProjectTask', { initialWeek });
          hide();
        }
      "
      href="#"
    >
      {{ t('time-entry.modal.modes.project_task') }}
    </a>
  </AppDropdownSection>
  <AppDropdownSection class="p-0">
    <a
      class="dropdown-option"
      @click.prevent="
        () => {
          emit('onAbsence', { type: 'absence', initialWeek });
          hide();
        }
      "
      href="#"
    >
      {{ t('time-entry.modal.modes.absence') }}
    </a>
  </AppDropdownSection>
  <AppDropdownSection class="p-0">
    <a
      class="dropdown-option"
      @click.prevent="
        () => {
          emit('onInternal', { type: 'internal', initialWeek });
          hide();
        }
      "
      href="#"
    >
      {{ t('time-entry.modal.modes.activity') }}
    </a>
  </AppDropdownSection>
  <AppDropdownSection v-if="newCustomerLink" class="p-0">
    <RouterLink :to="{ name: 'clients.create' }" custom v-slot="{ href, navigate }">
      <a class="dropdown-option" @click="navigate" :href="href" target="_blank">
        {{ t('common.customer') }}
      </a>
    </RouterLink>
  </AppDropdownSection>
</template>
