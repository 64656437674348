import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { ICountry } from '@/types/Country';
import api from '@/services/api';

const useCountriesStore = defineStore('CountriesStore', () => {
  const countries = ref<ICountry[]>([]);
  const countriesLoading = ref(false);

  const countryNames = computed(() => {
    return countries.value.reduce((acc, country) => ({ ...acc, [country.code]: country.name }), {});
  });

  async function getCountries() {
    try {
      countriesLoading.value = true;
      countries.value = await api.countries.index();
    } catch (error) {
      console.error(error);
    } finally {
      countriesLoading.value = false;
    }
  }

  return { countries, countriesLoading, countryNames, getCountries };
});

export default useCountriesStore;
