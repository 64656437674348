<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted, Ref } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import { useRouteQuery } from '@vueuse/router';
import { useI18n } from 'vue-i18n';
import { useModal } from 'vue-final-modal';
import { nanoid } from 'nanoid';
import { useConfirmDialog, useTitle } from '@vueuse/core';

import useLoader from '@/composables/useLoader';
import api from '@/services/api';
import {
  AppButton,
  AppAlert,
  AppTable,
  AppTableHead,
  AppTableTd,
  AppTableTr,
  AppTableTh,
  AppTableBody,
  ArticleStatus,
  FontIcon,
  ConfirmModal,
  FormInput,
  FormSwitch,
  SaveCancelModal,
  LeaveConfirmModal,
  AppLoader,
  HelpInformation,
} from '@/components';
import { IErpArticleResource } from '@/types/Article';
import { IInvoiceItemTemplateForm, IInvoiceItemTemplateResource } from '@/types/InvoiceItemTemplate';
import { InvoiceItemPriceType } from '@/types/Invoice';

const { isRevealed, reveal, confirm, cancel } = useConfirmDialog();

const { t } = useI18n({ useScope: 'global' });

enum AccountingTab {
  ARTICLES = 'articles',
  INVOICE_ITEMS = 'invoice-items',
}

const activeTab: Ref<AccountingTab> = useRouteQuery('tab', AccountingTab.ARTICLES);

const tabs = computed(() => [
  { id: AccountingTab.ARTICLES, title: t('accounting.index.tabs.articles') },
  { id: AccountingTab.INVOICE_ITEMS, title: t('accounting.index.tabs.invoice_items') },
]);

const articles = ref<IErpArticleResource[]>([]);
const invoiceItems = ref<IInvoiceItemTemplateForm[]>([]);
const originalInvoiceItems = ref('');

const loader = useLoader();
const fetchArticlesLoader = useLoader({ useProgress: false });
const saveInvoiceItemsLoader = useLoader({ useProgress: false });
const cancelInvoiceItemsLoader = useLoader({ useProgress: false });

async function getErpArticles() {
  try {
    const response = await api.erpArticles.index();
    articles.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

async function syncErpArticles() {
  try {
    fetchArticlesLoader.start();
    const response = await api.erpArticles.sync();
    articles.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    fetchArticlesLoader.finish();
  }
}

const disableArticleModal = useModal({
  component: ConfirmModal,
  attrs: {
    title: t('articles.confirm.disable.title'),
    message: t('articles.confirm.disable.text'),
    async onConfirm() {
      try {
        // @ts-ignore
        disableArticleModal.patchOptions({ attrs: { loading: true } });
        const id = disableArticleModal.options.attrs!.params?.id as number;
        await disableErpArticle(id);
        await Promise.all([getErpArticles(), getInvoiceItems()]);
        activeTab.value = AccountingTab.INVOICE_ITEMS;
        await disableArticleModal.close();
      } catch (error) {
        console.error(error);
      } finally {
        // @ts-ignore
        disableArticleModal.patchOptions({ attrs: { loading: false } });
      }
    },
    onCancel() {
      disableArticleModal.close();
    },
    // @ts-ignore
    onClosed() {
      // @ts-ignore
      disableArticleModal.patchOptions({ attrs: { loading: false } });
    },
  },
});

async function enableErpArticle(article: IErpArticleResource, event: PointerEvent) {
  if (event.target) {
    const target = event.target as HTMLElement;
    const button = (target.tagName.toLowerCase() === 'button' ? event.target : target.parentNode) as HTMLButtonElement;
    button.classList.add('is-loading');
    try {
      await api.erpArticles.update(article.id, { status: 'enabled' });
      await getErpArticles();
    } catch (error) {
      console.error(error);
    } finally {
      button.classList.remove('is-loading');
    }
  }
}

async function onErpArticleDisable(article: IErpArticleResource, event: PointerEvent) {
  const target = event.target as HTMLElement;
  const button = (target.tagName.toLowerCase() === 'button' ? event.target : target.parentNode) as HTMLButtonElement;
  try {
    button.classList.add('is-loading');
    const searchParams = new URLSearchParams();
    searchParams.append('erp_article', article.id.toString());
    const response = await api.invoiceItemTemplates.index(searchParams);
    if (response.data.length > 0) {
      // @ts-ignore
      disableArticleModal.patchOptions({ attrs: { params: { id: article.id } } });
      await disableArticleModal.open();
      return;
    } else {
      await disableErpArticle(article.id);
      await getErpArticles();
    }
  } catch (error) {
    console.error(error);
  } finally {
    button.classList.remove('is-loading');
  }
}

async function disableErpArticle(id: number) {
  try {
    await api.erpArticles.update(id, { status: 'disabled' });
  } catch (error) {
    console.error(error);
  }
}

function setInvoiceItems(items: IInvoiceItemTemplateResource[]) {
  invoiceItems.value = items.map((item) => ({
    ...item,
    uid: nanoid(),
    erp_article_id: item.erp_article?.id ?? '',
  }));
  originalInvoiceItems.value = JSON.stringify(invoiceItems.value);
}

async function getInvoiceItems() {
  try {
    const response = await api.invoiceItemTemplates.index();
    setInvoiceItems(response.data);
  } catch (error) {
    console.error(error);
  }
}

const wereInvoiceItemsChanged = computed(() => JSON.stringify(invoiceItems.value) !== originalInvoiceItems.value);

function addNewInvoiceItemTemplate() {
  invoiceItems.value.push({
    uid: nanoid(),
    type: 'manual',
    source: 'manual',
    price_type: '',
    description_en: '',
    description_sv: '',
    erp_article_id: null,
    is_inverted_amount: false,
    in_use: false,
  });
}

const saveCancelInvoiceItemsModal = useModal({
  component: SaveCancelModal,
  attrs: {
    onConfirm() {
      saveCancelInvoiceItemsModal.close();
      const { mode } = saveCancelInvoiceItemsModal.options.attrs!;
      if (mode === 'save') {
        saveInvoiceItems();
      } else if (mode === 'cancel') {
        cancelInvoiceItems();
      }
    },
    onCancel() {
      saveCancelInvoiceItemsModal.close();
    },
  },
});

const deleteInvoiceItemModal = useModal({
  component: ConfirmModal,
  attrs: {
    title: t('invoice-item-template.confirm.destroy.title'),
    message: t('invoice-item-template.confirm.destroy.text'),
    onConfirm() {
      const uid = deleteInvoiceItemModal.options.attrs!.params?.uid as string;
      invoiceItems.value = invoiceItems.value.filter((invoiceItem) => uid !== invoiceItem.uid);
      deleteInvoiceItemModal.close();
    },
    onCancel() {
      deleteInvoiceItemModal.close();
    },
  },
});

async function onInvoiceItemTemplateDelete(invoiceItem: IInvoiceItemTemplateForm) {
  // @ts-ignore
  deleteInvoiceItemModal.patchOptions({ attrs: { params: { uid: invoiceItem.uid } } });
  await deleteInvoiceItemModal.open();
}

async function onInvoiceItemsSave() {
  saveCancelInvoiceItemsModal.patchOptions({ attrs: { mode: 'save' } });
  await saveCancelInvoiceItemsModal.open();
}

async function saveInvoiceItems() {
  try {
    saveInvoiceItemsLoader.start();
    const response = await api.invoiceItemTemplates.update(
      invoiceItems.value.map((invoiceItem) => ({
        ...(invoiceItem.id ? { id: invoiceItem.id } : {}),
        type: invoiceItem.type,
        price_type: invoiceItem.price_type,
        description_en: invoiceItem.description_en,
        description_sv: invoiceItem.description_sv,
        is_inverted_amount: invoiceItem.is_inverted_amount,
        erp_article_id: invoiceItem.erp_article_id,
      })),
    );
    setInvoiceItems(response.data);
  } catch (error) {
    console.error(error);
  } finally {
    saveInvoiceItemsLoader.finish();
  }
}

async function onInvoiceItemsCancel() {
  saveCancelInvoiceItemsModal.patchOptions({ attrs: { mode: 'cancel' } });
  await saveCancelInvoiceItemsModal.open();
}

async function cancelInvoiceItems() {
  cancelInvoiceItemsLoader.start();
  await getInvoiceItems();
  cancelInvoiceItemsLoader.finish();
}

onMounted(async () => {
  loader.start();
  await Promise.all([getErpArticles(), getInvoiceItems()]);
  loader.finish();
});

// Prevent data loss: START
function onBeforeUnload(event: BeforeUnloadEvent) {
  if (!wereInvoiceItemsChanged.value) return true;
  event.preventDefault();
  const customMessage = t('common.confirms.unsaved.title');
  event.returnValue = customMessage;
  return customMessage;
}

onMounted(() => {
  window.addEventListener('beforeunload', onBeforeUnload);
});

onUnmounted(() => {
  window.removeEventListener('beforeunload', onBeforeUnload);
});

onBeforeRouteLeave(async (to, from, next) => {
  if (wereInvoiceItemsChanged.value) {
    const { data } = await reveal();
    data ? next() : next(false);
  } else {
    next();
  }
});

function onPriceTypeChange(invoiceItemTemplate: IInvoiceItemTemplateForm) {
  if (invoiceItemTemplate.price_type === InvoiceItemPriceType.ADVANCE) {
    invoiceItemTemplate.is_inverted_amount = false;
  }
}

const title = useTitle(computed(() => t('accounting.index.title')));
</script>

<template>
  <div class="container-wide">
    <div class="d-flex align-items-end mb-3">
      <h1 class="mb-0" v-text="title" />
      <HelpInformation class="ml-1" translation="accounting.index.help" />
    </div>
    <ul class="tabs">
      <li :class="{ active: tab.id === activeTab }" v-for="tab in tabs" :key="tab.id" class="tab-item">
        <a class="tab-link" @click.prevent="activeTab = tab.id" href="#" v-text="tab.title" />
      </li>
    </ul>

    <div class="mt-4">
      <!-- Articles -->
      <div v-show="activeTab === AccountingTab.ARTICLES">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <div class="d-flex align-items-end">
            <h1 class="mb-0" v-text="t('accounting.articles.title')" />
            <HelpInformation class="ml-1" translation="accounting.articles.help" />
          </div>
          <AppButton
            @click.prevent="syncErpArticles"
            color="secondary"
            :disabled="fetchArticlesLoader.isLoading.value"
            :loading="fetchArticlesLoader.isLoading.value"
          >
            {{ t('accounting.articles.fetch_articles') }}
          </AppButton>
        </div>
        <div v-if="loader.isLoading.value" class="text-center">
          <AppLoader size="large" />
        </div>
        <template v-else>
          <AppAlert v-if="articles.length === 0">{{ t('articles.empty') }}</AppAlert>
          <AppTable v-else hoverable>
            <AppTableHead>
              <AppTableTr>
                <AppTableTh nowrap>{{ t('articles.attributes.erp_id') }}</AppTableTh>
                <AppTableTh nowrap>{{ t('articles.attributes.name_sv') }}</AppTableTh>
                <AppTableTh nowrap>{{ t('articles.attributes.name_en') }}</AppTableTh>
                <AppTableTh nowrap>{{ t('articles.attributes.unit') }}</AppTableTh>
                <AppTableTh nowrap>{{ t('articles.attributes.status') }}</AppTableTh>
                <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
              </AppTableTr>
            </AppTableHead>
            <AppTableBody>
              <AppTableTr v-for="article in articles" :key="article.id">
                <AppTableTd nowrap>
                  <strong v-text="article.erp_id" />
                </AppTableTd>
                <AppTableTd nowrap>{{ article.name_sv }}</AppTableTd>
                <AppTableTd nowrap>{{ article.name_en }}</AppTableTd>
                <AppTableTd nowrap>{{ article.unit }}</AppTableTd>
                <AppTableTd nowrap>
                  <ArticleStatus :status="article.status" />
                </AppTableTd>
                <AppTableTd nowrap class="text-right">
                  <AppButton
                    v-if="article.status === 'disabled'"
                    v-tooltip.left="t('articles.tooltip.enable', { name: article.erp_id })"
                    @click.stop.prevent="enableErpArticle(article, $event)"
                    size="small"
                    color="success"
                    light
                    circle
                  >
                    <FontIcon name="check" />
                  </AppButton>
                  <AppButton
                    v-if="article.status === 'enabled'"
                    v-tooltip.left="t('articles.tooltip.disable', { name: article.erp_id })"
                    @click.stop.prevent="onErpArticleDisable(article, $event)"
                    size="small"
                    color="danger"
                    light
                    circle
                  >
                    <FontIcon name="ban" />
                  </AppButton>
                </AppTableTd>
              </AppTableTr>
            </AppTableBody>
          </AppTable>
        </template>
      </div>

      <!-- Invoice items -->
      <div v-show="activeTab === AccountingTab.INVOICE_ITEMS">
        <div v-if="loader.isLoading.value" class="text-center">
          <AppLoader size="large" />
        </div>
        <form v-else @submit.prevent="onInvoiceItemsSave">
          <div class="d-flex align-items-end mb-3">
            <h1 class="mb-0" v-text="t('accounting.invoice_items.title')" />
            <HelpInformation class="ml-1" translation="accounting.invoice_items.help" />
          </div>
          <AppAlert v-if="invoiceItems.length === 0">{{ t('invoice-item-template.empty') }}</AppAlert>
          <AppTable v-else hoverable>
            <AppTableHead>
              <AppTableTr>
                <AppTableTh nowrap>{{ t('invoice-item-template.attributes.type') }}</AppTableTh>
                <AppTableTh nowrap>{{ t('invoice-item-template.attributes.source') }}</AppTableTh>
                <AppTableTh nowrap>
                  {{ t('invoice-item-template.attributes.price_type') }}
                  <span class="text-danger-500">*</span>
                </AppTableTh>
                <AppTableTh nowrap>
                  {{ t('invoice-item-template.attributes.default_description_sv') }}
                  <span class="text-danger-500">*</span>
                </AppTableTh>
                <AppTableTh nowrap>
                  {{ t('invoice-item-template.attributes.default_description_en') }}
                  <span class="text-danger-500">*</span>
                </AppTableTh>
                <AppTableTh nowrap>
                  {{ t('invoice-item-template.attributes.article') }}
                  <span class="text-danger-500">*</span>
                </AppTableTh>
                <AppTableTh nowrap>{{ t('invoice-item-template.attributes.invert') }}</AppTableTh>
                <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
              </AppTableTr>
            </AppTableHead>
            <AppTableBody>
              <AppTableTr v-for="(invoiceItem, index) in invoiceItems" :key="index">
                <AppTableTd nowrap>{{ t(`invoice-item-template.type.${invoiceItem.type}`) }}</AppTableTd>
                <AppTableTd nowrap
                  >{{ t(`invoice-item-template.source.${invoiceItem.source}`) }}
                  <template v-if="invoiceItem.source === 'billing_plan_separate_fee'">
                    {{ t(`invoice-item-template.price_type.${invoiceItem.price_type}`) }}
                  </template>
                </AppTableTd>
                <AppTableTd nowrap>
                  <div class="form-wrapper is-small">
                    <select
                      class="form-control"
                      v-model="invoiceItem.price_type"
                      required
                      :disabled="invoiceItem.type === 'system'"
                      @change="onPriceTypeChange(invoiceItem)"
                    >
                      <option value="" selected disabled hidden v-text="t('common.select')" />
                      <option
                        v-for="option in Object.values(InvoiceItemPriceType)"
                        :key="option"
                        :value="option"
                        v-text="t(`invoice.price_type.${option}`)"
                      />
                    </select>
                  </div>
                </AppTableTd>
                <AppTableTd nowrap>
                  <FormInput size="small" v-model="invoiceItem.description_sv" required />
                </AppTableTd>
                <AppTableTd nowrap>
                  <FormInput size="small" v-model="invoiceItem.description_en" required />
                </AppTableTd>
                <AppTableTd nowrap :class="{ 'background-danger-100': invoiceItem.id && !invoiceItem.erp_article_id }">
                  <div class="form-wrapper is-small">
                    <select class="form-control" v-model="invoiceItem.erp_article_id" required>
                      <option value="" selected disabled hidden v-text="t('common.select')" />
                      <option
                        v-for="option in articles"
                        :key="option.id"
                        :value="option.id"
                        v-text="
                          option.status === 'enabled' ? option.erp_id : `${option.erp_id} (${t('common.disabled')})`
                        "
                        :disabled="option.status === 'disabled'"
                      />
                    </select>
                  </div>
                </AppTableTd>
                <AppTableTd nowrap>
                  <FormSwitch
                    group-class="mb-0"
                    :id="invoiceItem.uid"
                    v-model="invoiceItem.is_inverted_amount"
                    :disabled="
                      invoiceItem.type === 'system' ||
                      invoiceItem.in_use ||
                      invoiceItem.price_type === InvoiceItemPriceType.ADVANCE
                    "
                  />
                </AppTableTd>

                <AppTableTd nowrap class="text-right">
                  <AppButton
                    :disabled="invoiceItem.type === 'system' || invoiceItem.in_use"
                    v-tooltip.left="t('invoice-item-template.tooltip.destroy')"
                    @click.stop.prevent="onInvoiceItemTemplateDelete(invoiceItem)"
                    size="small"
                    color="danger"
                    light
                    circle
                  >
                    <FontIcon name="trash" />
                  </AppButton>
                </AppTableTd>
              </AppTableTr>
            </AppTableBody>
          </AppTable>
          <div class="mt-4">
            <AppButton
              @click.prevent="onInvoiceItemsCancel"
              color="danger"
              :loading="cancelInvoiceItemsLoader.isLoading.value"
              :disabled="!wereInvoiceItemsChanged || saveInvoiceItemsLoader.isLoading.value"
            >
              {{ t('common.cancel') }}
            </AppButton>
            <AppButton
              class="ml-2"
              color="success"
              :loading="saveInvoiceItemsLoader.isLoading.value"
              :disabled="!wereInvoiceItemsChanged || cancelInvoiceItemsLoader.isLoading.value"
            >
              {{ t('common.save') }}
            </AppButton>
            <AppButton
              class="ml-2"
              @click.prevent="addNewInvoiceItemTemplate"
              color="success"
              light
              circle
              v-tooltip.right="t('invoice-item-template.index.create')"
              :disabled="saveInvoiceItemsLoader.isLoading.value || cancelInvoiceItemsLoader.isLoading.value"
            >
              <FontIcon name="plus" />
            </AppButton>
          </div>
        </form>
      </div>
    </div>
  </div>
  <LeaveConfirmModal :is-revealed="isRevealed" @confirm="confirm" @cancel="cancel" />
</template>
