<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouteQuery } from '@vueuse/router';
import { useModal } from 'vue-final-modal';

import api from '@/services/api';
import {
  AppAlert,
  AppLoader,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  AppButton,
  FontIcon,
  ConfirmModal,
  AppPagination,
  HelpInformation,
  ActivityGroupModal,
} from '@/components';
import useLoader from '@/composables/useLoader';
import useActivityTemplatesStore from '@/store/ActivityTemplatesStore';
import { IActivityGroupListResource } from '@/types/Activity';
import { ConfirmDialogConfirmParams } from '@/types/Common';

const activityGroups = ref<IActivityGroupListResource[]>([]);
const currentPage = useRouteQuery('groups-page', '1', { transform: Number });
const perPage = ref(20);
const total = ref(0);

const { lockTabs, unlockTabs } = useActivityTemplatesStore();
const { t } = useI18n({ useScope: 'global' });
const loader = useLoader();

async function getActivityGroups() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('page', currentPage.value.toString());
    const response = await api.activityGroups.index({ searchParams });
    activityGroups.value = response.data;
    perPage.value = response.meta!.per_page;
    total.value = response.meta!.total;
  } catch (error) {
    console.error(error);
  }
}

function onCreate() {
  const { open, close, destroy } = useModal({
    component: ActivityGroupModal,
    attrs: {
      activityGroupType: null,
      activityGroup: null,
      async onCreated() {
        await getActivityGroups();
        await close();
      },
      onCancel() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onEdit(activityGroup: IActivityGroupListResource) {
  const { open, close, destroy } = useModal({
    component: ActivityGroupModal,
    attrs: {
      activityGroupType: null,
      activityGroup,
      async onUpdated() {
        await getActivityGroups();
        await close();
      },
      onCancel() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onDelete(activityGroup: IActivityGroupListResource) {
  const { open, close, destroy } = useModal({
    component: ConfirmModal,
    attrs: {
      title: t('activities.groups.confirm.destroy.title'),
      message: t('activities.groups.confirm.destroy.text', {
        name: activityGroup.name,
      }),
      async onConfirm({ setLoading }: ConfirmDialogConfirmParams) {
        try {
          setLoading(true);
          await api.activityGroups.destroy(activityGroup.id);
          await getActivityGroups();
          if (activityGroups.value.length === 0 && currentPage.value > 1) {
            currentPage.value -= 1;
          }
          await close();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onCancel() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function canBeDeleted(group: IActivityGroupListResource): boolean {
  return !group.templates_count;
}

onMounted(async () => {
  lockTabs();
  loader.start();
  await getActivityGroups();
  loader.finish();
  unlockTabs();
});

watch(currentPage, async () => {
  lockTabs();
  loader.start();
  await getActivityGroups();
  loader.finish();
  unlockTabs();
});
</script>

<template>
  <div v-if="loader.isLoading.value" class="text-center">
    <AppLoader size="large" />
  </div>
  <template v-else>
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="d-flex align-items-end">
        <h1 class="mb-0" v-text="t('activities.groups.index.title')" />
        <HelpInformation class="ml-1" translation="activities.groups.index.help" />
      </div>
      <AppButton @click.prevent="onCreate" color="secondary">
        {{ t('activities.groups.index.create') }}
      </AppButton>
    </div>
    <AppAlert v-if="activityGroups.length === 0">{{ t('activities.groups.empty') }}</AppAlert>
    <template v-else>
      <AppTable hoverable>
        <AppTableHead>
          <AppTableTr>
            <AppTableTh nowrap>{{ t('activities.groups.attributes.name') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('activities.groups.attributes.type') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('activities.groups.attributes.templates_count') }}</AppTableTh>
            <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
          </AppTableTr>
        </AppTableHead>
        <AppTableBody>
          <AppTableTr v-for="activityGroup in activityGroups" :key="activityGroup.id">
            <AppTableTd nowrap><strong v-text="activityGroup.name" /></AppTableTd>
            <AppTableTd>{{ t(`activities.groups.type.${activityGroup.type}`) }}</AppTableTd>
            <AppTableTd>{{ activityGroup.templates_count }}</AppTableTd>
            <AppTableTd nowrap class="text-right">
              <AppButton
                v-tooltip.left="t('activities.groups.tooltip.edit', { name: activityGroup.name })"
                @click.stop.prevent="onEdit(activityGroup)"
                size="small"
                light
                circle
              >
                <FontIcon name="pencil" />
              </AppButton>
              <span
                v-tooltip.left="
                  canBeDeleted(activityGroup)
                    ? t('activities.groups.tooltip.destroy', { name: activityGroup.name })
                    : t('activities.groups.tooltip.cant_be_deleted')
                "
              >
                <AppButton
                  @click.stop.prevent="onDelete(activityGroup)"
                  class="ml-2"
                  color="danger"
                  size="small"
                  light
                  circle
                  :disabled="!canBeDeleted(activityGroup)"
                >
                  <FontIcon name="trash" />
                </AppButton>
              </span>
            </AppTableTd>
          </AppTableTr>
        </AppTableBody>
      </AppTable>
      <AppPagination :per-page="perPage" :total="total" v-model="currentPage" />
    </template>
  </template>
</template>
