<script lang="ts" setup>
import { ITodoCommentResource } from '@/types/Todo';
import { useI18n } from 'vue-i18n';
import { AppButton, FontIcon, FormLabel, FormTextarea, UserTodoComment } from '@/components';
import api from '@/services/api';
import { nextTick, ref, useId, watchEffect } from 'vue';

const { todoId, comments, hideFormAfterSendComment } = defineProps<{
  todoId: number;
  comments: ITodoCommentResource[];
  hideFormAfterSendComment: boolean;
}>();

const emit = defineEmits<{
  commented: [text: string];
}>();

const formVisible = defineModel<boolean>('formVisible', { required: true });

const { t } = useI18n({ useScope: 'global' });
const text = ref('');
const commentLoading = ref(false);
const id = useId();

async function onSendComment() {
  commentLoading.value = true;
  try {
    await api.todos.comments.create(todoId, text.value);
    if (hideFormAfterSendComment) {
      formVisible.value = false;
    }
    emit('commented', text.value);
    text.value = '';
  } catch (error) {
    console.error(error);
  } finally {
    commentLoading.value = false;
  }
}

watchEffect(() => {
  if (formVisible.value) {
    nextTick().then(() => {
      document.getElementById(id!)?.focus();
    });
  }
});
</script>

<template>
  <div class="comments">
    <ul class="comment-list" v-if="comments.length > 0">
      <UserTodoComment v-for="comment in comments" :key="comment.id" :comment="comment" />
    </ul>
    <form class="mt-3" v-if="formVisible" @submit.prevent="onSendComment">
      <FormLabel :html-for="id">
        {{ t('common.comment') }}
      </FormLabel>
      <div class="d-flex align-items-center">
        <FormTextarea group-classes="flex-grow-1 mb-0" :id="id" v-model="text" autosize autofocus />
        <AppButton
          class="flex-shrink-0 mb-2 ml-3"
          :disabled="!text || commentLoading"
          v-tooltip.bottom="t('common.send')"
          color="secondary"
          circle
          :loading="commentLoading"
        >
          <FontIcon name="send" />
        </AppButton>
      </div>
    </form>
  </div>
</template>
