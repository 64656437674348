<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';
import { useRouteParams } from '@vueuse/router';

import api from '@/services/api';
import { AppBox, AppBoxBody, AppLoader, TimeSheetReport } from '@/components';
import useLoader from '@/composables/useLoader';

import { ITimeSheetPreviewResource, ITimeSheetResource } from '@/types/TimeSheet';
import useAuthStore from '@/store/AuthStore';
import { useTitle } from '@vueuse/core';

const type = useRouteParams('type');
const timeSheetId = ref(0);

const { t } = useI18n({ useScope: 'global' });
const loader = useLoader();
const { authenticatedUser } = useAuthStore();

const timeSheetsAbortController = new AbortController();
const timeSheets = ref<ITimeSheetPreviewResource[]>([]);

const timeSheetAbortController = new AbortController();
const timeSheet = ref<null | ITimeSheetResource>(null);

onMounted(async () => {
  loader.start();
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    searchParams.append('users_uuid[]', authenticatedUser.uuid);
    timeSheets.value = (await api.timeSheets.index({ searchParams, signal: timeSheetsAbortController.signal })).data;
    const currentTimeSheet = timeSheets.value.find(
      ({ time_period }) => time_period.month === DateTime.now().month && time_period.year === DateTime.now().year,
    );
    timeSheetId.value = currentTimeSheet ? currentTimeSheet.id : timeSheets.value[0].id;
  } catch (error) {
    console.error(error);
  }
  loader.finish();
});

onUnmounted(() => {
  timeSheetsAbortController.abort('Abort request: getTimeSheets');
  timeSheetAbortController.abort('Abort request: getTimeSheet');
});

watch(timeSheetId, async () => {
  try {
    loader.start();
    const response = await api.timeSheets.get(timeSheetId.value, { signal: timeSheetAbortController.signal });
    timeSheet.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    loader.finish();
  }
});

const title = useTitle(computed(() => t(`time-sheet.${type.value}.title`, { username: timeSheet.value?.user.name })));
</script>

<template>
  <div v-if="loader.isLoading.value" class="text-center">
    <AppLoader size="large" />
  </div>
  <template v-else>
    <div v-if="timeSheet" class="container-fluid">
      <div class="d-flex align-items-end">
        <h1 class="mb-0" v-text="title" />
      </div>
      <!-- Details -->
      <AppBox class="mt-3">
        <AppBoxBody>
          <TimeSheetReport v-model:id="timeSheetId" :time-sheet="timeSheet" :time-sheets="timeSheets" />
        </AppBoxBody>
      </AppBox>
    </div>
  </template>
</template>
