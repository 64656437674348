import { Decimal } from 'decimal.js';

export default function useTime() {
  function convertMinutesToTime(rawMinutes: number | string) {
    const minutes = typeof rawMinutes === 'string' ? Number.parseInt(rawMinutes) : rawMinutes;
    const isNegative = 0 > minutes;
    if (isNaN(minutes)) {
      return '00:00';
    }
    const hours = Math.floor(Math.abs(minutes) / 60);
    const mins = Math.ceil(Math.abs(minutes) % 60);
    return (isNegative ? '-' : '') + (String(hours).padStart(2, '0') + ':' + String(mins).padStart(2, '0'));
  }

  function parseMinutes(value: string): number {
    const floatValue = parseFloat(value.replace(',', '.'));
    if (floatValue >= 0 && floatValue <= 24 && !value.includes(':')) {
      return Math.round(floatValue * 60);
    } else {
      const inputString = value.toString().replace(/\D/g, '');
      if (inputString.length === 1 || inputString.length === 2) {
        return parseInt(inputString, 10);
      } else if (inputString.length === 3 || inputString.length === 4) {
        const hours = parseInt(inputString.slice(0, -2), 10);
        const minutes = parseInt(inputString.slice(-2), 10);
        return Math.min(hours * 60 + minutes, 99 * 60 + 45);
      }
      const minutes = parseInt(inputString.slice(-2), 10);
      const hours = parseInt(inputString.slice(0, -2), 10);
      return hours * 60 + minutes;
    }
  }

  function convertMinutesToValue(rawMinutes: number | string) {
    const minutes = typeof rawMinutes === 'string' ? Number.parseInt(rawMinutes) : rawMinutes;
    if (isNaN(minutes) || minutes <= 0) {
      return 0;
    }
    return new Decimal(minutes).div(60).toDecimalPlaces(2).toNumber();
  }

  function convertMinutesToShortTime(rawMinutes: number | string) {
    const minutes = typeof rawMinutes === 'string' ? Number.parseInt(rawMinutes) : rawMinutes;
    if (isNaN(minutes) || minutes <= 0) {
      return '0';
    }
    const hours = Math.floor(minutes / 60);
    const mins = Math.ceil(minutes % 60);
    return hours.toString() + (mins > 0 ? `:${String(mins).padStart(2, '0')}` : '');
  }

  return {
    convertMinutesToTime,
    convertMinutesToValue,
    convertMinutesToShortTime,
    parseMinutes,
  };
}
