import NProgress from 'nprogress';

const progress = {
  loading: false,
  start() {
    this.loading = true;
    NProgress.start();
  },
  done() {
    this.loading = false;
    NProgress.done();
  },
};

export default progress;
