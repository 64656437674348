<script lang="ts" setup>
import { computed } from 'vue';

type Props = {
  value: string;
  mode?: 'phone' | 'email';
};

const props = defineProps<Props>();

const phone = computed(() => 'tel: ' + props.value.replace(/\s+/g, ''));
const email = computed(() => 'mailto: ' + props.value);

const href = computed(() => {
  if (props.mode) {
    return props.mode === 'phone' ? phone.value : email.value;
  }
  return '';
});
</script>

<template>
  <a :href="href">
    <slot>
      {{ value }}
    </slot>
  </a>
</template>
