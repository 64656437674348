<script setup lang="ts">
const props = defineProps<{
  cssClass?: string;
}>();
</script>

<template>
  <div :class="props.cssClass" class="dropdown-item">
    <slot />
  </div>
</template>
