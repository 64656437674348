<script setup lang="ts">
import { Decimal } from 'decimal.js';
import { useI18n } from 'vue-i18n';

import { IInvoiceItemPostponeResource } from '@/types/Invoice';

type Props = {
  postpone: IInvoiceItemPostponeResource;
};

defineProps<Props>();

const { n, t } = useI18n({ useScope: 'global' });
</script>

<template>
  <div class="scheduled-item">
    <div class="scheduled-item-title">{{ t('client.billing.postponed') }}</div>
    {{ n(new Decimal(postpone.sum ?? 0).toNumber(), 'currency') }}
  </div>
</template>
