export default {
  role: {
    responsible: 'Project responsible',
    team_member: 'Team member',
  },

  frequency: {
    once: 'Once',
    weekly: 'Weekly',
    monthly: 'Monthly',
    quarterly: 'Quarterly',
    'semi-annually': 'Semi-annually',
    annually: 'Annually',
  },

  // Tooltips
  tooltip: {
    edit: 'Edit "{name}" task',
    destroy: 'Delete "{name}" task',
    restore: 'Restore "{name}"',
  },

  statuses: {
    all: 'All',
    done: 'Done',
    not_done: 'Not done',
  },

  // Confirms
  confirm: {
    destroy: {
      title: 'Delete task',
      text: 'Are you sure you want to delete <strong>{name}</strong> task from the list?',
      with_events: 'Also delete all events.',
    },
  },
};
