<script lang="ts" setup>
import { AppButton, FontIcon } from '@/components';
import { useI18n } from 'vue-i18n';

type Props = {
  isEditing: boolean;
  deletable: boolean;
  disabled: boolean;
  isSplitLoading: boolean;
  isSplitDisabled: boolean;
  isSubmitLoading: boolean;
  isDoneLoading: boolean;
  isModified: boolean;
  splittable: boolean;
};

const { isEditing, deletable, disabled, isSplitLoading, isSubmitLoading } = defineProps<Props>();

const emit = defineEmits<{
  delete: [];
  done: [];
  split: [];
  cancel: [];
}>();

const done = defineModel<boolean>('done', { required: true });

const { t } = useI18n({ useScope: 'global' });

function onDone() {
  if (!done.value) {
    done.value = true;
    emit('done');
  } else {
    done.value = !done.value;
  }
}
</script>

<template>
  <div class="d-flex justify-content-between align-items-center">
    <template v-if="isEditing">
      <AppButton
        v-if="deletable"
        color="danger"
        @click.prevent="emit('delete')"
        :disabled="isSplitLoading || isSubmitLoading"
      >
        <FontIcon name="trash" />
        {{ t('common.delete') }}
      </AppButton>
      <AppButton class="ml-2" light @click.prevent="emit('cancel')" :disabled="isSplitLoading || isSubmitLoading">
        <FontIcon name="x" />
        {{ t('common.cancel') }}
      </AppButton>
      <AppButton
        light
        class="ml-2"
        @click.prevent="emit('split')"
        :loading="isSplitLoading"
        :disabled="isSubmitLoading || isSplitDisabled"
        v-if="splittable"
      >
        <FontIcon name="arrows-split" />
        {{ t('time-entry.modal.split') }}
      </AppButton>
      <AppButton
        @click.prevent="onDone"
        class="mr-3 ml-auto"
        :light="!done"
        :color="done ? 'success' : undefined"
        :loading="isDoneLoading"
        :disabled="isSplitLoading || disabled || isSubmitLoading"
      >
        <FontIcon v-if="!done" name="check" style="color: var(--color-neutral-400-hex)" />
        <FontIcon v-else name="check" />
        {{ t('time-entry.modal.attributes.done') }}
      </AppButton>
      <AppButton
        :loading="isSubmitLoading"
        :disabled="isSplitLoading || disabled || isDoneLoading || !isModified"
        color="success"
      >
        <FontIcon name="device-floppy" />
        {{ t('common.update') }}
      </AppButton>
    </template>
    <template v-else>
      <AppButton light @click.prevent="emit('cancel')">
        <FontIcon name="x" />
        {{ t('common.close') }}
      </AppButton>
      <AppButton
        @click.prevent="onDone"
        class="mr-3 ml-auto"
        :light="!done"
        :color="done ? 'success' : undefined"
        :loading="isDoneLoading"
        :disabled="isSplitLoading || disabled || isSubmitLoading"
      >
        <FontIcon v-if="!done" name="check" style="color: var(--color-neutral-400-hex)" />
        <FontIcon v-else name="check" />
        {{ t('time-entry.modal.attributes.done') }}
      </AppButton>
      <AppButton :loading="isSubmitLoading" :disabled="disabled" color="secondary">
        <FontIcon name="circle-plus" />
        {{ t('common.create') }}
      </AppButton>
    </template>
  </div>
</template>
