import { Decimal } from 'decimal.js';

export default function useHelpers() {
  function findClosestIds(id: number, ids: number[]): { prev: number | null; next: number | null } {
    ids.sort((a, b) => a - b);
    let prev = null;
    let next = null;
    for (let i = 0; i < ids.length; i++) {
      if (ids[i] === id) {
        if (i > 0) {
          prev = ids[i - 1];
        }
        if (i < ids.length - 1) {
          next = ids[i + 1];
        }
        break;
      } else if (ids[i] < id) {
        prev = ids[i];
      } else {
        next = ids[i];
        break;
      }
    }
    return { prev, next };
  }

  function parseAmount(value: number | string | null, invert = false): number {
    if (value === '' || value === null) return 0.0;
    const parsedValue = parseFloat(typeof value === 'number' ? value.toString() : value);
    if (!isFinite(parsedValue) || isNaN(parsedValue)) {
      return 0.0;
    }
    return Math.round(invert ? parsedValue : Math.abs(parsedValue) * 100) / 100;
  }

  function calculateRatios(count: number, ...sums: number[]): number[] {
    if (count === 0) {
      throw new Error('Count cannot be zero');
    }
    return sums.map((sum) => sum / count);
  }

  function calculatePercentages(total: number, values: number[]): number[] {
    if (total <= 0) {
      return values.map(() => 0);
    }
    return values.map((value) => new Decimal(value).div(total).mul(100).toDecimalPlaces(2).toNumber());
  }

  return {
    findClosestIds,
    parseAmount,
    calculateRatios,
    calculatePercentages,
  };
}
