<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import VueSelect from 'vue-select';
import { computed } from 'vue';
import { useModal } from 'vue-final-modal';

import {
  AppBoxBody,
  AppButton,
  ClientsItSystemModal,
  FontIcon,
  FormInput,
  FormLabel,
  FormSwitch,
  FormTextarea,
} from '@/components';
import { SelectOption } from '@/types/Common';
import { ClientProcessesFramework, IClientItSystemResource } from '@/types/Client';

const emit = defineEmits<{
  systemCreated: [clientItSystem: IClientItSystemResource];
}>();

type Props = {
  id: string;
  title: string;
  itSystems: IClientItSystemResource[];
  itSystemsLoading: boolean;
};

const { t } = useI18n({ useScope: 'global' });
defineProps<Props>();

const enabled = defineModel<boolean>('enabled', { required: true });
const systems = defineModel<number[]>('systems', { required: true });
const we_do = defineModel<boolean>('we_do', { required: true });
const description = defineModel<string | null>('description', { required: true });
const showFrameworkField = defineModel<boolean>('show_framework_field', { required: false, default: false });
const showDeadlineField = defineModel<boolean>('show_deadline_field', { required: false, default: false });
const framework = defineModel<string | null>('framework', { required: false });
const deadline = defineModel<string | null>('deadline', { required: false });

const frameworkOptions = computed(() => {
  return [
    {
      label: t('client.processes.accounting_and_general_ledger_framework.k1'),
      value: ClientProcessesFramework.K1,
    },
    {
      label: t('client.processes.accounting_and_general_ledger_framework.k2'),
      value: ClientProcessesFramework.K2,
    },
    {
      label: t('client.processes.accounting_and_general_ledger_framework.k3_less'),
      value: ClientProcessesFramework.K3_LESS,
    },
    {
      label: t('client.processes.accounting_and_general_ledger_framework.k3_bigger'),
      value: ClientProcessesFramework.K3_BIGGER,
    },
    {
      label: t('client.processes.accounting_and_general_ledger_framework.ifrs'),
      value: ClientProcessesFramework.IFRS,
    },
    {
      label: t('client.processes.accounting_and_general_ledger_framework.arkl'),
      value: ClientProcessesFramework.ARKL,
    },
    {
      label: t('client.processes.accounting_and_general_ledger_framework.other'),
      value: ClientProcessesFramework.OTHER,
    },
  ];
});

const clientItSystemModal = useModal({
  component: ClientsItSystemModal,
  attrs: {
    onCreated(itSystem: IClientItSystemResource) {
      emit('systemCreated', itSystem);
      systems.value = [...systems.value, itSystem.id];
      clientItSystemModal.close();
    },
  },
});
</script>

<template>
  <AppBoxBody>
    <div class="row">
      <div class="col-10">
        <h3>{{ title }}</h3>
      </div>
      <div class="col-2 text-right">
        <FormSwitch group-class="mb-0" :id="`${id}-enabled`" v-model="enabled" />
      </div>
    </div>
    <template v-if="enabled">
      <div v-if="showFrameworkField || showDeadlineField" class="row">
        <div class="col-8">
          <div class="row row-cols-2">
            <div v-if="showFrameworkField" class="col">
              <div class="form-group">
                <FormLabel :html-for="`${id}-framework`">{{ t('client.processes.framework') }}</FormLabel>
                <VueSelect
                  :reduce="(option: SelectOption) => option.value"
                  :clearable="false"
                  :searchable="false"
                  v-model="framework"
                  :options="frameworkOptions"
                  label="label"
                  :input-id="`${id}-framework`"
                  :placeholder="t('common.select')"
                />
              </div>
            </div>
            <div v-if="showDeadlineField" class="col">
              <div class="form-group">
                <FormLabel :html-for="`${id}-deadline`">{{ t('client.processes.deadline') }}</FormLabel>
                <FormInput :id="`${id}-deadline`" v-model="deadline" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-8">
          <div class="row row-cols-2">
            <div class="col">
              <div class="form-group">
                <FormLabel :html-for="`${id}-system`">{{ t('client.processes.system') }}</FormLabel>
                <div class="d-flex">
                  <VueSelect
                    class="flex-grow-1"
                    :reduce="(option: IClientItSystemResource) => option.id"
                    :clearable="false"
                    :searchable="false"
                    v-model="systems"
                    :options="itSystems"
                    label="name"
                    :input-id="`${id}-system`"
                    :placeholder="t('common.select')"
                    :loading="itSystemsLoading"
                    multiple
                  />
                  <AppButton
                    @click.prevent="clientItSystemModal.open"
                    color="success"
                    class="flex-shrink-0 ml-2"
                    light
                    circle
                    v-tooltip.left="t('common.add')"
                  >
                    <FontIcon name="plus" />
                  </AppButton>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <FormLabel :html-for="`${id}-we_do`">{{ t('client.processes.we_do') }}</FormLabel>
                <FormSwitch group-class="mb-0" :id="`${id}-we_do`" v-model="we_do" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <FormLabel :html-for="`${id}-description`">{{ t('client.processes.description') }}</FormLabel>
        <FormTextarea :id="`${id}-description`" class="form-control" size="large" v-model="description" />
      </div>
    </template>
  </AppBoxBody>
</template>
