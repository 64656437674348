<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { InvoiceInternalStatusType } from '@/types/Invoice';

const { t } = useI18n({ useScope: 'global' });

const props = defineProps<{
  status: null | InvoiceInternalStatusType;
}>();

const color = computed(() => {
  switch (props.status) {
    case InvoiceInternalStatusType.DRAFT:
      return 'is-warning';
    case InvoiceInternalStatusType.APPROVED:
      return 'is-success';
    case InvoiceInternalStatusType.DELETED:
      return 'is-danger';
    case InvoiceInternalStatusType.UNMATCHED:
      return 'is-danger';
    case InvoiceInternalStatusType.MATCHED:
      return 'is-secondary';
    case InvoiceInternalStatusType.POSTPONED:
      return 'is-warning';
    case InvoiceInternalStatusType.ACTION_REQUIRED:
      return 'is-danger';
    default:
      return '';
  }
});

const icon = computed(() => {
  switch (props.status) {
    case InvoiceInternalStatusType.DRAFT:
      return 'ti-pencil';
    case InvoiceInternalStatusType.APPROVED:
      return 'ti-check';
    case InvoiceInternalStatusType.DELETED:
      return 'ti-trash';
    case InvoiceInternalStatusType.UNMATCHED:
      return 'ti-hourglass-off';
    case InvoiceInternalStatusType.MATCHED:
      return 'ti-hourglass';
    case InvoiceInternalStatusType.POSTPONED:
      return 'ti-ban';
    case InvoiceInternalStatusType.ACTION_REQUIRED:
      return 'ti-alert-triangle-filled';
    default:
      return '';
  }
});
</script>

<template>
  <span v-if="status" class="badge" :class="color">
    <i class="ti" :class="icon" />
    {{ t(`invoice.internal_status.${status}`) }}
  </span>
</template>
