import route from 'ziggy-js';
import httpClient from '@/services/api/ky';
import { ClientFilesSettings } from '@/types/Client';

export default {
  settings: {
    get() {
      return httpClient.get(route('we-file.settings.get')).json<ClientFilesSettings>();
    },
  },
};
