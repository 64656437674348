<script setup lang="ts">
import { useModal, VueFinalModal } from 'vue-final-modal';
import { computed, reactive, ref, onUnmounted, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import VueDatePicker from '@vuepic/vue-datepicker';
import { DateTime } from 'luxon';

import {
  AppAlert,
  AppButton,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  ConfirmModal,
  FontIcon,
  FormInput,
  FormLabel,
} from '@/components';

import api from '@/services/api';
import useLoader from '@/composables/useLoader';

import { IPriceMatrixRequestBody } from '@/types/PriceMatrix';
import { IServicePriceAdjustmentListResource } from '@/types/Service';

const { t, n, locale } = useI18n({ useScope: 'global' });
const submitLoader = useLoader({ useProgress: false });
const submitLoaderDelete = useLoader({ useProgress: false });

const props = defineProps<{
  loading?: boolean;
  title?: string;
  adjustment: boolean;
  prices?: IServicePriceAdjustmentListResource[];
  serviceUuid: string;
  userUuid: null | string;
}>();

const list = ref<IServicePriceAdjustmentListResource[]>([]);

const form = reactive<IPriceMatrixRequestBody>({
  price: 0,
  start_date: '',
});

const formAdjustmentForm = {
  price: 0,
  apply_date: '',
};

const disabled = computed(() => form.start_date === '' || !form.price);

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'update'): void;
}>();

async function submit() {
  try {
    submitLoader.start();
    if (props.adjustment) {
      formAdjustmentForm.price = form.price;
      formAdjustmentForm.apply_date = form.start_date;
      await api.priceMatrix.storeOrUpdateAdjustmentPrice(props.serviceUuid, formAdjustmentForm);
    } else {
      if (props.userUuid === null) return;
      await api.priceMatrix.store(props.serviceUuid, props.userUuid, form);
    }
    form.price = 0;
    form.start_date = '';
    await updateList();
  } catch (error) {
    console.error(error);
  } finally {
    submitLoader.finish();
  }
}

async function updateList() {
  emit('update');

  if (props.adjustment) {
    const response = await api.priceMatrix.getAdjustmentPrices(props.serviceUuid);
    list.value = response.data;
  } else {
    if (props.userUuid === null) return;
    const response = await api.priceMatrix.getHistoryPrices(props.serviceUuid, props.userUuid);
    list.value = response.data;
  }
}

const deleteModal = useModal({
  component: ConfirmModal,
  attrs: {
    title: t('price-matrix.confirm.destroy.title'),
    async onConfirm() {
      try {
        if (!deleteModal.options.attrs?.params?.id) return;
        const idToDelete = deleteModal.options.attrs.params.id as string;
        // @ts-ignore
        deleteModal.patchOptions({ attrs: { loading: true } });
        if (props.adjustment) {
          await api.priceMatrix.destroyAdjustmentPrice(props.serviceUuid, idToDelete);
          await updateList();
        } else {
          await api.priceMatrix.destroy(idToDelete);
          await updateList();
        }
        await deleteModal.close();
      } catch (error) {
        console.error(error);
      }
    },
    onCancel() {
      deleteModal.close();
    },
    // @ts-ignore
    onClosed() {
      // @ts-ignore
      deleteModal.patchOptions({ attrs: { loading: false } });
    },
  },
});

async function destroy(id: string, price: number) {
  try {
    deleteModal.patchOptions({
      // @ts-ignore
      attrs: {
        params: { id },
        message: t('price-matrix.confirm.destroy.text', { price }),
      },
    });
    await deleteModal.open();
  } catch (error) {
    console.error(error);
  } finally {
    submitLoaderDelete.finish();
  }
}

onMounted(() => {
  if (props.prices) {
    list.value = [...props.prices];
  }
});

onUnmounted(() => {
  form.price = 0;
  form.start_date = '';
});
</script>

<template>
  <VueFinalModal class="modal-overlay" content-class="modal-container" :click-to-close="false" :esc-to-close="true">
    <form>
      <div class="modal-header">
        <h2 v-text="props.title" />
      </div>
      <div class="modal-content">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <FormLabel html-for="default_fixed_price" required>
                {{ t('price-matrix.attributes.amount') }}
              </FormLabel>
              <FormInput min="1" step="1" type="number" id="default_fixed_price" v-model.number="form.price" required />
            </div>
          </div>
          <div class="col">
            <!-- Start date -->
            <div class="form-group">
              <div class="form-wrapper has-icon">
                <FormLabel html-for="dp-input-start_date" required>
                  {{ t('price-matrix.attributes.apply_from') }}
                </FormLabel>
                <VueDatePicker
                  uid="start_date"
                  :ui="{ input: 'form-control' }"
                  :placeholder="t('common.select')"
                  v-model="form.start_date"
                  model-type="format"
                  format="yyyy-MM-01"
                  :enable-time-picker="false"
                  :month-change-on-scroll="false"
                  required
                  :min-date="DateTime.now().plus({ months: 1 }).startOf('month').toJSDate()"
                  auto-apply
                  month-picker
                  :locale="locale"
                  teleport
                >
                  <template #input-icon><i class="form-icon ti ti-calendar" /></template>
                </VueDatePicker>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <AppButton
            @click.prevent="submit"
            class="mt-2 mt-sm-0"
            color="secondary"
            :loading="submitLoader.isLoading.value"
            :disabled="disabled"
          >
            {{ t('price-matrix.attributes.create_update') }}
          </AppButton>
        </div>
        <div class="mt-2">
          <h2>{{ t('price-matrix.modal.history') }}</h2>
        </div>
        <AppAlert v-if="list.length === 0">{{ t('price-matrix.empty_history') }}</AppAlert>
        <template v-else>
          <AppTable hoverable>
            <AppTableHead>
              <AppTableTr>
                <AppTableTh nowrap>{{ t('price-matrix.attributes.amount') }}</AppTableTh>
                <AppTableTh nowrap>{{ t('price-matrix.attributes.applied_from') }}</AppTableTh>
                <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
              </AppTableTr>
            </AppTableHead>
            <AppTableBody>
              <AppTableTr v-for="item in list" :key="item.id">
                <AppTableTd nowrap>{{ n(parseFloat(item.price), 'currency') }}</AppTableTd>
                <AppTableTd nowrap>{{ item.start_date }}</AppTableTd>
                <AppTableTd nowrap class="text-right">
                  <AppButton
                    v-if="DateTime.now() < DateTime.fromISO(item.start_date)"
                    @click.stop.prevent="destroy(item.id, item.price)"
                    class="ml-2"
                    size="small"
                    color="danger"
                    light
                    circle
                  >
                    <FontIcon name="trash" />
                  </AppButton>
                </AppTableTd>
              </AppTableTr>
            </AppTableBody>
          </AppTable>
        </template>
      </div>
      <div class="modal-footer">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
          <AppButton light @click.prevent="emit('cancel')" :disabled="loading">
            {{ t('common.cancel') }}
          </AppButton>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>
