<script setup lang="ts">
import { Decimal } from 'decimal.js';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { computed } from 'vue';

import { IClientBillingInvoiceInvoice } from '@/types/Client';
import { InvoiceExternalStatusType, InvoiceInternalStatusType } from '@/types/Invoice';

type Props = {
  invoice: IClientBillingInvoiceInvoice;
};

const { invoice } = defineProps<Props>();

const { n, t } = useI18n({ useScope: 'global' });
const router = useRouter();

const classes = computed(() => {
  const { internal_status, external_status } = invoice;
  if (internal_status === InvoiceInternalStatusType.DRAFT && external_status === null) {
    return 'is-grey';
  }
  if (internal_status === InvoiceInternalStatusType.ACTION_REQUIRED && external_status === null) {
    return 'is-yellow';
  }
  if (
    internal_status === InvoiceInternalStatusType.APPROVED &&
    (external_status === InvoiceExternalStatusType.PAID || external_status === InvoiceExternalStatusType.UNPAID)
  ) {
    return 'is-green';
  }
  if (
    internal_status === InvoiceInternalStatusType.APPROVED &&
    (external_status === InvoiceExternalStatusType.OVERDUE_REMINDER_SENT ||
      external_status === InvoiceExternalStatusType.OVERDUE_REMINDER_NOT_SENT)
  ) {
    return 'is-orange';
  }
  if (
    internal_status === InvoiceInternalStatusType.APPROVED &&
    external_status === InvoiceExternalStatusType.NOT_SENT
  ) {
    return 'is-blue';
  }
  if (internal_status === InvoiceInternalStatusType.EMPTY) {
    return 'is-red';
  }
  return '';
});

function onInvoiceClick(id: number) {
  const { href } = router.resolve({ name: 'invoices.edit', params: { id } });
  window.open(href, '_blank');
}
</script>

<template>
  <div
    @click.prevent="onInvoiceClick(invoice.id)"
    class="scheduled-item pointer"
    :class="classes"
    v-tooltip.top="`ID: ${invoice.id.toString()}`"
  >
    <div class="scheduled-item-title">{{ t('invoice.resource.name') }}</div>
    {{ n(new Decimal(invoice.price ?? 0).toNumber(), 'currency') }}
  </div>
</template>
