export default {
  index: {
    title: 'Price matrix',
    help: '',
    general_info: 'General info',
  },
  filter: {
    user: 'User',
    user_search: 'Write to search',
  },
  modal: {
    title: 'Schedule the price',
    history: 'Price history',
  },
  attributes: {
    amount: 'Amount',
    work_title: 'Work title',
    apply_from: 'Apply from',
    applied_from: 'Applied from',
    create_update: 'Create / Update',
  },
  // Confirms
  confirm: {
    destroy: {
      title: 'Delete price',
      text: 'Are you sure you want to delete <strong>{price}</strong> price?',
    },
  },

  // Tooltips
  tooltip: {
    price_change_is_scheduled: 'Price change is scheduled',
  },

  empty_history: 'No price history',
};
