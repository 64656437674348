export default {
  resource: {
    name: 'Internal project | Internal projects',
  },
  status: {
    draft: 'Draft',
    active: 'Active',
    closed: 'Closed',
  },
  index: {
    title: 'Internal projects & events',
    active_events: 'Active internal events',
    closed_events: 'Closed internal events',
    closed_events_description: 'List of internal events where end date is passed OR manually closed by User',
    add_event: 'Add event',
    help: '',
    tabs: {
      active: 'Active',
      closed: 'Closed',
    },
  },
  create: {
    title: 'Add internal event',
    help: '',
  },
  edit: {
    title: 'Edit internal event',
    help: '',
  },
  attributes: {
    name: 'Event name',
    start_date: 'Start date',
    end_date: 'End date',
    activity_group: 'Activity group',
    activities: 'Activities',
    frequency: {
      once: 'One time',
      weekly: 'Weekly',
      monthly: 'Monthly',
      quarterly: 'Quarterly',
      'semi-annually': 'Semi-annually',
      annually: 'Annually',
      varying: 'Varying',
    },
    task: {
      multiple: 'Multiple',
    },
  },
  activities: {
    title: 'Activities',
    empty: 'No activities',
    table: {
      name: 'Task',
      frequency: 'Frequency',
      start_week: 'Start week',
      scheduled_time: 'Estimated time',
      users: 'Employees',
      note: 'Task note',
    },
    tooltip: {
      add_people: 'Add people',
      note: 'Task note',
    },
  },
  table: {
    name: 'Event',
    status: 'Status',
    date: 'Date',
    task: 'Task',
    frequency: 'Frequency',
    hours: 'Hours',
    group: 'Group',
  },
  empty: 'No internal events',
  tooltip: {
    edit: 'Edit "{name}" internal event',
    destroy: 'Delete "{name}" internal event',
    activate: 'Activate "{name}" internal event',
    close: 'Close "{name}" internal event',
    reopen: 'Reopen "{name}" internal event',
  },
  messages: {
    choose_activity_group: 'First, choose an activity group',
  },
  confirm: {
    destroy: {
      title: 'Delete internal event',
      text: 'Are you sure you want to delete <strong>{name}</strong> internal event from the system?',
    },
    activate: {
      title: 'Activate internal event',
      text: 'Are you sure you want to activate <strong>{name}</strong> internal event?',
    },
    close: {
      title: 'Close internal event',
      text: 'Are you sure you want to close <strong>{name}</strong> internal event?',
    },
    reopen: {
      title: 'Reopen internal event',
      text: 'Are you sure you want to reopen <strong>{name}</strong> internal event?',
    },
  },
};
