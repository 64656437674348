import { computed, ref } from 'vue';
import { klona } from 'klona';
import diff from 'microdiff';
import get from 'lodash/get';

// eslint-disable-next-line
export default function useTrackChanges(input: Record<string, any>) {
  const source = ref(klona(input));

  function commit() {
    source.value = klona(input);
  }

  const differences = computed(() => diff(source.value, input));

  const isModified = computed(() => {
    return differences.value.length > 0;
  });

  const isFieldModified = (path: (string | number)[]) => {
    return get(source.value, path) !== get(input, path);
  };

  function getOriginal<T>(path: (string | number)[]): T {
    return get(source.value, path);
  }

  return {
    commit,
    differences,
    isModified,
    isFieldModified,
    getOriginal,
  };
}
