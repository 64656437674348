<script lang="ts" setup>
import { IResourcePlanningEmployeeEvent, IResourcePlanningEmployeeTask } from '@/types/Event';
import { AppTableTd, AppTableTr } from '@/components';
import useTime from '@/composables/useTime';
import { nanoid } from 'nanoid';

type Props = {
  weeks: number[];
  task: IResourcePlanningEmployeeTask;
  employeeUuid: string;
  selected: (id: string) => boolean;
  taskDraggable: boolean;
  eventDraggable: boolean;
};

const { weeks, task, selected, taskDraggable, eventDraggable } = defineProps<Props>();

const emit = defineEmits<{
  select: [id: string];
  dragstart: [
    dragEvent: DragEvent,
    event: IResourcePlanningEmployeeEvent,
    task: IResourcePlanningEmployeeTask,
    employeeUuid: string,
  ];
  dragend: [dragEvent: DragEvent, event: IResourcePlanningEmployeeEvent, task: IResourcePlanningEmployeeTask];
  dragover: [dragEvent: DragEvent, task: IResourcePlanningEmployeeTask, week: number];
  dragleave: [dragEvent: DragEvent];
  drop: [dragEvent: DragEvent, week: number, { dropType: 'task' }];

  dragstartTask: [dragEvent: DragEvent];
  dragendTask: [dragEvent: DragEvent];

  timeReport: [id: number];
}>();

const id = nanoid();
const { convertMinutesToTime } = useTime();

const isDraggableEvent = (event: IResourcePlanningEmployeeEvent) => eventDraggable && event.done_at === null;
</script>

<template>
  <AppTableTr :class="{ 'selected-row': selected(id) }" @click.prevent="emit('select', id)">
    <AppTableTd colspan="4" />
    <AppTableTd colspan="3">
      <div
        :class="{ pointer: taskDraggable }"
        :draggable="taskDraggable"
        @dragstart="emit('dragstartTask', $event)"
        @dragend="emit('dragendTask', $event)"
        style="padding-left: 32px"
      >
        {{ task.name }}
      </div>
    </AppTableTd>
    <AppTableTd
      class="dnd-droppable text-center"
      @dragenter.prevent
      @drop.prevent="emit('drop', $event, week, { dropType: 'task' })"
      @dragover.prevent="emit('dragover', $event, task, week)"
      @dragleave.prevent="emit('dragleave', $event)"
      v-for="week in weeks"
      :key="week"
    >
      <template v-for="event in task.events" :key="event.id">
        <div
          :draggable="isDraggableEvent(event)"
          @dragstart="emit('dragstart', $event, event, task, employeeUuid)"
          @dragend="emit('dragend', $event, event, task)"
          v-if="event.week === week"
          :class="{
            'text-neutral-300': event.time === 0,
            'text-success-500': event.done_at !== null,
          }"
          class="pointer"
          @click.prevent="emit('timeReport', event.id)"
        >
          {{ convertMinutesToTime(event.time) }}
        </div>
      </template>
    </AppTableTd>
    <AppTableTd />
  </AppTableTr>
</template>
