import { IUserPreviewResource } from '@/types/User';
import { ITimePeriodResource } from '@/types/TimePeriod';
import { IWeeklyReportedTimeResource } from '@/types/Event';

export enum TimeSheetStatus {
  LOCKED = 'locked',
  UNLOCKED = 'unlocked',
}
export enum TimeSheetReviewStatus {
  NOT_REVIEWED = 'not_reviewed',
  APPROVED = 'approved',
  NOT_APPROVED = 'not_approved',
}

export interface ITimeSheetPreviewResource {
  id: number;
  status: TimeSheetStatus;
  review_status: null | TimeSheetReviewStatus;
  is_completed: boolean;
  total_tracked_time: number;
  time_period: ITimePeriodResource;
  user: IUserPreviewResource;
}

export interface ITimeSheetResource
  extends Pick<ITimeSheetPreviewResource, 'id' | 'status' | 'review_status' | 'is_completed' | 'time_period' | 'user'> {
  review_comment: null | string;
  details: {
    absence_time: number;
    client_time: number;
    internal_time: number;
    overtime: number;
    reported_time: number;
    scheduled_time: number;
    time_balance_before_approval: number;
    time_balance_after_approval: null | number;
    weeks: {
      time_sheet: IWeeklyReportedTimeResource;
      week: number;
    }[];
  };
}

export interface ITimeSheetRequest {
  status?: TimeSheetStatus;
  review_status?: null | TimeSheetReviewStatus;
  review_comment?: string;
}
