<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { InvoiceExternalStatusType } from '@/types/Invoice';

const { t } = useI18n({ useScope: 'global' });

const props = defineProps<{
  status: null | InvoiceExternalStatusType;
}>();

const color = computed(() => {
  switch (props.status) {
    case InvoiceExternalStatusType.NOT_SENT:
      return '';
    case InvoiceExternalStatusType.UNPAID:
      return 'is-secondary';
    case InvoiceExternalStatusType.OVERDUE_REMINDER_SENT:
      return 'is-warning';
    case InvoiceExternalStatusType.OVERDUE_REMINDER_NOT_SENT:
      return 'is-warning';
    case InvoiceExternalStatusType.PAID:
      return 'is-success';
    case InvoiceExternalStatusType.CANCELLED:
      return 'is-danger';
    case InvoiceExternalStatusType.COLLECTION:
      return '';
    case InvoiceExternalStatusType.EXPIRES:
      return 'is-danger';
    default:
      return '';
  }
});

const icon = computed(() => {
  switch (props.status) {
    case InvoiceExternalStatusType.NOT_SENT:
      return 'ti-inbox';
    case InvoiceExternalStatusType.UNPAID:
      return 'ti-clock';
    case InvoiceExternalStatusType.OVERDUE_REMINDER_SENT:
      return 'ti-bell';
    case InvoiceExternalStatusType.OVERDUE_REMINDER_NOT_SENT:
      return 'ti-bell-off';
    case InvoiceExternalStatusType.PAID:
      return 'ti-check';
    case InvoiceExternalStatusType.CANCELLED:
      return 'ti-x';
    case InvoiceExternalStatusType.COLLECTION:
      return 'ti-calendar-month';
    case InvoiceExternalStatusType.EXPIRES:
      return 'ti-hourglass-low';
    default:
      return '';
  }
});
</script>

<template>
  <span v-if="status" class="badge" :class="color">
    <i class="ti" :class="icon" />
    {{ t(`invoice.external_status.${status}`) }}
  </span>
</template>
