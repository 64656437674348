import activities from '@/services/api/routes/activities';
import activityGroups from '@/services/api/routes/activity-groups';
import activityTemplates from '@/services/api/routes/activity-templates';
import activityTypes from '@/services/api/routes/activity-types';
import auth from '@/services/api/routes/auth';
import businessDay from '@/services/api/routes/business-day';
import clients from '@/services/api/routes/clients';
import contacts from '@/services/api/routes/contacts';
import countries from '@/services/api/routes/countries';
import crm from '@/services/api/routes/crm';
import departments from '@/services/api/routes/departments';
import erpArticles from '@/services/api/routes/erp-articles';
import events from '@/services/api/routes/events';
import expenses from '@/services/api/routes/expenses';
import financialYears from '@/services/api/routes/financial-years';
import freeDays from '@/services/api/routes/free-days';
import internalProjects from '@/services/api/routes/internal-projects';
import invoiceItemTemplates from '@/services/api/routes/invoice-item-templates';
import invoices from '@/services/api/routes/invoices';
import offices from '@/services/api/routes/offices';
import priceMatrix from '@/services/api/routes/price-matrix';
import projects from '@/services/api/routes/projects';
import salary from '@/services/api/routes/salary';
import search from '@/services/api/routes/search';
import serviceCompanies from '@/services/api/routes/service-companies';
import services from '@/services/api/routes/services';
import systemRedirects from '@/services/api/routes/system-redirects';
import timePeriods from '@/services/api/routes/time-periods';
import timeSheets from '@/services/api/routes/time-sheets';
import todos from '@/services/api/routes/todos';
import users from '@/services/api/routes/users';
import weFile from '@/services/api/routes/we-file';
import workTitles from '@/services/api/routes/work-titles';

export default {
  activities,
  activityGroups,
  activityTemplates,
  activityTypes,
  auth,
  businessDay,
  crm,
  clients,
  contacts,
  countries,
  departments,
  erpArticles,
  events,
  expenses,
  financialYears,
  freeDays,
  internalProjects,
  invoiceItemTemplates,
  invoices,
  offices,
  priceMatrix,
  projects,
  salary,
  search,
  serviceCompanies,
  services,
  systemRedirects,
  timePeriods,
  timeSheets,
  todos,
  users,
  weFile,
  workTitles,
};
