import { nanoid } from 'nanoid';

import { IPlannedProjectTaskResource, IProjectTaskPlanningView, IProjectTaskResource } from '@/types/ProjectTask';
import useHelpers from '@/composables/useHelpers';
import { IProjectTaskGenerateEventsRequestBody } from '@/types/Project';

export default function useProjectPlanning() {
  const { parseAmount } = useHelpers();

  function createTaskTemplate(task: Partial<IProjectTaskPlanningView> = {}) {
    const defaultTask: IProjectTaskPlanningView = {
      uid: nanoid(),
      service_task_id: task?.service_task_id ?? null,
      order: task?.order ?? 0,
      name: task?.name ?? null,
      sub_label: task?.sub_label ?? null,
      default_frequency: task?.default_frequency ?? null,
      default_time_budget: task?.default_time_budget ?? 0,
      task_week: task?.task_week ?? null,
      custom_price: task?.custom_price ?? false,
      custom_price_type: task?.custom_price_type ?? null,
      custom_price_value: task?.custom_price_value ?? 0,
      user_uuid: task?.user_uuid ?? null,
      has_not_activated_changes: task?.has_not_activated_changes ?? false,
      events: task?.events ?? [],
      collapsed: task?.collapsed ?? false,
      loading: task?.loading ?? false,
      is_planned: task?.is_planned ?? false,
      is_deleted: task?.is_deleted ?? false,
    };
    return defaultTask;
  }

  function taskResourceToView(
    task: IProjectTaskResource | IPlannedProjectTaskResource,
  ): Omit<IProjectTaskPlanningView, 'parent_project_task_id' | 'is_planned' | 'is_deleted'> {
    return {
      uid: nanoid(),
      ...task,
      service_task_id: task.serviceTask.id,
      custom_price_value: parseAmount(task.custom_price_value),
      user_uuid: task.user?.uuid ?? null,
      events: task.events.map((event) => ({
        uid: nanoid(),
        ...event,
      })),
      collapsed: false,
      loading: false,
    };
  }

  function taskViewToSummaryEstimatesRequest(task: IProjectTaskPlanningView) {
    return {
      task_week: task.task_week,
      user_uuid: task.user_uuid,
      default_time_budget: task.default_time_budget,
      default_frequency: task.default_frequency,
      custom_price: task.custom_price && task.custom_price_type === null ? false : task.custom_price,
      custom_price_value: task.custom_price_value ? task.custom_price_value : 0,
      custom_price_type: task.custom_price_type,
      events: task.events,
    };
  }

  function taskViewToGenerateEventRequest(task: IProjectTaskPlanningView): IProjectTaskGenerateEventsRequestBody {
    return {
      task_id: task.is_planned ? task.parent_project_task_id ?? null : task.id,
      week: task.task_week,
      default_frequency: task.default_frequency,
      user_uuid: task.user_uuid,
      default_time_budget: task.default_time_budget ?? 0,
    };
  }

  return {
    createTaskTemplate,
    taskResourceToView,
    taskViewToSummaryEstimatesRequest,
    taskViewToGenerateEventRequest,
  };
}
