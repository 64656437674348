<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import { CheckpointsList } from '@/components';

type Props = { uuid: string };
const { uuid } = defineProps<Props>();

const { t } = useI18n({ useScope: 'global' });
</script>
<template>
  <CheckpointsList :client-uuid="uuid" :title="t('checkpoint.index.title')" initial-view-mode="calendar" />
</template>
