import { computed } from 'vue';
import { ClientProcessesReportingFrequency } from '@/types/Client';
import { SelectOption } from '@/types/Common';
import { useI18n } from 'vue-i18n';

export default function useClientSettings() {
  const i18n = useI18n({ useScope: 'global' });
  const { t } = i18n;
  const reportingFrequencyOptions = computed(() => {
    return [
      {
        value: 'monthly',
        label: t('client.processes.vat_reporting_frequency.monthly'),
      },
      {
        value: 'quarterly',
        label: t('client.processes.vat_reporting_frequency.quarterly'),
      },
      {
        value: 'annually',
        label: t('client.processes.vat_reporting_frequency.annually'),
      },
    ];
  });

  const getVatReportingDeadlines = (reportingFrequency: ClientProcessesReportingFrequency | null) => {
    let options: SelectOption[] = [];
    switch (reportingFrequency) {
      case ClientProcessesReportingFrequency.ANNUALLY:
        options = [
          {
            value: '26_of_second_month_following_period',
            label: t('client.processes.vat_reporting_deadline.26_of_second_month_following_period'),
          },
          {
            value: 'submitted_separately',
            label: t('client.processes.vat_reporting_deadline.submitted_separately'),
          },
        ];
        break;
      case ClientProcessesReportingFrequency.QUARTERLY:
        options = [
          {
            value: '12_of_second_month_following_period',
            label: t('client.processes.vat_reporting_deadline.12_of_second_month_following_period'),
          },
        ];
        break;
      case ClientProcessesReportingFrequency.MONTHLY:
        options = [
          {
            value: '12_of_second_month_after_period',
            label: t('client.processes.vat_reporting_deadline.12_of_second_month_after_period'),
          },
          {
            value: '26_of_first_month_after_period',
            label: t('client.processes.vat_reporting_deadline.26_of_first_month_after_period'),
          },
        ];
        break;
    }
    return options;
  };

  return { reportingFrequencyOptions, getVatReportingDeadlines };
}
