export default {
  index: {
    title: 'Client visits',
    help: '',
    filters: {
      assigned_user: 'Employee',
      project_responsible: 'Project responsible',
      date_from: 'From',
      date_to: 'To',
      not_booked: 'Not booked',
    },
  },
  status: {
    booked: 'Booked',
    not_booked: 'Not booked',
  },
  attributes: {
    employee: 'Employee',
    project_responsible: 'Project responsible',
    week: 'Week planned',
    client: 'Client name',
    status: 'Status',
    address: 'Visit address (City)',
    task: 'Task',
    financial_year: 'Financial year',
  },
  empty: 'No events',
};
