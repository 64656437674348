<script setup lang="ts">
import { ref, onMounted, watch, computed } from 'vue';
import { useRouteQuery } from '@vueuse/router';
import { useI18n } from 'vue-i18n';
import { Dropdown, Tooltip } from 'floating-vue';
import { useModal } from 'vue-final-modal';

import api from '@/services/api';
import {
  AppAlert,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  AppButton,
  FontIcon,
  FormInput,
  FormLabel,
  AppPagination,
  AppLoader,
  AppCollapse,
  ColumnSettings,
  HelpInformation,
  ConfirmModal,
  AppLink,
  CommentModal,
  EmptyValue,
} from '@/components';
import useLoader from '@/composables/useLoader';
import useColumnSettings from '@/composables/useColumnSettings';
import { ConfirmDialogConfirmParams } from '@/types/Common';
import { useTitle } from '@vueuse/core';
import toast from '@/services/toast';
import { IContactListResource } from '@/types/Contact';
import route from 'ziggy-js';

const { t } = useI18n({ useScope: 'global' });
const loader = useLoader();

const contacts = ref<IContactListResource[]>([]);

const page = useRouteQuery<number>('page', 1, { transform: Number });
const perPage = ref(20);
const total = ref(0);

const { columns, isColumnEnable, enabledColumns } = useColumnSettings('contacts_columns_v2', [
  'primary_contact',
  'company_links',
  'id_check_result',
  'is_in_sanctions_list',
  'is_pep',
  'comment',
]);

const name = useRouteQuery<string>('name', '');

async function resetFilters() {
  name.value = '';
  await onFilter();
}

function onDelete(contact: IContactListResource) {
  const { open, close, destroy } = useModal({
    component: ConfirmModal,
    attrs: {
      title: t('contact.confirm.destroy.title'),
      message: t('contact.confirm.destroy.text', {
        name: contact.short_name ?? contact.name,
      }),
      async onConfirm({ setLoading }: ConfirmDialogConfirmParams) {
        setLoading(true);
        try {
          await api.contacts.delete(contact.uuid);
          contacts.value = contacts.value.filter(({ uuid }) => contact.uuid !== uuid);
          if (contacts.value.length === 0 && page.value > 1) {
            page.value -= 1;
          }
          await close();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

async function getContacts() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('page', page.value.toString());
    if (name.value) searchParams.append('name', name.value);
    const response = await api.contacts.index({ searchParams });
    contacts.value = response.data;
    perPage.value = response.meta.per_page;
    total.value = response.meta.total;
  } catch (error) {
    console.error(error);
  }
}

async function onComment(contact: IContactListResource) {
  const { open, close, destroy } = useModal({
    component: CommentModal,
    attrs: {
      title: contact.short_name ?? contact.name,
      label: t('common.comment'),
      required: false,
      initialComment: contact.comment ?? '',
      action: t('common.save'),
      async onSubmit({ setLoading }: ConfirmDialogConfirmParams, comment: string) {
        setLoading(true);
        try {
          await api.contacts.update(contact.uuid, { comment });
          contact.comment = comment;
          toast.success(t('common.updated'));
          await close();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onCancel() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  await open();
}

async function onFilter() {
  loader.start();
  page.value = 1;
  await getContacts();
  loader.finish();
}

onMounted(async () => {
  loader.start();
  await getContacts();
  loader.finish();
});

watch(page, async () => {
  loader.start();
  await getContacts();
  loader.finish();
});

const title = useTitle(computed(() => t('contact.index.title')));
</script>

<template>
  <div class="container-fluid">
    <div class="d-flex align-items-center">
      <div class="d-flex align-items-end">
        <h1 class="mb-0" v-text="title" />
        <HelpInformation class="ml-1" translation="contact.index.help" />
      </div>
      <Dropdown class="ml-auto" placement="bottom-end" :distance="10">
        <AppButton class="ml-2" light circle>
          <FontIcon name="table-options" />
        </AppButton>
        <template #popper>
          <ColumnSettings
            v-model="enabledColumns"
            :columns="columns"
            :get-label="(columnName) => t(`contact.attributes.${columnName}`)"
          />
        </template>
      </Dropdown>
    </div>
    <AppCollapse class="my-3" :title="t('common.filters')">
      <form @submit.prevent="onFilter" class="my-3">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 align-items-end">
          <!-- Customer name or number -->
          <div class="form-group col">
            <FormLabel html-for="filter_search">
              {{ t('contact.filters.search') }}
            </FormLabel>
            <FormInput
              type="search"
              v-model="name"
              id="filter_search"
              icon="search"
              :disabled="loader.isLoading.value"
            />
          </div>
        </div>
        <div>
          <AppButton :disabled="loader.isLoading.value">
            {{ t('common.apply_filters') }}
          </AppButton>
          <AppButton class="ml-2" light @click.prevent="resetFilters" :disabled="loader.isLoading.value">
            {{ t('common.reset_filters') }}
          </AppButton>
        </div>
      </form>
    </AppCollapse>
    <div v-if="loader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <template v-else>
      <AppAlert v-if="contacts.length === 0">
        {{ t('contact.empty') }}
      </AppAlert>
      <template v-else>
        <AppTable hoverable>
          <AppTableHead>
            <AppTableTr>
              <AppTableTh nowrap>{{ t('contact.attributes.name') }}</AppTableTh>
              <AppTableTh v-if="isColumnEnable('primary_contact')">
                {{ t('contact.attributes.primary_contact') }}
              </AppTableTh>
              <AppTableTh nowrap>{{ t('contact.attributes.email') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('contact.attributes.phone') }}</AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('company_links')">
                {{ t('contact.attributes.company_links') }}
              </AppTableTh>
              <AppTableTh class="text-center" nowrap v-if="isColumnEnable('id_check_result')">
                {{ t('contact.attributes.id_check_result') }}
                <FontIcon name="info-square-rounded" v-tooltip.top="t('contact.tooltip.id_check_result')" />
              </AppTableTh>
              <AppTableTh class="text-center" nowrap v-if="isColumnEnable('is_in_sanctions_list')">
                {{ t('contact.attributes.is_in_sanctions_list') }}
                <FontIcon name="info-square-rounded" v-tooltip.top="t('contact.tooltip.is_in_sanctions_list')" />
              </AppTableTh>
              <AppTableTh class="text-center" nowrap v-if="isColumnEnable('is_pep')">
                {{ t('contact.attributes.is_pep') }}
                <FontIcon name="info-square-rounded" v-tooltip.top="t('contact.tooltip.is_pep')" />
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('comment')">
                {{ t('contact.attributes.comment') }}
              </AppTableTh>
              <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
            </AppTableTr>
          </AppTableHead>
          <AppTableBody>
            <AppTableTr v-for="contact in contacts" :key="contact.uuid">
              <AppTableTd>
                <strong v-text="contact.short_name ?? contact.name" />
              </AppTableTd>
              <AppTableTd v-if="isColumnEnable('primary_contact')">
                <span v-if="contact.responsible_user" v-text="contact.responsible_user.name" />
                <EmptyValue v-else />
              </AppTableTd>
              <AppTableTd nowrap>
                <AppLink v-if="contact.email" :value="contact.email" mode="email" />
                <EmptyValue v-else />
              </AppTableTd>
              <AppTableTd nowrap>
                <AppLink v-if="contact.phone" :value="contact.phone" mode="phone" />
                <EmptyValue v-else />
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('company_links')">
                <EmptyValue v-if="contact.attached_to_companies.length === 0" />
                <span
                  v-else-if="contact.attached_to_companies.length === 1"
                  v-text="contact.attached_to_companies[0].name"
                />
                <Tooltip class="d-inline-block" v-else placement="bottom">
                  <RouterLink
                    :to="{
                      name: 'clients.index',
                      query: { contact: contact.uuid, show_only_active: 0 },
                    }"
                    target="_blank"
                  >
                    {{ contact.attached_to_companies.length }}
                  </RouterLink>
                  <template #popper>
                    <div v-for="client in contact.attached_to_companies" :key="client.uuid" v-text="client.name" />
                  </template>
                </Tooltip>
              </AppTableTd>
              <AppTableTd class="text-center" v-if="isColumnEnable('id_check_result')">
                <FontIcon class="text-neutral-300" v-if="contact.id_check_result === null" name="circle" />
                <FontIcon
                  :class="contact.id_check_result ? 'text-success-500' : 'text-danger-500'"
                  v-else
                  name="circle-filled"
                />
              </AppTableTd>
              <AppTableTd class="text-center" v-if="isColumnEnable('is_in_sanctions_list')">
                <FontIcon class="text-neutral-300" v-if="contact.is_in_sanctions_list === null" name="circle" />
                <FontIcon
                  :class="contact.is_in_sanctions_list ? 'text-danger-500' : 'text-success-500'"
                  v-else
                  name="circle-filled"
                />
              </AppTableTd>
              <AppTableTd class="text-center" v-if="isColumnEnable('is_pep')">
                <FontIcon class="text-neutral-300" v-if="contact.is_pep === null" name="circle" />
                <FontIcon
                  :class="contact.is_pep ? 'text-danger-500' : 'text-success-500'"
                  v-else
                  name="circle-filled"
                />
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('comment')">
                <FontIcon
                  @click="onComment(contact)"
                  class="pointer text-2"
                  :class="contact.comment ? 'text-neutral-600' : 'text-neutral-300'"
                  name="message"
                  v-tooltip.left="contact.comment"
                />
              </AppTableTd>
              <AppTableTd nowrap class="text-right">
                <div class="d-flex align-content-center justify-content-end">
                  <AppButton
                    v-tooltip.left="t('contact.tooltip.crm', { name: contact.short_name ?? contact.name })"
                    class="ml-2"
                    size="small"
                    light
                    circle
                    link
                    :href="route('system-redirects.crm.show-contact', { contact: contact.uuid })"
                    target="_blank"
                  >
                    <FontIcon name="target-arrow" />
                  </AppButton>
                  <Tooltip placement="left">
                    <span>
                      <AppButton
                        @click.stop="onDelete(contact)"
                        class="ml-2"
                        color="danger"
                        size="small"
                        light
                        circle
                        :disabled="contact.attached_to_companies.length > 0"
                      >
                        <FontIcon name="trash" />
                      </AppButton>
                    </span>
                    <template #popper>
                      {{
                        contact.attached_to_companies.length > 0
                          ? t('contact.tooltip.destroy_cause_link')
                          : t('contact.tooltip.destroy', { name: contact.short_name ?? contact.name })
                      }}
                    </template>
                  </Tooltip>
                </div>
              </AppTableTd>
            </AppTableTr>
          </AppTableBody>
        </AppTable>
        <AppPagination :per-page="perPage" :total="total" v-model="page" />
      </template>
    </template>
  </div>
</template>
