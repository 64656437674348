<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouteQuery } from '@vueuse/router';
import { useModal } from 'vue-final-modal';

import api from '@/services/api';
import {
  AppLoader,
  AppButton,
  AppAlert,
  FontIcon,
  AppTable,
  AppTableHead,
  AppTableBody,
  AppTableTr,
  AppTableTd,
  AppTableTh,
  AppPagination,
  ConfirmModal,
  DepartmentModal,
  HelpInformation,
} from '@/components';
import useLoader from '@/composables/useLoader';
import useAuthStore from '@/store/AuthStore';
import { ConfirmDialogConfirmParams } from '@/types/Common';
import { IDepartmentResource } from '@/types/Department';
import { useTitle } from '@vueuse/core';

const currentPage = useRouteQuery('page', '1', { transform: Number });
const { t } = useI18n({ useScope: 'global' });
const loader = useLoader();
const { isCustomerAdminRole, isSuperAdminRole } = useAuthStore();

const perPage = ref(20);
const total = ref(0);

const departments = ref<IDepartmentResource[]>([]);

const editable = computed(() => isSuperAdminRole || isCustomerAdminRole);

async function getDepartments() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('page', currentPage.value.toString());
    const response = await api.departments.list({ searchParams });
    departments.value = response.data;
    perPage.value = response.meta!.per_page;
    total.value = response.meta!.total;
  } catch (error) {
    console.error(error);
  }
}

function onCreate() {
  const { open, close, destroy } = useModal({
    component: DepartmentModal,
    attrs: {
      department: null,
      async onCreated() {
        await getDepartments();
        await close();
      },
      onCancel() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onEdit(department: IDepartmentResource) {
  const { open, close, destroy } = useModal({
    component: DepartmentModal,
    attrs: {
      department,
      async onUpdated() {
        await getDepartments();
        await close();
      },
      onCancel() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onDelete(department: IDepartmentResource) {
  const { open, close, destroy } = useModal({
    component: ConfirmModal,
    attrs: {
      title: t('department.confirm.destroy.title'),
      message: t('department.confirm.destroy.text', { name: department.name }),
      async onConfirm({ setLoading }: ConfirmDialogConfirmParams) {
        try {
          setLoading(true);
          await api.departments.destroy(department.id);
          if (departments.value.length === 0 && currentPage.value > 1) {
            currentPage.value -= 1;
          } else {
            await getDepartments();
          }
          await close();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

onMounted(async () => {
  loader.start();
  await getDepartments();
  loader.finish();
});

watch(currentPage, async () => {
  loader.start();
  await getDepartments();
  loader.finish();
});

const title = useTitle(computed(() => t('department.index.title')));
</script>

<template>
  <div v-if="loader.isLoading.value" class="text-center">
    <AppLoader size="large" />
  </div>
  <div class="container-fluid" v-else>
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="d-flex align-items-end">
        <h1 class="mb-0" v-text="title" />
        <HelpInformation class="ml-1" translation="department.index.help" />
      </div>
      <AppButton v-if="editable" color="secondary" @click.prevent="onCreate">
        {{ t('department.index.create') }}
      </AppButton>
    </div>
    <AppAlert v-if="departments.length === 0">{{ t('department.empty') }}</AppAlert>
    <AppTable v-else hoverable>
      <AppTableHead>
        <AppTableTr>
          <AppTableTh nowrap>{{ t('department.attributes.name') }}</AppTableTh>
          <AppTableTh v-if="editable" nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
        </AppTableTr>
      </AppTableHead>
      <AppTableBody>
        <AppTableTr v-for="department in departments" :key="department.id">
          <AppTableTd nowrap>
            <strong v-text="department.name" />
          </AppTableTd>
          <AppTableTd v-if="editable" nowrap class="text-right">
            <AppButton
              v-tooltip.left="t('department.tooltip.edit', { name: department.name })"
              @click.stop.prevent="onEdit(department)"
              size="small"
              light
              circle
            >
              <FontIcon name="pencil" />
            </AppButton>
            <AppButton
              v-tooltip.left="t('department.tooltip.destroy', { name: department.name })"
              @click.stop.prevent="onDelete(department)"
              class="ml-2"
              color="danger"
              size="small"
              light
              circle
            >
              <FontIcon name="trash" />
            </AppButton>
          </AppTableTd>
        </AppTableTr>
      </AppTableBody>
    </AppTable>
    <AppPagination :per-page="perPage" :total="total" v-model="currentPage" />
  </div>
</template>
