<script lang="ts" setup>
import { computed, MaybeRef } from 'vue';
import { useTextareaAutosize } from '@vueuse/core';

defineOptions({
  inheritAttrs: false,
});

const modelValue = defineModel<null | string>({
  required: true,
});

const { textarea, input } = useTextareaAutosize({
  input: modelValue as MaybeRef,
});

const props = defineProps<{
  size?: 'small' | 'large';
  placeholder?: string;
  groupClasses?: string;
  trim?: boolean;
  autosize?: boolean;
  invalid?: boolean;
}>();

const wrapperClasses = computed(() => [
  'form-wrapper',
  props.groupClasses ?? '',
  props.size && { [`is-${props.size}`]: props.size },
]);
</script>

<template>
  <div :class="wrapperClasses">
    <textarea
      v-if="autosize"
      ref="textarea"
      v-bind="$attrs"
      class="form-control"
      :class="{ 'textarea-autosize': autosize, 'is-invalid': props.invalid }"
      v-model="input"
    />
    <textarea
      v-else
      v-bind="$attrs"
      class="form-control"
      :class="{ 'is-invalid': props.invalid }"
      v-model="modelValue"
    />
  </div>
  <slot />
</template>
