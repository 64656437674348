import route from 'ziggy-js';
import { SearchParamsOption } from 'ky';
import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import { IInvoiceItemTemplateResource, IInvoiceItemTemplateRequestBody } from '@/types/InvoiceItemTemplate';

export default {
  async index(searchParams?: SearchParamsOption): Promise<IResponse<IInvoiceItemTemplateResource[]>> {
    return await httpClient.get(route('invoices.invoice-item-templates.index'), { searchParams }).json();
  },
  async get(invoiceItemTemplate: number): Promise<IResponse<IInvoiceItemTemplateResource>> {
    return await httpClient.get(route('invoices.invoice-item-templates.get', { invoiceItemTemplate })).json();
  },
  async update(body: IInvoiceItemTemplateRequestBody[]): Promise<IResponse<IInvoiceItemTemplateResource[]>> {
    return await httpClient.patch(route('invoices.invoice-item-templates.update'), { json: { items: body } }).json();
  },
};
