<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { InvoiceSyncStatusType } from '@/types/Invoice';

const { t } = useI18n({ useScope: 'global' });

const props = defineProps<{
  status: null | InvoiceSyncStatusType;
}>();

const color = computed(() => {
  switch (props.status) {
    case InvoiceSyncStatusType.SUCCESS:
      return 'is-success';
    case InvoiceSyncStatusType.ERROR:
      return 'is-danger';
    default:
      return '';
  }
});

const icon = computed(() => {
  switch (props.status) {
    case InvoiceSyncStatusType.SUCCESS:
      return 'ti-check';
    case InvoiceSyncStatusType.ERROR:
      return 'ti-x';
    default:
      return '';
  }
});
</script>

<template>
  <span v-if="status" class="badge" :class="color">
    <i class="ti" :class="icon" />
    {{ t(`invoice.sync_status.${status}`) }}
  </span>
</template>
