<script setup lang="ts">
import { ModalsContainer } from 'vue-final-modal';
import { storeToRefs } from 'pinia';

import { TheHeader, TheSidebar } from '@/components';
import useSidebarStore from '@/store/SidebarStore';

const sidebarStore = useSidebarStore();
const { expanded } = storeToRefs(sidebarStore);
</script>

<template>
  <div class="main-layout">
    <TheSidebar />
    <main class="entry" :class="{ expanded: !expanded }">
      <TheHeader />
      <div id="content" class="content">
        <slot />
      </div>
    </main>
  </div>
  <ModalsContainer />
  <DialogsWrapper />
</template>
