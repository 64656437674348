import { IUserListResource, IUserResource, UserRole, UserSystemRole } from '@/types/User';
import useAuthStore from '@/store/AuthStore';

const permissions = [
  // Users
  'users.update',
  'users.getWorkingDayDuration',
  'users.editInAdmin',
  'users.viewTimeline',
  'users.viewTimeBalanceTransactions',
  'users.createTimeBalanceTransaction',
  // Work rates
  'work-rates.viewAll',
  'work-rates.view',
  'work-rates.create',
  'work-rates.update',
  'work-rates.delete',
  // Contacts
  'contacts.delete',
  // Resource planning
  'resource-planning.view',
  // Internal activities
  'internal-activities.view',
] as const;

export default function usePermissions() {
  const { authenticatedUser } = useAuthStore();
  // Users
  function can(permission: 'users.update'): boolean;
  function can(permission: 'users.editInAdmin'): boolean;
  function can(permission: 'users.viewTimeline', user: IUserResource | IUserListResource): boolean;
  function can(permission: 'users.viewTimeBalanceTransactions', user: IUserResource | IUserListResource): boolean;
  function can(permission: 'users.createTimeBalanceTransaction', user: IUserResource | IUserListResource): boolean;

  // Work rates
  function can(permission: 'work-rates.viewAll'): boolean;
  function can(permission: 'work-rates.view'): boolean;
  function can(permission: 'work-rates.create'): boolean;
  function can(permission: 'work-rates.update'): boolean;
  function can(permission: 'work-rates.delete'): boolean;

  // Contacts
  function can(permission: 'contacts.delete', isContactManualCreated: boolean): boolean;

  // Resource planning
  function can(permission: 'resource-planning.view'): boolean;

  // Internal activities
  function can(permission: 'internal-activities.view'): boolean;

  function can(permission: (typeof permissions)[number], ...args: unknown[]) {
    // Users
    if (permission === 'users.editInAdmin' || permission === 'users.update') {
      return [UserRole.SuperAdmin, UserRole.CustomerAdmin].includes(authenticatedUser.role);
    } else if (permission === 'users.viewTimeline' || permission === 'users.viewTimeBalanceTransactions') {
      const [user] = args as [IUserResource];
      return (
        [UserRole.SuperAdmin, UserRole.CustomerAdmin].includes(authenticatedUser.role) ||
        user.uuid === authenticatedUser.uuid ||
        (authenticatedUser.system_role === UserSystemRole.Manager && user.manager?.uuid === authenticatedUser.uuid)
      );
    } else if (permission === 'users.createTimeBalanceTransaction') {
      const [user] = args as [IUserResource];
      return (
        [UserRole.SuperAdmin, UserRole.CustomerAdmin].includes(authenticatedUser.role) ||
        (authenticatedUser.system_role === UserSystemRole.Manager && user.manager?.uuid === authenticatedUser.uuid)
      );
    }

    // Work rates
    else if (
      permission === 'work-rates.viewAll' ||
      permission === 'work-rates.view' ||
      permission === 'work-rates.create' ||
      permission === 'work-rates.update' ||
      permission === 'work-rates.delete'
    ) {
      return [UserRole.SuperAdmin, UserRole.CustomerAdmin].includes(authenticatedUser.role);
    }

    // Contacts
    else if (permission === 'contacts.delete') {
      const [isContactManualCreated] = args as [boolean];
      return isContactManualCreated;
    }

    // Resource planning
    else if (permission === 'resource-planning.view') {
      return (
        [UserRole.SuperAdmin, UserRole.CustomerAdmin].includes(authenticatedUser.role) ||
        authenticatedUser.system_role === UserSystemRole.Manager
      );
    }

    // Internal activities
    else if (permission === 'internal-activities.view') {
      return (
        [UserRole.SuperAdmin, UserRole.CustomerAdmin].includes(authenticatedUser.role) ||
        authenticatedUser.system_role === UserSystemRole.Manager
      );
    }
  }

  return {
    can,
  };
}
