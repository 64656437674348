export default {
  index: {
    title: 'ToDo & meddelanden',
    help: '',
    create: 'Skapa',
  },
  create: {
    title: 'Skapa ToDo',
  },
  edit: {
    title: 'Redigera ToDo',
  },
  attributes: {
    type: 'Typ',
    client: 'Kund',
    recipient: 'Mottagare',
    text: 'Text',
    deadline: 'Deadline',
    status: 'Status',
    created_by: 'Skapad av',
    updated_at: 'Uppdaterad av',
  },
  filters: {
    search: 'Sök',
    from_date: 'Från datum',
    to_date: 'Till datum',
    created_by: 'Skapad av',
    recipient: 'Mottagare',
    client: 'Kund',
    project: 'Projekt',
    type: 'Typ',
    status: 'Status',
  },
  type: {
    Info: 'Information',
    ToDo: 'Att göra',
    Message: 'Fråga',
  },
  status: {
    opened: 'Öppen',
    closed: 'Slutförd',
  },
  tooltip: {
    complete: 'Klarmarkera uppgift',
    reopen: 'Återöppna uppgift',
    edit: 'Redigera uppgift',
    destroy: 'Radera uppgift',
    show_comments: 'Visa kommentarer',
  },
  empty: 'Inga uppgifter',
  message_or_todo: 'ToDos',
  confirm: {
    destroy: {
      title: 'Radera uppgift',
      text: 'Är du säker att du vill radera uppgiften?',
    },
  },
};
