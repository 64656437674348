<script setup lang="ts">
import { FontIcon } from '@/components';
import { IClientFullResource } from '@/types/Client';
import useAuthStore from '@/store/AuthStore';
import useClients from '@/composables/useClients';

const { isEmployeeRole } = useAuthStore();
const { clientTabs } = useClients();

const {
  client,
  disabled = false,
  isUserPartOfTeam,
} = defineProps<{ client: IClientFullResource; disabled?: boolean; isUserPartOfTeam: boolean }>();
</script>

<template>
  <ul class="tabs mb-3">
    <RouterLink
      v-for="link in clientTabs"
      :key="link.title"
      custom
      v-slot="{ isExactActive, href, navigate }"
      :to="{ name: link.route, params: { uuid: client.uuid } }"
    >
      <li class="tab-item" :class="{ active: isExactActive }">
        <a
          class="tab-link"
          :class="{ disabled: disabled || (link.route === 'client.files' && isEmployeeRole && !isUserPartOfTeam) }"
          @click="navigate"
          :href="href"
        >
          <FontIcon :name="link.icon" />
          {{ link.title }}
        </a>
      </li>
    </RouterLink>
  </ul>
</template>
