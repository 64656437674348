<script setup lang="ts">
import {
  AppAlert,
  AppButton,
  AppLoader,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTh,
  AppTableTr,
  FontIcon,
} from '@/components';
import useFinancialYears from '@/composables/useFinancialYears';
import { useI18n } from 'vue-i18n';
import { onMounted, ref } from 'vue';
import { IFinancialYearListResource } from '@/types/FinancialYear';

type Props = {
  clientUuid: string;
};

const { clientUuid } = defineProps<Props>();

const {
  financialYears,
  financialYearsLoading,
  getFinancialYears,
  openFinancialYearCreateModal,
  openFinancialYearEditModal,
  openFinancialYearDeleteModal,
} = useFinancialYears(clientUuid);
const { t, d } = useI18n({ useScope: 'global' });

const SHOW_ITEMS_COUNT = 3;
const showAll = ref(false);

function onCreate() {
  openFinancialYearCreateModal({
    async onCreated(_financialYear, { close }) {
      await Promise.all([getFinancialYears(), close()]);
    },
  });
}

function onEdit(financialYear: IFinancialYearListResource) {
  openFinancialYearEditModal(financialYear, {
    async onUpdated(_financialYear, { close }) {
      await Promise.all([getFinancialYears(), close()]);
    },
  });
}

function onDelete(financialYear: IFinancialYearListResource) {
  openFinancialYearDeleteModal(financialYear, {
    async onDeleted({ close }) {
      await Promise.all([getFinancialYears(), close()]);
    },
  });
}

onMounted(() => {
  getFinancialYears({
    searchParams: {
      without_pagination: 1,
    },
  });
});
</script>

<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <h3 class="mt-4" v-text="t('financial-year.index.title')" />
      <AppButton
        @click.prevent="onCreate"
        color="success"
        circle
        light
        :disabled="financialYearsLoading"
        v-tooltip.left="t('common.add')"
      >
        <FontIcon name="plus" />
      </AppButton>
    </div>
    <div v-if="financialYearsLoading" class="text-center py-3">
      <AppLoader size="small" />
    </div>
    <template v-else>
      <AppAlert v-if="financialYears.length === 0">{{ t('common.empty') }}</AppAlert>
      <AppTable bordered v-else>
        <AppTableHead>
          <AppTableTr>
            <AppTableTh nowrap>{{ t('financial-year.attributes.start_date') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('financial-year.attributes.end_date') }}</AppTableTh>
            <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
          </AppTableTr>
        </AppTableHead>
        <AppTableBody>
          <AppTableTr
            v-for="(financialYear, idx) in showAll ? financialYears : financialYears.slice(0, SHOW_ITEMS_COUNT)"
            :key="financialYear.uuid"
          >
            <AppTableTh nowrap>{{ d(financialYear.start_date, 'long') }}</AppTableTh>
            <AppTableTh nowrap>{{ d(financialYear.end_date, 'long') }}</AppTableTh>
            <AppTableTh nowrap class="text-right">
              <AppButton
                v-tooltip.left="t('common.edit')"
                @click.stop.prevent="onEdit(financialYear)"
                size="small"
                light
                circle
                :disabled="idx !== 0"
              >
                <FontIcon name="pencil" />
              </AppButton>
              <AppButton
                @click.stop.prevent="onDelete(financialYear)"
                class="ml-2"
                color="danger"
                size="small"
                light
                circle
                :disabled="idx !== 0 || financialYear.in_use"
              >
                <FontIcon name="trash" />
              </AppButton>
            </AppTableTh>
          </AppTableTr>
        </AppTableBody>
      </AppTable>
    </template>
    <AppButton
      v-if="financialYears.length > SHOW_ITEMS_COUNT"
      class="mt-3"
      @click.prevent="showAll = !showAll"
      light
      size="small"
      :disabled="financialYearsLoading"
    >
      {{ showAll ? t('common.show_less') : t('common.show_more') }}
    </AppButton>
  </div>
</template>
