import api from '@/services/api';
import { ref } from 'vue';
import { Options } from 'ky';
import { IDepartmentResource } from '@/types/Department';

type GetDepartmentsHooks = {
  onSet?: (data: IDepartmentResource[]) => IDepartmentResource[];
  onError?: (error: unknown) => void;
  onFinally?: () => void;
};

export default function useDepartments() {
  const departments = ref<IDepartmentResource[]>([]);
  const departmentsLoading = ref(false);

  async function getDepartments(options?: Options, hooks?: GetDepartmentsHooks) {
    try {
      departmentsLoading.value = true;
      const response = await api.departments.list(options);
      departments.value = hooks?.onSet ? hooks.onSet(response.data) : response.data;
    } catch (error) {
      console.error(error);
      if (hooks?.onError) {
        hooks?.onError(error);
      }
    } finally {
      departmentsLoading.value = false;
      if (hooks?.onFinally) {
        hooks?.onFinally();
      }
    }
  }
  return {
    departments,
    departmentsLoading,
    getDepartments,
  };
}
