<script setup lang="ts">
import { ref } from 'vue';
import { VueFinalModal } from 'vue-final-modal';
import { useI18n } from 'vue-i18n';
import { AppButton, FormLabel, FormSwitch } from '@/components';

const changeFutureResponsible = ref(false);

const emit = defineEmits<{
  confirm: [params: { changeFutureResponsible: boolean }];
  closed: [];
}>();

const { t } = useI18n({ useScope: 'global' });

function confirm() {
  emit('confirm', { changeFutureResponsible });
}
</script>
<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container"
    :click-to-close="false"
    :esc-to-close="true"
    :focus-trap="{
      initialFocus: '#confirm',
      allowOutsideClick: true,
      escapeDeactivates: false,
      returnFocusOnDeactivate: true,
    }"
    v-slot="{ close }"
    @closed="emit('closed')"
    @keydown.ctrl.enter.stop="confirm"
  >
    <div class="modal-header">
      <h2 class="mb-0" v-text="t('event.confirm.change_future_responsible.title')" />
    </div>
    <div class="modal-content">
      <p v-html="t('event.confirm.change_future_responsible.text')" />
      <div class="row">
        <div class="col-4">
          <FormLabel class="flex-shrink-0" html-for="change_future_responsible" required>
            {{ t('time-entry.modal.attributes.change_future_responsible') }}
          </FormLabel>
        </div>
        <div class="col">
          <FormSwitch group-class="mb-0" id="change_future_responsible" v-model="changeFutureResponsible" />
        </div>
      </div>
      <slot />
    </div>
    <div class="modal-footer">
      <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
        <AppButton light @click="close">
          {{ t('common.cancel') }}
        </AppButton>
        <AppButton
          id="confirm"
          color="secondary"
          class="mt-2 mt-sm-2"
          @click="emit('confirm', { changeFutureResponsible })"
        >
          {{ t('common.confirm') }}
        </AppButton>
      </div>
    </div>
  </VueFinalModal>
</template>
