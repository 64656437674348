<script setup lang="ts">
import { AppAlert, AppButton, AppCloseButton, AppModal, UserTodoComments } from '@/components';
import { useI18n } from 'vue-i18n';
import { ITodoCommentResource } from '@/types/Todo';
import { ref } from 'vue';
import useModalFeatures from '@/composables/useModalFeatures';

type Props = {
  todoId: number;
  comments: ITodoCommentResource[];
};

const { todoId, comments } = defineProps<Props>();

const emit = defineEmits<{
  addComment: [text: string];
  close: [];
  closed: [];
}>();

const { t } = useI18n({ useScope: 'global' });
const { onCtrlEnter } = useModalFeatures();
const showCommentForm = ref(true);

onCtrlEnter(() => {
  emit('close');
});
</script>

<template>
  <AppModal @closed="emit('closed')" v-slot="{ close }">
    <div class="modal-header">
      <h2 v-text="t('common.comments')" />
      <AppCloseButton @close="close" />
    </div>
    <div class="modal-content">
      <AppAlert v-if="comments.length === 0">{{ t('common.empty') }}</AppAlert>
      <UserTodoComments
        :comments="comments.toReversed()"
        :todo-id="todoId"
        :form-visible="showCommentForm"
        :hide-form-after-send-comment="false"
        @commented="(text) => emit('addComment', text)"
      />
      <slot />
    </div>
    <div class="modal-footer">
      <div class="d-flex">
        <AppButton light @click.prevent="close">
          {{ t('common.close') }}
        </AppButton>
      </div>
    </div>
  </AppModal>
</template>
