<script setup lang="ts">
import { onMounted, ref, reactive, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useModal } from 'vue-final-modal';
import VueSelect, { VueSelectInstance } from 'vue-select';
import { DateTime } from 'luxon';

import {
  AppLoader,
  AppButton,
  AppTable,
  AppTableHead,
  AppTableTh,
  AppTableTr,
  FormLabel,
  AppTableTd,
  AppTableBody,
  FontIcon,
  PriceMatrixModal,
  AppAlert,
  HelpInformation,
  AppCollapse,
} from '@/components';
import { IOfficeListResource } from '@/types/Office';
import api from '@/services/api';
import { IUserListResource } from '@/types/User';
import { IDepartmentResource } from '@/types/Department';
import { IWorkTitleResource } from '@/types/WorkTitle';
import useLoader from '@/composables/useLoader';
import { IPriceMatrixServiceResource, IPriceMatrixUserPrice, IPriceMatrixUserResource } from '@/types/PriceMatrix';
import useServices from '@/composables/useServices';
import { IServicePreviewResource, IServicePriceAdjustmentListResource } from '@/types/Service';
import { useTitle } from '@vueuse/core';

const { t, n } = useI18n({ useScope: 'global' });
const loader = useLoader();
const { services, getServices } = useServices();

const users = ref<IUserListResource[]>([]);
const offices = ref<IOfficeListResource[]>([]);
const departments = ref<IDepartmentResource[]>([]);
const workTitles = ref<IWorkTitleResource[]>([]);

const filterUserDropdown = ref<VueSelectInstance | null>(null);
const filterServiceDropdown = ref<VueSelectInstance | null>(null);
const filterOfficeDropdown = ref<VueSelectInstance | null>(null);
const filterDepartmentDropdown = ref<VueSelectInstance | null>(null);
const filterWorkTitleDropdown = ref<VueSelectInstance | null>(null);

const priceMatrixUsers = ref<IPriceMatrixUserResource[]>([]);
const priceMatrixServices = ref<IPriceMatrixServiceResource[]>([]);

const filters = reactive<{
  users_uuid: string[];
  offices_id: number[];
  departments_id: number[];
  work_titles_id: number[];
  services_uuid: IServicePreviewResource[];
}>({
  users_uuid: [],
  offices_id: [],
  departments_id: [],
  work_titles_id: [],
  services_uuid: [],
});

async function getUsers() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.users.list({ searchParams });
    users.value = response.data;
  } catch (error) {
    console.error(error);
  }
}
async function getOffices() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.offices.list({ searchParams });
    offices.value = response.data;
  } catch (error) {
    console.error(error);
  }
}
async function getDepartments() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.departments.list({ searchParams });
    departments.value = response.data;
  } catch (error) {
    console.error(error);
  }
}
async function getWorkTitles() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.workTitles.list({ searchParams });
    workTitles.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

async function getPriceMatrix() {
  try {
    const searchParams = new URLSearchParams();
    filters.users_uuid.forEach((uuid) => searchParams.append('users_uuid[]', uuid));
    filters.offices_id.forEach((id) => searchParams.append('offices_id[]', id.toString()));
    filters.departments_id.forEach((id) => searchParams.append('departments_id[]', id.toString()));
    filters.work_titles_id.forEach((id) => searchParams.append('work_titles_id[]', id.toString()));
    filters.services_uuid.forEach(({ uuid }) => searchParams.append('services_uuid[]', uuid));
    const response = await api.priceMatrix.list({ searchParams });
    priceMatrixUsers.value = response.data.users;
    priceMatrixServices.value = response.data.services;
  } catch (error) {
    console.error(error);
  }
}

onMounted(async () => {
  loader.start();
  await Promise.all([
    getPriceMatrix(),
    getUsers(),
    getOffices(),
    getDepartments(),
    getWorkTitles(),
    getServices({
      searchParams: {
        without_pagination: 1,
      },
    }),
  ]);
  loader.finish();
});

async function onFilter() {
  loader.start();
  await getPriceMatrix();
  loader.finish();
}

async function resetFilters() {
  filters.users_uuid = [];
  filterUserDropdown.value!.search = '';
  filters.services_uuid = [];
  filterServiceDropdown.value!.search = '';
  filters.offices_id = [];
  filterOfficeDropdown.value!.search = '';
  filters.departments_id = [];
  filterDepartmentDropdown.value!.search = '';
  filters.work_titles_id = [];
  filterWorkTitleDropdown.value!.search = '';
  await onFilter();
}

const createModal = useModal({
  component: PriceMatrixModal,
  attrs: {
    async onUpdate() {
      await getPriceMatrix();
    },
    onCancel() {
      createModal.close();
    },
    // @ts-ignore
    onClosed() {
      getPriceMatrix();
      // @ts-ignore
      createModal.patchOptions({ attrs: { loading: false } });
    },
  },
});

async function onCreate(service: IPriceMatrixServiceResource, userPrice: IPriceMatrixUserPrice) {
  createModal.patchOptions({
    attrs: {
      title: t('price-matrix.modal.title'),
      adjustment: false,
      prices: userPrice.price_history,
      serviceUuid: service.uuid,
      userUuid: userPrice.uuid,
    },
  });
  await createModal.open();
}

async function updatePriceAdjustment(service: IPriceMatrixServiceResource) {
  createModal.patchOptions({
    // @ts-ignore
    attrs: {
      title: t('price-matrix.modal.title'),
      serviceUuid: service.uuid,
      prices: service.default_hourly_price_adjustment_list,
      adjustment: true,
    },
  });
  await createModal.open();
}

function getColor(fixedPrice: number, price: number, userUuid: string) {
  const userHourlyPrice = priceMatrixUsers.value.find((item) => item.uuid === userUuid)?.hourly_price ?? 0;
  const fixedPriceWithHourlyPrice = fixedPrice + userHourlyPrice;

  if (fixedPriceWithHourlyPrice !== price && price !== 0) {
    return fixedPriceWithHourlyPrice < price ? 'text-success-500' : 'text-danger-500';
  }
}

function showIsPriceIsScheduled(prices: IServicePriceAdjustmentListResource[]) {
  return prices.some((item) => DateTime.fromISO(item.start_date) > DateTime.now());
}

const title = useTitle(computed(() => t('price-matrix.index.title')));
</script>

<template>
  <div class="container-fluid">
    <div class="d-flex align-items-end mb-3">
      <h1 class="mb-0" v-text="title" />
      <HelpInformation class="ml-1" translation="price-matrix.help.title" />
    </div>
    <AppCollapse class="my-3" :title="t('common.filters')">
      <form @submit.prevent="onFilter">
        <div class="row row-cols-sm-2 row-cols-md-3">
          <div class="col form-group">
            <FormLabel html-for="filter_user">
              {{ t('price-matrix.filter.user') }}
            </FormLabel>
            <VueSelect
              :reduce="(option: any) => option.uuid"
              v-model="filters.users_uuid"
              :options="users"
              label="name"
              input-id="user"
              :placeholder="t('common.search')"
              multiple
              ref="filterUserDropdown"
            />
          </div>
          <div class="col form-group">
            <FormLabel html-for="service">{{ t(`project.attributes.service`) }}</FormLabel>
            <VueSelect
              v-model="filters.services_uuid"
              :options="services"
              label="name"
              input-id="service"
              :placeholder="t('common.select')"
              multiple
              ref="filterServiceDropdown"
            />
          </div>
          <div class="col form-group">
            <FormLabel html-for="office">
              {{ t('user.attributes.office') }}
            </FormLabel>
            <VueSelect
              v-model="filters.offices_id"
              :reduce="(option: any) => option.id"
              :options="offices"
              label="name"
              input-id="office"
              :placeholder="t('common.search')"
              multiple
              ref="filterOfficeDropdown"
            />
          </div>
          <div class="col form-group">
            <FormLabel html-for="department">
              {{ t('user.attributes.department') }}
            </FormLabel>
            <VueSelect
              v-model="filters.departments_id"
              :reduce="(option: any) => option.id"
              :options="departments"
              label="name"
              input-id="department"
              :placeholder="t('common.select')"
              multiple
              ref="filterDepartmentDropdown"
            />
          </div>
          <div class="col form-group">
            <FormLabel html-for="work_title">
              {{ t('price-matrix.attributes.work_title') }}
            </FormLabel>
            <VueSelect
              v-model="filters.work_titles_id"
              :reduce="(option: any) => option.id"
              :options="workTitles"
              label="name"
              input-id="work_title"
              :placeholder="t('common.search')"
              multiple
              ref="filterWorkTitleDropdown"
            />
          </div>
        </div>
        <div>
          <AppButton :disabled="loader.isLoading.value">
            {{ t('common.apply_filters') }}
          </AppButton>
          <AppButton class="ml-2" light @click.prevent="resetFilters" :disabled="loader.isLoading.value">
            {{ t('common.reset_filters') }}
          </AppButton>
        </div>
      </form>
    </AppCollapse>
    <div v-if="loader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <template v-else>
      <AppAlert v-if="priceMatrixServices.length === 0">
        {{ t('common.no_results') }}
      </AppAlert>
      <AppTable v-else hoverable>
        <AppTableHead>
          <AppTableTr>
            <AppTableTh nowrap>{{ t('service.attributes.name') }}</AppTableTh>
            <AppTableTh>{{ t('service.attributes.default_fixed_price') }}</AppTableTh>
            <AppTableTh>{{ t('service.attributes.default_price_adjustment') }}</AppTableTh>
            <AppTableTh v-for="user in priceMatrixUsers" :key="user.uuid">
              {{ user.name }} ({{ n(user.hourly_price ?? 0, 'currency') }})
            </AppTableTh>
          </AppTableTr>
        </AppTableHead>
        <AppTableBody>
          <AppTableTr v-for="service in priceMatrixServices" :key="service.uuid">
            <AppTableTd nowrap><strong v-text="service.name" /></AppTableTd>
            <AppTableTd nowrap>{{ n(service.default_fixed_price ?? 0, 'currency') }}</AppTableTd>
            <AppTableTd nowrap>
              <div class="d-flex align-items-center">
                <u
                  @click.prevent="updatePriceAdjustment(service)"
                  class="pointer"
                  :class="{
                    'text-success-500': service.default_hourly_price_adjustment > 0,
                    'text-danger-500': service.default_hourly_price_adjustment < 0,
                  }"
                >
                  {{ n(service.default_hourly_price_adjustment ?? 0, 'currency') }}
                </u>
                <FontIcon
                  class="ml-2 text-3"
                  v-tooltip="t('price-matrix.tooltip.price_change_is_scheduled')"
                  name="alarm"
                  v-if="showIsPriceIsScheduled(service.default_hourly_price_adjustment_list)"
                />
              </div>
            </AppTableTd>
            <AppTableTd color="secondary" nowrap v-for="userPrice in service.users_prices" :key="userPrice.uuid">
              <div class="d-flex align-items-center">
                <span @click.prevent="onCreate(service, userPrice)">
                  <span
                    v-if="!userPrice.is_original"
                    class="pointer price-underline"
                    :class="getColor(service.default_hourly_price_adjustment, userPrice.price, userPrice.uuid)"
                  >
                    {{ n(userPrice.price ?? 0, 'currency') }}
                  </span>
                  <span v-else class="pointer">{{ n(userPrice.price ?? 0, 'currency') }}</span>
                </span>
                <FontIcon
                  class="ml-2 text-3"
                  v-tooltip="t('price-matrix.tooltip.price_change_is_scheduled')"
                  name="alarm"
                  v-if="showIsPriceIsScheduled(userPrice.price_history)"
                />
              </div>
            </AppTableTd>
          </AppTableTr>
        </AppTableBody>
      </AppTable>
    </template>
  </div>
</template>
