export default {
  index: {
    title: 'Checkpoints',
    help: '',
    filters: {
      project_responsible: 'Projektansvarig',
      customer_responsible: 'Kundansvarig',
      from_date: 'Period från',
      to_date: 'Period till',
      services: 'Tjänster',
      hide_done: 'Dölj klara',
    },
  },
  mode: {
    calendar: 'Kalendervy',
    list: 'Listvy',
  },
  attributes: {
    name: 'Namn',
    client: 'Kund',
    service: 'Tjänst',
    project: 'Projekt',
    customer_responsible: 'Kundansvarig',
    project_responsible: 'Projektansvarig',
    period: 'Period',
    status: 'Status',
    comment: 'Kommentar',
    frequency: 'Frekvens',
    checkpoint: 'Checkpoint',
  },
  state: {
    n_a: 'Ej satt',
    done: 'Klar',
    not_done: 'Ej klar',
  },
  events_status: {
    done: 'Klart ({completed}/{total})',
    not_done: 'Ej klart ({completed}/{total})',
  },
  edit: {
    title: 'Checkpoint',
  },
  empty: 'Använder filter för att visa data.',
};
