<script setup lang="ts">
import VueSelect from 'vue-select';
import { Decimal } from 'decimal.js';
import { useI18n } from 'vue-i18n';

import { FormLabel } from '@/components';
import { IVehicleResource, VehicleType } from '@/types/User';

type Props = {
  vehicles: IVehicleResource[];
  vehiclesLoading: boolean;
  disabled: boolean;
  paymentKmBeforeTaxEmployeeCarOther: number;
  paymentKmBeforeTaxOwnCar: number;
  paymentKmBeforeTaxEmployeeCarDiesel: number;
  paymentKmBeforeTaxEmployeeCarElectric: number;
};

const {
  vehicles,
  vehiclesLoading,
  disabled,
  paymentKmBeforeTaxOwnCar,
  paymentKmBeforeTaxEmployeeCarOther,
  paymentKmBeforeTaxEmployeeCarDiesel,
  paymentKmBeforeTaxEmployeeCarElectric,
} = defineProps<Props>();

const { t } = useI18n({ useScope: 'global' });

const visibility = defineModel<boolean>('visibility', { required: true });
const vehicleId = defineModel<null | number>('vehicleId', { required: true });
const mileage = defineModel<number>('mileage', { required: true });
const amount = defineModel<number>('amount', { required: true });

function getMileageAmountCalculated(kilometers: number): number {
  let result = 0;

  if (vehicleId.value != null) {
    const vehicleSelected = vehicles.find((x) => x.id === vehicleId.value);
    if (vehicleSelected) {
      const vehicleType = vehicleSelected.type;
      switch (vehicleType) {
        case VehicleType.PRIVATE_CAR:
          result = paymentKmBeforeTaxOwnCar * kilometers;
          break;
        case VehicleType.COMPANY_CAR_DIESEL:
          result = paymentKmBeforeTaxEmployeeCarDiesel * kilometers;
          break;
        case VehicleType.COMPANY_CAR_ELECTRIC:
          result = paymentKmBeforeTaxEmployeeCarElectric * kilometers;
          break;
        case VehicleType.COMPANY_CAR_PETROL:
          result = paymentKmBeforeTaxEmployeeCarOther * kilometers;
          break;
        default:
          result = 0;
          break;
      }
    }
  }
  return result;
}
</script>

<template>
  <!-- Mileage allowance -->
  <div class="form-group">
    <div class="row align-items-center">
      <div class="col-md-4">
        <FormLabel html-for="visit_is_booked">
          {{ t('time-entry.modal.attributes.add_mileage_allowance') }}
        </FormLabel>
      </div>
      <div class="col-md-8">
        <div class="form-group">
          <input id="mileage_allowance" type="checkbox" class="form-switch" v-model="visibility" :disabled="disabled" />
          <label for="mileage_allowance" class="form-label" v-text="'&nbsp;'" />
        </div>
      </div>
    </div>
  </div>
  <template v-if="visibility">
    <!-- Vehicle -->
    <div class="form-group">
      <div class="row align-items-center">
        <div class="col-md-4">
          <FormLabel html-for="vehicles" required>
            {{ t('time-entry.modal.attributes.vehicle') }}
          </FormLabel>
        </div>
        <div class="col-md-8">
          <VueSelect
            :clearable="false"
            v-model="vehicleId"
            :options="vehicles"
            input-id="vehicles"
            :placeholder="t('common.select')"
            label="name"
            :reduce="(option: IVehicleResource) => option.id"
            :disabled="disabled"
            :loading="vehiclesLoading"
          />
        </div>
      </div>
    </div>
    <!-- Mileage -->
    <div class="form-group">
      <div class="row align-items-center">
        <div class="col-md-4">
          <FormLabel html-for="mileage" required>
            {{ t('time-entry.modal.attributes.mileage') }}
          </FormLabel>
        </div>
        <div class="col-md-8">
          <div class="form-wrapper has-icon">
            <i class="form-icon ti ti-road" />
            <input
              id="mileage"
              type="text"
              :value="mileage"
              @change="(event) => {
                const input = event.target as HTMLInputElement;
                const value = new Decimal(input.value ? input.value : 0).toDecimalPlaces(2).abs();
                mileage = value.toNumber();
                input.value = value.toNumber().toString();
                amount = getMileageAmountCalculated(value.toNumber());
              }"
              class="form-control"
              min="0"
              step=".01"
              :disabled="disabled"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Reimbursement -->
    <div class="form-group">
      <div class="row align-items-center">
        <div class="col-md-4">
          <FormLabel html-for="reimbursement" required>
            {{ t('time-entry.modal.attributes.reimbursement') }}
          </FormLabel>
        </div>
        <div class="col-md-8">
          <div class="form-wrapper has-icon">
            <i class="form-icon ti ti-currency-krone-swedish" />
            <input :value="amount" class="form-control" :disabled="disabled" readonly />
          </div>
        </div>
      </div>
    </div>
  </template>
</template>
