<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { vOnClickOutside } from '@vueuse/components';
import { useMagicKeys } from '@vueuse/core';

const { escape } = useMagicKeys();

const active = ref<boolean>(false);

const props = defineProps<{
  color?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger';
  size?: 'tiny' | 'small' | 'medium' | 'large';
  loading?: boolean;
  circle?: boolean;
  light?: boolean;
  groupClass?: string;
  buttonClass?: string;
  menuClass?: string;
  position?: 'bottom-right' | 'bottom-left';
}>();

const groupClasses = computed(() => ['dropdown', props.groupClass]);

const menuClasses = computed(() => [
  'dropdown-menu',
  props.menuClass,
  { active: active.value },
  props.position || 'bottom-right',
]);

const buttonClasses = computed(() => [
  'button',
  props.buttonClass,
  props.color && { [`is-${props.color}`]: props.color },
  props.size && { [`is-${props.size}`]: props.size },
  { 'is-light': props.light },
  { 'is-circle': props.circle },
]);

watch(escape, (v) => {
  if (v && active.value) {
    close();
  }
});

function toggle() {
  active.value = !active.value;
}
function close() {
  active.value = false;
}
</script>

<template>
  <div :class="groupClasses">
    <button @click.stop="toggle" :class="buttonClasses">
      <slot name="button" />
    </button>
    <div v-if="active" :class="menuClasses" v-on-click-outside.bubble="close">
      <slot name="menu" />
    </div>
  </div>
</template>
