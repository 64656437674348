<script setup lang="ts">
import { onMounted, ref, computed, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal, useModal } from 'vue-final-modal';
import { DateTime } from 'luxon';
import VueDatePicker from '@vuepic/vue-datepicker';
import api from '@/services/api';
import {
  AppButton,
  AppCloseButton,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  AppPagination,
  AppLoader,
  AppAlert,
  FontIcon,
  ConfirmModal,
  FormLabel,
  FormNumber,
  FormSwitch,
  UserFlexibleWorkRatesTable,
} from '@/components';
import { IWorkRateRequest, IWorkRateResource, IWorkRateWeekDay } from '@/types/WorkRate';
import { ConfirmDialogConfirmParams } from '@/types/Common';
import { ITimePeriodResource } from '@/types/TimePeriod';
import useDayDurationStore from '@/store/DayDurationStore';
import { klona } from 'klona';
import { Decimal } from 'decimal.js';

type Props = {
  userUuid: string;
  timePeriods: ITimePeriodResource[];
};

const { timePeriods, userUuid } = defineProps<Props>();

const emit = defineEmits<{
  closed: [];
}>();

const { t, d, locale } = useI18n({ useScope: 'global' });

const page = ref(1);
const perPage = ref(8);
const total = ref(0);

const workRates = ref<IWorkRateResource[]>([]);
const workRatesLoading = ref(false);
const workRateLoading = ref(false);

const paginated = computed(() => {
  const offset = (page.value - 1) * perPage.value;
  return workRates.value.slice(offset, offset + perPage.value);
});

async function getWorkRates() {
  try {
    workRatesLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.users.workRates.index(userUuid, { searchParams });
    workRates.value = response.data;
    total.value = response.data.length;
  } catch (error) {
    console.error(error);
  } finally {
    workRatesLoading.value = false;
  }
}

const form = reactive<Omit<IWorkRateRequest, 'week_days'>>({
  date: null,
  rate: 100,
  is_flexible: false,
  is_flexible_week: true,
});

const weeksDaysEven = ref<IWorkRateWeekDay[]>([]);
const weeksDaysOdd = ref<IWorkRateWeekDay[]>([]);

const { defaultWorkingHours, customDayDuration } = useDayDurationStore();

const workingHours = computed(() => {
  if (form.date) {
    const possibleHours = customDayDuration.find(({ months }) =>
      months.includes(DateTime.fromISO(form.date as string).month),
    );
    return possibleHours?.hours ?? defaultWorkingHours;
  }
  return defaultWorkingHours;
});

const workRateDisabled = computed(() => !form.date || form.rate === null);

const minDate = computed(() => {
  if (timePeriods.length === 0) return '';
  const lastClosedTimePeriodIndex = timePeriods.findIndex(({ status }) => status === 'closed');
  if (lastClosedTimePeriodIndex >= 0) {
    const { month, year } = timePeriods.at(lastClosedTimePeriodIndex)!;
    return DateTime.fromObject({ day: 1, month, year }).plus({ months: 1 }).toJSDate();
  }
  const { month, year } = timePeriods.at(-1)!;
  return DateTime.fromObject({ day: 1, month, year }).toJSDate();
});

async function submit() {
  workRateLoading.value = true;
  try {
    const workRate = workRates.value.find((workRate) => workRate.date === form.date);
    const data: IWorkRateRequest = {
      ...form,
      rate: form.is_flexible
        ? [...weeksDaysEven.value, ...weeksDaysOdd.value]
            .filter((item) => item.day <= 5)
            .reduce((total, item) => total.plus(item.rate), new Decimal(0))
            .div(form.is_flexible_week ? 5 : 10)
            .toDecimalPlaces(2)
            .toNumber()
        : form.rate,
      is_flexible_week: !form.is_flexible_week,
      week_days: [...weeksDaysEven.value, ...weeksDaysOdd.value],
    };
    if (workRate) {
      await api.users.workRates.update(userUuid, workRate.id, data);
    } else {
      await api.users.workRates.store(userUuid, data);
    }
    form.date = null;
    form.rate = 100;
    form.is_flexible = false;
    form.is_flexible_week = true;
    weeksDaysEven.value = [];
    weeksDaysOdd.value = [];
    await getWorkRates();
  } catch (error) {
    console.error(error);
  } finally {
    workRateLoading.value = false;
  }
}

function onEdit(workRate: IWorkRateResource) {
  form.date = workRate.date;
  form.rate = workRate.rate;
  form.is_flexible = workRate.is_flexible;
  form.is_flexible_week = !workRate.is_flexible_week;
  if (workRate.is_flexible_week) {
    weeksDaysEven.value = klona(workRate.week_days.filter((item) => item.week_type === 'even'));
    weeksDaysOdd.value = klona(workRate.week_days.filter((item) => item.week_type === 'odd'));
  } else {
    weeksDaysEven.value = klona(workRate.week_days);
    weeksDaysOdd.value = [];
  }
}

function onDelete(workRate: IWorkRateResource) {
  const { open, close, destroy } = useModal({
    component: ConfirmModal,
    attrs: {
      title: t('user.work_rates.confirm.destroy.title'),
      message: t('user.work_rates.confirm.destroy.text'),
      async onConfirm({ setLoading }: ConfirmDialogConfirmParams) {
        try {
          setLoading(true);
          await api.users.workRates.delete(userUuid, workRate.id);
          workRates.value = workRates.value.filter(({ id }) => workRate.id !== id);
          if (workRates.value.length === 0 && page.value > 1) {
            page.value -= 1;
          }
          await close();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onFlexibleChange() {
  weeksDaysEven.value = form.is_flexible
    ? [...Array.from({ length: 7 }, (_, index) => createWeekDay(index + 1, null))]
    : [];
  form.is_flexible_week = true;
  weeksDaysOdd.value = [];
}

function onFlexibleWeekChange() {
  if (form.is_flexible_week) {
    weeksDaysEven.value = weeksDaysEven.value.map((item) => ({ ...item, week_type: null }));
    weeksDaysOdd.value = [];
  } else {
    weeksDaysEven.value = weeksDaysEven.value.map((item) => ({ ...item, week_type: 'even' }));
    weeksDaysOdd.value = [
      ...[...Array.from<unknown, IWorkRateWeekDay>({ length: 7 }, (_, index) => createWeekDay(index + 1, 'odd'))],
    ];
  }
}

function createWeekDay(day: number, weekType: IWorkRateWeekDay['week_type'], rate = 100) {
  return {
    day,
    rate: day > 5 ? 0 : rate,
    week_type: weekType,
  };
}

const netTotalDefaultWorkingHours = computed(() => Decimal.mul(workingHours.value, 5).toDecimalPlaces(2).toNumber());

const netTotalScheduledWorkingHours = computed(() =>
  [...weeksDaysEven.value, ...weeksDaysOdd.value].reduce(
    (total, rate) =>
      total.plus(Decimal.div(Decimal.mul(workingHours.value, rate.rate), 100).toDecimalPlaces(2).toNumber()),
    new Decimal(0),
  ),
);

const netTotalScheduledWorkingRate = computed(() =>
  Decimal.div(Decimal.mul(netTotalScheduledWorkingHours.value.toNumber(), 100), netTotalDefaultWorkingHours.value)
    .div(2)
    .toDecimalPlaces(2)
    .toNumber(),
);

onMounted(async () => {
  await getWorkRates();
});
</script>

<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container is-huge"
    :click-to-close="false"
    :esc-to-close="true"
    v-slot="{ close }"
    @closed="emit('closed')"
  >
    <div class="modal-header">
      <h2 v-text="t('user.work_rates.title')" />
      <AppCloseButton @close="close" />
    </div>
    <div class="modal-content">
      <div class="row" style="min-height: 300px">
        <div class="col-md-8">
          <h3 v-text="t('user.work_rates.edit.title')" />
          <form @keydown.enter.prevent @submit.prevent="submit">
            <!-- Date -->
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col">
                  <FormLabel html-for="dp-input-date" required>
                    {{ t('user.work_rates.form.date') }}
                  </FormLabel>
                </div>
                <div class="col">
                  <div class="form-wrapper has-icon">
                    <VueDatePicker
                      :ui="{ input: 'form-control' }"
                      :placeholder="t('common.select')"
                      v-model="form.date"
                      model-type="format"
                      :enable-time-picker="false"
                      :month-change-on-scroll="false"
                      auto-apply
                      text-input
                      :locale="locale"
                      uid="date"
                      required
                      format="yyyy-MM-dd"
                      :min-date="minDate"
                      :month-picker="false"
                      :week-num-name="t('common.week_short')"
                      :disabled="workRatesLoading || workRateLoading"
                      :clearable="false"
                    >
                      <template #input-icon><i class="form-icon ti ti-calendar" /></template>
                    </VueDatePicker>
                  </div>
                </div>
              </div>
            </div>

            <!-- Rate -->
            <div v-if="!form.is_flexible" class="form-group">
              <div class="row align-items-center">
                <div class="col">
                  <FormLabel html-for="rate" required>{{ t('user.work_rates.form.rate') }}</FormLabel>
                </div>
                <div class="col">
                  <FormNumber
                    id="rate"
                    v-model="form.rate"
                    :min="0"
                    :max="100"
                    :step="10"
                    :disabled="workRatesLoading || workRateLoading"
                    :decimal-places="2"
                    float
                  />
                </div>
              </div>
            </div>

            <!-- Variable rate -->
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col">
                  <FormLabel html-for="is_flexible">{{ t('user.work_rates.form.is_flexible') }}</FormLabel>
                </div>
                <div class="col">
                  <FormSwitch
                    id="is_flexible"
                    v-model="form.is_flexible"
                    :disabled="workRatesLoading || workRateLoading"
                    @change="onFlexibleChange"
                  />
                </div>
              </div>
              <template v-if="form.is_flexible">
                <div class="form-group row align-items-center">
                  <div class="col">
                    <FormLabel html-for="is_flexible_week">{{ t('user.work_rates.form.is_flexible_week') }}</FormLabel>
                  </div>
                  <div class="col">
                    <FormSwitch
                      id="is_flexible_week"
                      v-model="form.is_flexible_week"
                      :disabled="workRatesLoading || workRateLoading"
                      @change="onFlexibleWeekChange"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <h3 v-if="!form.is_flexible_week">{{ t('user.work_rates.flexible_time.even_weeks') }}</h3>
                    <UserFlexibleWorkRatesTable :working-hours="workingHours" v-model:rates="weeksDaysEven" />
                  </div>
                  <div class="col-sm-6" v-if="!form.is_flexible_week">
                    <h3>{{ t('user.work_rates.flexible_time.odd_weeks') }}</h3>
                    <UserFlexibleWorkRatesTable :working-hours="workingHours" v-model:rates="weeksDaysOdd" />
                  </div>
                  <div class="col-sm-6 mt-3" v-if="!form.is_flexible_week">
                    <AppTable bordered>
                      <AppTableBody>
                        <AppTableTr class="font-bold">
                          <AppTableTd style="width: 25%">
                            {{ t('user.work_rates.flexible_time.attributes.net_total') }}
                          </AppTableTd>
                          <AppTableTd style="width: 25%" class="text-right">
                            {{ netTotalDefaultWorkingHours }}
                          </AppTableTd>
                          <AppTableTd style="width: 30%" class="text-right">
                            {{ netTotalScheduledWorkingHours.div(2).toDecimalPlaces(2).toNumber() }}
                          </AppTableTd>
                          <AppTableTd style="width: 20%" class="text-right">
                            {{ netTotalScheduledWorkingRate }}
                          </AppTableTd>
                        </AppTableTr>
                      </AppTableBody>
                    </AppTable>
                  </div>
                </div>
              </template>
            </div>
            <AppButton
              class="mt-2 mt-sm-0"
              color="success"
              :loading="workRateLoading"
              :disabled="workRatesLoading || workRateDisabled"
            >
              {{ t('common.update') }}
            </AppButton>
          </form>
        </div>
        <div class="col-md">
          <h3 v-text="t('user.work_rates.history.title')" />
          <div v-if="workRatesLoading" class="text-center">
            <AppLoader size="large" />
          </div>
          <template v-else>
            <AppAlert v-if="workRates.length === 0">{{ t('common.empty') }}</AppAlert>
            <AppTable bordered v-else hoverable>
              <AppTableHead>
                <AppTableTr>
                  <AppTableTh nowrap>{{ t('user.work_rates.attributes.date') }}</AppTableTh>
                  <AppTableTh nowrap class="text-right">{{ t('user.work_rates.attributes.rate') }}, %</AppTableTh>
                  <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
                </AppTableTr>
              </AppTableHead>
              <AppTableBody>
                <AppTableTr v-for="workRate in paginated" :key="workRate.id">
                  <AppTableTd nowrap>{{ d(workRate.date) }}</AppTableTd>
                  <AppTableTd nowrap class="text-right">{{ workRate.rate }}</AppTableTd>
                  <AppTableTd nowrap class="text-right">
                    <AppButton
                      v-tooltip.left="t('common.edit')"
                      @click.stop.prevent="onEdit(workRate)"
                      size="small"
                      light
                      circle
                    >
                      <FontIcon name="pencil" />
                    </AppButton>
                    <AppButton
                      v-tooltip.left="t('common.delete')"
                      @click.stop.prevent="onDelete(workRate)"
                      class="ml-2"
                      color="danger"
                      size="small"
                      light
                      circle
                    >
                      <FontIcon name="trash" />
                    </AppButton>
                  </AppTableTd>
                </AppTableTr>
              </AppTableBody>
            </AppTable>
            <AppPagination :per-page="perPage" :total="total" v-model="page" />
          </template>
        </div>
      </div>
      <slot />
    </div>
    <div class="modal-footer">
      <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
        <AppButton light @click.prevent="close">
          {{ t('common.close') }}
        </AppButton>
      </div>
    </div>
  </VueFinalModal>
</template>
