import ky from 'ky';
import useLocaleStore from '@/store/LocaleStore';
import ErrorService from '@/services/errors/ErrorService';
import useAuthStore from '@/store/AuthStore';
import toast from '@/services/toast';

const httpClient = ky.create({
  timeout: 120000,
  retry: 1,
  hooks: {
    beforeRequest: [
      (request) => {
        const { locale } = useLocaleStore();
        request.headers.set('X-APP-LOCALE', locale);
      },
    ],
    beforeError: [
      async (error) => {
        if (error.response.status === 401) {
          const { unsetAuthenticatedUser } = useAuthStore();
          unsetAuthenticatedUser();
          return error;
        }
        // if (![404].includes(error.response.status)) {
        if (error.response && error.response.body) {
          error.name = error.response.statusText;
          const errors = await error.response.json();
          ErrorService.displayErrors(errors.message);
        }

        return error;
      },
    ],
    afterResponse: [
      async (request, options, response) => {
        if ([200, 201].includes(response.status)) {
          const { message = null } = await response.json();
          if (message && typeof message === 'string') {
            toast.success(message);
          }
        }
      },
    ],
  },
});

export default httpClient;
