<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useRouteQuery } from '@vueuse/router';
import { useI18n } from 'vue-i18n';
import { useModal } from 'vue-final-modal';
import { createConfirmDialog } from 'vuejs-confirm-dialog';
import { storeToRefs } from 'pinia';

import api from '@/services/api';
import {
  AppAlert,
  AppBox,
  AppBoxBody,
  AppButton,
  AppLoader,
  AppPagination,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  CancelOrRolloverProjectModal,
  ClientChooseModal,
  ConfirmDialog,
  FontIcon,
  FormInput,
  FormLabel,
  HelpInformation,
  ProjectStatus,
} from '@/components';
import useLoader from '@/composables/useLoader';
import useClientStore from '@/store/ClientStore';
import { IProjectPreviewResource, ProjectStatusType } from '@/types/Project';
import { useTitle } from '@vueuse/core';

const { uuid } = defineProps<{
  uuid?: string;
}>();

const clientStore = useClientStore();
const { client } = storeToRefs(clientStore);

const projects = ref<IProjectPreviewResource[]>([]);
const currentPage = useRouteQuery('page', '1', { transform: Number });
const perPage = ref(20);
const total = ref(0);
const filters = reactive({
  dirty: false,
  search: '',
});

const filterDisabled = computed(() => loader.isLoading.value);
const filterApplyDisabled = computed(() => filters.search === '');

const router = useRouter();
const { t } = useI18n({ useScope: 'global' });
const loader = useLoader();

const createProjectModal = useModal({
  component: ClientChooseModal,
  attrs: {
    onConfirm(clientUuid: string) {
      router.push({ name: 'projects.create', params: { uuid: clientUuid } });
      createProjectModal.close();
    },
    onCancel() {
      createProjectModal.close();
    },
  },
});

async function getProjects() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('page', currentPage.value.toString());
    if (filters.search) searchParams.append('search', filters.search);
    const response = uuid ? await api.projects.index(uuid, searchParams) : await api.projects.list(searchParams);
    projects.value = response.data;
    perPage.value = response.meta!.per_page;
    total.value = response.meta!.total;
  } catch (error) {
    console.error(error);
  }
}

async function deleteProject(project: IProjectPreviewResource) {
  const dialog = createConfirmDialog(ConfirmDialog, {
    title: t('project.confirm.destroy.title'),
    message: t('project.confirm.destroy.text', { name: project.name }),
    confirmBtnType: 'danger',
    delayTime: 0,
    size: 'm',
    confirmCallback: async () => {
      if (project.client) {
        await api.projects.destroy(project.client.uuid, project.id);
        await getProjects();
        if (projects.value.length === 0 && currentPage.value > 1) {
          currentPage.value -= 1;
        }
      }
    },
  });

  dialog.reveal();
}

function cancelProject(project: IProjectPreviewResource) {
  if (!project.client) {
    return;
  }

  const dialog = createConfirmDialog(CancelOrRolloverProjectModal, {
    type: 'cancel',
    clientUuid: project.client.uuid,
    project: {
      id: project.id,
      name: project.name,
      has_unfinished_tasks: project.has_unfinished_tasks,
    },
    onConfirmed: async (newProjectId: number | null) => {
      await getProjects();
      if (newProjectId && project.client) {
        await router.push({ name: 'projects.edit', params: { uuid: project.client.uuid, id: newProjectId } });
      }
    },
  });

  dialog.reveal();
}

function reactivateProject(project: IProjectPreviewResource) {
  const dialog = createConfirmDialog(ConfirmDialog, {
    title: t('project.reactivate_modal.title', { name: project.name }),
    confirmBtnType: 'success',
    delayTime: 0,
    size: 's',
    confirmCallback: async () => {
      if (project.client) {
        await api.projects.reactivate(project.client.uuid, project.id);
        await getProjects();
      }
    },
  });

  dialog.reveal();
}

function rolloverProject(project: IProjectPreviewResource) {
  if (!project.client) {
    return;
  }

  const dialog = createConfirmDialog(CancelOrRolloverProjectModal, {
    type: 'rollover',
    clientUuid: project.client.uuid,
    project: {
      id: project.id,
      name: project.name,
      has_unfinished_tasks: project.has_unfinished_tasks,
    },
    onConfirmed: async (newProjectId: number | null) => {
      await getProjects();
      if (newProjectId && project.client) {
        await router.push({ name: 'projects.edit', params: { uuid: project.client.uuid, id: newProjectId } });
      }
    },
  });

  dialog.reveal();
}

function onCreateProject() {
  if (uuid) {
    router.push({ name: 'projects.create', params: { uuid } });
  } else {
    createProjectModal.open();
  }
}

async function onFilter() {
  loader.start();
  currentPage.value = 1;
  await getProjects();
  loader.finish();
  filters.dirty = true;
}

async function resetFilters() {
  filters.search = '';
  await onFilter();
  filters.dirty = false;
}

onMounted(async () => {
  loader.start();
  await getProjects();
  loader.finish();
});

watch(currentPage, async () => {
  loader.start();
  await getProjects();
  loader.finish();
});

const title = useTitle(
  computed(() => {
    return uuid
      ? t('client.projects.index.title', { name: client.value?.name, org_number: client.value?.org_number })
      : t('project.index.title');
  }),
);
</script>

<template>
  <div class="container-fluid">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-end">
        <h1 class="mb-0" v-text="title" />
        <HelpInformation class="ml-1" translation="project.index.help" />
      </div>
      <AppButton @click.prevent="onCreateProject" color="secondary">
        {{ t('client.projects.index.create') }}
      </AppButton>
    </div>
    <form @submit.prevent="onFilter" class="my-3">
      <AppBox>
        <AppBoxBody>
          <div class="row d-flex align-items-end">
            <div class="col-3">
              <FormLabel html-for="filter_name">
                {{ uuid ? t('project.index.filters.name') : t('project.index.filters.name_or_client') }}
              </FormLabel>
              <FormInput
                type="search"
                v-model="filters.search"
                id="filter_name"
                icon="search"
                :disabled="filterDisabled"
              />
            </div>
            <div class="col-9">
              <AppButton :disabled="filterDisabled || filterApplyDisabled">
                {{ t('common.apply_filters') }}
              </AppButton>
              <AppButton class="ml-2" light @click.prevent="resetFilters" :disabled="filterDisabled || !filters.dirty">
                {{ t('common.reset_filters') }}
              </AppButton>
            </div>
          </div>
        </AppBoxBody>
      </AppBox>
    </form>
    <div v-if="loader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <template v-else>
      <AppAlert v-if="projects.length === 0">
        {{ filters.dirty ? t('common.no_search_results') : t('project.empty') }}
      </AppAlert>
      <template v-else>
        <AppTable hoverable>
          <AppTableHead>
            <AppTableTr>
              <AppTableTh nowrap>{{ t('project.attributes.name') }}</AppTableTh>
              <AppTableTh v-if="!uuid" nowrap>{{ t('project.attributes.client') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('project.attributes.status') }}</AppTableTh>
              <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
            </AppTableTr>
          </AppTableHead>
          <AppTableBody>
            <AppTableTr
              @click="
                router.push({
                  name: 'projects.edit',
                  params: { uuid: project.client.uuid, id: project.id },
                })
              "
              class="pointer"
              v-for="project in projects"
              :key="project.id"
            >
              <AppTableTd nowrap>
                <strong v-text="project.name" />
              </AppTableTd>
              <AppTableTd nowrap v-if="!uuid">
                {{ project.client?.name }}
              </AppTableTd>
              <AppTableTd nowrap>
                <ProjectStatus :status="project.status" />
              </AppTableTd>
              <AppTableTd nowrap class="text-right">
                <AppButton
                  v-tooltip.left="t('project.tooltip.view', { name: project.name })"
                  @click.stop="
                    router.push({
                      name: 'projects.view',
                      params: { uuid: project.client.uuid, id: project.id },
                    })
                  "
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="eye" />
                </AppButton>
                <AppButton
                  v-tooltip.left="t('project.tooltip.edit', { name: project.name })"
                  @click.stop="
                    router.push({
                      name: 'projects.edit',
                      params: { uuid: project.client.uuid, id: project.id },
                    })
                  "
                  class="ml-2"
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="pencil" />
                </AppButton>
                <AppButton
                  v-tooltip.left="t('project.tooltip.destroy', { name: project.name })"
                  @click.stop="deleteProject(project)"
                  class="ml-2"
                  size="small"
                  color="danger"
                  light
                  circle
                  v-if="project.status === ProjectStatusType.Draft"
                >
                  <FontIcon name="trash" />
                </AppButton>
                <AppButton
                  v-if="project.status === ProjectStatusType.Active"
                  v-tooltip.left="t('project.tooltip.cancel', { name: project.name })"
                  @click.stop="cancelProject(project)"
                  class="ml-2"
                  color="danger"
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="ban" />
                </AppButton>
                <AppButton
                  v-if="project.status === ProjectStatusType.Active"
                  v-tooltip.left="t('project.tooltip.rollover', { name: project.name })"
                  @click.stop="rolloverProject(project)"
                  class="ml-2"
                  color="secondary"
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="reload" />
                </AppButton>
                <AppButton
                  v-if="project.status === ProjectStatusType.Cancelled"
                  v-tooltip.left="t('project.tooltip.reactivate', { name: project.name })"
                  @click.stop="reactivateProject(project)"
                  class="ml-2"
                  color="success"
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="arrow-back-up" />
                </AppButton>
              </AppTableTd>
            </AppTableTr>
          </AppTableBody>
        </AppTable>
        <AppPagination :per-page="perPage" :total="total" v-model="currentPage" />
      </template>
    </template>
  </div>
</template>
