export default [
  {
    code: 1110,
    text: 'Odling av spannmål (utom ris), baljväxter och oljeväxter',
  },
  {
    code: 1120,
    text: 'Odling av ris',
  },
  {
    code: 1131,
    text: 'Potatisodling',
  },
  {
    code: 1132,
    text: 'Sockerbetsodling',
  },
  {
    code: 1133,
    text: 'Odling av grönsaker (köksväxter) på friland',
  },
  {
    code: 1134,
    text: 'Odling av grönsaker (köksväxter) i växthus',
  },
  {
    code: 1135,
    text: 'Svampodling m.m.',
  },
  {
    code: 1140,
    text: 'Odling av sockerrör',
  },
  {
    code: 1150,
    text: 'Odling av tobak',
  },
  {
    code: 1160,
    text: 'Odling av fiberväxter',
  },
  {
    code: 1191,
    text: 'Odling av prydnadsväxter i växthus',
  },
  {
    code: 1199,
    text: 'Odling av övriga ett- och tvååriga växter',
  },
  {
    code: 1210,
    text: 'Odling av druvor',
  },
  {
    code: 1220,
    text: 'Odling av tropiska och subtropiska frukter',
  },
  {
    code: 1230,
    text: 'Odling av citrusfrukter',
  },
  {
    code: 1240,
    text: 'Odling av kärnfrukter och stenfrukter',
  },
  {
    code: 1250,
    text: 'Odling av andra frukter och bär samt nötter',
  },
  {
    code: 1260,
    text: 'Odling av oljehaltiga frukter',
  },
  {
    code: 1270,
    text: 'Odling av växter för dryckesframställning',
  },
  {
    code: 1280,
    text: 'Odling av kryddväxter, drog- och medicinalväxter',
  },
  {
    code: 1290,
    text: 'Odling av andra fleråriga växter',
  },
  {
    code: 1301,
    text: 'Odling av plantskoleväxter i växthus',
  },
  {
    code: 1302,
    text: 'Odling av plantskoleväxter m.m. på friland',
  },
  {
    code: 1410,
    text: 'Mjölkproduktion och uppfödning av nötkreatur av mjölkras',
  },
  {
    code: 1420,
    text: 'Uppfödning av andra nötkreatur och bufflar',
  },
  {
    code: 1430,
    text: 'Uppfödning av hästar och andra hästdjur',
  },
  {
    code: 1440,
    text: 'Uppfödning av kameler och kameldjur',
  },
  {
    code: 1450,
    text: 'Uppfödning av får och getter',
  },
  {
    code: 1461,
    text: 'Uppfödning av smågrisar',
  },
  {
    code: 1462,
    text: 'Uppfödning av slaktsvin',
  },
  {
    code: 1471,
    text: 'Äggproduktion (för försäljning)',
  },
  {
    code: 1472,
    text: 'Uppfödning av fjäderfä, ej äggproduktion',
  },
  {
    code: 1491,
    text: 'Renskötsel',
  },
  {
    code: 1492,
    text: 'Uppfödning av sällskapsdjur',
  },
  {
    code: 1499,
    text: 'Övrig uppfödning av andra djur',
  },
  {
    code: 1500,
    text: 'Blandat jordbruk',
  },
  {
    code: 1610,
    text: 'Service till växtodling',
  },
  {
    code: 1620,
    text: 'Service till husdjursskötsel',
  },
  {
    code: 1630,
    text: 'Bearbetning av skördade växter',
  },
  {
    code: 1640,
    text: 'Bearbetning av utsäde',
  },
  {
    code: 1700,
    text: 'Jakt och service i anslutning härtill',
  },
  {
    code: 2101,
    text: 'Skogsförvaltning',
  },
  {
    code: 2102,
    text: 'Skogsskötsel',
  },
  {
    code: 2109,
    text: 'Övrig skoglig verksamhet',
  },
  {
    code: 2200,
    text: 'Drivning',
  },
  {
    code: 2300,
    text: 'Insamling av annat vilt växande skogsmaterial än trä',
  },
  {
    code: 2401,
    text: 'Virkesmätning',
  },
  {
    code: 2409,
    text: 'Övrig service till skogsbruk',
  },
  {
    code: 3111,
    text: 'Trålfiske i saltvatten',
  },
  {
    code: 3119,
    text: 'Övrigt saltvattensfiske',
  },
  {
    code: 3120,
    text: 'Sötvattensfiske',
  },
  {
    code: 3210,
    text: 'Fiskodling i saltvatten',
  },
  {
    code: 3220,
    text: 'Fiskodling i sötvatten',
  },
  {
    code: 5100,
    text: 'Stenkolsutvinning',
  },
  {
    code: 5200,
    text: 'Brunkolsutvinning',
  },
  {
    code: 6100,
    text: 'Utvinning av råpetroleum',
  },
  {
    code: 6200,
    text: 'Utvinning av naturgas',
  },
  {
    code: 7100,
    text: 'Järnmalmsutvinning',
  },
  {
    code: 7210,
    text: 'Utvinning av uran- och toriummalm',
  },
  {
    code: 7290,
    text: 'Utvinning av annan malm',
  },
  {
    code: 8110,
    text: 'Brytning av natursten, kalk- och gipssten, krita och skiffer',
  },
  {
    code: 8120,
    text: 'Utvinning av sand, grus och berg; utvinning av lera och kaolin',
  },
  {
    code: 8910,
    text: 'Brytning av kemiska mineral',
  },
  {
    code: 8920,
    text: 'Torvutvinning',
  },
  {
    code: 8930,
    text: 'Saltutvinning',
  },
  {
    code: 8990,
    text: 'Diverse övrig utvinning av mineral',
  },
  {
    code: 9100,
    text: 'Stödtjänster till råpetroleum- och naturgasutvinning',
  },
  {
    code: 9900,
    text: 'Stödtjänster till annan utvinning',
  },
  {
    code: 10111,
    text: 'Kreatursslakt',
  },
  {
    code: 10112,
    text: 'Styckning av kött',
  },
  {
    code: 10120,
    text: 'Beredning och hållbarhetsbehandling av fjäderfäkött',
  },
  {
    code: 10130,
    text: 'Charkuteri- och annan köttvarutillverkning',
  },
  {
    code: 10200,
    text: 'Beredning och hållbarhetsbehandling av fisk samt skal- och blötdjur',
  },
  {
    code: 10310,
    text: 'Beredning och hållbarhetsbehandling av potatis',
  },
  {
    code: 10320,
    text: 'Juice- och safttillverkning',
  },
  {
    code: 10390,
    text: 'Annan beredning och hållbarhetsbehandling av frukt, bär och grönsaker',
  },
  {
    code: 10410,
    text: 'Framställning av oljor och fetter',
  },
  {
    code: 10420,
    text: 'Matfettstillverkning',
  },
  {
    code: 10511,
    text: 'Osttillverkning',
  },
  {
    code: 10519,
    text: 'Annan mejerivarutillverkning',
  },
  {
    code: 10520,
    text: 'Glasstillverkning',
  },
  {
    code: 10611,
    text: 'Mjöltillverkning',
  },
  {
    code: 10612,
    text: 'Tillverkning av frukostflingor, mixer och andra livsmedelsberedningar av kvarnprodukter',
  },
  {
    code: 10620,
    text: 'Stärkelsetillverkning',
  },
  {
    code: 10710,
    text: 'Tillverkning av mjukt matbröd och färska bakverk',
  },
  {
    code: 10721,
    text: 'Knäckebrödstillverkning',
  },
  {
    code: 10722,
    text: 'Tillverkning av kex och konserverade bakverk',
  },
  {
    code: 10730,
    text: 'Tillverkning av pastaprodukter',
  },
  {
    code: 10810,
    text: 'Sockertillverkning',
  },
  {
    code: 10821,
    text: 'Tillverkning av sockerkonfektyrer',
  },
  {
    code: 10822,
    text: 'Tillverkning av choklad och chokladkonfektyrer',
  },
  {
    code: 10830,
    text: 'Framställning av te och kaffe',
  },
  {
    code: 10840,
    text: 'Tillverkning av senap, ketchup, kryddor och andra smaksättningsmedel',
  },
  {
    code: 10850,
    text: 'Tillverkning av lagad mat och färdigrätter',
  },
  {
    code: 10860,
    text: 'Tillverkning av homogeniserade livsmedelspreparat inklusive dietmat',
  },
  {
    code: 10890,
    text: 'Framställning av andra livsmedel',
  },
  {
    code: 10910,
    text: 'Framställning av beredda fodermedel',
  },
  {
    code: 10920,
    text: 'Framställning av mat till sällskapsdjur',
  },
  {
    code: 11010,
    text: 'Destillering, rening och tillblandning av spritdrycker',
  },
  {
    code: 11020,
    text: 'Framställning av vin från druvor',
  },
  {
    code: 11030,
    text: 'Framställning av cider och andra fruktviner',
  },
  {
    code: 11040,
    text: 'Framställning av andra icke-destillerade jästa drycker',
  },
  {
    code: 11050,
    text: 'Framställning av öl',
  },
  {
    code: 11060,
    text: 'Framställning av malt',
  },
  {
    code: 11070,
    text: 'Framställning av läskedrycker, mineralvatten och annat vatten på flaska',
  },
  {
    code: 12000,
    text: 'Tobaksvarutillverkning',
  },
  {
    code: 13100,
    text: 'Garntillverkning',
  },
  {
    code: 13200,
    text: 'Vävnadstillverkning',
  },
  {
    code: 13300,
    text: 'Blekning, färgning och annan textilberedning',
  },
  {
    code: 13910,
    text: 'Tillverkning av trikåväv',
  },
  {
    code: 13921,
    text: 'Sömnad av gardiner, sängkläder och linnevaror',
  },
  {
    code: 13922,
    text: 'Tillverkning av presenningar, tält, segel o.d.',
  },
  {
    code: 13930,
    text: 'Tillverkning av mattor',
  },
  {
    code: 13940,
    text: 'Tågvirkes- och bindgarnstillverkning',
  },
  {
    code: 13950,
    text: 'Tillverkning av bondad duk',
  },
  {
    code: 13960,
    text: 'Tillverkning av andra tekniska textilier och industritextilier',
  },
  {
    code: 13990,
    text: 'Övrig textilietillverkning',
  },
  {
    code: 14110,
    text: 'Tillverkning av läder- och skinnkläder',
  },
  {
    code: 14120,
    text: 'Tillverkning av arbets-, skydds- och överdragskläder',
  },
  {
    code: 14130,
    text: 'Tillverkning av andra gång- och ytterkläder',
  },
  {
    code: 14140,
    text: 'Tillverkning av underkläder, skjortor och blusar',
  },
  {
    code: 14190,
    text: 'Tillverkning av andra beklädnadsvaror och tillbehör',
  },
  {
    code: 14200,
    text: 'Tillverkning av pälsvaror',
  },
  {
    code: 14310,
    text: 'Tillverkning av strumpor',
  },
  {
    code: 14390,
    text: 'Tillverkning av andra trikåvaror',
  },
  {
    code: 15110,
    text: 'Garvning och annan läderberedning; pälsberedning',
  },
  {
    code: 15120,
    text: 'Tillverkning av reseffekter, handväskor, sadel- och seldon m. m.',
  },
  {
    code: 15200,
    text: 'Tillverkning av skodon',
  },
  {
    code: 16101,
    text: 'Sågning av trä',
  },
  {
    code: 16102,
    text: 'Hyvling av trä',
  },
  {
    code: 16103,
    text: 'Träimpregnering',
  },
  {
    code: 16210,
    text: 'Tillverkning av fanér och träbaserade skivor',
  },
  {
    code: 16220,
    text: 'Tillverkning av sammansatta parkettgolv',
  },
  {
    code: 16231,
    text: 'Tillverkning av monteringsfärdiga trähus',
  },
  {
    code: 16232,
    text: 'Tillverkning av dörrar av trä',
  },
  {
    code: 16233,
    text: 'Tillverkning av fönster av trä',
  },
  {
    code: 16239,
    text: 'Tillverkning av övriga byggnads- och inredningssnickerier',
  },
  {
    code: 16240,
    text: 'Träförpackningstillverkning',
  },
  {
    code: 16291,
    text: 'Tillverkning av förädlade trädbränslen',
  },
  {
    code: 16292,
    text: 'Övrig trävarutillverkning',
  },
  {
    code: 16293,
    text: 'Tillverkning av varor av kork, halm, rotting o.d.',
  },
  {
    code: 17111,
    text: 'Tillverkning av mekanisk eller halvkemisk massa',
  },
  {
    code: 17112,
    text: 'Sulfatmassatillverkning',
  },
  {
    code: 17113,
    text: 'Sulfitmassatillverkning',
  },
  {
    code: 17121,
    text: 'Tillverkning av tidnings- och journalpapper',
  },
  {
    code: 17122,
    text: 'Tryckpapperstillverkning, ej tidnings- och journalpapper',
  },
  {
    code: 17123,
    text: 'Tillverkning av kraftpapper och kraftpapp',
  },
  {
    code: 17129,
    text: 'Övrig tillverkning av papper och papp',
  },
  {
    code: 17211,
    text: 'Tillverkning av wellpapp och wellpappförpackningar',
  },
  {
    code: 17219,
    text: 'Övrig tillverkning av pappers- och pappförpackningar',
  },
  {
    code: 17220,
    text: 'Tillverkning av hushålls- och hygienartiklar av papper',
  },
  {
    code: 17230,
    text: 'Tillverkning av skrivpapper, kuvert o.d.',
  },
  {
    code: 17240,
    text: 'Tapettillverkning',
  },
  {
    code: 17290,
    text: 'Tillverkning av andra pappers- och pappvaror',
  },
  {
    code: 18110,
    text: 'Tryckning av dagstidningar',
  },
  {
    code: 18121,
    text: 'Tryckning av tidskrifter',
  },
  {
    code: 18122,
    text: 'Tryckning av böcker och övriga trycksaker',
  },
  {
    code: 18130,
    text: 'Grafiska tjänster före tryckning (prepress/premedia)',
  },
  {
    code: 18140,
    text: 'Bokbindning och andra tjänster i samband med tryckning',
  },
  {
    code: 18200,
    text: 'Reproduktion av inspelningar',
  },
  {
    code: 19100,
    text: 'Tillverkning av stenkolsprodukter',
  },
  {
    code: 19200,
    text: 'Petroleumraffinering',
  },
  {
    code: 20110,
    text: 'Industrigasframställning',
  },
  {
    code: 20120,
    text: 'Tillverkning av färgämnen',
  },
  {
    code: 20130,
    text: 'Tillverkning av andra oorganiska baskemikalier',
  },
  {
    code: 20140,
    text: 'Tillverkning av andra organiska baskemikalier',
  },
  {
    code: 20150,
    text: 'Tillverkning av gödselmedel och kväveprodukter',
  },
  {
    code: 20160,
    text: 'Basplastframställning',
  },
  {
    code: 20170,
    text: 'Tillverkning av syntetiskt basgummi',
  },
  {
    code: 20200,
    text: 'Tillverkning av bekämpningsmedel och andra lantbrukskemiska produkter',
  },
  {
    code: 20300,
    text: 'Tillverkning av färg, lack, tryckfärg m.m.',
  },
  {
    code: 20410,
    text: 'Tillverkning av tvål, såpa, tvättmedel och polermedel',
  },
  {
    code: 20420,
    text: 'Tillverkning av parfymer och toalettartiklar',
  },
  {
    code: 20510,
    text: 'Sprängämnestillverkning',
  },
  {
    code: 20520,
    text: 'Tillverkning av lim',
  },
  {
    code: 20530,
    text: 'Tillverkning av eteriska oljor',
  },
  {
    code: 20590,
    text: 'Tillverkning av övriga kemiska produkter',
  },
  {
    code: 20600,
    text: 'Konstfibertillverkning',
  },
  {
    code: 21100,
    text: 'Tillverkning av farmaceutiska basprodukter',
  },
  {
    code: 21200,
    text: 'Tillverkning av läkemedel',
  },
  {
    code: 22110,
    text: 'Tillverkning av däck och slangar; regummering',
  },
  {
    code: 22190,
    text: 'Annan gummivarutillverkning',
  },
  {
    code: 22210,
    text: 'Tillverkning av plasthalvfabrikat',
  },
  {
    code: 22220,
    text: 'Plastförpackningstillverkning',
  },
  {
    code: 22230,
    text: 'Byggplastvarutillverkning',
  },
  {
    code: 22290,
    text: 'Annan plastvarutillverkning',
  },
  {
    code: 23110,
    text: 'Framställning av planglas',
  },
  {
    code: 23120,
    text: 'Bearbetning av planglas',
  },
  {
    code: 23130,
    text: 'Tillverkning av buteljer, glasförpackningar och husgeråd av glas',
  },
  {
    code: 23140,
    text: 'Tillverkning av glasfiber',
  },
  {
    code: 23190,
    text: 'Tillverkning av andra glasvaror inklusive tekniska glasvaror',
  },
  {
    code: 23200,
    text: 'Tillverkning av eldfasta produkter',
  },
  {
    code: 23310,
    text: 'Tillverkning av keramiska golv- och väggplattor',
  },
  {
    code: 23320,
    text: 'Tillverkning av murtegel, takpannor och andra byggvaror av tegel',
  },
  {
    code: 23410,
    text: 'Tillverkning av keramiska hushålls- och prydnadsartiklar',
  },
  {
    code: 23420,
    text: 'Tillverkning av keramiska sanitetsartiklar',
  },
  {
    code: 23430,
    text: 'Tillverkning av keramiska isolatorer o.d.',
  },
  {
    code: 23440,
    text: 'Tillverkning av andra tekniska keramiska produkter',
  },
  {
    code: 23490,
    text: 'Tillverkning av andra keramiska produkter',
  },
  {
    code: 23510,
    text: 'Tillverkning av cement',
  },
  {
    code: 23520,
    text: 'Tillverkning av kalk och gips',
  },
  {
    code: 23610,
    text: 'Tillverkning av betongvaror för byggändamål',
  },
  {
    code: 23620,
    text: 'Tillverkning av gipsvaror för byggändamål',
  },
  {
    code: 23630,
    text: 'Tillverkning av fabriksblandad betong',
  },
  {
    code: 23640,
    text: 'Tillverkning av murbruk',
  },
  {
    code: 23650,
    text: 'Tillverkning av fibercementvaror',
  },
  {
    code: 23690,
    text: 'Tillverkning av andra varor av betong, cement och gips',
  },
  {
    code: 23701,
    text: 'Huggning, formning och slutlig bearbetning av sten för byggnadsändamål',
  },
  {
    code: 23709,
    text: 'Huggning, formning och slutlig bearbetning av sten för prydnadsändamål',
  },
  {
    code: 23910,
    text: 'Slipmedelstillverkning',
  },
  {
    code: 23991,
    text: 'Tillverkning av varor av sten- och mineralull',
  },
  {
    code: 23999,
    text: 'Diverse övrig tillverkning av icke-metalliska mineraliska produkter',
  },
  {
    code: 24100,
    text: 'Framställning av järn och stål samt ferrolegeringar',
  },
  {
    code: 24200,
    text: 'Tillverkning av rör, ledningar, ihåliga profiler och tillbehör av stål',
  },
  {
    code: 24310,
    text: 'Tillverkning av kalldragen stålstång',
  },
  {
    code: 24320,
    text: 'Tillverkning av kallvalsade stålband',
  },
  {
    code: 24330,
    text: 'Tillverkning av andra kallformade produkter av stål',
  },
  {
    code: 24340,
    text: 'Tillverkning av kalldragen ståltråd',
  },
  {
    code: 24410,
    text: 'Framställning av ädla metaller',
  },
  {
    code: 24420,
    text: 'Framställning av aluminium',
  },
  {
    code: 24430,
    text: 'Framställning av bly, zink och tenn',
  },
  {
    code: 24440,
    text: 'Framställning av koppar',
  },
  {
    code: 24450,
    text: 'Framställning av andra metaller',
  },
  {
    code: 24460,
    text: 'Tillverkning av kärnbränsle',
  },
  {
    code: 24510,
    text: 'Gjutning av järn',
  },
  {
    code: 24520,
    text: 'Gjutning av stål',
  },
  {
    code: 24530,
    text: 'Gjutning av lättmetall',
  },
  {
    code: 24540,
    text: 'Gjutning av andra metaller',
  },
  {
    code: 25110,
    text: 'Tillverkning av metallstommar och delar därav',
  },
  {
    code: 25120,
    text: 'Tillverkning av dörrar och fönster av metall',
  },
  {
    code: 25210,
    text: 'Tillverkning av radiatorer och pannor för centraluppvärmning',
  },
  {
    code: 25290,
    text: 'Tillverkning av andra cisterner, tankar, kar och andra behållare av metall',
  },
  {
    code: 25300,
    text: 'Tillverkning av ånggeneratorer utom varmvattenpannor för centraluppvärmning',
  },
  {
    code: 25400,
    text: 'Tillverkning av vapen och ammunition',
  },
  {
    code: 25500,
    text: 'Smidning, pressning, prägling och valsning av metall; pulvermetallurgi',
  },
  {
    code: 25610,
    text: 'Beläggning och överdragning av metall',
  },
  {
    code: 25620,
    text: 'Metallegoarbeten',
  },
  {
    code: 25710,
    text: 'Tillverkning av bestick',
  },
  {
    code: 25720,
    text: 'Tillverkning av lås och gångjärn',
  },
  {
    code: 25730,
    text: 'Tillverkning av verktyg och redskap',
  },
  {
    code: 25910,
    text: 'Tillverkning av stålfat o.d. behållare',
  },
  {
    code: 25920,
    text: 'Tillverkning av lättmetallförpackningar',
  },
  {
    code: 25930,
    text: 'Tillverkning av metalltrådvaror, kedjor och fjädrar',
  },
  {
    code: 25940,
    text: 'Tillverkning av nitar och skruvar',
  },
  {
    code: 25991,
    text: 'Tillverkning av diskbänkar, sanitetsgods m. m. av metall för byggändamål',
  },
  {
    code: 25999,
    text: 'Diverse övrig metallvarutillverkning',
  },
  {
    code: 26110,
    text: 'Tillverkning av elektroniska komponenter',
  },
  {
    code: 26120,
    text: 'Tillverkning av kretskort',
  },
  {
    code: 26200,
    text: 'Tillverkning av datorer och kringutrustning',
  },
  {
    code: 26300,
    text: 'Tillverkning av kommunikationsutrustning',
  },
  {
    code: 26400,
    text: 'Tillverkning av hemelektronik',
  },
  {
    code: 26510,
    text: 'Tillverkning av instrument och apparater för mätning, provning och navigering',
  },
  {
    code: 26520,
    text: 'Urtillverkning',
  },
  {
    code: 26600,
    text: 'Tillverkning av strålningsutrustning samt elektromedicinsk och elektroterapeutisk utrustning',
  },
  {
    code: 26700,
    text: 'Tillverkning av optiska instrument och fotoutrustning',
  },
  {
    code: 26800,
    text: 'Tillverkning av magnetiska och optiska medier',
  },
  {
    code: 27110,
    text: 'Tillverkning av elmotorer, generatorer och transformatorer',
  },
  {
    code: 27120,
    text: 'Tillverkning av eldistributions- och elkontrollapparater',
  },
  {
    code: 27200,
    text: 'Batteri- och ackumulatortillverkning',
  },
  {
    code: 27310,
    text: 'Tillverkning av optiska fiberkablar',
  },
  {
    code: 27320,
    text: 'Tillverkning av andra elektroniska och elektriska ledningar och kablar',
  },
  {
    code: 27330,
    text: 'Tillverkning av kabeltillbehör',
  },
  {
    code: 27400,
    text: 'Tillverkning av belysningsarmatur',
  },
  {
    code: 27510,
    text: 'Tillverkning av elektriska hushållsmaskiner och hushållsapparater',
  },
  {
    code: 27520,
    text: 'Tillverkning av icke-elektriska hushållsmaskiner och hushållsapparater',
  },
  {
    code: 27900,
    text: 'Tillverkning av annan elapparatur',
  },
  {
    code: 28110,
    text: 'Tillverkning av motorer och turbiner utom för luftfartyg och fordon',
  },
  {
    code: 28120,
    text: 'Tillverkning av fluidteknisk utrustning',
  },
  {
    code: 28130,
    text: 'Tillverkning av andra pumpar och kompressorer',
  },
  {
    code: 28140,
    text: 'Tillverkning av andra kranar och ventiler',
  },
  {
    code: 28150,
    text: 'Tillverkning av lager, kugghjul och andra delar för kraftöverföring',
  },
  {
    code: 28210,
    text: 'Tillverkning av ugnar och brännare',
  },
  {
    code: 28220,
    text: 'Tillverkning av lyft- och godshanteringsanordningar',
  },
  {
    code: 28230,
    text: 'Tillverkning av kontorsmaskiner och kontorsutrustning (utom datorer och kringutrustning)',
  },
  {
    code: 28240,
    text: 'Tillverkning av motordrivna handverktyg',
  },
  {
    code: 28250,
    text: 'Tillverkning av maskiner och apparater för kyla och ventilation utom för hushåll',
  },
  {
    code: 28290,
    text: 'Övrig tillverkning av maskiner för allmänt ändamål',
  },
  {
    code: 28300,
    text: 'Tillverkning av jord- och skogsbruksmaskiner',
  },
  {
    code: 28410,
    text: 'Tillverkning av verktygsmaskiner för metallbearbetning',
  },
  {
    code: 28490,
    text: 'Tillverkning av övriga verktygsmaskiner',
  },
  {
    code: 28910,
    text: 'Tillverkning av maskiner för metallurgi',
  },
  {
    code: 28920,
    text: 'Tillverkning av gruv-, bergbrytnings- och byggmaskiner',
  },
  {
    code: 28930,
    text: 'Tillverkning av maskiner för framställning av livsmedel, drycker och tobaksvaror',
  },
  {
    code: 28940,
    text: 'Tillverkning av maskiner för produktion av textil-, beklädnads- och lädervaror',
  },
  {
    code: 28950,
    text: 'Tillverkning av maskiner för produktion av massa, papper och papp',
  },
  {
    code: 28960,
    text: 'Tillverkning av maskiner för gummi och plast',
  },
  {
    code: 28990,
    text: 'Tillverkning av övriga specialmaskiner',
  },
  {
    code: 29101,
    text: 'Tillverkning av personbilar och andra lätta motorfordon',
  },
  {
    code: 29102,
    text: 'Tillverkning av lastbilar och andra tunga motorfordon',
  },
  {
    code: 29200,
    text: 'Tillverkning av karosserier för motorfordon; tillverkning av släpfordon och påhängsvagnar',
  },
  {
    code: 29310,
    text: 'Tillverkning av elektrisk och elektronisk utrustning för motorfordon',
  },
  {
    code: 29320,
    text: 'Tillverkning av andra delar och tillbehör till motorfordon',
  },
  {
    code: 30110,
    text: 'Byggande av fartyg och flytande materiel',
  },
  {
    code: 30120,
    text: 'Byggande av fritidsbåtar',
  },
  {
    code: 30200,
    text: 'Tillverkning av rälsfordon',
  },
  {
    code: 30300,
    text: 'Tillverkning av luftfartyg, rymdfarkoster o.d.',
  },
  {
    code: 30400,
    text: 'Tillverkning av militära stridsfordon',
  },
  {
    code: 30910,
    text: 'Tillverkning av motorcyklar',
  },
  {
    code: 30920,
    text: 'Tillverkning av cyklar och invalidfordon',
  },
  {
    code: 30990,
    text: 'Diverse övrig transportmedelstillverkning',
  },
  {
    code: 31011,
    text: 'Tillverkning av kontors- och butiksmöbler',
  },
  {
    code: 31012,
    text: 'Tillverkning av kontors- och butiksinredningar',
  },
  {
    code: 31021,
    text: 'Tillverkning av köksmöbler',
  },
  {
    code: 31022,
    text: 'Tillverkning av köksinredningar',
  },
  {
    code: 31030,
    text: 'Tillverkning av madrasser',
  },
  {
    code: 31090,
    text: 'Tillverkning av andra möbler',
  },
  {
    code: 32110,
    text: 'Prägling av mynt',
  },
  {
    code: 32120,
    text: 'Tillverkning av smycken, guld- och silversmedsvaror',
  },
  {
    code: 32130,
    text: 'Tillverkning av bijouterier o.d.',
  },
  {
    code: 32200,
    text: 'Tillverkning av musikinstrument',
  },
  {
    code: 32300,
    text: 'Tillverkning av sportartiklar',
  },
  {
    code: 32400,
    text: 'Tillverkning av spel och leksaker',
  },
  {
    code: 32501,
    text: 'Tillverkning av medicinska och dentala instrument och tillbehör',
  },
  {
    code: 32502,
    text: 'Tillverkning av tandproteser',
  },
  {
    code: 32910,
    text: 'Tillverkning av borstbinderiarbeten',
  },
  {
    code: 32990,
    text: 'Diverse övrig tillverkning',
  },
  {
    code: 33110,
    text: 'Reparation av metallvaror',
  },
  {
    code: 33120,
    text: 'Reparation av maskiner',
  },
  {
    code: 33130,
    text: 'Reparation av elektronisk och optisk utrustning',
  },
  {
    code: 33140,
    text: 'Reparation av elapparatur',
  },
  {
    code: 33150,
    text: 'Reparation och underhåll av fartyg och båtar',
  },
  {
    code: 33160,
    text: 'Reparation och underhåll av luftfartyg och rymdfarkoster',
  },
  {
    code: 33170,
    text: 'Reparation och underhåll av andra transportmedel',
  },
  {
    code: 33190,
    text: 'Reparation av annan utrustning',
  },
  {
    code: 33200,
    text: 'Installation av industrimaskiner och -utrustning',
  },
  {
    code: 35110,
    text: 'Generering av elektricitet',
  },
  {
    code: 35120,
    text: 'Överföring av elektricitet',
  },
  {
    code: 35130,
    text: 'Distribution av elektricitet',
  },
  {
    code: 35140,
    text: 'Handel med elektricitet',
  },
  {
    code: 35210,
    text: 'Framställning av gas',
  },
  {
    code: 35220,
    text: 'Distribution av gasformiga bränslen via rörnät',
  },
  {
    code: 35230,
    text: 'Handel med gas via rörnät',
  },
  {
    code: 35300,
    text: 'Försörjning av värme och kyla',
  },
  {
    code: 36001,
    text: 'Grundvattenförsörjning',
  },
  {
    code: 36002,
    text: 'Ytvattenförsörjning',
  },
  {
    code: 37000,
    text: 'Avloppsrening',
  },
  {
    code: 38110,
    text: 'Insamling av icke-farligt avfall',
  },
  {
    code: 38120,
    text: 'Insamling av farligt avfall',
  },
  {
    code: 38210,
    text: 'Behandling och bortskaffande av icke-farligt avfall',
  },
  {
    code: 38220,
    text: 'Behandling och bortskaffande av farligt avfall',
  },
  {
    code: 38311,
    text: 'Demontering av uttjänta fordon',
  },
  {
    code: 38312,
    text: 'Demontering av elektrisk och elektronisk utrustning',
  },
  {
    code: 38319,
    text: 'Demontering av övrig kasserad utrustning',
  },
  {
    code: 38320,
    text: 'Återvinning av källsorterat material',
  },
  {
    code: 39000,
    text: 'Sanering, efterbehandling av jord och vatten samt annan verksamhet för föroreningsbekämpning',
  },
  {
    code: 41100,
    text: 'Utformning av byggprojekt',
  },
  {
    code: 41200,
    text: 'Byggande av bostadshus och andra byggnader',
  },
  {
    code: 42110,
    text: 'Anläggning av vägar och motorvägar',
  },
  {
    code: 42120,
    text: 'Anläggning av järnvägar och tunnelbanor',
  },
  {
    code: 42130,
    text: 'Anläggning av broar och tunnlar',
  },
  {
    code: 42210,
    text: 'Allmännyttiga anläggningsarbeten för värme, vatten och avlopp',
  },
  {
    code: 42220,
    text: 'Anläggningsarbeten för el och telekommunikation',
  },
  {
    code: 42910,
    text: 'Vattenbyggnad',
  },
  {
    code: 42990,
    text: 'Övriga anläggningsarbeten',
  },
  {
    code: 43110,
    text: 'Rivning av hus och byggnader',
  },
  {
    code: 43120,
    text: 'Mark- och grundarbeten',
  },
  {
    code: 43130,
    text: 'Markundersökning',
  },
  {
    code: 43210,
    text: 'Elinstallationer',
  },
  {
    code: 43221,
    text: 'Värme- och sanitetsarbeten',
  },
  {
    code: 43222,
    text: 'Ventilationsarbeten',
  },
  {
    code: 43223,
    text: 'Kyl- och frysinstallationsarbeten',
  },
  {
    code: 43229,
    text: 'Övriga VVS-arbeten',
  },
  {
    code: 43290,
    text: 'Andra bygginstallationer',
  },
  {
    code: 43310,
    text: 'Puts-, fasad- och stuckatörsarbeten',
  },
  {
    code: 43320,
    text: 'Byggnadssnickeriarbeten',
  },
  {
    code: 43330,
    text: 'Golv- och väggbeläggningsarbeten',
  },
  {
    code: 43341,
    text: 'Måleriarbeten',
  },
  {
    code: 43342,
    text: 'Glasmästeriarbeten',
  },
  {
    code: 43390,
    text: 'Annan slutbehandling av byggnader',
  },
  {
    code: 43911,
    text: 'Takarbeten av plåt',
  },
  {
    code: 43912,
    text: 'Takarbeten av andra material än plåt',
  },
  {
    code: 43991,
    text: 'Uthyrning av bygg- och anläggningsmaskiner med förare',
  },
  {
    code: 43999,
    text: 'Diverse övrig specialiserad bygg- och anläggningsverksamhet',
  },
  {
    code: 45110,
    text: 'Handel med personbilar och lätta motorfordon',
  },
  {
    code: 45191,
    text: 'Handel med lastbilar, bussar och specialfordon',
  },
  {
    code: 45192,
    text: 'Handel med husvagnar, husbilar, släpfordon och påhängsvagnar',
  },
  {
    code: 45201,
    text: 'Allmän service och reparation av motorfordon utom motorcyklar',
  },
  {
    code: 45202,
    text: 'Plåt-, lack- och glasreparationer på motorfordon utom motorcyklar',
  },
  {
    code: 45203,
    text: 'Installationer och reparationer av elsystem till motorfordon utom motorcyklar',
  },
  {
    code: 45204,
    text: 'Däckservice',
  },
  {
    code: 45310,
    text: 'Parti- och provisionshandel med reservdelar och tillbehör till motorfordon utom motorcyklar',
  },
  {
    code: 45320,
    text: 'Detaljhandel med reservdelar och tillbehör till motorfordon utom motorcyklar',
  },
  {
    code: 45400,
    text: 'Handel med och service av motorcyklar inklusive reservdelar och tillbehör',
  },
  {
    code: 46110,
    text: 'Provisionshandel med jordbruksråvaror, levande djur, textilråvaror och textilhalvfabrikat',
  },
  {
    code: 46120,
    text: 'Provisionshandel med bränsle, malm, metaller och industrikemikalier',
  },
  {
    code: 46130,
    text: 'Provisionshandel med virke och byggmaterial',
  },
  {
    code: 46141,
    text: 'Provisionshandel med maskiner, industriell utrustning, fartyg och luftfartyg utom kontorsutrustning och datorer',
  },
  {
    code: 46142,
    text: 'Provisionshandel med kontorsutrustning och datorer',
  },
  {
    code: 46150,
    text: 'Provisionshandel med möbler, hushålls- och järnhandelsvaror',
  },
  {
    code: 46160,
    text: 'Provisionshandel med textilier, kläder, skodon och lädervaror',
  },
  {
    code: 46170,
    text: 'Provisionshandel med livsmedel, drycker och tobak',
  },
  {
    code: 46180,
    text: 'Provisionshandel med annat specialsortiment',
  },
  {
    code: 46190,
    text: 'Provisionshandel med blandat sortiment',
  },
  {
    code: 46210,
    text: 'Partihandel med spannmål, råtobak, utsäde och djurfoder',
  },
  {
    code: 46220,
    text: 'Partihandel med blommor och växter',
  },
  {
    code: 46230,
    text: 'Partihandel med levande djur',
  },
  {
    code: 46240,
    text: 'Partihandel med hudar, skinn och läder',
  },
  {
    code: 46310,
    text: 'Partihandel med frukt och grönsaker',
  },
  {
    code: 46320,
    text: 'Partihandel med kött- och köttvaror',
  },
  {
    code: 46330,
    text: 'Partihandel med mejeriprodukter, ägg, matolja och matfett',
  },
  {
    code: 46340,
    text: 'Partihandel med drycker',
  },
  {
    code: 46350,
    text: 'Partihandel med tobak',
  },
  {
    code: 46360,
    text: 'Partihandel med socker, choklad och sockerkonfektyrer',
  },
  {
    code: 46370,
    text: 'Partihandel med kaffe, te, kakao och kryddor',
  },
  {
    code: 46380,
    text: 'Partihandel med andra livsmedel, bl.a. fisk samt skal- och blötdjur',
  },
  {
    code: 46390,
    text: 'Icke specialiserad partihandel med livsmedel, drycker och tobak',
  },
  {
    code: 46410,
    text: 'Partihandel med textilier',
  },
  {
    code: 46420,
    text: 'Partihandel med kläder och skodon',
  },
  {
    code: 46431,
    text: 'Partihandel med elektriska hushållsmaskiner och -apparater',
  },
  {
    code: 46432,
    text: 'Partihandel med ljud- och bildanläggningar samt videoutrustning',
  },
  {
    code: 46433,
    text: 'Partihandel med inspelade band och skivor för musik och bild',
  },
  {
    code: 46434,
    text: 'Partihandel med elartiklar',
  },
  {
    code: 46435,
    text: 'Partihandel med fotografiska och optiska produkter',
  },
  {
    code: 46440,
    text: 'Partihandel med glas och porslin, rengöringsmedel',
  },
  {
    code: 46450,
    text: 'Partihandel med parfym och kosmetika',
  },
  {
    code: 46460,
    text: 'Partihandel med medicinsk utrustning och apoteksvaror',
  },
  {
    code: 46470,
    text: 'Partihandel med möbler, mattor och belysningsartiklar',
  },
  {
    code: 46480,
    text: 'Partihandel med ur och guldsmedsvaror',
  },
  {
    code: 46491,
    text: 'Partihandel med sport- och fritidsartiklar',
  },
  {
    code: 46492,
    text: 'Partihandel med kontorsförbrukningsvaror',
  },
  {
    code: 46499,
    text: 'Partihandel med övriga hushållsvaror',
  },
  {
    code: 46510,
    text: 'Partihandel med datorer och kringutrustning samt programvara',
  },
  {
    code: 46521,
    text: 'Partihandel med elektronikkomponenter',
  },
  {
    code: 46522,
    text: 'Partihandel med teleprodukter',
  },
  {
    code: 46610,
    text: 'Partihandel med jordbruksmaskiner och -utrustning',
  },
  {
    code: 46620,
    text: 'Partihandel med verktygsmaskiner',
  },
  {
    code: 46630,
    text: 'Partihandel med gruv-, bygg- och anläggningsmaskiner',
  },
  {
    code: 46640,
    text: 'Partihandel med textil-, sy- och stickmaskiner',
  },
  {
    code: 46650,
    text: 'Partihandel med kontorsmöbler',
  },
  {
    code: 46660,
    text: 'Partihandel med andra kontorsmaskiner och kontorsutrustning',
  },
  {
    code: 46691,
    text: 'Partihandel med mät- och precisionsinstrument',
  },
  {
    code: 46692,
    text: 'Partihandel med datoriserad materialhanteringsutrustning',
  },
  {
    code: 46699,
    text: 'Partihandel med diverse övriga maskiner och utrustning',
  },
  {
    code: 46710,
    text: 'Partihandel med bränslen',
  },
  {
    code: 46720,
    text: 'Partihandel med metaller och metallmalmer',
  },
  {
    code: 46731,
    text: 'Partihandel med virke och andra byggmaterial',
  },
  {
    code: 46732,
    text: 'Partihandel med sanitetsgods',
  },
  {
    code: 46741,
    text: 'Partihandel med järnhandelsvaror',
  },
  {
    code: 46742,
    text: 'Partihandel med VVS-varor',
  },
  {
    code: 46750,
    text: 'Partihandel med kemiska produkter',
  },
  {
    code: 46761,
    text: 'Partihandel med industriförnödenheter',
  },
  {
    code: 46762,
    text: 'Partihandel med emballage',
  },
  {
    code: 46769,
    text: 'Partihandel med övriga insatsvaror',
  },
  {
    code: 46771,
    text: 'Partihandel med uttjänta fordon',
  },
  {
    code: 46772,
    text: 'Partihandel med metallavfall och metallskrot',
  },
  {
    code: 46773,
    text: 'Partihandel med avfall och skrot av icke-metall',
  },
  {
    code: 46900,
    text: 'Övrig partihandel',
  },
  {
    code: 47111,
    text: 'Varuhus- och stormarknadshandel, mest livsmedel, drycker och tobak',
  },
  {
    code: 47112,
    text: 'Livsmedelshandel med brett sortiment, ej varuhus eller stormarknad',
  },
  {
    code: 47191,
    text: 'Annan varuhus- och stormarknadshandel',
  },
  {
    code: 47199,
    text: 'Övrig detaljhandel med brett sortiment',
  },
  {
    code: 47210,
    text: 'Specialiserad butikshandel med frukt och grönsaker',
  },
  {
    code: 47220,
    text: 'Specialiserad butikshandel med kött och charkuterier',
  },
  {
    code: 47230,
    text: 'Specialiserad butikshandel med fisk, skal- och blötdjur',
  },
  {
    code: 47241,
    text: 'Specialiserad butikshandel med bröd och konditorivaror',
  },
  {
    code: 47242,
    text: 'Specialiserad butikshandel med konfektyrer',
  },
  {
    code: 47250,
    text: 'Specialiserad butikshandel med alkoholhaltiga och andra drycker',
  },
  {
    code: 47260,
    text: 'Specialiserad butikshandel med tobaksvaror',
  },
  {
    code: 47291,
    text: 'Specialiserad butikshandel med hälsokost',
  },
  {
    code: 47299,
    text: 'Övrig specialiserad butikshandel med livsmedel',
  },
  {
    code: 47300,
    text: 'Specialiserad detaljhandel med drivmedel',
  },
  {
    code: 47410,
    text: 'Specialiserad butikshandel med datorer, programvara, data- och tv-spel',
  },
  {
    code: 47420,
    text: 'Specialiserad butikshandel med telekommunikationsutrustning',
  },
  {
    code: 47430,
    text: 'Specialiserad butikshandel med ljud- och bildanläggningar samt videoutrustning',
  },
  {
    code: 47510,
    text: 'Specialiserad butikshandel med textilier',
  },
  {
    code: 47521,
    text: 'Specialiserad butikshandel med virke och byggvaror',
  },
  {
    code: 47522,
    text: 'Specialiserad butikshandel med järn- och VVS-varor',
  },
  {
    code: 47523,
    text: 'Specialiserad butikshandel med färger, fernissor och lacker',
  },
  {
    code: 47531,
    text: 'Specialiserad butikshandel med mattor och annan vägg- och golvbeklädnad',
  },
  {
    code: 47532,
    text: 'Specialiserad butikshandel med inredningstextilier',
  },
  {
    code: 47540,
    text: 'Specialiserad butikshandel med elektriska hushållsmaskiner och hushållsapparater',
  },
  {
    code: 47591,
    text: 'Specialiserad butikshandel med möbler för hemmet',
  },
  {
    code: 47592,
    text: 'Specialiserad butikshandel med kontorsmöbler',
  },
  {
    code: 47593,
    text: 'Specialiserad butikshandel med glas, porslin och andra bosättningsvaror',
  },
  {
    code: 47594,
    text: 'Specialiserad butikshandel med belysningsartiklar',
  },
  {
    code: 47595,
    text: 'Specialiserad butikshandel med musikinstrument och noter',
  },
  {
    code: 47610,
    text: 'Specialiserad butikshandel med böcker',
  },
  {
    code: 47621,
    text: 'Specialiserad butikshandel med tidningar',
  },
  {
    code: 47622,
    text: 'Specialiserad butikshandel med kontorsförbrukningsvaror',
  },
  {
    code: 47630,
    text: 'Specialiserad butikshandel med inspelade och oinspelade band och skivor för musik och bild',
  },
  {
    code: 47641,
    text: 'Specialiserad butikshandel med sport- och fritidsartiklar utom cyklar och båtar',
  },
  {
    code: 47642,
    text: 'Specialiserad butikshandel med cyklar',
  },
  {
    code: 47643,
    text: 'Specialiserad butikshandel med båtar',
  },
  {
    code: 47650,
    text: 'Specialiserad butikshandel med spel och leksaker',
  },
  {
    code: 47711,
    text: 'Specialiserad butikshandel med herr-, dam- och barnkläder, blandat',
  },
  {
    code: 47712,
    text: 'Specialiserad butikshandel med herrkläder',
  },
  {
    code: 47713,
    text: 'Specialiserad butikshandel med damkläder',
  },
  {
    code: 47714,
    text: 'Specialiserad butikshandel med barnkläder',
  },
  {
    code: 47715,
    text: 'Specialiserad butikshandel med pälsar',
  },
  {
    code: 47721,
    text: 'Specialiserad butikshandel med skodon',
  },
  {
    code: 47722,
    text: 'Specialiserad butikshandel med väskor, reseffekter och lädervaror',
  },
  {
    code: 47730,
    text: 'Apotekshandel',
  },
  {
    code: 47740,
    text: 'Specialiserad butikshandel med sjukvårdsartiklar',
  },
  {
    code: 47750,
    text: 'Specialiserad butikshandel med kosmetika och hygienartiklar',
  },
  {
    code: 47761,
    text: 'Specialiserad butikshandel med blommor och andra växter, frön och gödselmedel',
  },
  {
    code: 47762,
    text: 'Specialiserad butikshandel med små sällskapsdjur',
  },
  {
    code: 47771,
    text: 'Specialiserad butikshandel med ur',
  },
  {
    code: 47772,
    text: 'Specialiserad butikshandel med guldsmedsvaror och smycken',
  },
  {
    code: 47781,
    text: 'Specialiserad butikshandel med glasögon och andra optiska artiklar utom fotoutrustning',
  },
  {
    code: 47782,
    text: 'Specialiserad butikshandel med fotoutrustning',
  },
  {
    code: 47783,
    text: 'PT',
  },
  {
    code: 47784,
    text: 'Specialiserad butikshandel med mynt och frimärken',
  },
  {
    code: 47789,
    text: 'Övrig specialiserad butikshandel',
  },
  {
    code: 47791,
    text: 'Specialiserad butikshandel med antikviteter och begagnade böcker',
  },
  {
    code: 47792,
    text: 'Specialiserad butikshandel med övriga begagnade varor',
  },
  {
    code: 47793,
    text: 'Auktioner i butik',
  },
  {
    code: 47810,
    text: 'Torg- och marknadshandel med livsmedel, drycker och tobak',
  },
  {
    code: 47820,
    text: 'Torg- och marknadshandel med textilier, kläder och skodon',
  },
  {
    code: 47890,
    text: 'Torg- och marknadshandel med övriga varor',
  },
  {
    code: 47911,
    text: 'Postorderhandel och detaljhandel på Internet med brett sortiment',
  },
  {
    code: 47912,
    text: 'Postorderhandel och detaljhandel på Internet med beklädnadsvaror',
  },
  {
    code: 47913,
    text: 'Postorderhandel och detaljhandel på Internet med böcker och andra mediavaror',
  },
  {
    code: 47914,
    text: 'Postorderhandel och detaljhandel på Internet med datorer och annan elektronisk utrustning',
  },
  {
    code: 47915,
    text: 'Postorderhandel och detaljhandel på Internet med sport- och fritidsutrustning',
  },
  {
    code: 47916,
    text: 'Postorderhandel och detaljhandel på Internet med bosättningsvaror',
  },
  {
    code: 47917,
    text: 'Auktioner på Internet',
  },
  {
    code: 47919,
    text: 'Postorderhandel och detaljhandel på Internet med övriga varor',
  },
  {
    code: 47991,
    text: 'Provisionsdetaljhandel (ej auktioner)',
  },
  {
    code: 47992,
    text: 'Ambulerande och tillfällig handel med livsmedel',
  },
  {
    code: 47993,
    text: 'Ambulerande och tillfällig handel med övriga varor',
  },
  {
    code: 47994,
    text: 'Auktioner ej i butik eller på Internet',
  },
  {
    code: 47999,
    text: 'Övrig detaljhandel ej i butik',
  },
  {
    code: 49100,
    text: 'Järnvägstransport, passagerartrafik',
  },
  {
    code: 49200,
    text: 'Järnvägstransport, godstrafik',
  },
  {
    code: 49311,
    text: 'Linjebussverksamhet',
  },
  {
    code: 49319,
    text: 'Övrig kollektivtrafik',
  },
  {
    code: 49320,
    text: 'Taxitrafik',
  },
  {
    code: 49390,
    text: 'Annan landtransport av passagerare',
  },
  {
    code: 49410,
    text: 'Vägtransport, godstrafik',
  },
  {
    code: 49420,
    text: 'Flyttjänster',
  },
  {
    code: 49500,
    text: 'Transport i rörsystem',
  },
  {
    code: 50101,
    text: 'Reguljär sjötrafik över hav och kust av passagerare',
  },
  {
    code: 50102,
    text: 'Icke reguljär sjötrafik över hav och kust av passagerare',
  },
  {
    code: 50201,
    text: 'Reguljär sjötrafik över hav och kust av gods',
  },
  {
    code: 50202,
    text: 'Icke reguljär sjötrafik över hav och kust av gods',
  },
  {
    code: 50301,
    text: 'Reguljär sjötrafik på inre vattenvägar av passagerare',
  },
  {
    code: 50302,
    text: 'Icke reguljär sjötrafik på inre vattenvägar av passagerare',
  },
  {
    code: 50401,
    text: 'Reguljär sjötrafik på inre vattenvägar av gods',
  },
  {
    code: 50402,
    text: 'Icke reguljär sjötrafik på inre vattenvägar av gods',
  },
  {
    code: 51101,
    text: 'Reguljär lufttransport av passagerare',
  },
  {
    code: 51102,
    text: 'Icke reguljär lufttransport av passagerare',
  },
  {
    code: 51211,
    text: 'Reguljär lufttransport av gods',
  },
  {
    code: 51212,
    text: 'Icke reguljär lufttransport av gods',
  },
  {
    code: 51220,
    text: 'Rymdfart',
  },
  {
    code: 52100,
    text: 'Magasinering och varulagring',
  },
  {
    code: 52211,
    text: 'Bärgning för landtransport',
  },
  {
    code: 52219,
    text: 'Övriga stödtjänster till landtransport',
  },
  {
    code: 52220,
    text: 'Stödtjänster till sjötransport',
  },
  {
    code: 52230,
    text: 'Stödtjänster till lufttransport',
  },
  {
    code: 52241,
    text: 'Hamngodshantering',
  },
  {
    code: 52249,
    text: 'Övrig godshantering',
  },
  {
    code: 52290,
    text: 'Övriga stödtjänster till transport',
  },
  {
    code: 53100,
    text: 'Postbefordran via nationella posten',
  },
  {
    code: 53201,
    text: 'Annan postbefordran',
  },
  {
    code: 53202,
    text: 'Bud- och kurirverksamhet',
  },
  {
    code: 53203,
    text: 'Tidningsdistribution',
  },
  {
    code: 55101,
    text: 'Hotellverksamhet med restaurangrörelse',
  },
  {
    code: 55102,
    text: 'Drift av konferensanläggningar',
  },
  {
    code: 55103,
    text: 'Hotellverksamhet utan restaurangrörelse',
  },
  {
    code: 55201,
    text: 'Vandrarhemsverksamhet',
  },
  {
    code: 55202,
    text: 'Stugbyverksamhet m.m.',
  },
  {
    code: 55300,
    text: 'Campingplatsverksamhet',
  },
  {
    code: 55900,
    text: 'Annan logiverksamhet',
  },
  {
    code: 56100,
    text: 'Restaurangverksamhet',
  },
  {
    code: 56210,
    text: 'Cateringverksamhet vid enskilda evenemang',
  },
  {
    code: 56291,
    text: 'Drift av personalmatsalar',
  },
  {
    code: 56292,
    text: 'Centralköksverksamhet för sjukhus',
  },
  {
    code: 56293,
    text: 'Centralköksverksamhet för skolor, omsorgs- och andra institutioner',
  },
  {
    code: 56294,
    text: 'Cateringverksamhet för transportsektorn',
  },
  {
    code: 56299,
    text: 'Övrig cateringverksamhet',
  },
  {
    code: 56300,
    text: 'Barverksamhet',
  },
  {
    code: 58110,
    text: 'Bokutgivning',
  },
  {
    code: 58120,
    text: 'Publicering av kataloger och sändlistor',
  },
  {
    code: 58131,
    text: 'Dagstidningsutgivning',
  },
  {
    code: 58132,
    text: 'Annonstidningsutgivning',
  },
  {
    code: 58140,
    text: 'Utgivning av tidskrifter',
  },
  {
    code: 58190,
    text: 'Annan förlagsverksamhet',
  },
  {
    code: 58210,
    text: 'Utgivning av dataspel',
  },
  {
    code: 58290,
    text: 'Utgivning av annan programvara',
  },
  {
    code: 59110,
    text: 'Produktion av film, video och TV-program',
  },
  {
    code: 59120,
    text: 'Efterproduktion av film, video och TV-program',
  },
  {
    code: 59130,
    text: 'Film-, video- och TV-programdistribution',
  },
  {
    code: 59140,
    text: 'Filmvisning',
  },
  {
    code: 59200,
    text: 'Ljudinspelning och fonogramutgivning',
  },
  {
    code: 60100,
    text: 'Sändning av radioprogram',
  },
  {
    code: 60200,
    text: 'Planering av TV-program och sändningsverksamhet',
  },
  {
    code: 61100,
    text: 'Trådbunden telekommunikation',
  },
  {
    code: 61200,
    text: 'Trådlös telekommunikation',
  },
  {
    code: 61300,
    text: 'Telekommunikation via satellit',
  },
  {
    code: 61900,
    text: 'Annan telekommunikation',
  },
  {
    code: 62010,
    text: 'Dataprogrammering',
  },
  {
    code: 62020,
    text: 'Datakonsultverksamhet',
  },
  {
    code: 62030,
    text: 'Datordrifttjänster',
  },
  {
    code: 62090,
    text: 'Andra IT- och datatjänster',
  },
  {
    code: 63110,
    text: 'Databehandling, hosting o.d.',
  },
  {
    code: 63120,
    text: 'Webbportaler',
  },
  {
    code: 63910,
    text: 'Nyhetsservice',
  },
  {
    code: 63990,
    text: 'Övriga informationstjänster',
  },
  {
    code: 64110,
    text: 'Centralbanksverksamhet',
  },
  {
    code: 64190,
    text: 'Annan monetär finansförmedling',
  },
  {
    code: 64201,
    text: 'Holdingverksamhet i finansiella koncerner',
  },
  {
    code: 64202,
    text: 'Holdingverksamhet i icke-finansiella koncerner',
  },
  {
    code: 64301,
    text: 'Investeringsfonder',
  },
  {
    code: 64309,
    text: 'Andra fonder och liknande finansiella enheter',
  },
  {
    code: 64910,
    text: 'Finansiell leasing',
  },
  {
    code: 64920,
    text: 'Annan kreditgivning',
  },
  {
    code: 64991,
    text: 'Investment- och riskkapitalbolagsverksamhet',
  },
  {
    code: 64992,
    text: 'Handel med och förvaltning av värdepapper, för egen räkning',
  },
  {
    code: 64993,
    text: 'Förvaltning av och handel med värdepapper för en begränsad och sluten krets av ägare',
  },
  {
    code: 64999,
    text: 'Diverse övrig finansförmedling',
  },
  {
    code: 65111,
    text: 'Fondanknuten livförsäkring',
  },
  {
    code: 65119,
    text: 'Övrig livförsäkring',
  },
  {
    code: 65120,
    text: 'Skadeförsäkring',
  },
  {
    code: 65200,
    text: 'Återförsäkring',
  },
  {
    code: 65300,
    text: 'Pensionsfondsverksamhet',
  },
  {
    code: 66110,
    text: 'Administrativa tjänster till finansiella marknader',
  },
  {
    code: 66120,
    text: 'Verksamhet utförd av värdepappers- och varumäklare',
  },
  {
    code: 66190,
    text: 'Andra stödtjänster till finansiella tjänster utom försäkring och pensionsfondsverksamhet',
  },
  {
    code: 66210,
    text: 'Risk- och skadebedömning',
  },
  {
    code: 66220,
    text: 'Verksamhet utförd av försäkringsombud och försäkringsmäklare',
  },
  {
    code: 66290,
    text: 'Andra stödtjänster till försäkring och pensionsfondsverksamhet',
  },
  {
    code: 66301,
    text: 'Förvaltning av investeringsfonder',
  },
  {
    code: 66309,
    text: 'Annan fondförvaltning',
  },
  {
    code: 68100,
    text: 'Handel med egna fastigheter',
  },
  {
    code: 68201,
    text: 'Uthyrning och förvaltning av egna eller arrenderade bostäder',
  },
  {
    code: 68202,
    text: 'Uthyrning och förvaltning av egna eller arrenderade industrilokaler',
  },
  {
    code: 68203,
    text: 'Uthyrning och förvaltning av egna eller arrenderade, andra lokaler',
  },
  {
    code: 68204,
    text: 'Förvaltning i bostadsrättsföreningar',
  },
  {
    code: 68209,
    text: 'Övrig förvaltning av egna eller arrenderade fastigheter',
  },
  {
    code: 68310,
    text: 'Fastighetsförmedling',
  },
  {
    code: 68320,
    text: 'Fastighetsförvaltning på uppdrag',
  },
  {
    code: 69101,
    text: 'Advokatbyråverksamhet',
  },
  {
    code: 69102,
    text: 'Juridiska byråers verksamhet m.m.',
  },
  {
    code: 69103,
    text: 'Patentbyråverksamhet m.m.',
  },
  {
    code: 69201,
    text: 'Redovisning och bokföring',
  },
  {
    code: 69202,
    text: 'Revision',
  },
  {
    code: 69203,
    text: 'Skatterådgivning',
  },
  {
    code: 70100,
    text: 'Verksamheter som utövas av huvudkontor',
  },
  {
    code: 70210,
    text: 'PR och kommunikation',
  },
  {
    code: 70220,
    text: 'Konsultverksamhet avseende företags organisation',
  },
  {
    code: 71110,
    text: 'Arkitektverksamhet',
  },
  {
    code: 71121,
    text: 'Teknisk konsultverksamhet inom bygg- och anläggningsteknik',
  },
  {
    code: 71122,
    text: 'Teknisk konsultverksamhet inom industriteknik',
  },
  {
    code: 71123,
    text: 'Teknisk konsultverksamhet inom elteknik',
  },
  {
    code: 71124,
    text: 'Teknisk konsultverksamhet inom energi-, miljö- och VVS-teknik',
  },
  {
    code: 71129,
    text: 'Övrig teknisk konsultverksamhet',
  },
  {
    code: 71200,
    text: 'Teknisk provning och analys',
  },
  {
    code: 72110,
    text: 'Bioteknisk forskning och utveckling',
  },
  {
    code: 72190,
    text: 'Annan naturvetenskaplig och teknisk forskning och utveckling',
  },
  {
    code: 72200,
    text: 'Samhällsvetenskaplig och humanistisk forskning och utveckling',
  },
  {
    code: 73111,
    text: 'Reklambyråverksamhet',
  },
  {
    code: 73112,
    text: 'Direktreklamverksamhet',
  },
  {
    code: 73119,
    text: 'Övrig reklamverksamhet',
  },
  {
    code: 73120,
    text: 'Mediebyråverksamhet och annonsförsäljning',
  },
  {
    code: 73200,
    text: 'Marknads- och opinionsundersökning',
  },
  {
    code: 74101,
    text: 'Industri- och produktdesignverksamhet',
  },
  {
    code: 74102,
    text: 'Grafisk designverksamhet',
  },
  {
    code: 74103,
    text: 'Inredningsarkitektverksamhet',
  },
  {
    code: 74201,
    text: 'Porträttfotoverksamhet',
  },
  {
    code: 74202,
    text: 'Reklamfotoverksamhet',
  },
  {
    code: 74203,
    text: 'Press- och övrig fotografverksamhet',
  },
  {
    code: 74204,
    text: 'Fotolaboratorieverksamhet',
  },
  {
    code: 74300,
    text: 'Översättning och tolkning',
  },
  {
    code: 74900,
    text: 'Övrig verksamhet inom juridik, ekonomi, vetenskap och teknik',
  },
  {
    code: 75000,
    text: 'Veterinärverksamhet',
  },
  {
    code: 77110,
    text: 'Uthyrning och leasing av personbilar och lätta motorfordon',
  },
  {
    code: 77120,
    text: 'Uthyrning och leasing av lastbilar och andra tunga motorfordon',
  },
  {
    code: 77210,
    text: 'Uthyrning och leasing av fritids- och sportutrustning',
  },
  {
    code: 77220,
    text: 'Uthyrning av videokassetter och dvd-skivor',
  },
  {
    code: 77290,
    text: 'Uthyrning och leasing av andra hushållsartiklar och varor för personligt bruk',
  },
  {
    code: 77310,
    text: 'Uthyrning och leasing av jordbruksmaskiner och jordbruksredskap',
  },
  {
    code: 77320,
    text: 'Uthyrning och leasing av bygg- och anläggningsmaskiner',
  },
  {
    code: 77330,
    text: 'Uthyrning och leasing av kontorsmaskiner och kontorsutrustning (inklusive datorer)',
  },
  {
    code: 77340,
    text: 'Uthyrning och leasing av fartyg och båtar',
  },
  {
    code: 77350,
    text: 'Uthyrning och leasing av flygplan',
  },
  {
    code: 77390,
    text: 'Uthyrning och leasing av övrig utrustning och övriga maskiner och materiella tillgångar',
  },
  {
    code: 77400,
    text: 'Leasing av immateriell egendom och liknande produkter, med undantag för upphovsrättsskyddade verk',
  },
  {
    code: 78100,
    text: 'Arbetsförmedling och rekrytering',
  },
  {
    code: 78200,
    text: 'Personaluthyrning',
  },
  {
    code: 78300,
    text: 'Övrigt tillhandahållande av personalfunktioner',
  },
  {
    code: 79110,
    text: 'Resebyråverksamhet',
  },
  {
    code: 79120,
    text: 'Researrangemang',
  },
  {
    code: 79900,
    text: 'Turist- och bokningsservice',
  },
  {
    code: 80100,
    text: 'Säkerhetsverksamhet',
  },
  {
    code: 80200,
    text: 'Säkerhetssystemtjänster',
  },
  {
    code: 80300,
    text: 'Spanings- och detektivverksamhet',
  },
  {
    code: 81100,
    text: 'Fastighetsrelaterade stödtjänster',
  },
  {
    code: 81210,
    text: 'Lokalvård',
  },
  {
    code: 81221,
    text: 'Rengöring av byggnader',
  },
  {
    code: 81222,
    text: 'Skorstensfejarverksamhet',
  },
  {
    code: 81290,
    text: 'Annan rengöring',
  },
  {
    code: 81300,
    text: 'Skötsel och underhåll av grönytor',
  },
  {
    code: 82110,
    text: 'Kombinerade kontorstjänster',
  },
  {
    code: 82190,
    text: 'Kopiering, dokumentsammanställning och andra specialiserade kontorstjänster',
  },
  {
    code: 82200,
    text: 'Callcenterverksamhet',
  },
  {
    code: 82300,
    text: 'Arrangemang av kongresser och mässor',
  },
  {
    code: 82910,
    text: 'Inkassoföretags och kreditupplysningsföretags verksamhet',
  },
  {
    code: 82920,
    text: 'Förpackningsverksamhet',
  },
  {
    code: 82990,
    text: 'Övriga företagstjänster',
  },
  {
    code: 84111,
    text: 'Stats- och kommunledning, lagstiftning och övergripande planering',
  },
  {
    code: 84112,
    text: 'Inspektion, kontroll, tillståndsgivning',
  },
  {
    code: 84113,
    text: 'Skatteförvaltning, indrivning',
  },
  {
    code: 84114,
    text: 'Samhällelig informationsförsörjning',
  },
  {
    code: 84115,
    text: 'Personalförvaltning och andra allmänna stödtjänster',
  },
  {
    code: 84121,
    text: 'Administration av grundskole- och gymnasieskoleutbildning',
  },
  {
    code: 84122,
    text: 'Administration av universitets- och högskoleutbildning samt forskning',
  },
  {
    code: 84123,
    text: 'Administration av hälso- och sjukvård',
  },
  {
    code: 84124,
    text: 'Administration av omsorg och socialtjänst',
  },
  {
    code: 84125,
    text: 'Administration av program för kultur, miljö, boende m.m.',
  },
  {
    code: 84131,
    text: 'Administration av infrastrukturprogram',
  },
  {
    code: 84132,
    text: 'Administration av program för jordbruk, skogsbruk, jakt och fiske',
  },
  {
    code: 84133,
    text: 'Administration av arbetsmarknadsprogram',
  },
  {
    code: 84139,
    text: 'Administration av andra näringslivsprogram',
  },
  {
    code: 84210,
    text: 'Utrikesförvaltning',
  },
  {
    code: 84221,
    text: 'Militärt försvar',
  },
  {
    code: 84222,
    text: 'Gemensam verksamhet för totalförsvaret',
  },
  {
    code: 84223,
    text: 'Civilt försvar och frivilligförsvar',
  },
  {
    code: 84231,
    text: 'Åklagarverksamhet',
  },
  {
    code: 84232,
    text: 'Domstolsverksamhet',
  },
  {
    code: 84233,
    text: 'Kriminalvård',
  },
  {
    code: 84240,
    text: 'Polisverksamhet',
  },
  {
    code: 84250,
    text: 'Brand- och räddningsverksamhet',
  },
  {
    code: 84300,
    text: 'Obligatorisk socialförsäkring',
  },
  {
    code: 85100,
    text: 'Förskoleutbildning',
  },
  {
    code: 85201,
    text: 'Grundskoleutbildning och förskoleklass',
  },
  {
    code: 85202,
    text: 'Utbildning inom grundsärskola',
  },
  {
    code: 85311,
    text: 'Studieförberedande gymnasial utbildning',
  },
  {
    code: 85312,
    text: 'Kommunal vuxenutbildning o.d.',
  },
  {
    code: 85321,
    text: 'Gymnasial yrkesutbildning',
  },
  {
    code: 85322,
    text: 'Utbildning inom gymnasiesärskola',
  },
  {
    code: 85323,
    text: 'Annan gymnasial utbildning',
  },
  {
    code: 85324,
    text: 'Yrkesförarutbildning m.m.',
  },
  {
    code: 85410,
    text: 'Eftergymnasial utbildning vid annat än universitet och högskola',
  },
  {
    code: 85420,
    text: 'Universitets- eller högskoleutbildning',
  },
  {
    code: 85510,
    text: 'Sport- och fritidsutbildning',
  },
  {
    code: 85521,
    text: 'Kommunala kulturskolans utbildning',
  },
  {
    code: 85522,
    text: 'Övrig musik-, dans- och kulturell utbildning',
  },
  {
    code: 85530,
    text: 'Trafikskoleverksamhet',
  },
  {
    code: 85591,
    text: 'Arbetsmarknadsutbildning',
  },
  {
    code: 85592,
    text: 'Folkhögskoleutbildning',
  },
  {
    code: 85593,
    text: 'Studieförbundens och frivilligorganisationernas utbildning',
  },
  {
    code: 85594,
    text: 'Personalutbildning',
  },
  {
    code: 85599,
    text: 'Diverse övrig utbildning',
  },
  {
    code: 85600,
    text: 'Stödverksamhet för utbildningsväsendet',
  },
  {
    code: 86101,
    text: 'Sluten primärvård',
  },
  {
    code: 86102,
    text: 'Specialiserad sluten somatisk hälso- och sjukvård på sjukhus',
  },
  {
    code: 86103,
    text: 'Specialiserad sluten psykiatrisk hälso- och sjukvård på sjukhus',
  },
  {
    code: 86211,
    text: 'Primärvårdsmottagningar med läkare m.m.',
  },
  {
    code: 86212,
    text: 'Annan allmän öppen hälso- och sjukvård, ej primärvård',
  },
  {
    code: 86221,
    text: 'Specialistläkarverksamhet inom öppenvård, på sjukhus',
  },
  {
    code: 86222,
    text: 'Specialistläkarverksamhet inom öppenvård, ej på sjukhus',
  },
  {
    code: 86230,
    text: 'Tandläkarverksamhet',
  },
  {
    code: 86901,
    text: 'Medicinsk laboratorieverksamhet m.m.',
  },
  {
    code: 86902,
    text: 'Ambulanstransporter och ambulanssjukvård',
  },
  {
    code: 86903,
    text: 'Primärvård, ej läkare',
  },
  {
    code: 86904,
    text: 'Tandhygienistverksamhet',
  },
  {
    code: 86905,
    text: 'Fysioterapeutisk verksamhet o.d.',
  },
  {
    code: 86909,
    text: 'Annan öppen hälso- och sjukvård, utan läkare',
  },
  {
    code: 87100,
    text: 'Boende med sjuksköterskevård',
  },
  {
    code: 87201,
    text: 'Boende med särskild service för personer med utvecklingsstörning eller psykiska funktionshinder',
  },
  {
    code: 87202,
    text: 'Boende med särskild service för barn och ungdomar med missbruksproblem',
  },
  {
    code: 87203,
    text: 'Boende med särskild service för vuxna med missbruksproblem',
  },
  {
    code: 87301,
    text: 'Vård och omsorg i särskilda boendeformer för äldre personer',
  },
  {
    code: 87302,
    text: 'Vård och omsorg i särskilda boendeformer för personer med funktionshinder',
  },
  {
    code: 87901,
    text: 'Heldygnsvård med boende för barn och ungdomar med sociala problem',
  },
  {
    code: 87902,
    text: 'Omsorg och sociala insatser i övriga boendeformer för vuxna',
  },
  {
    code: 88101,
    text: 'Öppna sociala insatser för äldre personer',
  },
  {
    code: 88102,
    text: 'Öppna sociala insatser för personer med funktionshinder',
  },
  {
    code: 88910,
    text: 'Dagbarnvård',
  },
  {
    code: 88991,
    text: 'Öppna sociala insatser för barn och ungdomar med sociala problem',
  },
  {
    code: 88992,
    text: 'Öppna sociala insatser för vuxna med missbruksproblem',
  },
  {
    code: 88993,
    text: 'Övriga öppna sociala insatser för vuxna',
  },
  {
    code: 88994,
    text: 'Humanitära insatser',
  },
  {
    code: 88995,
    text: 'Drift av flyktingförläggning',
  },
  {
    code: 90010,
    text: 'Artistisk verksamhet',
  },
  {
    code: 90020,
    text: 'Stödtjänster till artistisk verksamhet',
  },
  {
    code: 90030,
    text: 'Litterärt och konstnärligt skapande',
  },
  {
    code: 90040,
    text: 'Drift av teatrar, konserthus o.d.',
  },
  {
    code: 91011,
    text: 'Biblioteksverksamhet',
  },
  {
    code: 91012,
    text: 'Arkivverksamhet',
  },
  {
    code: 91020,
    text: 'Museiverksamhet',
  },
  {
    code: 91030,
    text: 'Vård av historiska minnesmärken och byggnader och liknande sevärdheter',
  },
  {
    code: 91040,
    text: 'Drift av botaniska trädgårdar, djurparker och naturreservat',
  },
  {
    code: 92000,
    text: 'Spel- och vadhållningsverksamhet',
  },
  {
    code: 93111,
    text: 'Drift av skidsportanläggningar',
  },
  {
    code: 93112,
    text: 'Drift av golfbanor',
  },
  {
    code: 93113,
    text: 'Drift av motorbanor',
  },
  {
    code: 93114,
    text: 'Drift av trav- och galoppbanor',
  },
  {
    code: 93119,
    text: 'Drift av sporthallar, idrottsplatser och andra sportanläggningar',
  },
  {
    code: 93120,
    text: 'Sportklubbars och idrottsföreningars verksamhet',
  },
  {
    code: 93130,
    text: 'Drift av gymanläggningar',
  },
  {
    code: 93191,
    text: 'Tävling med hästar',
  },
  {
    code: 93199,
    text: 'Övrig sportverksamhet',
  },
  {
    code: 93210,
    text: 'Nöjes- och temaparksverksamhet',
  },
  {
    code: 93290,
    text: 'Övrig fritids- och nöjesverksamhet',
  },
  {
    code: 94111,
    text: 'Intressebevakning inom branschorganisationer',
  },
  {
    code: 94112,
    text: 'Intressebevakning inom arbetsgivarorganisationer',
  },
  {
    code: 94120,
    text: 'Intressebevakning inom yrkesorganisationer',
  },
  {
    code: 94200,
    text: 'Intressebevakning inom arbetstagarorganisationer',
  },
  {
    code: 94910,
    text: 'Verksamhet i religiösa samfund',
  },
  {
    code: 94920,
    text: 'Verksamhet i politiska organisationer',
  },
  {
    code: 94990,
    text: 'Verksamhet i andra intresseorganisationer',
  },
  {
    code: 95110,
    text: 'Reparation av datorer och kringutrustning',
  },
  {
    code: 95120,
    text: 'Reparation av kommunikationsutrustning',
  },
  {
    code: 95210,
    text: 'Reparation av hemelektronik',
  },
  {
    code: 95220,
    text: 'Reparation av hushållsapparater samt av utrustning för hem och trädgård',
  },
  {
    code: 95230,
    text: 'Lagning av skodon och lädervaror',
  },
  {
    code: 95240,
    text: 'Reparation av möbler och heminredning',
  },
  {
    code: 95250,
    text: 'Reparation av ur och guldsmedsvaror',
  },
  {
    code: 95290,
    text: 'Reparation av andra hushållsartiklar och personliga artiklar',
  },
  {
    code: 96011,
    text: 'Industri- och institutionstvätt',
  },
  {
    code: 96012,
    text: 'Konsumenttvätt',
  },
  {
    code: 96021,
    text: 'Hårvård',
  },
  {
    code: 96022,
    text: 'Skönhetsvård',
  },
  {
    code: 96030,
    text: 'Begravningsverksamhet',
  },
  {
    code: 96040,
    text: 'Kroppsvård',
  },
  {
    code: 96090,
    text: 'Övriga konsumenttjänster',
  },
  {
    code: 97000,
    text: 'Förvärvsarbete i hushåll',
  },
  {
    code: 98100,
    text: 'Hushållens produktion av diverse varor för eget bruk',
  },
  {
    code: 98200,
    text: 'Hushållens produktion av diverse tjänster för eget bruk',
  },
  {
    code: 99000,
    text: 'Verksamhet vid internationella organisationer, utländska ambassader o.d.',
  },
];
