<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal';
import { useI18n } from 'vue-i18n';

import { AppButton } from '@/components';

const { t } = useI18n({ useScope: 'global' });

const props = defineProps<{
  mode?: 'save' | 'cancel';
}>();

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'confirm'): void;
}>();
</script>
<template>
  <VueFinalModal class="modal-overlay" content-class="modal-container" :click-to-close="false" :esc-to-close="false">
    <div class="modal-header">
      <h2 v-if="props.mode === 'save'" v-text="t('common.confirms.save.title')" />
      <h2 v-if="props.mode === 'cancel'" v-text="t('common.confirms.cancel.title')" />
    </div>
    <div class="modal-content">
      <slot />
    </div>
    <div class="modal-footer">
      <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
        <AppButton light @click="emit('cancel')">
          {{ t('common.cancel') }}
        </AppButton>
        <AppButton class="mt-2 mt-sm-0" :color="mode === 'save' ? 'success' : 'danger'" @click="emit('confirm')">
          {{ t('common.confirm') }}
        </AppButton>
      </div>
    </div>
  </VueFinalModal>
</template>
