import { watch } from 'vue';
import { useMagicKeys } from '@vueuse/core';

export default function useModalFeatures() {
  const ctrlEnter = useMagicKeys()['Ctrl+Enter'];

  function onCtrlEnter(callback: () => void) {
    return watch(ctrlEnter, (v) => {
      if (v) {
        callback();
      }
    });
  }

  return {
    onCtrlEnter,
  };
}
