<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { ProjectStatusType } from '@/types/Project';

const { t } = useI18n({ useScope: 'global' });

const props = defineProps<{
  status: ProjectStatusType;
}>();

const color = computed(() => {
  switch (props.status) {
    case 'draft':
      return 'is-warning';
    case 'active':
      return 'is-secondary';
    case 'deleted':
      return 'is-danger';
    case 'cancelled':
      return 'is-danger';
    case 'done':
      return 'is-success';
    default:
      return '';
  }
});

const icon = computed(() => {
  switch (props.status) {
    case 'draft':
      return 'ti-pencil';
    case 'active':
      return 'ti-player-play';
    case 'deleted':
      return 'ti-trash';
    case 'cancelled':
      return 'ti-ban';
    case 'done':
      return 'ti-check';
    default:
      return '';
  }
});
</script>

<template>
  <span class="badge" :class="color">
    <i class="ti" :class="icon" />
    {{ t(`project.statuses.${status}`) }}
  </span>
</template>
