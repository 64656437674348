import route from 'ziggy-js';
import { Options } from 'ky';
import httpClient from '@/services/api/ky';
import { ITodoRequest, ITodoResource } from '@/types/Todo';

export default {
  all(options: Options = {}) {
    return httpClient.get(route('todos.get_all'), options).json<{
      extraInformation: null | string;
      list: ITodoResource[];
      page: number;
      pageSize: number;
      total: number;
    }>();
  },
  close(id: number, options: Options = {}) {
    return httpClient.put(route('todos.close', { id }), options).json<unknown>();
  },
  reopen(id: number, options: Options = {}) {
    return httpClient.put(route('todos.reopen', { id }), options).json<unknown>();
  },
  markAsRead(id: number, options: Options = {}) {
    return httpClient.post(route('todos.mark_as_read', { id }), options).json<unknown>();
  },
  create(json: ITodoRequest, options: Options = {}) {
    return httpClient.post(route('todos.create'), { ...options, json }).json<ITodoResource>();
  },
  update(id: number, json: ITodoRequest, options: Options = {}) {
    return httpClient.put(route('todos.update', { id }), { ...options, json: { ...json, id } }).json<ITodoResource>();
  },
  delete(id: number, options: Options = {}) {
    return httpClient.delete(route('todos.delete', { id }), options).json<void>();
  },
  comments: {
    create(id: number, text: string, options: Options = {}) {
      return httpClient
        .post(route('todos.create_todo_comment', { id }), { ...options, json: { text } })
        .json<unknown>();
    },
  },
  user: {
    todos: {
      all(userUuid: string, options: Options = {}) {
        return httpClient.get(route('todos.get_todos_for_user', { userUuid }), options).json<ITodoResource[]>();
      },
    },
    infos: {
      all(userUuid: string, options: Options = {}) {
        return httpClient.get(route('todos.get_infos_for_user', { userUuid }), options).json<ITodoResource[]>();
      },
    },
  },
};
