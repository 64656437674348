<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useRouteQuery } from '@vueuse/router';
import { useI18n } from 'vue-i18n';

import api from '@/services/api';
import {
  AppAlert,
  AppLoader,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  AppButton,
  FontIcon,
  AppPagination,
  ConfirmModal,
  HelpInformation,
} from '@/components';
import useLoader from '@/composables/useLoader';
import { IActivityTemplateListResource } from '@/types/Activity';
import { useModal } from 'vue-final-modal';
import useActivityTemplatesStore from '@/store/ActivityTemplatesStore';

const activityTemplates = ref<IActivityTemplateListResource[]>([]);
const currentPage = useRouteQuery('templates-page', '1', { transform: Number });
const perPage = ref(20);
const total = ref(0);

const { lockTabs, unlockTabs } = useActivityTemplatesStore();
const router = useRouter();
const { t } = useI18n({ useScope: 'global' });
const loader = useLoader();

const deleteModal = useModal({
  component: ConfirmModal,
  keepAlive: false,
  // @ts-ignore
  attrs: {
    async onConfirm() {
      try {
        // @ts-ignore
        deleteModal.patchOptions({ attrs: { loading: true } });
        const groupId = deleteModal.options.attrs?.params?.groupId as number;
        const id = deleteModal.options.attrs?.params?.id as number;
        await api.activityTemplates.destroy(groupId, id);
        await getActivityTemplates();
        if (activityTemplates.value.length === 0 && currentPage.value > 1) {
          currentPage.value -= 1;
        }
        await deleteModal.close();
      } catch (error) {
        console.error(error);
      } finally {
        // @ts-ignore
        deleteModal.patchOptions({ attrs: { loading: false } });
      }
    },
  },
});

async function getActivityTemplates() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('page', currentPage.value.toString());
    const response = await api.activityTemplates.list({ searchParams });
    activityTemplates.value = response.data;
    perPage.value = response.meta!.per_page;
    total.value = response.meta!.total;
  } catch (error) {
    console.error(error);
  }
}

async function onDelete(activityTemplate: IActivityTemplateListResource) {
  deleteModal.patchOptions({
    attrs: {
      title: t('activities.templates.confirm.destroy.title'),
      message: t('activities.templates.confirm.destroy.text', {
        name: activityTemplate.name,
      }),
      params: {
        id: activityTemplate.id,
        groupId: activityTemplate.activity_group.id,
      },
    },
  });
  await deleteModal.open();
}

onMounted(async () => {
  lockTabs();
  loader.start();
  await getActivityTemplates();
  loader.finish();
  unlockTabs();
});

watch(currentPage, async () => {
  lockTabs();
  loader.start();
  await getActivityTemplates();
  loader.finish();
  unlockTabs();
});
</script>

<template>
  <div v-if="loader.isLoading.value" class="text-center">
    <AppLoader size="large" />
  </div>
  <template v-else>
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="d-flex align-items-end">
        <h1 class="mb-0" v-text="t('activities.templates.index.title')" />
        <HelpInformation class="ml-1" translation="activities.templates.index.help" />
      </div>
      <AppButton @click.prevent="router.push({ name: 'activities.templates.create' })" color="secondary">
        {{ t('activities.templates.index.create') }}
      </AppButton>
    </div>
    <AppAlert v-if="activityTemplates.length === 0">{{ t('activities.templates.empty') }}</AppAlert>
    <template v-else>
      <AppTable hoverable>
        <AppTableHead>
          <AppTableTr>
            <AppTableTh nowrap>{{ t('activities.templates.attributes.name') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('activities.templates.attributes.group') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('activities.templates.attributes.type') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('activities.templates.attributes.duration_rule') }}</AppTableTh>
            <AppTableTh nowrap class="text-right">
              {{ t('activities.templates.attributes.meta_input') }}
            </AppTableTh>
            <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
          </AppTableTr>
        </AppTableHead>
        <AppTableBody>
          <AppTableTr
            @click="
              router.push({
                name: 'activities.templates.edit',
                params: {
                  id: activityTemplate.id,
                  groupId: activityTemplate.activity_group.id,
                },
              })
            "
            class="pointer"
            v-for="activityTemplate in activityTemplates"
            :key="activityTemplate.id"
          >
            <AppTableTd nowrap>
              <strong v-text="activityTemplate.name" />
            </AppTableTd>
            <AppTableTd nowrap>{{ activityTemplate.activity_group.name }}</AppTableTd>
            <AppTableTd nowrap>{{ t(`activities.groups.type.${activityTemplate.activity_type}`) }}</AppTableTd>
            <AppTableTd nowrap>
              <span
                v-if="activityTemplate.duration_rule"
                v-text="t(`activities.templates.duration_rule.${activityTemplate.duration_rule}`)"
              />
              <span v-else v-text="t('activities.templates.duration_rule.none')" />
            </AppTableTd>
            <AppTableTd class="text-right">
              <i v-if="activityTemplate.meta_input.length === 0" class="ti ti-x text-danger-500 text-3" />
              <i v-else class="ti ti-check text-success-500 text-3" />
            </AppTableTd>
            <AppTableTd nowrap class="text-right">
              <AppButton
                v-tooltip.left="t('activities.templates.tooltip.edit', { name: activityTemplate.name })"
                @click.stop.prevent="
                  router.push({
                    name: 'activities.templates.edit',
                    params: {
                      id: activityTemplate.id,
                      groupId: activityTemplate.activity_group.id,
                    },
                  })
                "
                size="small"
                light
                circle
              >
                <FontIcon name="pencil" />
              </AppButton>
              <AppButton
                v-tooltip.left="t('activities.templates.tooltip.destroy', { name: activityTemplate.name })"
                @click.stop.prevent="onDelete(activityTemplate)"
                class="ml-2"
                color="danger"
                size="small"
                light
                circle
              >
                <FontIcon name="trash" />
              </AppButton>
            </AppTableTd>
          </AppTableTr>
        </AppTableBody>
      </AppTable>
      <AppPagination :per-page="perPage" :total="total" v-model="currentPage" />
    </template>
  </template>
</template>
