export default {
  index: {
    title: 'Accounting',
    help: '',
    tabs: {
      articles: 'Articles',
      invoice_items: 'Invoice items',
    },
  },
  articles: {
    title: 'Articles',
    help: '',
    fetch_articles: 'Fetch articles',
    attributes: {
      number: 'Number',
      name_en: 'Name (ENG)',
      name_sv: 'Name (SWE)',
      unit: 'Unit',
      status: 'Status',
    },

    status: {
      enabled: 'Enabled',
      disabled: 'Disabled',
    },
  },
  invoice_items: {
    title: 'Invoice items',
    help: '',
  },
  attributes: {
    activity_type: 'Activity type',
    activity_group: 'Activity group',
    activity_template: 'Activity template',
    assigned: 'Assigned to',
    comment: 'Comment',
    start_date: 'Start date',
    end_date: 'End date',
  },
  empty: 'No tasks',
  types: {
    index: {
      title: 'Types',
      create: 'Create activity type',
    },
    create: {
      title: 'Create activity type',
    },
    edit: {
      title: 'Edit activity type: {name}',
    },
    empty: 'No activity types',
    attributes: {
      name: 'Name',
      groups_count: 'Groups count',
    },
    tooltip: {
      edit: 'Edit "{name}" activity type',
      destroy: 'Delete "{name}" activity type',
    },

    confirm: {
      destroy: {
        title: 'Delete activity type',
        text: "Are you sure you want to delete <strong>{name}</strong> activity type and it's groups from the system?",
      },
    },
  },
  groups: {
    index: {
      title: 'Groups',
      create: 'Create activity group',
    },
    create: {
      title: 'Create activity group',
    },
    edit: {
      title: 'Edit activity group: {name}',
    },
    empty: 'No activity groups',
    attributes: {
      name: 'Name',
      type: 'Type',
      templates_count: 'Templates count',
    },
    tooltip: {
      edit: 'Edit "{name}" activity group',
      destroy: 'Delete "{name}" activity group',
    },

    confirm: {
      destroy: {
        title: 'Delete activity group',
        text: 'Are you sure you want to delete <strong>{name}</strong> activity group from the system?',
      },
    },
  },
  templates: {
    index: {
      title: 'Templates',
      create: 'Create activity template',
    },
    create: {
      title: 'Create activity template',
    },
    edit: {
      title: 'Edit activity template: {name}',
    },

    empty: 'No activity templates',

    attributes: {
      name: 'Name',
      type: 'Type',
      group: 'Group name',
      duration_rule: 'Rule',
      meta_input: 'Additional fields',
    },

    tooltip: {
      edit: 'Edit "{name}" activity template',
      destroy: 'Delete "{name}" activity template',
    },

    confirm: {
      destroy: {
        title: 'Delete activity template',
        text: 'Are you sure you want to delete <strong>{name}</strong> activity template from the system?',
      },
    },

    duration_rule: {
      none: 'None',
      available_all_day: 'Available all day',
      available_time_percentage_range: 'Available time percentage range',
      only_all_day: 'Only all day',
    },
  },
  meta_input: {
    attributes: {
      label: 'Label',
      is_required: 'Required',
      type: 'Type',
    },
    type: {
      input_text: 'Input text',
    },
    tooltip: {
      destroy: 'Delete "{name}" additional field',
    },
    confirm: {
      destroy: {
        title: 'Delete additional field',
        text: 'Are you sure you want to delete <strong>{name}</strong> additional field from the system?',
      },
    },
  },
};
