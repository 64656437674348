<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';

import api from '@/services/api';
import toast from '@/services/toast';
import { AppButton, AppCloseButton, FormInput, FormLabel } from '@/components';
import useModalFeatures from '@/composables/useModalFeatures';
import useTrackChanges from '@/composables/useTrackChanges';

import { IVehicleRequest, IVehicleResource, VehicleType } from '@/types/User';
import VueSelect from 'vue-select';

type Props = {
  userUuid: string;
  vehicle: null | IVehicleResource;
};

const { userUuid, vehicle } = defineProps<Props>();

const emit = defineEmits<{
  cancel: [];
  created: [vehicle: IVehicleResource];
  updated: [vehicle: IVehicleResource];
  closed: [];
}>();

const { t } = useI18n({ useScope: 'global' });
const { onCtrlEnter } = useModalFeatures();

const loading = ref(false);

const form = reactive<IVehicleRequest>({
  name: null,
  type: null,
});
const tracker = useTrackChanges(form);

const disabled = computed(() => !(form.name && form.type));

async function submit() {
  loading.value = true;
  try {
    if (vehicle) {
      const response = await api.users.vehicles.update(userUuid, vehicle.id, form);
      emit('updated', response.data);
      toast.success(t('common.messages.has_been_updated', { name: response.data.name }));
    } else {
      const response = await api.users.vehicles.store(userUuid, form);
      emit('created', response.data);
      toast.success(t('common.messages.has_been_created', { name: response.data.name }));
    }
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
}

onMounted(() => {
  form.name = vehicle?.name ?? null;
  form.type = vehicle?.type ?? VehicleType.PRIVATE_CAR;
  tracker.commit();
});

onCtrlEnter(() => {
  if (!disabled.value) {
    if (tracker.isModified.value) {
      submit();
    } else {
      emit('cancel');
    }
  }
});
</script>

<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container"
    content-style="overflow: visible;"
    :click-to-close="false"
    :esc-to-close="true"
    v-slot="{ close }"
    @closed="emit('closed')"
  >
    <form @submit.prevent="submit" @keydown.enter.prevent>
      <div class="modal-header">
        <h2 v-text="vehicle ? t('user.vehicle.edit.title') : t('user.vehicle.create.title')" />
        <AppCloseButton @close="close" />
      </div>
      <div class="modal-content">
        <!-- Name -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="name" required>{{ t('user.vehicle.attributes.name') }}</FormLabel>
            </div>
            <div class="col-md-9">
              <FormInput v-model="form.name" id="name" required />
            </div>
          </div>
        </div>

        <!-- Type -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="type" required>{{ t('user.vehicle.attributes.type') }}</FormLabel>
            </div>
            <div class="col-md-9">
              <VueSelect
                :clearable="false"
                v-model="form.type"
                input-id="type"
                :placeholder="t('common.select')"
                :options="Object.values(VehicleType)"
                :get-option-label="(option: string) => t(`user.vehicle.type.${option}`)"
              />
            </div>
          </div>
        </div>

        <slot />
      </div>
      <div class="modal-footer">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
          <AppButton light @click.prevent="close" :disabled="loading">
            {{ t('common.cancel') }}
          </AppButton>
          <AppButton
            v-if="vehicle"
            class="mt-2 mt-sm-0"
            color="success"
            :loading="loading"
            :disabled="disabled || !tracker.isModified.value"
          >
            {{ t('common.update') }}
          </AppButton>
          <AppButton v-else class="mt-2 mt-sm-0" color="secondary" :loading="loading" :disabled="disabled">
            {{ t('common.create') }}
          </AppButton>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>
