export default {
  index: {
    title: 'Contacts',
    help: '',
    export_contacts: 'Export all',
  },
  attributes: {
    name: 'Name',
    email: 'Email',
    phone: 'Phone',
    primary_contact: 'Primary contact',
    company_links: 'Company links',
    service_company_link: 'Service organisation link',
    service_company_type: 'Service organisation type',
    service_company_title: 'Title at service organisation',
    id_check_result: 'ID',
    is_in_sanctions_list: 'Sanction list',
    is_pep: 'PEP',
    comment: 'Comment',
  },
  filters: {
    search: 'Search',
  },
  tooltip: {
    crm: 'Open "{name}" in CRM',
    destroy: 'Delete "{name}" contact',
    destroy_cause_link: 'Remove the contacts linked organisations to delete the contact.',
    id_check_result: 'Have you performed ID-control?',
    is_in_sanctions_list: 'Is the person listed in sanctions list?',
    is_pep: 'Is the contact a PEP',
  },
  empty: 'No contacts',
  // Confirms
  confirm: {
    destroy: {
      title: 'Delete contact',
      text: 'Are you sure you want to delete <strong>{name}</strong> contact from the system?',
    },
  },
  role: {
    create: {
      title: 'Create new contact role',
    },
    attributes: {
      name_sv: 'Name (Swedish)',
      name_en: 'Name (English)',
    },
    tooltip: {
      create: 'Create new role',
    },
  },
};
