<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal, useModal } from 'vue-final-modal';
import api from '@/services/api';
import {
  AppButton,
  AppCloseButton,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  AppPagination,
  AppLoader,
  UserCreateTimeBalanceTransactionModal,
  AppAlert,
} from '@/components';
import { ConfirmDialogConfirmParams } from '@/types/Common';
import { ITimeBalanceTransactionRequest, ITimeBalanceTransactionResource, IUserResource } from '@/types/User';
import useTime from '@/composables/useTime';

type Props = {
  user: IUserResource;
  editable: boolean;
};

const { user, editable } = defineProps<Props>();

const emit = defineEmits<{
  closed: [updateAfterClose: boolean];
}>();

const { t, d } = useI18n({ useScope: 'global' });
const { convertMinutesToTime } = useTime();

const page = ref(1);
const perPage = ref(0);
const total = ref(0);
const updateAfterClose = ref(false);
const currentBalance = ref(0);
const lastTransactionDate = ref<null | string>(null);

const timeBalanceTransactions = ref<ITimeBalanceTransactionResource[]>([]);
const timeBalanceTransactionsLoading = ref(false);

async function getTimeBalanceTransaction() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('page', page.value.toString());
    const response = await api.users.timeBalance.index(user.uuid, { searchParams });
    timeBalanceTransactions.value = response.data;
    currentBalance.value = response.current_balance;
    lastTransactionDate.value = response.last_transaction_date;
    if (response.meta) {
      perPage.value = response.meta.per_page;
      total.value = response.meta.total;
    }
  } catch (error) {
    console.error(error);
  }
}

function onTransactionCreate() {
  const { open, close, destroy } = useModal({
    component: UserCreateTimeBalanceTransactionModal,
    attrs: {
      async onCreate(
        type: 'deposit' | 'debit',
        values: ITimeBalanceTransactionRequest,
        { setLoading }: ConfirmDialogConfirmParams,
      ) {
        setLoading(true);
        try {
          await api.users.timeBalance[type](user.uuid, values);
          await getTimeBalanceTransaction();
          updateAfterClose.value = true;
          await close();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

watch(page, getTimeBalanceTransaction);

onMounted(async () => {
  timeBalanceTransactionsLoading.value = true;
  await getTimeBalanceTransaction();
  timeBalanceTransactionsLoading.value = false;
});
</script>

<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container is-large"
    :click-to-close="false"
    :esc-to-close="true"
    v-slot="{ close }"
    @closed="emit('closed', updateAfterClose)"
  >
    <div class="modal-header">
      <h2 v-text="t('user.time_balance.title', { name: user.name })" />
      <AppCloseButton @close="close" />
    </div>
    <div class="modal-content">
      <div v-if="timeBalanceTransactionsLoading" class="text-center py-3">
        <AppLoader />
      </div>
      <template v-else>
        <AppTable bordered>
          <AppTableBody>
            <AppTableTr>
              <AppTableTd style="width: 40%" class="font-bold">{{ t('user.time_balance.current_balance') }}</AppTableTd>
              <AppTableTd style="width: 30%" class="font-bold">
                {{ convertMinutesToTime(currentBalance) }}
              </AppTableTd>
              <AppTableTd style="width: 30%" class="font-bold">
                {{ lastTransactionDate ? d(lastTransactionDate, 'long') : '-' }}
              </AppTableTd>
            </AppTableTr>
          </AppTableBody>
        </AppTable>
        <h2 class="mt-4">{{ t('user.time_balance.manual_edit') }}</h2>
        <AppAlert v-if="timeBalanceTransactions.length === 0">{{ t('common.empty') }}</AppAlert>
        <AppTable bordered v-if="timeBalanceTransactions.length > 0">
          <AppTableHead>
            <AppTableTr>
              <AppTableTh style="width: 20%">{{ t('user.time_balance.attributes.date') }}</AppTableTh>
              <AppTableTh style="width: 20%">{{ t('user.time_balance.attributes.change') }}</AppTableTh>
              <AppTableTh style="width: 30%">{{ t('user.time_balance.attributes.balance') }}</AppTableTh>
              <AppTableTh style="width: 30%">{{ t('user.time_balance.attributes.description') }}</AppTableTh>
            </AppTableTr>
          </AppTableHead>
          <AppTableBody>
            <AppTableTr v-for="transaction in timeBalanceTransactions" :key="transaction.id">
              <AppTableTd>{{ d(transaction.created_at, 'long') }}</AppTableTd>
              <AppTableTd>{{ convertMinutesToTime(transaction.sum) }}</AppTableTd>
              <AppTableTd>{{ convertMinutesToTime(transaction.balance_after) }}</AppTableTd>
              <AppTableTd>{{ transaction.comment }}</AppTableTd>
            </AppTableTr>
          </AppTableBody>
        </AppTable>
        <AppPagination :per-page="perPage" :total="total" v-model="page" />
        <slot />
      </template>
    </div>
    <div class="modal-footer">
      <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
        <AppButton light @click.prevent="close">
          {{ t('common.close') }}
        </AppButton>
        <AppButton
          v-if="editable"
          color="secondary"
          @click.prevent="onTransactionCreate"
          :disabled="timeBalanceTransactionsLoading"
        >
          {{ t('common.create') }}
        </AppButton>
      </div>
    </div>
  </VueFinalModal>
</template>
