export default {
  index: {
    title: 'Offices',
    help: '',
    create: 'Create office',
  },

  create: {
    title: 'Create office',
  },
  edit: {
    title: 'Edit office: {name}',
  },
  form: {
    name: 'Name',
  },

  empty: 'No offices',

  // Attributes
  attributes: {
    name: 'Name',
    manager: 'Manager',
    employees: 'Employees',
    active: 'Active',
  },

  // Tooltips
  tooltip: {
    edit: 'Edit "{name}" office',
    destroy: 'Delete "{name}" office',
  },

  // Confirms
  confirm: {
    destroy: {
      title: 'Delete office',
      text: 'Are you sure you want to delete <strong>{name}</strong> office from the system?',
    },
  },
};
