import { readonly } from 'vue';

export default function useProject() {
  const projectSteps = readonly({
    service: { order: 0, visible: true },
    price: { order: 1, visible: true },
    team: { order: 2, visible: true },
    planning: { order: 3, visible: true },
    activate: { order: 4, visible: true },
    active: { order: 5, visible: false },
  });

  function calculateAverageCost(externalTotalPrice: number, totalMinutes: number): string | null {
    const totalTimeInHours = totalMinutes > 0 ? totalMinutes / 60 : 0;
    if (totalTimeInHours === 0) {
      return Number(0).toString();
    }
    const cost = externalTotalPrice / totalTimeInHours;
    if (cost > 0) {
      return cost.toFixed();
    }
    return null;
  }

  return {
    projectSteps,
    calculateAverageCost,
  };
}
