import { IClientPreviewResource } from '@/types/Client';
import { IUserPreviewResource } from '@/types/User';
import { SortOrder } from '@/types/Common';

export interface ITodoCommentResource {
  id: number;
  createdAt: string;
  createdByUserUuid: string;
  authorUser: IUserPreviewResource;
  text: string;
}

export interface ITodoResource {
  id: number;
  type: TodoType;
  text: null | string;
  title: string;
  client: null | IClientPreviewResource;
  clientLink: null | string;
  clientUuid: null | string;
  comments: ITodoCommentResource[];
  createdByUserUuid: string;
  authorUser: IUserPreviewResource;
  deadline: null | string;
  isClosed: boolean;
  isPrivate: boolean;
  link: string;
  linkDescription: string;
  projectId: null | number;
  receiptantName: null | string;
  recepientUser: null | IUserPreviewResource;
  recepientUserUuid: null | string;
  createdAt: string;
  updatedAt: string;
  closedAt: null | string;
}

export enum TodoType {
  Info = 'Info',
  Todo = 'ToDo',
  Message = 'Message',
}

export interface ITodoRequest {
  type: null | TodoType;
  clientUuid: null | string;
  text: string;
  recepientUserUuid: null | string;
  deadline: null | string;
  link: string;
  linkDescription: string;
}

export interface IGetAllTodosRequest {
  fromDate: string;
  toDate: string;
  sortOrder: null | SortOrder;
  sortBy: null | string;
  client: null | string;
  creator: null | string;
  recipient: null | string;
  type: null | string;
  status: null | string;
  search: string;
}

export type HiddenTodo = {
  id: number;
  comments_count: number;
};
