import route from 'ziggy-js';
import { Options } from 'ky';

import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import { ITimePeriodAvailableDates, ITimePeriodRequestBody, ITimePeriodResource } from '@/types/TimePeriod';

export default {
  async index(options: Options = {}): Promise<IResponse<ITimePeriodResource[]>> {
    return await httpClient.get(route('time-periods.index'), options).json();
  },
  async update(
    timePeriod: number,
    json: ITimePeriodRequestBody,
    options: Options = {},
  ): Promise<IResponse<ITimePeriodResource>> {
    return await httpClient.patch(route('time-periods.update', { timePeriod }), { json, ...options }).json();
  },
  async availableDates(options: Options = {}): Promise<ITimePeriodAvailableDates> {
    return await httpClient.get(route('time-periods.get_available_dates'), options).json();
  },
};
