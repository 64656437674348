<script setup lang="ts">
import { AppTableTd, AppTableTr } from '@/components';
import { Decimal } from 'decimal.js';
import { computed, useTemplateRef } from 'vue';

type Props = {
  workingHours: number;
  maxHours: number;
  day: string;
};

const { workingHours, maxHours, day } = defineProps<Props>();

const rate = defineModel<number>('rate', { required: true });

const hoursNodeElement = useTemplateRef<HTMLInputElement>('hoursNodeElement');

const hours = computed({
  get() {
    return Decimal.div(Decimal.mul(maxHours, rate.value), 100).toDecimalPlaces(2).toNumber();
  },
  set(value) {
    const parsedValue = parseFloat(typeof value === 'number' ? value.toString() : value);
    if (!isFinite(parsedValue) || isNaN(parsedValue)) {
      rate.value = 0;
      hoursNodeElement.value!.value = '0';
    } else {
      const newValue = Math.min(new Decimal(value).toDecimalPlaces(2).abs().toNumber(), maxHours);
      rate.value = Decimal.div(new Decimal(newValue).mul(100), maxHours).toDecimalPlaces(2).toNumber();
      hoursNodeElement.value!.value = newValue.toString();
    }
  },
});
</script>

<template>
  <AppTableTr>
    <AppTableTd>
      {{ day }}
    </AppTableTd>
    <AppTableTd class="text-right">
      {{ workingHours }}
    </AppTableTd>
    <AppTableTd class="text-right">
      <div class="has-icon form-wrapper is-small">
        <i class="form-icon ti ti-clock" />
        <input
          type="number"
          v-model.lazy="hours"
          class="text-right form-control"
          min="0"
          :max="maxHours"
          step=".01"
          required
          ref="hoursNodeElement"
        />
      </div>
    </AppTableTd>
    <AppTableTd class="text-right">
      <slot name="rate" />
    </AppTableTd>
  </AppTableTr>
</template>
