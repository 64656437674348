export default {
  index: {
    title: 'Inställningar för fakturarader',
    create: 'Skapa ny',
  },
  attributes: {
    type: 'Typ',
    source: 'Prisplan',
    price_type: 'Pristyp',
    default_description_sv: 'Text på fakturarad (SWE)',
    default_description_en: 'Text på fakturarad (ENG)',
    article: 'Artikel',
    invert: 'Negativt belopp',
  },
  type: {
    system: 'System',
    manual: 'Manuell',
  },
  price_type: {
    fixed: 'fast',
    hourly: 'tid',
  },
  source: {
    billing_plan_base_fee: 'Fakturaplan grundarvode',
    billing_plan_separate_fee: 'Fakturaplan separat arvode',
    reported_time: 'Rapporterad tid',
    manual: 'Manuell',
    expense: 'Utlägg',
    extra_time: 'Merarbete utfört',
  },
  empty: 'Inga fakturarader. Tryck på plus för att skap',
  tooltip: {
    destroy: 'Radera denna',
  },
  confirm: {
    destroy: {
      title: 'Radera fakturarad',
      text: 'Är du säker att du vill radera denna fakturarad?',
    },
  },
};
