export default {
  index: {
    title: 'My schedule',
    help: '',
    create: 'Create schedule',
  },
  create: {
    title: 'Create task (internal activity)',
  },
  edit: {
    title: 'Edit task (internal activity)',
  },
  settings: {
    title: 'Activities',
    help: 'On these pages, you can make settings for time reporting on internal time codes. These are built in a hierarchy where the highest level is Internal time types (normal absence and internal working hours). The next level is categories where you can create subgroups, e.g. parental leave. Finally, you set internal time codes that the employees will report time on, e.g. Care of children.',
  },
  attributes: {
    activity_type: 'Activity type',
    activity_group: 'Activity group',
    activity_template: 'Activity template',
    assigned: 'Assigned to',
    comment: 'Comment',
    start_date: 'Start date',
    end_date: 'End date',
  },
  empty: 'No tasks',
  types: {
    index: {
      title: 'Types',
      help: 'Types are used as top separators and the most common setting is absence and internal working hours',
      create: 'Create activity type',
    },
    create: {
      title: 'Create activity type',
    },
    edit: {
      title: 'Edit activity type: {name}',
    },
    empty: 'No activity types',
    attributes: {
      name: 'Name',
      groups_count: 'Groups count',
    },
    tooltip: {
      edit: 'Edit "{name}" activity type',
      destroy: 'Delete "{name}" activity type',
    },

    confirm: {
      destroy: {
        title: 'Delete activity type',
        text: "Are you sure you want to delete <strong>{name}</strong> activity type and it's groups from the system?",
      },
    },
  },
  groups: {
    index: {
      title: 'Groups',
      help: 'Categories are used to group types of internal time. Examples of categories with different internal time codes can be, Business development, Management, Parental leave.',
      create: 'Create activity group',
    },
    create: {
      title: 'Create activity group',
    },
    edit: {
      title: 'Edit activity group: {name}',
    },
    type: {
      internal_project: 'Internal project',
      independent: 'Independent',
      absence: 'Absence',
      internal_work: 'Internal work',
    },
    empty: 'No activity groups',
    attributes: {
      name: 'Name',
      type: 'Type',
      templates_count: 'Templates count',
    },
    tooltip: {
      edit: 'Edit "{name}" activity group',
      destroy: 'Delete "{name}" activity group',
      cant_be_deleted: "Group has templates and can't be deleted",
    },

    confirm: {
      destroy: {
        title: 'Delete activity group',
        text: 'Are you sure you want to delete <strong>{name}</strong> activity group from the system?',
      },
    },
  },
  templates: {
    index: {
      title: 'Templates',
      help: 'Here you can add the time codes that employees should use to report internal time. Each time code shall be referred to the type and category. You can also set time rules for how employees can report time. For example, annual leave can only be reported for a full day. You can also specify whether users should enter Text Info on their time registration. Or enter a predetermined value from a dropdown',
      create: 'Create activity template',
    },
    create: {
      title: 'Create activity template',
      help: '',
    },
    edit: {
      title: 'Edit activity template: {name}',
      help: '',
    },

    empty: 'No activity templates',

    attributes: {
      name: 'Name',
      type: 'Type',
      group: 'Group name',
      duration_rule: 'Rule',
      meta_input: 'Additional fields',
      comment_field_type: 'Comment field type',
      free_text: 'Free text',
      dropdown: 'Dropdown',
      add_option: 'Add option',
      include_in_planning: 'Auto-include in planning',
      default_frequency: 'Default frequency',
      order: 'Order',
      salary_code: 'Salary code',
    },

    tooltip: {
      edit: 'Edit "{name}" activity template',
      destroy: 'Delete "{name}" activity template',
    },

    confirm: {
      destroy: {
        title: 'Delete activity template',
        text: 'Are you sure you want to delete <strong>{name}</strong> activity template from the system?',
      },
    },

    duration_rule: {
      none: 'None',
      available_all_day: 'Available all day',
      available_time_percentage_range: 'Available time percentage range',
      only_all_day: 'Only all day',
    },
  },
  meta_input: {
    attributes: {
      label: 'Label',
      is_required: 'Required',
      type: 'Type',
    },
    type: {
      input_text: 'Input text',
    },
    tooltip: {
      destroy: 'Delete "{name}" additional field',
    },
    confirm: {
      destroy: {
        title: 'Delete additional field',
        text: 'Are you sure you want to delete <strong>{name}</strong> additional field from the system?',
      },
    },
  },
};
