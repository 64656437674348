<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { useModal } from 'vue-final-modal';
import { useI18n } from 'vue-i18n';
import { useRouteQuery } from '@vueuse/router';
import { Dropdown } from 'floating-vue';

import {
  AppAlert,
  AppButton,
  AppLoader,
  AppPagination,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  ColumnSettings,
  ConfirmModal,
  EmptyValue,
  ExpenseModal,
  FontIcon,
  HelpInformation,
} from '@/components';
import api from '@/services/api';
import toast from '@/services/toast';
import useLoader from '@/composables/useLoader';
import useColumnSettings from '@/composables/useColumnSettings';
import { ExpenseSource, IExpenseResource } from '@/types/Expense';
import { ConfirmDialogConfirmParams } from '@/types/Common';
import { IProjectPreviewResource, ProjectStatusType } from '@/types/Project';
import { Decimal } from 'decimal.js';

type Props = { uuid: string };
const { uuid } = defineProps<Props>();

const { t, n, d } = useI18n({ useScope: 'global' });

const loader = useLoader({ useProgress: false });

const projects = ref<IProjectPreviewResource[]>([]);

const expenses = ref<IExpenseResource[]>([]);
const page = useRouteQuery<number>('expenses_page', 1, { transform: Number });
const perPage = ref(20);
const total = ref(0);

const { columns, isColumnEnable, enabledColumns } = useColumnSettings('expenses_columns', [
  'type',
  'ref_number',
  'date',
  'project',
  'description',
  'source',
  'amount',
  'is_invoiced',
] as const);

async function getExpenses() {
  try {
    loader.start();
    const searchParams = new URLSearchParams();
    searchParams.append('page', page.value.toString());
    searchParams.append('per_page', perPage.value.toString());
    searchParams.append('client_uuid', uuid);
    const response = await api.expenses.index({ searchParams });
    expenses.value = response.data;
    if (response.meta) {
      total.value = response.meta.total;
    }
  } catch (error) {
    console.error(error);
  } finally {
    loader.finish();
  }
}

async function getProjects() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    searchParams.append('statuses[]', ProjectStatusType.Active);
    const response = await api.clients.projects.list(uuid, { searchParams });
    projects.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

function onCreate() {
  const { open, close, destroy } = useModal({
    component: ExpenseModal,
    defaultModelValue: false,
    attrs: {
      expense: null,
      projects: projects.value,
      clientUuid: uuid,
      onCreated() {
        getExpenses();
        close();
      },
      onCancel() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onEdit(expense: IExpenseResource) {
  const { open, close, destroy } = useModal({
    component: ExpenseModal,
    defaultModelValue: false,
    attrs: {
      expense,
      projects: projects.value,
      clientUuid: uuid,
      onUpdated() {
        getExpenses();
        close();
      },
      onCancel() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onDelete(expense: IExpenseResource) {
  const { open, close, destroy } = useModal({
    component: ConfirmModal,
    attrs: {
      title: t('expense.confirm.destroy.title'),
      message: t('expense.confirm.destroy.text'),
      async onConfirm({ setLoading }: ConfirmDialogConfirmParams) {
        try {
          setLoading(true);
          await api.expenses.delete(expense.id);
          await getExpenses();
          toast.success(t('common.messages.has_been_deleted', { name: t('expense.resource.name') }));
          await close();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

onMounted(async () => {
  loader.start();
  await Promise.all([getExpenses(), getProjects()]);
  loader.finish();
});

watch(page, async () => {
  loader.start();
  await getExpenses();
  loader.finish();
});

onUnmounted(() => {
  page.value = 1;
});
</script>

<template>
  <!-- Header -->
  <div class="d-flex align-items-center mb-3">
    <div class="d-flex align-items-end">
      <h2 class="mb-0" v-text="t('expense.index.title')" />
      <HelpInformation class="ml-1" translation="expense.index.help" />
    </div>
    <AppButton class="ml-auto" color="secondary" @click.prevent="onCreate" :disabled="loader.isLoading.value">
      {{ t('expense.index.create') }}
    </AppButton>
    <Dropdown placement="bottom-end" :distance="10">
      <AppButton class="ml-2" light circle>
        <FontIcon name="table-options" />
      </AppButton>
      <template #popper>
        <ColumnSettings
          v-model="enabledColumns"
          :columns="columns"
          :get-label="(columnName) => t(`expense.attributes.${columnName}`)"
        />
      </template>
    </Dropdown>
  </div>

  <!-- Loader -->
  <div v-if="loader.isLoading.value" class="text-center">
    <AppLoader size="large" />
  </div>

  <!-- Table -->
  <template v-else>
    <AppAlert v-if="expenses.length === 0">
      {{ t('expense.empty') }}
    </AppAlert>
    <template v-else>
      <AppTable hoverable>
        <AppTableHead>
          <AppTableTr>
            <AppTableTh nowrap v-if="isColumnEnable('type')">
              {{ t('expense.attributes.type') }}
            </AppTableTh>
            <AppTableTh nowrap v-if="isColumnEnable('ref_number')">
              {{ t('expense.attributes.ref_number') }}
            </AppTableTh>
            <AppTableTh nowrap v-if="isColumnEnable('date')">
              {{ t('expense.attributes.date') }}
            </AppTableTh>
            <AppTableTh nowrap v-if="isColumnEnable('project')">
              {{ t('expense.attributes.project') }}
            </AppTableTh>
            <AppTableTh nowrap v-if="isColumnEnable('description')">
              {{ t('expense.attributes.description') }}
            </AppTableTh>
            <AppTableTh nowrap v-if="isColumnEnable('source')">
              {{ t('expense.attributes.source') }}
            </AppTableTh>
            <AppTableTh nowrap class="text-right" v-if="isColumnEnable('amount')">
              {{ t('expense.attributes.amount') }}
            </AppTableTh>
            <AppTableTh nowrap class="text-right" v-if="isColumnEnable('is_invoiced')">
              {{ t('expense.attributes.is_invoiced') }}
            </AppTableTh>
            <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
          </AppTableTr>
        </AppTableHead>
        <AppTableBody>
          <AppTableTr v-for="expense in expenses" :key="expense">
            <AppTableTd nowrap v-if="isColumnEnable('type')">
              {{ t(`expense.type.${expense.type}`) }}
            </AppTableTd>
            <AppTableTd nowrap v-if="isColumnEnable('ref_number')">
              <span v-if="expense.ref_number" v-text="expense.ref_number" />
              <EmptyValue v-else />
            </AppTableTd>
            <AppTableTd nowrap v-if="isColumnEnable('date')">
              {{ d(expense.date) }}
            </AppTableTd>
            <AppTableTd nowrap v-if="isColumnEnable('project')">
              <RouterLink target="_blank" :to="{ name: 'projects.view', params: { uuid, id: expense.project.id } }">
                {{ expense.project.name }}
              </RouterLink>
            </AppTableTd>
            <AppTableTd v-if="isColumnEnable('description')">
              <span v-if="expense.description" v-text="expense.description" />
              <EmptyValue v-else />
            </AppTableTd>
            <AppTableTd nowrap v-if="isColumnEnable('source')">
              {{ t(`expense.source.${expense.source}`) }}
            </AppTableTd>
            <AppTableTd nowrap class="text-right" v-if="isColumnEnable('amount')">
              {{ n(new Decimal(expense.amount).toNumber(), 'currency') }}
            </AppTableTd>
            <AppTableTd nowrap class="text-right" v-if="isColumnEnable('is_invoiced')">
              {{ t(`common.${expense.is_invoiced ? 'yes' : 'no'}`) }}
            </AppTableTd>
            <AppTableTd nowrap class="text-right">
              <template v-if="expense.source === ExpenseSource.MANUAL && !expense.is_invoiced">
                <AppButton v-tooltip.left="t('common.edit')" @click.stop="onEdit(expense)" size="small" light circle>
                  <FontIcon name="pencil" />
                </AppButton>
                <AppButton
                  v-tooltip.left="t('common.delete')"
                  color="danger"
                  @click.stop="onDelete(expense)"
                  class="ml-2"
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="trash" />
                </AppButton>
              </template>
            </AppTableTd>
          </AppTableTr>
        </AppTableBody>
      </AppTable>
      <AppPagination :per-page="perPage" :total="total" v-model="page" />
    </template>
  </template>
</template>
