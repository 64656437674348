<script setup lang="ts">
import { reactive, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';

import { AppButton, FormLabel } from '@/components';
import api from '@/services/api';
import useLoader from '@/composables/useLoader';
import { IProjectClientTabResource, IProjectResource, IProjectRequestNotes } from '@/types/Project';
import FormTextarea from '@/components/FormTextarea.vue';

const { t } = useI18n({ useScope: 'global' });
const submitLoader = useLoader({ useProgress: false });

const props = defineProps<{
  clientUuid: string;
  project: IProjectClientTabResource;
}>();

const form = reactive<IProjectRequestNotes>({
  notes: props.project.notes ?? '',
});

const disabled = computed(() => form.notes === '');

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'update', newNotes: IProjectResource): void;
}>();

async function submit() {
  try {
    submitLoader.start();
    const response = await api.projects.update(form, props.clientUuid, props.project.id);
    emit('update', response.data);
  } catch (error) {
    console.error(error);
  } finally {
    submitLoader.finish();
  }
}
</script>

<template>
  <VueFinalModal class="modal-overlay" content-class="modal-container" :click-to-close="false" :esc-to-close="true">
    <form @submit.prevent="submit">
      <div class="modal-header">
        <h2 v-text="t('client.project.attributes.project_notes')" />
      </div>
      <div class="modal-content">
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="office_name">{{ t('client.project.attributes.project_notes') }}</FormLabel>
            </div>
            <div class="col-md-9">
              <FormTextarea v-model="form.notes" id="note" trim />
            </div>
          </div>
        </div>
        <slot />
      </div>
      <div class="modal-footer">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
          <AppButton light @click.prevent="emit('cancel')">
            {{ t('common.cancel') }}
          </AppButton>
          <AppButton
            class="mt-2 mt-sm-0"
            color="secondary"
            :disabled="disabled"
            :loading="submitLoader.isLoading.value"
          >
            {{ t('common.save') }}
          </AppButton>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>
