import api from '@/services/api';
import useLoader from '@/composables/useLoader';
import useAuthStore from '@/store/AuthStore';
import ErrorService from '@/services/errors/ErrorService';

export default function useAuth() {
  const loader = useLoader();
  const { unsetAuthenticatedUser } = useAuthStore();

  async function logout() {
    try {
      loader.start();
      await api.auth.logout();
      unsetAuthenticatedUser();
    } catch (error: unknown) {
      ErrorService.onError(error);
    } finally {
      loader.finish();
    }
  }

  return { logout };
}
