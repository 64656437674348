<script setup lang="ts">
import { computed, reactive, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';

import api from '@/services/api';
import { AppButton, FormLabel, FormInput, AppCloseButton } from '@/components';
import useModalFeatures from '@/composables/useModalFeatures';
import useTrackChanges from '@/composables/useTrackChanges';
import { IWorkTitleResource, IWorkTitleRequestBody } from '@/types/WorkTitle';

const { t } = useI18n({ useScope: 'global' });
const { onCtrlEnter } = useModalFeatures();

type Props = {
  workTitle: null | IWorkTitleResource;
};

const { workTitle } = defineProps<Props>();

const emit = defineEmits<{
  cancel: [];
  created: [workTitle: IWorkTitleResource];
  updated: [workTitle: IWorkTitleResource];
  closed: [];
}>();

const loading = ref(false);

const form = reactive<IWorkTitleRequestBody>({
  name: '',
});
const tracker = useTrackChanges(form);

const disabled = computed(() => !form.name);

async function submit() {
  loading.value = true;
  try {
    if (workTitle) {
      const response = await api.workTitles.update(workTitle.id, form);
      emit('updated', response.data);
    } else {
      const response = await api.workTitles.store(form);
      emit('created', response.data);
    }
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
}

onMounted(() => {
  form.name = workTitle?.name ?? '';
  tracker.commit();
});

onCtrlEnter(() => {
  if (!disabled.value) {
    if (tracker.isModified.value) {
      submit();
    } else {
      emit('cancel');
    }
  }
});
</script>

<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container"
    :click-to-close="false"
    :esc-to-close="true"
    v-slot="{ close }"
    @closed="emit('closed')"
  >
    <form @submit.prevent="submit" @keydown.enter.prevent>
      <div class="modal-header">
        <h2 v-text="workTitle ? t('work-title.edit.title', { name: workTitle.name }) : t('work-title.create.title')" />
        <AppCloseButton @close="close" />
      </div>
      <div class="modal-content">
        <!-- Name -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="work_title_name" required>{{ t('work-title.attributes.name') }}</FormLabel>
            </div>
            <div class="col-md-9">
              <FormInput v-model="form.name" id="work_title_name" required />
            </div>
          </div>
        </div>
        <slot />
      </div>
      <div class="modal-footer">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
          <AppButton light @click.prevent="close" :disabled="loading">
            {{ t('common.cancel') }}
          </AppButton>
          <AppButton v-if="workTitle" class="mt-2 mt-sm-0" color="success" :loading="loading" :disabled="disabled">
            {{ t('common.update') }}
          </AppButton>
          <AppButton v-else class="mt-2 mt-sm-0" color="secondary" :loading="loading" :disabled="disabled">
            {{ t('common.create') }}
          </AppButton>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>
