<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { InternalProjectStatusType } from '@/types/InternalProject';

const { t } = useI18n({ useScope: 'global' });

const props = defineProps<{
  status: InternalProjectStatusType;
}>();

const color = computed(() => {
  switch (props.status) {
    case 'draft':
      return 'is-warning';
    case 'active':
      return 'is-secondary';
    case 'closed':
      return 'is-danger';
    default:
      return '';
  }
});

const icon = computed(() => {
  switch (props.status) {
    case 'draft':
      return 'ti-pencil';
    case 'active':
      return 'ti-player-play';
    case 'closed':
      return 'ti-ban';
    default:
      return '';
  }
});
</script>

<template>
  <span class="badge" :class="color">
    <i class="ti" :class="icon" />
    {{ t(`internal-projects.status.${status}`) }}
  </span>
</template>
