export default {
  index: {
    mapped_services_title: 'Mapped services',
    mapped_services_help: '',
    not_mapped_services_title: 'Not mapped services',
    not_mapped_services_help: '',
    link_services: 'Link services',
    table: {
      services_in_planner: 'Services in WePlan',
      services_in_crm: 'Services in WeCRM',
      comment: 'Mapping comment',
    },
  },
  create: {
    title: 'Link new services',
  },
  edit: {
    title: 'Edit linked services',
  },
  mapping_comment_modal: {
    title: 'Update',
    note: 'Mapping comment',
  },
  form: {
    planner_services: 'WePlan service',
    crm_services: 'WeCRM service',
    internal_note: 'Internal note',
  },

  // Confirms
  confirm: {
    destroy: {
      title: 'Delete linked service',
      text: 'Are you sure you want to delete the linked service from the system?',
    },
  },
};
