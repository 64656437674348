<script setup lang="ts">
import { HelpInformation } from '@/components';
import { IClientFullResource } from '@/types/Client';
import { useI18n } from 'vue-i18n';

const { client } = defineProps<{ client: IClientFullResource }>();

const { t, d } = useI18n({ useScope: 'global' });
</script>

<template>
  <div class="d-flex align-items-end mb-2">
    <h1 class="mb-0" v-text="client.name" />
    <HelpInformation class="ml-1" translation="client.edit.help" />
  </div>
  <p>{{ t('client.attributes.responsible_user') }}: <strong v-text="client.responsible_user.name" /></p>
  <p>
    {{ client.org_number }}
    <template v-if="client.current_financial_year.start_date && client?.current_financial_year.end_date">
      | {{ d(client.current_financial_year.start_date) }} -
      {{ d(client.current_financial_year.end_date) }}
    </template>
  </p>
</template>
