import { RouteRecordRaw } from 'vue-router';

import Login from '@/views/Auth/Login.vue';
import PortalUserAcceptInvite from '@/views/Auth/PortalUserAcceptInvite.vue';
import AuthLayout from '@/layouts/AuthLayout.vue';

const routes: RouteRecordRaw[] = [
  {
    name: 'login',
    path: '/login',
    component: Login,
    meta: {
      isAuthPage: true,
      layout: AuthLayout,
    },
  },
  {
    name: 'portal-user-accept-invite',
    path: '/accept-invite',
    component: PortalUserAcceptInvite,
    meta: {
      layout: AuthLayout,
      authenticatedUser: false,
    },
  },
];

export default routes;
