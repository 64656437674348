<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouteQuery } from '@vueuse/router';
import { useRouter } from 'vue-router';

import { IAuthPortalUserAcceptInviteRequestBody } from '@/types/Auth';
import api from '@/services/api';
import useLoader from '@/composables/useLoader';
import { AppButton, FormLabel, FormInput, AppLoader, AppAlert } from '@/components';
import { useTitle } from '@vueuse/core';

const loader = useLoader();
const router = useRouter();
const { t } = useI18n({ useScope: 'global' });

const form = reactive<IAuthPortalUserAcceptInviteRequestBody>({
  password: '',
  password_confirmation: '',
});

const invite = useRouteQuery('invite', '');
const customer = useRouteQuery('customer', '');
const signature = useRouteQuery('signature', '');
const checkLoader = useLoader({ useProgress: false });
const checkError = ref(false);

const isLoginFormValid = computed(() => form.password.length >= 8 && form.password === form.password_confirmation);

async function submit() {
  try {
    loader.start();
    const searchParams = new URLSearchParams();
    searchParams.append('customer', customer.value);
    searchParams.append('signature', signature.value);
    await api.auth.invite.accept(invite.value, form, searchParams);
    await router.push({ name: 'login' });
  } catch (error: unknown) {
    console.error(error);
  } finally {
    loader.finish();
  }
}

async function check() {
  try {
    checkLoader.start();
    const searchParams = new URLSearchParams();
    searchParams.append('customer', customer.value);
    searchParams.append('signature', signature.value);
    await api.auth.invite.check(invite.value, form, searchParams);
    checkError.value = false;
  } catch (error: unknown) {
    console.error(error);
    checkError.value = true;
  } finally {
    checkLoader.finish();
  }
}

onMounted(check);

const title = useTitle(computed(() => t('auth.accept_invite.title')));
</script>

<template>
  <div v-if="checkLoader.isLoading.value" class="text-center">
    <AppLoader size="large" />
  </div>
  <template v-else>
    <form v-if="!checkError" class="p-sm-4" @submit.prevent="submit">
      <h1 class="mb-2 text-center" v-text="title" />
      <div class="form-group">
        <FormLabel html-for="password" required>{{ t('common.password') }}</FormLabel>
        <FormInput
          type="password"
          id="password"
          v-model="form.password"
          :placeholder="t('auth.enter_your_password')"
          required
        />
      </div>
      <div class="form-group">
        <FormLabel html-for="password_confirmation" required>{{ t('common.password_confirm') }}</FormLabel>
        <FormInput
          type="password"
          id="password_confirmation"
          v-model="form.password_confirmation"
          :placeholder="t('auth.confirm_your_password')"
          required
        />
      </div>
      <AppButton class="w-100 mt-3" color="primary" :loading="loader.isLoading.value" :disabled="!isLoginFormValid">
        {{ t('common.confirm') }}
      </AppButton>
    </form>
    <AppAlert v-else type="danger" class="text-center">The invitation has been revoked</AppAlert>
  </template>
</template>
