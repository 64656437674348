export default {
  index: {
    title: 'Artiklar',
    fetch_articles: 'Hämta artiklar',
  },
  attributes: {
    erp_id: 'Nummer',
    erp_type: 'Typ',
    name_sv: 'Namn (SWE)',
    name_en: 'Name (ENG)',
    unit: 'Enhet',
    status: 'Status',
  },
  status: {
    enabled: 'Aktiverad',
    disabled: 'Inaktiverad',
  },
  empty: 'Inga artiklar',
  tooltip: {
    enable: 'Aktivera {name}',
    disable: 'Inaktivera {name}',
  },
  confirm: {
    disable: {
      title: 'Inaktivera artikel',
      text: 'Denna artikel används redan. Du behöver välja en annan aktiv artikel.',
    },
  },
};
