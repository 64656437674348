<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps<{
  htmlFor?: string;
  required?: boolean;
}>();

const classes = computed(() => ['form-label', { required: props.required }]);
</script>

<template>
  <label :for="htmlFor" :class="classes">
    <slot />
  </label>
</template>
