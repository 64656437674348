<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal';
import { useI18n } from 'vue-i18n';
import { AppButton } from '@/components';
import { EventType } from '@/types/Event';
import useLoading from '@/composables/useLoading';
import { ConfirmDialogConfirmParams } from '@/types/Common';

const { t } = useI18n({ useScope: 'global' });

defineProps<{
  type: EventType;
  hasEvents?: boolean;
}>();

const { loading, setLoading } = useLoading();

const emit = defineEmits<{
  closed: [];
  confirm: [withFutureEvents: boolean, params: ConfirmDialogConfirmParams];
}>();
</script>
<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container"
    :click-to-close="false"
    :esc-to-close="false"
    v-slot="{ close }"
    @closed="emit('closed')"
  >
    <div class="modal-header">
      <h2 class="mb-0" v-text="t('event.confirm.destroy.title')" />
    </div>
    <div class="modal-content">
      <p v-html="t('event.confirm.destroy.text')" />
      <slot />
    </div>
    <div class="modal-footer">
      <div class="d-flex">
        <AppButton light :disabled="loading" @click="close">
          {{ t('common.cancel') }}
        </AppButton>
        <div class="d-flex ml-auto">
          <AppButton
            v-if="hasEvents"
            class="mr-2"
            color="danger"
            :disabled="loading"
            @click="emit('confirm', true, { setLoading })"
          >
            {{ t(`event.confirm.destroy.${type}.confirm_with_future_events`) }}
          </AppButton>
          <AppButton color="danger" :disabled="loading" @click="emit('confirm', false, { setLoading })">
            {{ t(`event.confirm.destroy.${type}.confirm`) }}
          </AppButton>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>
