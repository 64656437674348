import { ref } from 'vue';

import { SetLoading } from '@/types/Common';

export default function useLoading() {
  const loading = ref(false);

  const setLoading: SetLoading = (value: boolean) => {
    loading.value = value;
  };

  return {
    loading,
    setLoading,
  };
}
