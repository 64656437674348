export default {
  resource: {
    name: 'Kostnad | Kostnader',
  },
  index: {
    title: 'Kostnad',
    help: '',
    create: 'Lägg till kostnad',
  },

  create: {
    title: 'Lägg till kostnad',
  },
  edit: {
    title: 'Redigera kostnad',
  },

  source: {
    sync: 'Synkad',
    time_sheet: 'Tidrapport',
    manual: 'Manuallt tillagd',
  },

  type: {
    receipt: 'Kvitto',
    invoice: 'Faktura',
    mileage: 'Milersättning',
  },

  empty: 'Inga kostnader',

  attributes: {
    type: 'Typ',
    ref_number: 'RefNr',
    date: 'Datum',
    client: 'Kund',
    project: 'Projekt',
    description: 'Beskrivning',
    source: 'Källa',
    amount: 'Nettobelopp',
    is_invoiced: 'Tillagd på faktura',
  },

  confirm: {
    destroy: {
      title: 'Radera kostnad',
      text: 'Är du säker på att du vill ta bort denna kostnad?',
    },
  },
};
