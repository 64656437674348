import route from 'ziggy-js';
import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import { IAdjustmentPriceMatrixRequestBody, IPriceMatrixRequestBody, IPriceMatrixResponse } from '@/types/PriceMatrix';
import { IServicePriceAdjustmentListResource } from '@/types/Service';

export default {
  async list({ searchParams }: { searchParams: URLSearchParams }): Promise<IResponse<IPriceMatrixResponse>> {
    return await httpClient.get(route('services.service-price-matrix'), { searchParams }).json();
  },
  async getHistoryPrices(
    service: string,
    user_uuid: string,
  ): Promise<IResponse<IServicePriceAdjustmentListResource[]>> {
    return await httpClient.get(route('services.service-user-price.get', { service, user_uuid })).json();
  },
  async store(service: string, user_uuid: string, body: IPriceMatrixRequestBody): Promise<{ message: string }> {
    return await httpClient
      .patch(route('services.service-user-price.update', { service, user_uuid }), { json: body })
      .json();
  },
  async destroy(id: string): Promise<void> {
    return await httpClient.delete(route('services.service-user-price.delete', { service_price: id })).json();
  },

  // adjustment prices
  async getAdjustmentPrices(service: string): Promise<IResponse<IServicePriceAdjustmentListResource[]>> {
    return await httpClient.get(route('services.service-price-adjustment.get', { service })).json();
  },
  async storeOrUpdateAdjustmentPrice(
    service: string,
    body: IAdjustmentPriceMatrixRequestBody,
  ): Promise<{ message: string }> {
    return await httpClient
      .patch(route('services.service-price-adjustment.store-or-update', { service }), { json: body })
      .json();
  },
  async destroyAdjustmentPrice(service: string, id: string): Promise<{ message: string }> {
    return await httpClient
      .delete(route('services.service-price-adjustment.delete', { service: service, price_adjustment: id }))
      .json();
  },
};
