import route from 'ziggy-js';
import { Options } from 'ky';
import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import { IInternalProjectCreateOrUpdateRequestBody, IInternalProjectResource } from '@/types/InternalProject';

export default {
  async index(options: Options = {}): Promise<IResponse<IInternalProjectResource[]>> {
    return await httpClient.get(route('internal-projects.index'), options).json();
  },
  async store(
    json: IInternalProjectCreateOrUpdateRequestBody,
    options: Options = {},
  ): Promise<IResponse<IInternalProjectResource>> {
    return await httpClient.post(route('internal-projects.create'), { json, ...options }).json();
  },
  async get(internalProject: number, options: Options = {}): Promise<IResponse<IInternalProjectResource>> {
    return await httpClient.get(route('internal-projects.get', { internalProject }), options).json();
  },
  async update(
    internalProject: number,
    json: IInternalProjectCreateOrUpdateRequestBody,
    options: Options = {},
  ): Promise<IResponse<IInternalProjectResource>> {
    return await httpClient.patch(route('internal-projects.update', { internalProject }), { json, ...options }).json();
  },
  async destroy(internalProject: number, options: Options = {}): Promise<IResponse<void>> {
    return await httpClient.delete(route('internal-projects.delete', { internalProject }), options).json();
  },
  async activate(internalProject: number, options: Options = {}): Promise<IResponse<IInternalProjectResource>> {
    return await httpClient.post(route('internal-projects.activate', { internalProject }), options).json();
  },
  async close(internalProject: number, options: Options = {}): Promise<IResponse<IInternalProjectResource>> {
    return await httpClient.post(route('internal-projects.close', { internalProject }), options).json();
  },
};
