export default {
  index: {
    title: 'Räkenskapsår',
  },
  create: {
    title: 'Skapa nytt räkenskapsår',
  },
  edit: {
    title: 'Redigera räkenskapsår',
  },
  attributes: {
    start_date: 'Startdatum',
    end_date: 'Slutdatum',
  },
  confirm: {
    delete: {
      title: 'Radera räkenskapsår',
      text: 'Är du säker att du vill radera detta räkenskapsår?',
    },
  },
  message: {
    can_be_deleted:
      'Räkenskapsåret har projekt kopplade till sig. Ta bort projekt, eller koppla till annat räkenskapsår för att kunna radera.',
    editing_can_have_effect:
      'Detta ändrar räkenskapsåret. Eventuella länkade projekt kan behöva få uppdaterade projektdatum och deadlines. Eventuell kundmapp som är namngiven efter räkenskapsåret kommer att namnändras automatiskt. Vill du fortsätta?',
  },
};
