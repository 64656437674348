import { useModal } from 'vue-final-modal';
import { ConfirmModal, ReassignModal, TodoModal } from '@/components';
import { ConfirmDialogConfirmParams } from '@/types/Common';
import { HiddenTodo, ITodoRequest, ITodoResource } from '@/types/Todo';
import api from '@/services/api';
import toast from '@/services/toast';
import { useI18n } from 'vue-i18n';
import { Ref } from 'vue';
import { useLocalStorage } from '@vueuse/core';
import useAuthStore from '@/store/AuthStore';

type Callbacks = {
  onCreated?: (todo: ITodoResource) => void | Promise<void>;
  onUpdated?: (todo: ITodoResource) => void | Promise<void>;
  onDeleted?: (todo: ITodoResource) => void | Promise<void>;
};

export default function useTodos(callbacks?: Callbacks) {
  const { t } = useI18n({ useScope: 'global' });
  const { authenticatedUser } = useAuthStore();

  async function onCreate(initialValues: Partial<ITodoRequest> = {}) {
    const { open, close, destroy } = useModal({
      component: TodoModal,
      attrs: {
        initialValues,
        async onCreated(todo: ITodoResource, { setLoading }: ConfirmDialogConfirmParams) {
          if (callbacks?.onCreated) {
            await callbacks.onCreated(todo);
          }
          setLoading(false);
          await close();
        },
        onCancel() {
          close();
        },
        onClosed() {
          destroy();
        },
      },
    });
    await open();
  }

  async function onEdit(todo: ITodoResource) {
    const { open, close, destroy } = useModal({
      component: TodoModal,
      attrs: {
        todo,
        async onUpdated(todo: ITodoResource, { setLoading }: ConfirmDialogConfirmParams) {
          if (callbacks?.onUpdated) {
            await callbacks.onUpdated(todo);
          }
          setLoading(false);
          await close();
        },
        onCancel() {
          close();
        },
        onClosed() {
          destroy();
        },
      },
    });
    await open();
  }

  async function onDelete(todo: ITodoResource) {
    const { open, close, destroy } = useModal({
      component: ConfirmModal,
      attrs: {
        title: t('todo.confirm.destroy.title'),
        message: t('todo.confirm.destroy.text'),
        async onConfirm({ setLoading }: ConfirmDialogConfirmParams) {
          try {
            setLoading(true);
            await api.todos.delete(todo.id);
            if (callbacks?.onUpdated) {
              await callbacks.onUpdated(todo);
            }
            toast.success(t('common.deleted'));
            await close();
          } catch (error) {
            console.error(error);
          } finally {
            setLoading(false);
          }
        },
        onCancel() {
          close();
        },
        onClosed() {
          destroy();
        },
      },
    });
    await open();
  }
  async function onReassign(todo: ITodoResource) {
    if (todo.recepientUserUuid === null) return;
    const { open, close, destroy } = useModal({
      component: ReassignModal,
      attrs: {
        initialUuid: todo.recepientUserUuid,
        async onReassign(uuid: string, { setLoading }: ConfirmDialogConfirmParams) {
          try {
            setLoading(true);
            const { id, deadline, type, text, link, linkDescription, clientUuid } = todo;
            await api.todos.update(id, {
              deadline,
              type,
              text: text ?? '',
              link,
              clientUuid,
              linkDescription,
              recepientUserUuid: uuid,
            });
            if (callbacks?.onUpdated) {
              await callbacks.onUpdated(todo);
            }
            toast.success(t('common.updated'));
            await close();
          } catch (error) {
            console.error(error);
          } finally {
            setLoading(false);
          }
        },
        onCancel() {
          close();
        },
        onClosed() {
          destroy();
        },
      },
    });
    await open();
  }

  const hiddenTodos: Ref<HiddenTodo[]> = useLocalStorage(`hidden_todos_${authenticatedUser.uuid}`, [], {});

  function isHiddenTodo(id: number) {
    return !!hiddenTodos.value.find((t) => t.id === id);
  }

  function getHiddenTodo(id: number) {
    return hiddenTodos.value.find((t) => t.id === id);
  }

  return {
    onCreate,
    onEdit,
    onDelete,
    onReassign,
    hiddenTodos,
    isHiddenTodo,
    getHiddenTodo,
  };
}
