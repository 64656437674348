const companyCategoriesMapping: Record<string, string> = {
  'Privat aktiebolag': 'Aktiebolag',
  'Publikt aktiebolag': 'Aktiebolag',
  Aktiebolag: 'Aktiebolag',
  Bostadsrättsförening: 'Brf',
  'Ekonomisk förening': 'EkonomiskForening',
  'Enskild firma': 'EnskildFirma',
  Handelsbolag: 'HandelsBolag',
  'Ideell förening': 'IdeellForening',
  Kommanditbolag: 'Kommanditbolag',
  Privat: 'Privat',
  Stiftelse: 'Stiftelse',
  Offentligt: 'Offentligt',
  Filial: 'Filial',
};

export default companyCategoriesMapping;
