import { computed, ref } from 'vue';
import { useLocalStorage } from '@vueuse/core';

export default function useColumnSettings<T extends string>(name: string, initialValue: T[]) {
  const columns = ref<string[]>([...initialValue]);

  const enabledColumns = useLocalStorage(name, initialValue);

  const isColumnEnable = computed(() => {
    return (columnName: T) => enabledColumns.value.includes(columnName);
  });

  return {
    columns,
    enabledColumns,
    isColumnEnable,
  };
}
