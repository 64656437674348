<script setup lang="ts">
import {
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  UserFlexibleWorkRateRow,
} from '@/components';
import { useI18n } from 'vue-i18n';
import { IWorkRateWeekDay } from '@/types/WorkRate';
import { DateTime } from 'luxon';
import { Decimal } from 'decimal.js';
import { computed } from 'vue';

type Props = {
  workingHours: number;
};

const { workingHours } = defineProps<Props>();

const { t, locale } = useI18n({ useScope: 'global' });

const rates = defineModel<IWorkRateWeekDay[]>('rates', { required: true });

const totalDefaultWorkingHours = computed(() => Decimal.mul(workingHours, 5).toDecimalPlaces(2).toNumber());

const totalScheduledWorkingHours = computed(() =>
  rates.value.reduce(
    (total, rate) => total.plus(Decimal.div(Decimal.mul(workingHours, rate.rate), 100).toDecimalPlaces(2).toNumber()),
    new Decimal(0),
  ),
);

const totalScheduledWorkingRate = computed(() => {
  return Decimal.div(Decimal.mul(totalScheduledWorkingHours.value, 100), totalDefaultWorkingHours.value)
    .toDecimalPlaces(2)
    .toNumber();
});
</script>

<template>
  <AppTable bordered hoverable>
    <AppTableHead>
      <AppTableTr>
        <AppTableTh style="width: 25%" nowrap>{{ t('user.work_rates.flexible_time.attributes.day') }}</AppTableTh>
        <AppTableTh style="width: 25%" class="text-right" nowrap>
          {{ t('user.work_rates.flexible_time.attributes.default') }}
        </AppTableTh>
        <AppTableTh style="width: 30%" class="text-right" nowrap>
          {{ t('user.work_rates.flexible_time.attributes.employee') }}
        </AppTableTh>
        <AppTableTh style="width: 20%" class="text-right" nowrap>
          {{ t('user.work_rates.flexible_time.attributes.rate') }}, %
        </AppTableTh>
      </AppTableTr>
    </AppTableHead>
    <AppTableBody>
      <UserFlexibleWorkRateRow
        v-for="(rate, index) in rates"
        :key="rate.day"
        :day="DateTime.now().setLocale(locale).startOf('week').plus({ days: index }).toFormat('ccc')"
        v-model:rate="rate.rate"
        :working-hours="rate.day <= 5 ? workingHours : 0"
        :max-hours="workingHours"
      >
        <template #rate>
          {{ rate.day <= 5 ? `${rate.rate}` : `-` }}
        </template>
      </UserFlexibleWorkRateRow>
      <AppTableTr class="font-bold">
        <AppTableTd>
          {{ t('user.work_rates.flexible_time.attributes.total') }}
        </AppTableTd>
        <AppTableTd class="text-right">
          {{ totalDefaultWorkingHours }}
        </AppTableTd>
        <AppTableTd class="text-right">
          {{ totalScheduledWorkingHours }}
        </AppTableTd>
        <AppTableTd class="text-right"> {{ totalScheduledWorkingRate }} </AppTableTd>
      </AppTableTr>
    </AppTableBody>
  </AppTable>
</template>
