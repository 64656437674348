export default {
  index: {
    title: 'Checkpoints',
    help: '',
    filters: {
      project_responsible: 'Project responsible',
      customer_responsible: 'Customer responsible',
      from_date: 'Period from',
      to_date: 'Period to',
      services: 'Services',
      hide_done: 'Hide done',
    },
  },
  mode: {
    calendar: 'Calendar view',
    list: 'List view',
  },
  attributes: {
    name: 'Name',
    client: 'Customer',
    service: 'Service',
    project: 'Project',
    customer_responsible: 'Customer responsible',
    project_responsible: 'Project responsible',
    period: 'Period',
    status: 'Status',
    comment: 'Comment',
    frequency: 'Frequency',
    checkpoint: 'Checkpoint',
  },
  state: {
    n_a: 'Not set',
    done: 'Done',
    not_done: 'Not done',
  },
  events_status: {
    done: 'Done ({completed}/{total})',
    not_done: 'Not done ({completed}/{total})',
  },
  edit: {
    title: 'Checkpoint',
  },
  empty: 'Apply filter to show data.',
};
