<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';

import useLocaleStore from '@/store/LocaleStore';

const { messages, t } = useI18n({ useScope: 'global' });
const localeStore = useLocaleStore();
const { locale } = storeToRefs(localeStore);
const { setLocale } = localeStore;
</script>

<template>
  <div class="form-group mb-0">
    <div class="form-wrapper">
      <select class="form-control w-auto" id="language" :value="locale" @change="setLocale($event.target.value)">
        <option v-for="code in Object.keys(messages)" :key="code" :value="code" v-text="t(`common.${code}`)" />
      </select>
    </div>
  </div>
</template>
