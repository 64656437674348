export default {
  index: {
    title: 'Min veckoöversikt',
    filters: 'Veckovis översikt filter',
    create: 'Lägg till',
    task: 'Uppgifter & notifieringar',
    week_one_letter: 'v',
    firm_activities: 'Interna aktiviteter',
    info: 'Info',
  },
  filters: {
    hide_done: 'Dölj klarmarkerade',
    show_all: 'Visa alla uppgifter',
    prioritised: 'Prio',
    has_deadline: 'Har deadline',
    this_week_or_earlier: 'Deadline denna vecka el. fg.',
    search_placeholder: 'Sök efter kund, projekt, tjänst, uppgift eller teammedlem',
  },
  buttons: {
    load_prev_week: 'Visa föregående vecka',
    load_next_week: 'Visa nästa vecka',
  },
  timesheet: {
    timesheet: 'Tidkort',
    summary: 'Summa',
  },
  invoice_until_today:
    'Fakturera fram till idag. Detta skapar en faktura för denna kund eller projekt med alla priser, utlägg med mera fram till dagens datum.',
};
