import route from 'ziggy-js';
import { Options } from 'ky';
import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import { IActivityGroupListResource, IActivityGroupRequestBody, IActivityGroupResource } from '@/types/Activity';

export default {
  async index(options: Options = {}) {
    return await httpClient
      .get(route('activities.activity-groups.index'), options)
      .json<IResponse<IActivityGroupListResource[]>>();
  },
  async store(json: IActivityGroupRequestBody, options: Options = {}) {
    return await httpClient
      .post(route('activities.activity-groups.store'), { ...options, json })
      .json<IResponse<IActivityGroupResource>>();
  },
  async get(group: number, options: Options = {}) {
    return await httpClient
      .get(route('activities.activity-groups.show', { group }), options)
      .json<IResponse<IActivityGroupResource>>();
  },
  async update(group: number, json: IActivityGroupRequestBody, options: Options = {}) {
    return await httpClient
      .patch(route('activities.activity-groups.update', { group }), { ...options, json })
      .json<IResponse<IActivityGroupResource>>();
  },
  async destroy(group: number, options: Options = {}) {
    return await httpClient.delete(route('activities.activity-groups.destroy', { group }), options).json<void>();
  },
};
