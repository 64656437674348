import { RouteRecordRaw } from 'vue-router';

import MainLayout from '@/layouts/MainLayout.vue';
import Clients from '@/views/Clients/Clients.vue';
import ClientCreate from '@/views/Clients/ClientCreate.vue';
import Client from '@/views/Clients/Client.vue';
import Overview from '@/components/Clients/Overview.vue';

import useClientStore from '@/store/ClientStore';
import Planning from '@/components/Clients/Planning/Planning.vue';
import Invoicing from '@/components/Clients/Invoicing.vue';
import Project from '@/components/Clients/Project.vue';
import Checkpoints from '@/components/Clients/Checkpoints.vue';
import ReportedTime from '@/components/Clients/ReportedTime.vue';
import Expenses from '@/components/Clients/Expenses/Expenses.vue';
import Processes from '@/components/Clients/Processes/Processes.vue';
import Settings from '@/components/Clients/Settings.vue';
import progress from '@/services/progress';

const prefix = 'clients';

const routes: RouteRecordRaw[] = [
  {
    path: `/${prefix}`,
    name: `${prefix}.index`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: Clients,
  },
  {
    path: `/${prefix}/create`,
    name: `${prefix}.create`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: ClientCreate,
  },
  {
    path: `/${prefix}/:uuid`,
    name: `${prefix}.view`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: Client,
    props: true,
    async beforeEnter(to, _from, next) {
      const { fetchClient, fetchFilesSettings, setClient, setFilesSettings, setUserPartOfTeam } = useClientStore();
      progress.start();
      try {
        const { data, meta } = await fetchClient(to.params.uuid as string);
        const settings = await fetchFilesSettings();
        setFilesSettings(settings);
        setClient(data);
        setUserPartOfTeam(meta.is_user_part_of_team);
        next();
      } catch (error) {
        console.error(error);
      } finally {
        progress.done();
      }
    },
    children: [
      { path: 'overview', name: 'client.overview', component: Overview, props: true },
      {
        path: 'files',
        name: 'client.files',
        component: { render: () => null },
        props: true,
      },
      { path: 'planning', name: 'client.planning', component: Planning, props: true },
      { path: 'billing', name: 'client.billing', component: Invoicing, props: true },
      { path: 'projects', name: 'client.projects', component: Project, props: true },
      { path: 'checkpoints', name: 'client.checkpoints', component: Checkpoints, props: true },
      { path: 'reported-time', name: 'client.reported_time', component: ReportedTime, props: true },
      { path: 'expenses', name: 'client.expenses', component: Expenses, props: true },
      { path: 'processes', name: 'client.processes', component: Processes, props: true },
      { path: 'settings', name: 'client.settings', component: Settings, props: true },
    ],
  },
];

export default routes;
