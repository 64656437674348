<script setup lang="ts">
import { computed } from 'vue';
import { FontIcon } from '@/components';

const emit = defineEmits<{
  'update:modelValue': [current: number];
  paginate: [current: number];
}>();

const props = defineProps<{
  modelValue: number;
  perPage: number;
  total: number;
}>();

const pages = computed(() => {
  return new Array(Math.ceil(props.total / props.perPage)).fill(null).map((_, index) => index + 1);
});

const visiblePages = computed(() => {
  const pagesToShow = 7;
  const currentIndex = pages.value.indexOf(props.modelValue);
  const startIndex = Math.max(currentIndex - pagesToShow, 0);
  const endIndex = Math.min(startIndex + pagesToShow * 2 + 1, pages.value.length);

  return pages.value.slice(startIndex, endIndex);
});

function setCurrent(page: number) {
  emit('update:modelValue', page);
  emit('paginate', page);
}
</script>

<template>
  <ul class="pagination" v-if="total > perPage">
    <li class="page-item" :class="{ disabled: props.modelValue === 1 }">
      <a class="page-link" href="#" @click.prevent="setCurrent(1)">
        <FontIcon name="chevrons-left" />
      </a>
    </li>
    <li class="page-item" :class="{ disabled: props.modelValue === 1 }">
      <a class="page-link" href="#" @click.prevent="setCurrent(props.modelValue - 1)">
        <FontIcon name="chevron-left" />
      </a>
    </li>
    <li class="page-item disabled" v-if="1 !== visiblePages[0]">
      <span class="page-link">...</span>
    </li>
    <li v-for="page in visiblePages" :key="page" class="page-item" :class="{ active: props.modelValue === page }">
      <span class="page-link" v-if="props.modelValue === page" v-text="page" aria-current="page" />
      <a class="page-link" v-else href="#" v-text="page" @click.prevent="setCurrent(page)" />
    </li>
    <li class="page-item disabled" v-if="pages.length !== visiblePages[visiblePages.length - 1]">
      <span class="page-link">...</span>
    </li>
    <li class="page-item" :class="{ disabled: props.modelValue === pages.length }">
      <a class="page-link" href="#" @click.prevent="setCurrent(props.modelValue + 1)">
        <FontIcon name="chevron-right" />
      </a>
    </li>
    <li class="page-item" :class="{ disabled: props.modelValue === pages.length }">
      <a class="page-link" href="#" @click.prevent="setCurrent(pages.length)">
        <FontIcon name="chevrons-right" />
      </a>
    </li>
  </ul>
</template>
