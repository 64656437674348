import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

export default function useTask() {
  const { t } = useI18n({ useScope: 'global' });

  const taskFrequencyOptions = computed(() => [
    { value: null, label: t('task.frequency.once') },
    { value: 'weekly', label: t('task.frequency.weekly') },
    { value: 'monthly', label: t('task.frequency.monthly') },
    { value: 'quarterly', label: t('task.frequency.quarterly') },
    { value: 'semi-annually', label: t('task.frequency.semi-annually') },
    { value: 'annually', label: t('task.frequency.annually') },
  ]);

  const taskRoleOptions = computed(() => [
    { value: 'responsible', label: t('task.role.responsible') },
    { value: 'team_member', label: t('task.role.team_member') },
  ]);

  return {
    taskFrequencyOptions,
    taskRoleOptions,
  };
}
