export default {
  index: {
    title: 'Kontakter',
    help: '',
    export_contacts: 'Exportera alla',
  },
  attributes: {
    name: 'Namn',
    email: 'Epost',
    phone: 'Tele',
    primary_contact: 'Primär kontakt',
    company_links: 'Koppling till företag',
    service_company_link: 'Koppling till samarbetspartners',
    service_company_type: 'Samarbetspartner-typ',
    service_company_title: 'Titel hos samarbetspartner',
    id_check_result: 'ID',
    is_in_sanctions_list: 'Sanktion',
    is_pep: 'PEP',
    comment: 'Kommentar',
  },
  filters: {
    search: 'Sök',
  },
  tooltip: {
    crm: 'Öpnna "{name}" i CRM',
    destroy: 'Radera kontakt "{name}"',
    destroy_cause_link: 'Ta bort kontaktens länkade organisation för att kunna radera kontakten.',
    id_check_result: 'Har du gjort en ID-kontroll?',
    is_in_sanctions_list: 'Är personen med på sanktionslistor?',
    is_pep: 'Är personen PEP?',
  },
  empty: 'Inga kontakter',
  // Confirms
  confirm: {
    destroy: {
      title: 'Ta bort kontakt',
      text: 'Är du säker på att du vill ta bort <strong>{name}</strong>?',
    },
  },
  role: {
    create: {
      title: 'Skapa ny kontaktroll',
    },
    attributes: {
      name_sv: 'Namn (Swedish)',
      name_en: 'Namn (English)',
    },
    tooltip: {
      create: 'Skapa ny roll',
    },
  },
};
