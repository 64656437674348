<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouteQuery } from '@vueuse/router';
import { DateTime } from 'luxon';
import { useModal } from 'vue-final-modal';

import toast from '@/services/toast';
import api from '@/services/api';
import useLoader from '@/composables/useLoader';
import authStore from '@/store/AuthStore';
import {
  AppAlert,
  AppButton,
  AppLoader,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  ConfirmModal,
  FontIcon,
  FreeDayModal,
  HelpInformation,
} from '@/components';
import { FreeDayType, IFreeDayResource } from '@/types/FreeDay';
import { ConfirmDialogConfirmParams } from '@/types/Common';
import { ITimePeriodResource } from '@/types/TimePeriod';
import VueDatePicker from '@vuepic/vue-datepicker';
import { useTitle } from '@vueuse/core';

const { t, d, locale } = useI18n({ useScope: 'global' });
const loader = useLoader();
const { isSuperAdminRole, isCustomerAdminRole } = authStore();

const year = useRouteQuery<number>('year', DateTime.now().year, { transform: Number });

const freeDays = ref<IFreeDayResource[]>([]);
const freeDaysLoading = ref(false);

const timePeriods = ref<ITimePeriodResource[]>([]);
const timePeriodsLoading = ref(false);

const disabledDates = computed<Date[]>(() => {
  return timePeriods.value
    .filter((timePeriod) => timePeriod.status === 'closed')
    .reduce((dates, timePeriod) => {
      const start = DateTime.local(timePeriod.year, timePeriod.month);
      if (start.isValid) {
        return [...dates, ...Array.from({ length: start.daysInMonth }, (_, i) => start.plus({ days: i }).toJSDate())];
      }
      return dates;
    }, [] as Date[]);
});

const canManage = computed(() => isCustomerAdminRole || isSuperAdminRole);

async function getFreeDays() {
  try {
    freeDaysLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('year', year.value.toString());
    const response = await api.freeDays.index({ searchParams });
    freeDays.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    freeDaysLoading.value = false;
  }
}

async function getTimePeriods() {
  try {
    timePeriodsLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.timePeriods.index({ searchParams });
    timePeriods.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    timePeriodsLoading.value = false;
  }
}

async function onCreate() {
  const { open, close, destroy } = useModal({
    component: FreeDayModal,
    attrs: {
      freeDay: null,
      disabledDates: disabledDates.value,
      async onCreated() {
        await getFreeDays();
        await close();
      },
      onCancel() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  await open();
}

async function onEdit(freeDay: IFreeDayResource) {
  const { open, close, destroy } = useModal({
    component: FreeDayModal,
    attrs: {
      freeDay,
      disabledDates: disabledDates.value,
      async onUpdated() {
        await getFreeDays();
        await close();
      },
      onCancel() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  await open();
}

function onDelete(freeDay: IFreeDayResource) {
  const { open, close, destroy } = useModal({
    component: ConfirmModal,
    attrs: {
      title: t('free-day.confirm.destroy.title'),
      message: t('free-day.confirm.destroy.text', { name: freeDay.name }),
      async onConfirm({ setLoading }: ConfirmDialogConfirmParams) {
        try {
          setLoading(true);
          await api.freeDays.destroy(freeDay.id);
          freeDays.value = freeDays.value.filter(({ id }) => id !== freeDay.id);
          toast.success(t('common.messages.has_been_deleted', { name: freeDay.name }));
          await close();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

const isPast = (freeDay: IFreeDayResource) =>
  DateTime.fromISO(freeDay.date).startOf('day') < DateTime.now().startOf('day');

watch(year, async () => {
  loader.start();
  await getFreeDays();
  loader.finish();
});

onMounted(async () => {
  loader.start();
  await Promise.all([getTimePeriods(), getFreeDays()]);
  loader.finish();
});

const title = useTitle(computed(() => t('free-day.index.title')));
</script>

<template>
  <div class="container-wide">
    <!-- Header -->
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center mb-3">
        <h1 class="mb-0" v-text="title" />
        <HelpInformation class="ml-1" translation="free-day.index.help" />
      </div>
      <AppButton v-if="canManage" color="secondary" @click.prevent="onCreate" :disabled="loader.isLoading.value">
        {{ t('free-day.index.create') }}
      </AppButton>
    </div>

    <!-- Filters -->
    <div class="row">
      <div class="form-group col-md-3">
        <div class="form-wrapper has-icon">
          <VueDatePicker v-model="year" format="yyyy" year-picker auto-apply>
            <template #input-icon><i class="form-icon ti ti-calendar" /></template>
          </VueDatePicker>
        </div>
      </div>
    </div>
    <!-- Loader -->
    <div v-if="loader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>

    <!-- Table -->
    <template v-else>
      <AppAlert v-if="freeDays.length === 0">
        {{ t('free-day.empty') }}
      </AppAlert>
      <template v-else>
        <AppTable hoverable>
          <AppTableHead>
            <AppTableTr>
              <AppTableTh nowrap>{{ t('free-day.attributes.date') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('free-day.attributes.name') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('free-day.attributes.weekday') }}</AppTableTh>
              <AppTableTh nowrap class="text-right">{{ t('free-day.attributes.workday') }}</AppTableTh>
              <AppTableTh nowrap class="text-right">{{ t('free-day.attributes.type') }}</AppTableTh>
              <AppTableTh nowrap class="text-right" v-if="canManage">{{ t('common.actions') }}</AppTableTh>
            </AppTableTr>
          </AppTableHead>
          <AppTableBody>
            <AppTableTr :class="{ 'is-past': isPast(freeDay) }" v-for="freeDay in freeDays" :key="freeDay.id">
              <AppTableTd nowrap>{{ d(freeDay.date) }}</AppTableTd>
              <AppTableTd nowrap><strong v-text="freeDay.name" /></AppTableTd>
              <AppTableTd nowrap style="text-transform: capitalize">
                {{ DateTime.fromISO(freeDay.date).setLocale(locale).toFormat('cccc') }}
              </AppTableTd>
              <AppTableTd nowrap class="text-right">{{ freeDay.workday_percent }}</AppTableTd>
              <AppTableTd nowrap class="text-right">{{ t(`free-day.type.${freeDay.type}`) }}</AppTableTd>
              <AppTableTd nowrap class="text-right" v-if="canManage">
                <AppButton
                  @click.stop.prevent="onEdit(freeDay)"
                  v-if="
                    freeDay.type === FreeDayType.ADDED &&
                    !disabledDates
                      .map((date) => DateTime.fromJSDate(date).toFormat('yyyy-MM-dd'))
                      .includes(freeDay.date)
                  "
                  size="small"
                  light
                  circle
                  v-tooltip.left="t('common.tooltip.edit', { name: freeDay.name })"
                >
                  <FontIcon name="pencil" />
                </AppButton>
                <AppButton
                  @click.stop.prevent="onDelete(freeDay)"
                  v-if="
                    freeDay.type === FreeDayType.ADDED &&
                    !disabledDates
                      .map((date) => DateTime.fromJSDate(date).toFormat('yyyy-MM-dd'))
                      .includes(freeDay.date)
                  "
                  class="ml-2"
                  color="danger"
                  size="small"
                  light
                  circle
                  v-tooltip.left="t('common.tooltip.delete', { name: freeDay.name })"
                >
                  <FontIcon name="trash" />
                </AppButton>
              </AppTableTd>
            </AppTableTr>
          </AppTableBody>
        </AppTable>
      </template>
    </template>
  </div>
</template>

<style scoped>
.table tr.is-past {
  background-color: var(--color-neutral-50-hex);
  color: var(--color-neutral-400-hex);
}
</style>
