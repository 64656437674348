import route from 'ziggy-js';
import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import { IActivityTypeListResource, IActivityTypeRequestBody, IActivityTypeResource } from '@/types/Activity';

export default {
  async index(): Promise<IResponse<IActivityTypeListResource[]>> {
    return await httpClient.get(route('activities.activity-types.index')).json();
  },
  async store(body: IActivityTypeRequestBody): Promise<IResponse<IActivityTypeResource>> {
    return await httpClient.post(route('activities.activity-types.store'), { json: body }).json();
  },
  async get(type: number): Promise<IResponse<IActivityTypeResource>> {
    return await httpClient.get(route('activities.activity-types.show', { type })).json();
  },
  async update(type: number, body: IActivityTypeRequestBody): Promise<IResponse<IActivityTypeResource>> {
    return await httpClient.patch(route('activities.activity-types.update', { type }), { json: body }).json();
  },
  async destroy(type: number): Promise<IResponse<never>> {
    return await httpClient.delete(route('activities.activity-types.destroy', { type })).json();
  },
};
