import route from 'ziggy-js';
import { Options } from 'ky';

import httpClient from '@/services/api/ky';
import { IExpenseRequest, IExpenseResource } from '@/types/Expense';
import { IResponse } from '@/types/Response';

export default {
  index(options: Options = {}) {
    return httpClient.get(route('expenses.list'), options).json<IResponse<IExpenseResource[]>>();
  },
  create(json: IExpenseRequest, options: Options = {}) {
    return httpClient.post(route('expenses.store'), { json, ...options }).json<IResponse<IExpenseResource>>();
  },
  get(expense: number, json: IExpenseRequest, options: Options = {}) {
    return httpClient.get(route('expenses.get', { expense }), { json, ...options }).json<IResponse<IExpenseResource>>();
  },
  update(expense: number, json: IExpenseRequest, options: Options = {}) {
    return httpClient
      .put(route('expenses.update', { expense }), { json, ...options })
      .json<IResponse<IExpenseResource>>();
  },
  delete(expense: number, options: Options = {}) {
    return httpClient.delete(route('expenses.delete', { expense }), options).json<void>();
  },
};
