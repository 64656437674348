import route from 'ziggy-js';
import { Options } from 'ky';
import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import { IFinancialYearResource, IFinancialYearRequestBody, IFinancialYearListResource } from '@/types/FinancialYear';

export default {
  list(client: string, options: Options = {}) {
    return httpClient
      .get(route('financial-years.index', { client }), options)
      .json<IResponse<IFinancialYearListResource[]>>();
  },
  store(client: string, json: IFinancialYearRequestBody, options: Options = {}) {
    return httpClient
      .post(route('financial-years.store', { client }), { ...options, json })
      .json<IResponse<IFinancialYearResource>>();
  },
  update(client: string, financialYear: string, json: IFinancialYearRequestBody, options: Options = {}) {
    return httpClient
      .put(route('financial-years.update', { client, financialYear }), { ...options, json })
      .json<IResponse<IFinancialYearResource>>();
  },
  destroy(client: string, financialYear: string, options: Options = {}) {
    return httpClient.delete(route('financial-years.destroy', { client, financialYear }), options).json<void>();
  },
};
