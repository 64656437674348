import { RouteRecordRaw } from 'vue-router';
import Overview from '@/views/Overview.vue';
import MainLayout from '@/layouts/MainLayout.vue';
import Profile from '@/views/Profile.vue';

const routes: RouteRecordRaw[] = [
  {
    name: 'profile',
    path: '/profile',
    component: Profile,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
  },
  {
    name: 'dashboard',
    path: '/',
    redirect: { name: 'overview' },
  },
  {
    name: 'overview',
    path: '/overview/:uuid?',
    component: Overview,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
  },
];

export default routes;
