<script setup lang="ts">
import { reactive, onMounted, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';
import VueSelect from 'vue-select';

import { AppButton, FormLabel, FormInput } from '@/components';
import { IServiceCheckpointRequest, ServiceCheckpointForm } from '@/types/Service';
import useModalFeatures from '@/composables/useModalFeatures';
import useTrackChanges from '@/composables/useTrackChanges';
import { CheckpointFrequency } from '@/types/Checkpoint';

const { t } = useI18n({ useScope: 'global' });
const { onCtrlEnter } = useModalFeatures();

type Props = {
  checkpoint: null | IServiceCheckpointRequest;
};

const { checkpoint } = defineProps<Props>();

const form = reactive<ServiceCheckpointForm>({
  name: '',
  frequency: null,
});
const tracker = useTrackChanges(form);

const disabled = computed(() => {
  return !(form.name && form.frequency);
});

const emit = defineEmits<{
  cancel: [];
  created: [task: ServiceCheckpointForm];
  updated: [task: ServiceCheckpointForm];
  closed: [];
}>();

function submit() {
  if (checkpoint) {
    emit('updated', form);
  } else {
    emit('created', form);
  }
}
onMounted(() => {
  form.name = checkpoint?.name ?? '';
  form.frequency = checkpoint?.frequency ?? null;
  tracker.commit();
});

onCtrlEnter(() => {
  if (!disabled.value) {
    if (tracker.isModified.value) {
      submit();
    } else {
      emit('cancel');
    }
  }
});
</script>

<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container"
    content-style="overflow: visible;"
    :click-to-close="false"
    :esc-to-close="true"
    v-slot="{ close }"
    @closed="emit('closed')"
  >
    <form @keydown.enter.prevent @submit.prevent="submit">
      <div class="modal-header">
        <h2 v-text="checkpoint ? t('service.checkpoints.edit.title') : t('service.checkpoints.create.title')" />
      </div>
      <div class="modal-content">
        <!-- Name -->
        <div class="row form-group align-items-center">
          <div class="col-3">
            <FormLabel html-for="checkpoint_name" required>{{ t('service.checkpoints.attributes.name') }}</FormLabel>
          </div>
          <div class="col">
            <FormInput v-model="form.name" id="checkpoint_name" required />
          </div>
        </div>
        <!-- Frequency -->
        <div class="row form-group align-items-center">
          <div class="col-3">
            <FormLabel html-for="checkpoint_frequency" required>
              {{ t('service.checkpoints.attributes.frequency') }}
            </FormLabel>
          </div>
          <div class="col">
            <VueSelect
              :clearable="false"
              v-model="form.frequency"
              :options="Object.values(CheckpointFrequency)"
              label="label"
              input-id="checkpoint_frequency"
              :placeholder="t('common.select')"
              :get-option-label="(option: string) => t(`service.checkpoints.frequency.${option}`)"
            />
          </div>
        </div>
        <slot />
      </div>
      <div class="modal-footer d-flex">
        <AppButton type="button" light @click.prevent="close">
          {{ t('common.cancel') }}
        </AppButton>
        <AppButton v-if="checkpoint" class="ml-auto" color="success" :disabled="disabled || !tracker.isModified.value">
          {{ t('common.update') }}
        </AppButton>
        <AppButton v-else class="ml-auto" color="secondary" :disabled="disabled">
          {{ t('common.add') }}
        </AppButton>
      </div>
    </form>
  </VueFinalModal>
</template>
