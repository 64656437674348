<script setup lang="ts">
import { computed, onMounted, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';
import VueSelect from 'vue-select';
import VueDatePicker from '@vuepic/vue-datepicker';

import { AppButton, FormLabel, FormMinutesDuration } from '@/components';
import useDate from '@/composables/useDate';
import useAuthStore from '@/store/AuthStore';
import { EventType, ISplitEventRequestBody } from '@/types/Event';
import { IUserPreviewResource } from '@/types/User';
import { ActivityDurationRule } from '@/types/Activity';
import useLoading from '@/composables/useLoading';
import { ConfirmDialogConfirmParams } from '@/types/Common';

const { t, locale } = useI18n({ useScope: 'global' });
const { convertDateRangeToWeekNumber, convertWeekNumberToDateRange } = useDate();
const { loading, setLoading } = useLoading();
const { authenticatedUser } = useAuthStore();

const props = defineProps<{
  modalMode: EventType;
  users: IUserPreviewResource[];
  initialTime: number;
  maxTime: number;
  initialWeek: number;
  minWeek: number;
  rule: ActivityDurationRule | null;
}>();

interface IForm {
  user_uuid: null | string;
  week: null | number;
  time: number;
}

const form = reactive<IForm>({
  user_uuid: null,
  week: null,
  time: 0,
});

const emit = defineEmits<{
  closed: [];
  confirm: [form: ISplitEventRequestBody, params: ConfirmDialogConfirmParams];
}>();

const disabled = computed(() => !form.user_uuid || !form.week);

function submit() {
  if (!form.user_uuid || !form.week) return;
  emit(
    'confirm',
    {
      user_uuid: form.user_uuid,
      time: form.time,
      week: form.week,
    },
    { setLoading },
  );
}

async function onWeekChange(event: [Date, Date] | null) {
  form.week = event ? convertDateRangeToWeekNumber(event) : null;
}

onMounted(() => {
  form.user_uuid = authenticatedUser?.uuid ?? null;

  if (props.initialWeek) {
    form.week = props.initialWeek;
  }
  if (props.initialTime) {
    form.time = props.initialTime;
  }
});
</script>

<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container is-small"
    :click-to-close="false"
    :esc-to-close="false"
    content-style="overflow: visible;"
    @closed="emit('closed')"
    v-slot="{ close }"
  >
    <form @submit.prevent="submit">
      <div class="modal-header">
        <h2 v-text="t(`event.split.title_${modalMode}`)" />
      </div>
      <div class="modal-content">
        <div class="form-group">
          <FormLabel html-for="split_user_uuid" required>
            {{ t('time-entry.modal.attributes.employee') }}
          </FormLabel>
          <VueSelect
            :clearable="false"
            v-model="form.user_uuid"
            :options="users"
            label="name"
            input-id="split_user_uuid"
            :placeholder="t('common.select')"
            :reduce="(option: IUserPreviewResource) => option.uuid"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" :required="!form.user_uuid" v-bind="attributes as object" v-on="events" />
            </template>
          </VueSelect>
        </div>
        <div class="form-group">
          <FormLabel html-for="split_time_week" required>
            {{ t('time-entry.modal.attributes.week') }}
          </FormLabel>
          <div class="form-wrapper has-icon d-flex align-items-center">
            <VueDatePicker
              :ui="{ input: 'form-control' }"
              :placeholder="t('common.select')"
              :model-value="form.week ? convertWeekNumberToDateRange(form.week) : null"
              @update:model-value="onWeekChange"
              :enable-time-picker="false"
              :month-change-on-scroll="false"
              required
              week-picker
              week-numbers="iso"
              :week-num-name="t('common.week_short')"
              format="RR-ww"
              auto-apply
              uid="split_time_week"
              :min-date="convertWeekNumberToDateRange(props.minWeek)[0]"
              :locale="locale"
            >
              <template #input-icon><i class="form-icon ti ti-calendar" /></template>
            </VueDatePicker>
          </div>
        </div>
        <div class="form-group">
          <FormLabel required>
            {{ t('time-entry.modal.attributes.estimated_time') }}
          </FormLabel>
          <FormMinutesDuration :max="maxTime" v-model="form.time" />
        </div>
        <slot />
      </div>
      <div class="modal-footer">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
          <AppButton light @click.prevent="close" :disabled="loading">
            {{ t('common.cancel') }}
          </AppButton>
          <AppButton class="mt-2 mt-sm-0" color="success" :loading="loading" :disabled="disabled">
            {{ t('common.save') }}
          </AppButton>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>
