export default {
  index: {
    title: 'Kontor',
    create: 'Skapa kontor',
    help: '',
  },

  create: {
    title: 'Skapa kontor',
  },
  edit: {
    title: 'Redigera kontor: {name}',
  },
  form: {
    name: 'Namn',
  },

  empty: 'Inga kontor',

  // Attributes
  attributes: {
    name: 'Namn',
    manager: 'Manager',
    employees: 'Anställda',
    active: 'Aktiv',
  },

  // Tooltips
  tooltip: {
    edit: 'Redigera "{name}"',
    destroy: 'Radera "{name}"',
  },

  // Confirms
  confirm: {
    destroy: {
      title: 'Radera kontor',
      text: 'Är du säker att du vill radera <strong>{name}</strong>?',
    },
  },
};
