import route from 'ziggy-js';
import { Options } from 'ky';

import httpClient from '@/services/api/ky';

export default {
  admin: {
    users: {
      edit(user: string, options: Options = {}) {
        return httpClient.get(route('system-redirects.admin.edit-user', { user }), options).json();
      },
    },
  },
};
