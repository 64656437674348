export default {
  index: {
    title: 'Invoice item templates',
    create: 'Create item',
  },
  attributes: {
    type: 'Type',
    source: 'Item source',
    price_type: 'Price type',
    default_description_sv: 'Default description (SWE)',
    default_description_en: 'Default description (ENG)',
    article: 'Article',
    invert: 'Invert the amount',
  },
  type: {
    system: 'System',
    manual: 'Manual',
  },
  price_type: {
    fixed: 'fixed',
    hourly: 'hourly',
  },
  source: {
    billing_plan_base_fee: 'Billing plan base fee',
    billing_plan_separate_fee: 'Billing plan separate fee',
    reported_time: 'Reported time',
    manual: 'Manual',
    expense: 'Outlays',
    extra_time: 'Additional tasks',
  },
  empty: 'No invoice item templates',
  tooltip: {
    destroy: 'Delete this item',
  },
  confirm: {
    destroy: {
      title: 'Delete invoice item template',
      text: 'Are you sure you want to delete this invoice item template?',
    },
  },
};
