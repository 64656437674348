import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { debounce } from 'perfect-debounce';
import { IServicePreviewResource } from '@/types/Service';
import api from '@/services/api';
import { Options } from 'ky';

type GetServicesHooks = {
  onSet?: (data: IServicePreviewResource[]) => IServicePreviewResource[];
  onError?: (error: unknown) => void;
  onFinally?: () => void;
};

export default function useServices() {
  const { t } = useI18n({ useScope: 'global' });

  const priceOptions = computed(() => [
    { value: 'fixed', label: t('service.price_type.fixed') },
    { value: 'hourly', label: t('service.price_type.hourly') },
  ]);

  const invoiceFrequencyOptions = computed(() => [
    //{ value: null, label: t('service.invoice_frequency.once') },
    { value: 'monthly', label: t('service.invoice_frequency.monthly') },
    { value: 'quarterly', label: t('service.invoice_frequency.quarterly') },
    { value: 'semi-annually', label: t('service.invoice_frequency.semi-annually') },
    { value: 'annually', label: t('service.invoice_frequency.annually') },
  ]);

  const services = ref<IServicePreviewResource[]>([]);

  const servicesLoading = ref(false);
  async function getServices(options?: Options, hooks?: GetServicesHooks) {
    try {
      servicesLoading.value = true;
      const response = await api.services.list(options);
      services.value = hooks?.onSet ? hooks.onSet(response.data) : response.data;
    } catch (error) {
      console.error(error);
      if (hooks?.onError) {
        hooks?.onError(error);
      }
    } finally {
      servicesLoading.value = false;
      if (hooks?.onFinally) {
        hooks?.onFinally();
      }
    }
  }

  const onSearchServices = debounce(async (search: string, loading: (isLoading: boolean) => void) => {
    if (search.length <= 1) return;
    loading(true);
    await getServices({
      searchParams: {
        search,
        without_pagination: 1,
      },
    });
    loading(false);
  }, 300);

  return {
    priceOptions,
    invoiceFrequencyOptions,
    services,
    servicesLoading,
    getServices,
    onSearchServices,
  };
}
