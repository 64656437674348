export default {
  index: {
    title: 'Mina uppgifter',
    help: '',
    create: 'Planera uppgift',
  },
  create: {
    title: 'Lägg till intern tid',
  },
  edit: {
    title: 'Redigera intern tid',
  },
  settings: {
    title: 'Intern tid',
    help: 'På dessa sidor gör ni inställningar för tidrapportering på interna tidskoder. Dessa byggs i en hierarki där högsta nivån är Interntidstyper (normalt frånvaro och intern arbetstid). Nästa nivå är kategorier där ni kan skapa undergrupper, t.ex. föräldraledigt. Slutligen sätter ni interntidskoder som de anställda kommer tidrapportera på, t.ex. Vård av barn.',
  },
  attributes: {
    activity_type: 'Typ',
    activity_group: 'Kategori',
    activity_template: 'Interntidskod',
    assigned: 'Tilldelad',
    comment: 'Kommentar',
    start_date: 'Start-datum',
    end_date: 'Slut-datum',
  },
  empty: 'Inga uppgifter',
  types: {
    index: {
      title: 'Interntidstyper',
      help: 'Typer används som översta avskiljare och vanligaste inställningen är frånvaro och intern arbetstid',
      create: 'Skapa Interntidstyp',
    },
    create: {
      title: 'Skapa Interntidstyp',
    },
    edit: {
      title: 'Redigera Interntidstyp: {name}',
    },
    empty: 'Inga Interntidstyper',
    attributes: {
      name: 'Namn',
      groups_count: 'Antal',
    },
    tooltip: {
      edit: 'Redigera Interntidstyp "{name}"',
      destroy: 'Radera Interntidstyp "{name}"',
    },

    confirm: {
      destroy: {
        title: 'Radera Interntidstyp "{name}',
        text: 'Är du säker att du vill radera Interntidstyp <strong>{name}</strong> och dess kategoier?',
      },
    },
  },
  groups: {
    index: {
      title: 'Interntidskategorier',
      help: 'Kategorier används för att gruppera typer av interntid. Exempel på kategorier med olika interntidskoder kan vara, Marknad, Ledning, Föräldraledigheter.',
      create: 'Skapa interntidskategori',
    },
    create: {
      title: 'Skapa interntidskategori',
    },
    edit: {
      title: 'Redigera interntidskategori: {name}',
    },
    empty: 'Ingen interntidskategori',
    type: {
      internal_project: 'Internt projekt',
      independent: 'Oberoende',
      absence: 'Frånvaro',
      internal_work: 'Internt arbete',
    },
    attributes: {
      name: 'Namn',
      type: 'Typ',
      templates_count: 'Antal',
    },
    tooltip: {
      edit: 'Redigera "{name}" aktivitetskategori',
      destroy: 'Radera "{name}" aktivitetskategori',
      cant_be_deleted: 'Gruppen har mallar och kan inte tas bort',
    },

    confirm: {
      destroy: {
        title: 'Radera aktivitetskategori',
        text: 'Är du säker att du vill radera aktivitetskategori <strong>{name}</strong>?',
      },
    },
  },
  templates: {
    index: {
      title: 'Interntidskoder',
      help: 'Här lägger ni upp de tidkoder som anställda ska rapportera intern tid på. Varje tidkod ska hänfyras till typ och kategori. Ni kan också sätta tidsregler för hur anställda kan tidraportera. T.ex. semester kan endast rapporteras heldag. Ni kan också ange om användare ska ange Textinfo på sin tidsregistrering. Eller ange ett förbestämt värde från en dropdown',
      create: 'Skapa interntidskod',
    },
    create: {
      title: 'Skapa interntidskod',
      help: '',
    },
    edit: {
      title: 'Redigera interntidskod: {name}',
      help: '',
    },

    empty: 'Inga interntidskoder',

    attributes: {
      name: 'Namn',
      type: 'Typ',
      group: 'Kategori',
      duration_rule: 'Tidsregler',
      meta_input: 'Extra info',
      comment_field_type: 'Kommentarfältstyp',
      free_text: 'Fritext',
      dropdown: 'Dropdown',
      add_option: 'Lägg till val',
      include_in_planning: 'Auto-inkludera i planering',
      default_frequency: 'Standardfrekvens',
      order: 'Ordning',
      salary_code: 'Löneid',
    },

    tooltip: {
      edit: 'Redigera interntidskod "{name}"',
      destroy: 'Radera interntidskod "{name}"',
    },

    confirm: {
      destroy: {
        title: 'Radera interntidskod',
        text: 'Är du säker att du vill radera interntidskod <strong>{name}</strong>?',
      },
    },

    duration_rule: {
      none: 'Inga begränsningar - Standard',
      available_all_day: 'Max en arbetsdag - Interntid',
      available_time_percentage_range: 'Del av dag - Föräldraledig',
      only_all_day: 'Endast hel dag - Semester',
    },
  },
  meta_input: {
    attributes: {
      label: 'Rubrik',
      is_required: 'Obligatorisk',
      type: 'Datatyp',
    },
    type: {
      input_text: 'fritext',
    },
    tooltip: {
      destroy: 'Radera extra info "{name}"',
    },
    confirm: {
      destroy: {
        title: 'Radera extra info ',
        text: 'Är du säker att du vill radera extra info <strong>{name}</strong>?',
      },
    },
  },
};
